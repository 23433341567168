import React from 'react';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, StackingColumnSeries, Tooltip } from '@syncfusion/ej2-react-charts';

export default class StackChart extends React.Component {
    constructor(props) {
        super(props);

        let max = 0;
        this.props.elements[0].dataSource.forEach(d => {
            max = Math.max(max, d.y);
        })

        this.stackedPrimaryXAxis = {
            majorGridLines: { width: 0 },
            minorGridLines: { width: 0 },
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
            interval: 1,
            lineStyle: { width: 0 },
            labelIntersectAction: 'Rotate45',
            valueType: 'Category',
        };

        this.stackedPrimaryYAxis = {
            lineStyle: { width: 0 },
            minimum: 0,
            maximum: max + 50,
            interval: 50,
            majorTickLines: { width: 0 },
            majorGridLines: { width: 1 },
            minorGridLines: { width: 1 },
            minorTickLines: { width: 0 },
            labelFormat: '{value}',
          };

        this.state = { };
    }

    render() {

        return(
            <ChartComponent
                id="charts"
                primaryXAxis={this.stackedPrimaryXAxis}
                primaryYAxis={this.stackedPrimaryYAxis}
                width={this.props.width}
                height={this.props.height}
                chartArea={{ border: { width: 0 } }}
                tooltip={{ enable: true }}
                background={'transparent'}
                legendSettings={{ background: 'white' }}
                >
                <Inject services={[StackingColumnSeries, Category, Legend, Tooltip]} />
                <SeriesCollectionDirective>
                    {this.props.elements.map((item, index) => <SeriesDirective key={index} {...item} />)}
                </SeriesCollectionDirective>
            </ChartComponent>
        );
    }
}