import React, { useEffect } from "react";
import "./style.css";
import "./bootstrap.min.css";
import "./owl.carousel.css";
import "./owl.theme.css";
import "./owl.transitions.css";
import "./animate.css";
import "./preloader.css";
import NavbarMain from "../../components/NavbarMain";

export default class Terms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: false,
    };
  }

  handleResize() {
    this.setState({ bannerHeight: window.innerHeight });
    if (document.getElementById("navbar")) {
      if (window.innerWidth < 992) {
        this.setState({ isMobile: true });
      } else {
        this.setState({ isMobile: false, isMenuOpen: false });
      }
    }
  }

  componentDidMount() {
    window.document.title = "PersonalDoc - Condizioni di Utilizzo";
    window.addEventListener("resize", this.handleResize.bind(this));
    this.handleResize();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize.bind(this));
  }

  render() {
    return (
      <div
        id="main_scroll"
        className="mainBody"
        style={{ width: "100%", height: "100%", backgroundColor: "white" }}
      >
        <NavbarMain noButtons />
        <link
          rel="stylesheet"
          href="bootstrap-4.1.1-dist/css/bootstrap.min.css"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
          integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
          crossOrigin="anonymous"
        />

        <header id="home">
          <div className="banner" style={{ height: 300 }}>
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                height: 300,
                right: 0,
                backgroundColor: "black",
                opacity: 0.55,
              }}
            />
            <div
              className="container position-relative"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {!this.state.isMobile && (
                <div
                  style={{
                    position: "absolute",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1100,
                    backgroundColor: "black",
                    borderRadius: 10,
                    right: 30,
                    bottom: 30,
                    paddingTop: 5,
                    paddingLeft: 30,
                    paddingRight: 30,
                    boxShadow: "0px 2px 4px black",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(
                      "https://www.personaldoc-app.com/docs/terms.pdf",
                      "_blank"
                    );
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      marginBottom: 5,
                    }}
                  >
                    Scarica
                  </p>
                </div>
              )}
              <div
                className="row"
                style={{
                  zIndex: 1000,
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  widht: "100%",
                }}
              >
                <div className="col-md-8 offset-md-2" style={{ width: "100%" }}>
                  <div className="caption">
                    <h1
                      style={{
                        paddingTop: 100,
                        paddingBottom: 80,
                        color: "white",
                        fontWeight: "bold",
                        fontFamily: `url(${require("../../fonts/Nexa.ttf")})`,
                        fontSize: this.state.isMobile ? 20 : 30,
                      }}
                    >
                      Condizioni Generali di Servizio
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div
          style={{
            width: "85%",
            paddingLeft: "15%",
            paddingTop: 50,
            paddingBottom: 100,
            backgroundColor: "white",
          }}
        >
          <div className="WordSection1">
            <p
              className="MsoNormal"
              align="center"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "0in",
                textAlign: "center",
                lineHeight: "115%",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                >
                  Premesso che
                </span>
              </b>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
                lineHeight: "115%",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                (A)
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;
                </span>
              </span>
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                I presenti termini e condizioni generali di servizio (di seguito
                “<b>Termini e Condizioni</b>”) disciplinano l’utilizzo della
                piattaforma informatica gestita da Zanc S.r.l., società a
                responsabilità limitata con sede legale in Viale Nino Bixio n.
                95, 31100, Treviso, C.F., P.IVA e n. di iscrizione al Registro
                delle Imprese di Treviso-Belluno 05371470260 (“<b>Zanc</b>”);
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
                lineHeight: "115%",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                (B)
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;
                </span>
              </span>
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                Zanc ha creato una piattaforma <i>online</i> al fine di
                facilitare l’interazione paziente-professionista. In
                particolare, attraverso il portale “PersonalDoc” (“
                <b>PersonalDoc</b>”), il Professionista (come di seguito
                definito) potrà gestire agevolmente il rapporto con gli Utenti
                (come di seguito definiti) e questi ultimi potranno cercare
                professionisti sanitari tra tutti coloro iscritti a PersonalDoc,
                prenotare e trovare la disponibilità di un appuntamento in poche
                ore, pagare in modo sicuro e chattare con lo specialista di
                riferimento condividendo documenti e informazioni; e
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
                lineHeight: "115%",
              }}
            >
              <a name="_Ref138163230">
                <span
                  lang="IT"
                  style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                >
                  (C)
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;{" "}
                  </span>
                </span>
                <span
                  lang="IT"
                  style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                >
                  i presenti Termini e Condizioni sono consultabili attraverso
                  l’apposita sezione presente sul sito web di PersonalDoc al
                  seguente link “<i>https://www.personaldoc-app.it</i>” (il “
                  <b>Sito Web</b>”) e disponibili in favore degli Utenti e dei
                  Professionisti (come di seguito definiti) per la riproduzione
                  e conservazione ai sensi dell’art. 12, D.lgs. n. 70/2003.
                </span>
              </a>
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                {" "}
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "0in",
                textAlign: "justify",
                lineHeight: "115%",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                >
                  Tutto ciò premesso, si conviene quanto segue
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "0in",
                textAlign: "justify",
                lineHeight: "115%",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                >
                  Articolo 1 – Definizioni
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "0in",
                lineHeight: "115%",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                I termini e le espressioni in lettera maiuscola utilizzati nei
                Termini e Condizioni hanno il significato ad essi di seguito
                attribuito.
              </span>
            </p>
            <table
              className="MsoTableGrid"
              border={0}
              cellSpacing={0}
              cellPadding={0}
              width={643}
              style={{
                width: "min(481.95pt, 90%)",
                borderCollapse: "collapse",
                border: "none",
              }}
            >
              <tbody>
                <tr>
                  <td
                    width={149}
                    valign="top"
                    style={{
                      width: "111.75pt",
                      padding: "0in 5.4pt 0in 5.4pt",
                    }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginBottom: "0in",
                        textAlign: "left",
                        lineHeight: "115%",
                      }}
                    >
                      <b>
                        <span
                          lang="IT"
                          style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                        >
                          “Codice del Consumo”
                        </span>
                      </b>
                    </p>
                  </td>
                  <td
                    width={494}
                    valign="top"
                    style={{ width: "370.2pt", padding: "0in 5.4pt 0in 5.4pt" }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginBottom: "0in",
                        textAlign: "left",
                        lineHeight: "115%",
                      }}
                    >
                      <span
                        lang="IT"
                        style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                      >
                        ha il significato di cui al paragrafo{" "}
                      </span>
                      <span
                        lang="IT"
                        style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                      >
                        3.4
                      </span>
                      <span
                        lang="IT"
                        style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                      >
                        ;{" "}
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    width={149}
                    valign="top"
                    style={{
                      width: "111.75pt",
                      padding: "0in 5.4pt 0in 5.4pt",
                    }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginBottom: "0in",
                        textAlign: "left",
                        lineHeight: "115%",
                      }}
                    >
                      <b>
                        <span
                          lang="IT"
                          style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                        >
                          “Contratto”
                        </span>
                      </b>
                    </p>
                  </td>
                  <td
                    width={494}
                    valign="top"
                    style={{ width: "370.2pt", padding: "0in 5.4pt 0in 5.4pt" }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginBottom: "0in",
                        textAlign: "left",
                        lineHeight: "115%",
                      }}
                    >
                      <span
                        lang="IT"
                        style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                      >
                        negozio giuridico avente per oggetto i servizi concluso
                        tra Zanc e gli Utenti o i Professionisti (come di
                        seguito definiti) mediante l’accettazione dei presenti
                        Termini e Condizioni;
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    width={149}
                    valign="top"
                    style={{
                      width: "111.75pt",
                      padding: "0in 5.4pt 0in 5.4pt",
                    }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginBottom: "0in",
                        textAlign: "left",
                        lineHeight: "115%",
                      }}
                    >
                      <b>
                        <span
                          lang="IT"
                          style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                        >
                          “Informativa Privacy”
                        </span>
                      </b>
                    </p>
                  </td>
                  <td
                    width={494}
                    valign="top"
                    style={{ width: "370.2pt", padding: "0in 5.4pt 0in 5.4pt" }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginBottom: "0in",
                        textAlign: "left",
                        lineHeight: "115%",
                      }}
                    >
                      <span
                        lang="IT"
                        style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                      >
                        si intende l’informativa privacy di Zanc
                      </span>
                      <span lang="IT"> </span>
                      <span
                        lang="IT"
                        style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                      >
                        reperibile al seguente{" "}
                        <a href="/#/main/privacy/" target="_blank">
                          link
                        </a>
                        ;
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    width={149}
                    valign="top"
                    style={{
                      width: "111.75pt",
                      padding: "0in 5.4pt 0in 5.4pt",
                    }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginBottom: "0in",
                        textAlign: "left",
                        lineHeight: "115%",
                      }}
                    >
                      <b>
                        <span
                          lang="IT"
                          style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                        >
                          “Offerte”
                        </span>
                      </b>
                    </p>
                  </td>
                  <td
                    width={494}
                    valign="top"
                    style={{ width: "370.2pt", padding: "0in 5.4pt 0in 5.4pt" }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginBottom: "0in",
                        textAlign: "left",
                        lineHeight: "115%",
                      }}
                    >
                      <span
                        lang="IT"
                        style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                      >
                        ha il significato di cui all’articolo 7 (
                        <i>Costo per il Professionista</i>);
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    width={149}
                    valign="top"
                    style={{
                      width: "111.75pt",
                      padding: "0in 5.4pt 0in 5.4pt",
                    }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginBottom: "0in",
                        textAlign: "left",
                        lineHeight: "115%",
                      }}
                    >
                      <b>
                        <span
                          lang="IT"
                          style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                        >
                          “PersonalDoc”
                        </span>
                      </b>
                    </p>
                  </td>
                  <td
                    width={494}
                    valign="top"
                    style={{ width: "370.2pt", padding: "0in 5.4pt 0in 5.4pt" }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginBottom: "0in",
                        textAlign: "left",
                        lineHeight: "115%",
                      }}
                    >
                      <span
                        lang="IT"
                        style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                      >
                        ha il significato di cui alla Premessa (B);
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    width={149}
                    valign="top"
                    style={{
                      width: "111.75pt",
                      padding: "0in 5.4pt 0in 5.4pt",
                    }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginBottom: "0in",
                        textAlign: "left",
                        lineHeight: "115%",
                      }}
                    >
                      <b>
                        <span
                          lang="IT"
                          style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                        >
                          “Professionisti”
                        </span>
                      </b>
                    </p>
                  </td>
                  <td
                    width={494}
                    valign="top"
                    style={{ width: "370.2pt", padding: "0in 5.4pt 0in 5.4pt" }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginBottom: "0in",
                        textAlign: "left",
                        lineHeight: "115%",
                      }}
                    >
                      <span
                        lang="IT"
                        style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                      >
                        I professionisti regolarmente iscritti ai rispettivi
                        Albi professionali che esercitano la professione
                        sanitaria, quali, <i>inter alia</i>, psicologi,
                        fisioterapisti, osteopati, nutrizionisti, logopedisti,
                        educatori professionali, podologhi, terapisti
                        occupazionali, medici in libera professione (psichiatri,
                        dietologi, dermatologi, ginecoloogi e cardiologi.
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    width={149}
                    valign="top"
                    style={{
                      width: "111.75pt",
                      padding: "0in 5.4pt 0in 5.4pt",
                    }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginBottom: "0in",
                        textAlign: "left",
                        lineHeight: "115%",
                      }}
                    >
                      <b>
                        <span
                          lang="IT"
                          style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                        >
                          “Sito Web”
                        </span>
                      </b>
                    </p>
                  </td>
                  <td
                    width={494}
                    valign="top"
                    style={{ width: "370.2pt", padding: "0in 5.4pt 0in 5.4pt" }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginBottom: "0in",
                        textAlign: "left",
                        lineHeight: "115%",
                      }}
                    >
                      <span
                        lang="IT"
                        style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                      >
                        ha il significato di cui alla Premessa{" "}
                      </span>
                      <span
                        lang="IT"
                        style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                      >
                        (C)
                      </span>
                      <span
                        lang="IT"
                        style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                      >
                        ;
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    width={149}
                    valign="top"
                    style={{
                      width: "111.75pt",
                      padding: "0in 5.4pt 0in 5.4pt",
                    }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginBottom: "0in",
                        textAlign: "left",
                        lineHeight: "115%",
                      }}
                    >
                      <b>
                        <span
                          lang="IT"
                          style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                        >
                          “Termini e Condizioni”
                        </span>
                      </b>
                    </p>
                  </td>
                  <td
                    width={494}
                    valign="top"
                    style={{ width: "370.2pt", padding: "0in 5.4pt 0in 5.4pt" }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginBottom: "0in",
                        textAlign: "left",
                        lineHeight: "115%",
                      }}
                    >
                      <span
                        lang="IT"
                        style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                      >
                        ha il significato di cui alla Premessa (A){" "}
                      </span>
                      <span
                        lang="IT"
                        style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                      >
                        ;
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    width={149}
                    valign="top"
                    style={{
                      width: "111.75pt",
                      padding: "0in 5.4pt 0in 5.4pt",
                    }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginBottom: "0in",
                        textAlign: "left",
                        lineHeight: "115%",
                      }}
                    >
                      <b>
                        <span
                          lang="IT"
                          style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                        >
                          “Utente”
                        </span>
                      </b>
                    </p>
                  </td>
                  <td
                    width={494}
                    valign="top"
                    style={{ width: "370.2pt", padding: "0in 5.4pt 0in 5.4pt" }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginBottom: "0in",
                        textAlign: "left",
                        lineHeight: "115%",
                      }}
                    >
                      <span
                        lang="IT"
                        style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                      >
                        la persona fisica che si iscrive al, e/o utilizza il,
                        portale PersonalDoc per usufruire delle sue
                        funzionalità; e
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    width={149}
                    valign="top"
                    style={{
                      width: "111.75pt",
                      padding: "0in 5.4pt 0in 5.4pt",
                    }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginBottom: "0in",
                        textAlign: "left",
                        lineHeight: "115%",
                      }}
                    >
                      <b>
                        <span
                          lang="IT"
                          style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                        >
                          “Zanc”
                        </span>
                      </b>
                    </p>
                  </td>
                  <td
                    width={494}
                    valign="top"
                    style={{ width: "370.2pt", padding: "0in 5.4pt 0in 5.4pt" }}
                  >
                    <p
                      className="MsoNormal"
                      style={{
                        marginBottom: "0in",
                        textAlign: "left",
                        lineHeight: "115%",
                      }}
                    >
                      <span
                        lang="IT"
                        style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                      >
                        ha il significato di cui alla Premessa (A){" "}
                      </span>
                      <span
                        lang="IT"
                        style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                      >
                        .
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p
              className="MsoNormal"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "0in",
                textAlign: "justify",
                lineHeight: "115%",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                >
                  Articolo 2 – Interpretazione{" "}
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "0in",
                textAlign: "justify",
                lineHeight: "115%",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                Le premesse ai presenti Termini e Condizioni costituiscono parte
                integrante ed essenziale degli stessi.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "0in",
                textAlign: "justify",
                lineHeight: "115%",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                >
                  Articolo 3 – Accettazione dei Termini e Condizioni
                </span>
              </b>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
                lineHeight: "115%",
                display: "none",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                1.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
                lineHeight: "115%",
                display: "none",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                2.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".25in",
                textIndent: "-.25in",
                lineHeight: "115%",
                display: "none",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                1.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".25in",
                textIndent: "-.25in",
                lineHeight: "115%",
                display: "none",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                2.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".25in",
                textIndent: "-.25in",
                lineHeight: "115%",
                display: "none",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                3.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textIndent: "-28.35pt",
                lineHeight: "115%",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                3.1.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                I presenti Termini e Condizioni{" "}
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "64.35pt",
                textAlign: "justify",
                textIndent: "-.5in",
                lineHeight: "115%",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                (i)
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                devono essere consultati presso l’apposita sezione sul Sito Web,
                prima della registrazione del proprio <i>account</i> riservato;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "64.35pt",
                textAlign: "justify",
                textIndent: "-.5in",
                lineHeight: "115%",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                (ii)
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                devono essere espressamente ed incondizionatamente accettati e
                sottoscritti;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "64.35pt",
                textAlign: "justify",
                textIndent: "-.5in",
                lineHeight: "115%",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                (iii)
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                stabiliscono un Contratto vincolante tra Zanc e gli Utenti e/o i
                Professionisti; e
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "64.35pt",
                textAlign: "justify",
                textIndent: "-.5in",
                lineHeight: "115%",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                (iv)
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                si applicano a tutti gli Utenti e Professionisti che utilizzano
                PersonalDoc.
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                3.2.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                Zanc potrà modificare, aggiornare e/o integrare i presenti
                Termini e Condizioni in qualsiasi momento. A tal proposito, Zanc
                si impegna a darne pronta comunicazione tramite il Sito Web.
                Qualora l’Utente o il Professionista dovessero continuare ad
                utilizzare PersonalDoc anche dopo tali modifiche, aggiornamenti
                e/o integrazioni, costoro saranno a conoscenza del fatto che ciò
                equivarrà ad un’espressa presa di conoscenza e accettazione
                delle suddette modifiche, aggiornamenti e/o integrazioni.
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "#1B2734",
                }}
              >
                3.3.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                I presenti Termini e Condizioni e i Servizi offerti attraverso
                il Sito Web e PersonalDoc, rappresentano un’offerta al pubblico
                ai sensi dell’articolo 1336 del Codice Civile Italiano; è dunque
                necessaria l’accettazione rispettivamente dell’Utente e del
                Professionista affinché il Contratto possa considerarsi
                concluso. Tale accettazione dovrà essere manifestata in fase di
                registrazione e creazione del proprio profilo <i>online</i>,
                mediante l’esatta compilazione delle apposite sezioni. Sarà
                inoltre necessario accettare espressamente l’Informativa
                Privacy, consultabile anch’essa presso il Sito Web.
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
              }}
            >
              <a name="_Ref138236165">
                <span
                  lang="IT"
                  style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                >
                  3.4.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  </span>
                </span>
                <span
                  lang="IT"
                  style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                >
                  Il Contratto è, <i>inter alia</i>, regolato dalle disposizioni
                  di cui al D. Lgs. n. 206/2005 (“<b>Codice del Consumo</b>”).
                </span>
              </a>
              <a name="_Ref138013334" />
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                3.5.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                La protezione dei dati personali trattati da PersonalDoc è
                sottoposta alla disciplina del D.Lgs. 30/06/2003 n. 196
                ss.mm.ii., e del Regolamento UE 2016/679, successive modifiche
                ed integrazioni. Per maggiori informazioni, si rimanda
                all’Informativa Privacy presente sul Sito Web e PersonalDoc.{" "}
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                3.6.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                Il Sito Web si serve di <i>cookies</i>. Per maggiori
                informazioni al riguardo si rimanda al paragrafo 11
                dell’Informativa Privacy descritta presso l’apposita sezione del
                Sito Web e PersonalDoc.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "0in",
                textAlign: "justify",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    lineHeight: "115%",
                    color: "black",
                  }}
                >
                  Articolo 4 – Accesso ai Servizi
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "0in",
                textAlign: "justify",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                Per utilizzare PersonalDoc, gli Utenti e i Professionisti
                dovranno registrarsi sul Sito Web utilizzando una valida e-mail
                ed una <i>password</i>. Inoltre, sarà possibile accedere a
                PersonalDoc anche scaricando l’apposita applicazione da Google
                Play o Apple Store.{" "}
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "0in",
                textAlign: "justify",
                lineHeight: "115%",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                >
                  Articolo 5 – Funzionamento di PersonalDoc
                </span>
              </b>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
                lineHeight: "115%",
                display: "none",
                background: "white",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                4.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
                lineHeight: "115%",
                display: "none",
                background: "white",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                5.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "#1B2734",
                }}
              >
                5.1.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                PersonalDoc è un portale creato a favore di tutti i
                Professionisti, per consentire loro di
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "11.5pt",
                  lineHeight: "115%",
                  fontFamily: "Roboto",
                  color: "#1B2734",
                }}
              ></span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "#1B2734",
                }}
              >
                organizzare la propria agenda di appuntamenti, rendersi
                reperibili e visibili sul territorio e interagire con pazienti
                attuali o potenziali
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                .
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                {" "}
                Attraverso PersonalDoc, il Professionista presenta i servizi
                offerti, indica la propria specializzazione e precisa in modo
                trasparente le tariffe di ciascun servizio, così da garantire
                agli Utenti una scelta informata e consapevole.{" "}
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "#1B2734",
                }}
              >
                5.2.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                L’apposita sezione “<i>Agenda</i>” predisposta sul portale
                PersonalDoc consente al Professionista di indicare le
                disponibilità temporali, cosicché l’Utente potrà prenotare il
                proprio appuntamento. Si noti che il servizio di prenotazione
                <i> online</i> delle visite è un servizio reso da Zanc al
                Professionista; pertanto, non si instaura alcun rapporto
                contrattuale tra Zanc e l’Utente, ma esclusivamente tra l’Utente
                ed il Professionista, il quale è l’unico responsabile della
                propria attività professionale.
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                5.3.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                Attraverso il servizio
                <i>chat</i>, il Professionista potrà comunicare con gli Utenti,
                condividere documenti, fogli di lavoro, referti e organizzare
                incontri <i>online</i> e <i>videocall</i>. Inoltre, il
                Professionista potrà decidere di attivare le notifiche per sé e
                per gli Utenti, così da ricordare loro gli appuntamenti, il
                pagamento delle prestazioni, etc..{" "}
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                5.4.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                Si precisa che gli incontri <i>online</i> e le <i>videocall</i>{" "}
                tra Utenti e Professionisti si terranno su piattaforma Google
                Meet, il cui utilizzo è regolato dai relativi termini e
                condizioni.{" "}
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "0in",
                textAlign: "justify",
                lineHeight: "115%",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                >
                  Articolo 6 – Modalità di pagamento del Professionista
                </span>
              </b>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
                lineHeight: "115%",
                display: "none",
                background: "white",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                6.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <a name="_Ref138239096">
                <span
                  lang="IT"
                  style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                >
                  6.1.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    lineHeight: "115%",
                    color: "black",
                  }}
                >
                  Il
                </span>
              </a>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                {" "}
                pagamento della prestazione fornita dal Professionista dovrà
                avvenire in via anticipata, entro tre giorni dalla data
                dell’appuntamento con l’Utente.
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                {" "}
                L’Utente potrà scegliere il metodo di pagamento che predilige
                tra quelli previsti dal portale PersonalDoc. Si precisa che{" "}
                <b>(i)</b> i dati di pagamento (<i>i.e.</i>, numero carta di
                credito, data di scadenza, banca di appoggio) sono gestiti
                direttamente da Stripe, per quanto riguarda gli Utenti (nei casi
                in cui non vengano concordate, tra Utente e Professionista,
                modalità di pagamento diverse da Stripe), e PayPal, per quanto
                riguarda i Professionisti, con reindirizzamento verso le
                relative pagine web, e <b>(ii)</b> Zanc non ha accesso ai
                suddetti dati di pagamento, neanche potenzialmente.
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                {" "}
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                6.2.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                Attraverso l’utilizzo di piattaforme esterne, basate su tecnologia API,
                PersonalDoc consente l’emissione e l’invio delle Fatture
                Elettroniche P.A. e delle Fatture Elettroniche B2B (
                <i>Business to Business</i>), calcolando automaticamente la
                quota previdenziale assistenziale dovuta per ciascuna categoria
                di Professionista. Inoltre, il Professionista avrà la
                possibilità di escludere la suddetta quota qualora la fattura
                debba essere emessa nei confronti di Utenti per i quali è
                necessario aggiungere l’IVA.
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                6.3.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                Nel caso in cui l’Utente abbia previamente inserito i dati per
                la detrazione fiscale, PersonalDoc, per il tramite delle
                suddette piattaforme esterne, trasmetterà automaticamente
                le relative fatture al Sistema Tessera Sanitaria, salvo diversa
                indicazione dell’Utente trasmessa al relativo Professionista. Il
                Professionista dovrà semplicemente inserire l’apposita{" "}
                <i>password</i>
                personale e spuntare una casella per inviare i dati degli Utenti
                selezionati al portale del Sistema Tessera Sanitaria.
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                6.4.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                Resta inteso che (i) al fine dell’utilizzo delle piattaforme esterne di cui ai paragrafi 6.2 e 6.3, rispettivamente, il
                Professionista o l’Utente, a seconda dei casi, riceveranno un’apposita e-mail dal gestore della suddetta
                piattaforma al fine dell’utilizzo della stessa, e (ii) tali piattaforme esterne sono regolate da autonomi termini e
                condizioni.
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                6.5.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                Nei limiti di quanto già specificato nel precedente Paragrafo
                6.1, Stripe si occuperà della riconciliazione bancaria
                automatica degli accrediti, ossia verifica che le registrazioni
                contabili corrispondano ai dati riportati nell’estratto conto.
                Attraverso la riconciliazione degli incassi, Stripe abbina
                quindi il pagamento effettuato da ciascun Utente ad ogni entrata
                registrata dal conto corrente del Professionista e, nel momento
                in cui l’associazione è verificata, emette automaticamente la
                fattura a nome e per conto del Professionista.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "0in",
                textAlign: "justify",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    lineHeight: "115%",
                    color: "black",
                  }}
                >
                  Articolo 7 – Costo per il Professionista
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "0in",
                textAlign: "justify",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                A seconda del periodo di tempo durante il quale il
                Professionista vorrà usufruire dei servizi offerti da
                PersonalDoc, potrà scegliere tra tre offerte di abbonamento (le
                “<b>Offerte</b>”), ossia:
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <a name="_Ref138355604">
                <span
                  lang="IT"
                  style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                >
                  (i)
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    lineHeight: "115%",
                    color: "black",
                  }}
                >
                  Euro 60,00 + 22% IVA per un periodo di 3 mesi;
                </span>
              </a>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                (ii)
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                Euro 107,00 + 22% IVA per un periodo di 6 mesi; e
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                (iii)
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                Euro 186,00 + 22% IVA per un periodo di 12 mesi.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "0in",
                textAlign: "justify",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                Per maggiori dettagli si rimanda a quanto specificato al
                paragrafo{" "}
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                6.1
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                {" "}
                e all’apposita sezione su Sito Web.{" "}
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "0in",
                textAlign: "justify",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                Zanc potrà modificare, aggiornare e/o integrare le Offerte in
                qualsiasi momento. A tal proposito, Zanc si impegna a darne
                pronta comunicazione tramite il Sito Web e, quando
                ragionevolmente utile per Zanc stessa, aggiornare anche il
                presente Articolo 7 dei presenti Termini e Condizioni.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "0in",
                textAlign: "justify",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    lineHeight: "115%",
                    color: "black",
                  }}
                >
                  Articolo 8 – Funzionalità e obblighi a carico dell’Utente
                </span>
              </b>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
                lineHeight: "115%",
                display: "none",
                background: "white",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                7.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
                lineHeight: "115%",
                display: "none",
                background: "white",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                8.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                8.1.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                Attraverso la registrazione al portare PersonalDoc, l’Utente
                potrà <b>(i)</b> prenotare un appuntamento con il Professionista
                scelto, <b>(ii)</b> pagare la prestazione in modo sicuro,{" "}
                <b>(iii)</b>
                contattare il Professionista utilizzando l’apposito servizio{" "}
                <i>chat</i>, <b>(iv)</b>
                condividere documenti e informazioni, e <b>(v)</b> gestire i
                propri appuntamenti, effettuando eventuali modifiche o
                cancellazioni.
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <a name="_Ref138082576">
                <span
                  lang="IT"
                  style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                >
                  8.2.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    lineHeight: "115%",
                    color: "black",
                  }}
                >
                  Tramite il servizio <i>chat </i>del Sito Web o, comunque, per
                  iscritto, l’Utente è tenuto a comunicare la necessità di
                  modificare o cancellare la prenotazione dell’appuntamento con
                  almeno 24 ore di preavviso. Qualora la richiesta di modifica o
                  cancellazione dell’appuntamento venga fatta con meno di 24 ore
                  di preavviso, non sarà possibile effettuare tale cancellazione
                  o modifica e l’Utente sarà in ogni caso tenuto al pagamento di
                  una penale pari al costo della seduta prenotata.
                </span>
              </a>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "0in",
                textAlign: "justify",
                lineHeight: "115%",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                >
                  Articolo 9 – Cancellazione da PersonalDoc
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "0in",
                textAlign: "justify",
                lineHeight: "115%",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                Qualora l’Utente o il Professionista decida di non voler più
                usufruire dei servizi offerti, dovrà cancellare il proprio
                profilo/<i>account</i> da PersonalDoc utilizzando la specifica
                funzione presente nella propria area personale sul suddetto
                portale.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "0in",
                textAlign: "justify",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    lineHeight: "115%",
                    color: "black",
                  }}
                >
                  Articolo 10 – Limitazioni di responsabilità di Zanc{" "}
                </span>
              </b>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
                lineHeight: "115%",
                display: "none",
                background: "white",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                9.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
                lineHeight: "115%",
                display: "none",
                background: "white",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                10.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                10.1.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                Si precisa che Zanc
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "#1B2734",
                }}
              >
                {" "}
                non si assume alcuna responsabilità in relazione alle attività
                svolte dai Professionisti e al rispetto degli impegni da loro
                assunti, né può esercitare una funzione di risoluzione delle
                contestazioni, non avendo a ciò alcun titolo, ed essendo
                esclusivamente un <i>provider</i> di tecnologia informatica.{" "}
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                Pertanto, si invita l’Utente a valutare attentamente il
                Professionista scelto, decidendo autonomamente chi consultare e,
                se necessario, cercando consigli da esperti.{" "}
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                10.2.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                Si precisa inoltre che Zanc non effettua intermediazioni, non
                percepisce percentuali sulle visite da alcun Professionista e
                non distribuisce o rivende alcunché.
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                10.3.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                Zanc si limita a fornire il portale PersonalDoc tramite il quale
                il Professionista organizza la propria agenda di appuntamenti,
                si rende reperibile sul territorio, interagisce con gli Utenti.
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                10.4.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                Il Professionista e l’Utente prendono rispettivamente atto e
                accettano che Zanc non sarà responsabile, in alcun modo e per
                nessun motivo, di eventuali passività, perdite o danni di
                qualsivoglia natura derivanti da o relativi a:{" "}
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "64.35pt",
                textAlign: "justify",
                textIndent: "-.5in",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                (i)
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                manutenzione ordinaria o straordinaria di PersonalDoc eseguita
                da Zanc (e/o dai suoi consulenti) e conseguente interruzione del
                servizio fornito dalla Piattaforma, nonché qualsiasi modifica
                derivante da tale manutenzione; e/o
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "64.35pt",
                textAlign: "justify",
                textIndent: "-.5in",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                (ii)
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  color: "black",
                }}
              >
                qualsiasi danno subito per azioni, omissioni e/o violazioni dei
                presenti Termini e Condizioni da parte degli Utenti o dei
                Professionisti.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "0in",
                textAlign: "justify",
                lineHeight: "115%",
                background: "white",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    lineHeight: "115%",
                    color: "black",
                  }}
                >
                  Articolo 11 – Disabilitazione dell’<i>account</i>{" "}
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "0in",
                textAlign: "justify",
                lineHeight: "115%",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                Zanc potrà temporaneamente o permanentemente disabilitare l’
                <i>account</i> dell’Utente o del Professionista qualora
                <b>(i) </b>
                PersonalDoc venisse utilizzato per perpetrare comportamenti
                inappropriati o illeciti nei confronti di terze parti, inclusi,
                tra gli altri, Utenti o Professionisti, e <b>(ii) </b>venisse
                violata una qualsiasi delle disposizioni dei presenti Termini e
                Condizioni.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "0in",
                textAlign: "justify",
                lineHeight: "115%",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                >
                  Articolo 12 – Diritti di Proprietà Intellettuale
                </span>
              </b>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
                lineHeight: "115%",
                display: "none",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                5
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
                lineHeight: "115%",
                display: "none",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                6
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
                lineHeight: "115%",
                display: "none",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                7
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
                lineHeight: "115%",
                display: "none",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                8
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
                lineHeight: "115%",
                display: "none",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                9
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
                lineHeight: "115%",
                display: "none",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                10
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
                lineHeight: "115%",
                display: "none",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                11
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
                lineHeight: "115%",
                display: "none",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                12
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                12.1
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                Zanc possiede esclusivamente tutti i diritti, i titoli e gli
                interessi relativi al portale PersonalDoc e a tutti i suoi
                elementi, ivi compresi: il<i> software</i>, il codice sorgente e
                oggetto, i dati e i materiali ivi utilizzati o disponibili, il
                design, l’organizzazione del portale.
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                12.2
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                Gli Utenti e i Professionisti, in particolare, a titolo
                esemplificativo e non esaustivo, non potranno in alcun modo:
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "49.65pt",
                textAlign: "justify",
                textIndent: "-21.3pt",
                lineHeight: "115%",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  fontFamily: '"Courier New"',
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                effettuare <i>reverse engineering</i>, decompilare,
                disassemblare, modificare o creare <i>software</i> derivati
                basati sui software di proprietà di Zanc;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "49.65pt",
                textAlign: "justify",
                textIndent: "-21.3pt",
                lineHeight: "115%",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  fontFamily: '"Courier New"',
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                aggirare i sistemi informatici per accedere a sezioni del sito
                senza autorizzazione;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "49.65pt",
                textAlign: "justify",
                textIndent: "-21.3pt",
                lineHeight: "115%",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  fontFamily: '"Courier New"',
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                copiare, conservare, modificare, cambiare, preparare lavori
                derivati o alterare in qualunque modo qualsiasi contenuto di
                Zanc;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "49.65pt",
                textAlign: "justify",
                textIndent: "-21.3pt",
                lineHeight: "115%",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  fontFamily: '"Courier New"',
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                utilizzare qualunque robot, <i>spider</i>, applicazione di
                ricerca/reperimento di siti, o qualunque altro dispositivo,
                processo o mezzo automatico per accedere, recuperare, effettuare{" "}
                <i>scraping </i>o indicizzare qualunque porzione della{" "}
                <i>web app</i> o dei suoi contenuti;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "49.65pt",
                textAlign: "justify",
                textIndent: "-21.3pt",
                lineHeight: "115%",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  fontFamily: '"Courier New"',
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                utilizzare il <i>know how</i>, i materiali, le idee, le
                immagini, i testi, il format, gli script di Zanc per creare
                servizi simili o derivati;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "49.65pt",
                textAlign: "justify",
                textIndent: "-21.3pt",
                lineHeight: "115%",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  fontFamily: '"Courier New"',
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                consentire l’accesso alla propria area riservata da parte di
                persone terze estranee al Contratto;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "49.65pt",
                textAlign: "justify",
                textIndent: "-21.3pt",
                lineHeight: "115%",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  fontFamily: '"Courier New"',
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                divulgare o condividere i contenuti dell’area riservata con
                soggetti terzi estranei al Contratto; e
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "49.65pt",
                textAlign: "justify",
                textIndent: "-21.3pt",
                lineHeight: "115%",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  fontFamily: '"Courier New"',
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                utilizzare il servizio per scopi diversi da quelli dichiarati,
                con o senza fine di lucro.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "0in",
                textAlign: "justify",
                lineHeight: "115%",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                >
                  Articolo 13 – Dichiarazione di nullità parziale
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "0in",
                textAlign: "justify",
                lineHeight: "115%",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                Nel caso in cui una clausola o parte dei presenti Termini e
                Condizioni e/o dell’Informativa Privacy di Zanc dovesse essere
                ritenuta nulla, illecita o illegittima dall’autorità
                giudiziaria, non andrà ad intaccare il resto del Contratto e la
                sua validità ed efficacia.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "0in",
                textAlign: "justify",
                lineHeight: "115%",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{ fontSize: "9.0pt", lineHeight: "115%" }}
                >
                  Articolo 14 – Legge applicabile e foro competente
                </span>
              </b>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
                lineHeight: "115%",
                display: "none",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                13
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
                lineHeight: "115%",
                display: "none",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                14
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  lineHeight: "115%",
                  display: "none",
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                14.1
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                Ogni controversia relativa all'applicazione, esecuzione,
                interpretazione e violazione dei presenti Termini e Condizioni è
                sottoposta alla legge italiana; per quanto non disciplinato nei
                presenti Termini e Condizioni si rinvia, ove applicabile, a
                quanto disposto dal Codice del Consumo.
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                14.2
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                Il Foro competente a dirimere qualsiasi controversia con
                l’Utente riguardante i presenti Termini e Condizioni sarà il
                Tribunale del luogo di residenza o di domicilio del medesimo.
              </span>
            </p>
            <p
              className="MsoListParagraph"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "28.35pt",
                textAlign: "justify",
                textIndent: "-28.35pt",
                lineHeight: "115%",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                14.3
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                Per la risoluzione stragiudiziale delle controversie sorte in
                relazione ai Termini e Condizioni, l’Utente potrà ricorrere alla
                procedura di mediazione di cui al D.lgs. n. 28/2010, ovvero
                potrà ricorrere agli organismi di ADR mediante la piattaforma
                ODR di risoluzione <i>online</i> delle controversie, istituita e
                gestita dalla Commissione Europea, e accessibile tramite il
                seguente link http://ec.europa.eu/consumers/odr/.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "6.0pt",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "0in",
                textAlign: "justify",
                lineHeight: "115%",
              }}
            >
              <span lang="IT" style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
                &nbsp;
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
