
import React from 'react';
import { getServiceName } from '../../utils/consts';

// Styles 
import './style.css';
import './bootstrap.min.css';
import './owl.carousel.css';
import './owl.theme.css';
import './owl.transitions.css';
import './animate.css';
import './preloader.css';

import Cookies from 'universal-cookie';
import requestNewStripeUrl from '../../services/requestNewStripeUrl';
import NavbarMain from '../../components/NavbarMain';

const cookies = new Cookies();

export default class StripeError extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            isLoading: true, 
            isMobile: window.innerWidth < 992,
            bannerHeight: window.innerHeight,

        };
    }

    async componentDidMount() {
        document.title = getServiceName() + ' - Controllo...';

        let stripeAccountId = cookies.get('stripe_account', { path: '/' });

        let res = requestNewStripeUrl(stripeAccountId);
        if (res.result === 'KO') {
            window.location.href = 'https://www.personaldoc-app.com/#/main/home';
        } else if (res.stripe_url) {
            let url = res.stripe_url;
            window.location.href = url;
        } else {
            window.location.href = 'https://www.personaldoc-app.com/#/main/home';
        }
    } 

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {

        this.setState({ bannerHeight: window.innerHeight });

        if (window.innerWidth < 767) {
            this.setState({ isMobile: true });
            return;
        }

        this.setState({ isMobile: false });
    }

    render () {

        return (
            <div className='mainBody' style={{width: '100%', height: '100%'}}>
                
                <header id='home' style={{height: '100%'}}>
                    {!this.state.isMobile && <NavbarMain />}

                    <div className="new-banner" style={{position: 'absolute', top: 0, left: 0, width: '100vw', flex: 1, height: this.state.bannerHeight }}>
                        <div className={this.state.isMobile ? "" : "container"} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div className={this.state.isMobile ? "" : "row"} style={{ zIndex: 1000, display: 'flex', alignItems: 'center', height: '100%' }}>
                                <div className={this.state.isMobile ? "" : "col-md-8 offset-md-2"}>
                                    <div className={this.state.isMobile ? "" : "caption"}>
                                            <div className="loginContainer" style={!this.state.isMobile ? {
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            } : {
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                height: 350,
                                                minHeight: 0,
                                                marginTop: 0,
                                                width: window.innerWidth - 50,
                                                marginLeft: 25,
                                        }}>
                                            <p style={{ color: 'lightgrey', marginTop: 30, fontWeight: 'bold' }}>Si è verificato un errore durante la compilazione dell'account. Attendi di essere reindirizzato...</p>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </header>
            </div>
        );
    }
}
