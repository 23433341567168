import React from 'react';
import translateString from '../../utils/Locales';
import { AiFillMail, AiFillPhone, AiOutlineMail } from 'react-icons/ai';
import { RiSmartphoneFill } from 'react-icons/ri';
import ReactHtmlParser from 'react-html-parser';
import { BsCalendar3Event, BsGenderAmbiguous, BsPersonFill } from 'react-icons/bs';
import { MdSportsBasketball, MdOutlineWork, MdSchool, MdFastfood, MdGroup, MdOutlineSms } from 'react-icons/md';
import { FaChild, FaBrain, FaBookMedical } from 'react-icons/fa';

import './style.css';
import User from '../../images/user.png';

let t = translateString;

export default class MostraPaginaPersonale extends React.Component {
    constructor(props) {
        super(props);

        var servizi = [
            { id: 0, icon: <MdFastfood color="#7B90C4" />, servizio: 'Disturbi alimentari', selezionato: false }, 
            { id: 1, icon: <BsGenderAmbiguous color="#7B90C4" />, servizio: 'Sessuologia', selezionato: false },
            { id: 2, icon: <BsPersonFill color="#7B90C4" />, servizio: 'Analisi della persona', selezionato: false },
            { id: 3, icon: <MdSportsBasketball color="#7B90C4" />, servizio: 'Psicologia dello sport', selezionato: false },
            { id: 4, icon: <MdOutlineWork color="#7B90C4" />, servizio: 'Psicologia del lavoro', selezionato: false },
            { id: 5, icon: <FaChild color="#7B90C4" />, servizio: 'Psicologia pediatrica', selezionato: false },
            { id: 6, icon: <MdSchool color="#7B90C4" />, servizio: 'Psicologia dell\'educazione', selezionato: false },
            { id: 7, icon: <FaBrain color="#7B90C4" />, servizio: 'Neuro psicologia', selezionato: false },
            { id: 8, icon: <FaBookMedical color="#7B90C4" />, servizio: 'Psicologia clinica', selezionato: false },
            { id: 9, icon: <MdGroup color="#7B90C4" />, servizio: 'Psicologia sociale', selezionato: false },
        ];

        if (props.pagina.servizi) {
            props.pagina.servizi.forEach((s) => {
                servizi[s.id].selezionato = true;
            });
        }

        this.state = {
            id: props.pagina.id_psicologo,
            foto: props.pagina.foto, 
            titolo: props.pagina.titolo === '1' ? t('titolo_m') + ' ' : props.pagina.titolo === '2' ? t('titolo_f') + ' ' : '', 
            nome: props.psicologo.nome, 
            cognome: props.psicologo.cognome,
            mail: props.pagina.mail, 
            telefono: props.pagina.telefono, 
            cellulare: props.pagina.cellulare, 
            descrizione: props.pagina.descrizione,
            show_piani: props.pagina.show_piani, 
            servizi,
        }
    }

    componentDidMount() {

    }

    render() {

        return(
            <div>
                <div>
                    <div className='header-pagina'>
                        <img className='profile-image' src={ this.state.foto ? this.state.foto : User } />
                    </div>
                    <div style={{ position: 'relative' }}>
                        <p className='profile-name'>{this.state.titolo}{this.state.nome} {this.state.cognome}</p>
                        <div className='info-view'>
                            { this.state.mail && (
                                <section className='info-child'>
                                    <AiFillMail color='black'/>
                                    <p style={{color:'black'}}>{this.state.mail}</p>
                                </section>
                            )}
                            { this.state.telefono && (
                                <section className='info-child'>
                                    <AiFillPhone />
                                    <p>{this.state.telefono}</p>
                                </section>
                            )}
                            { this.state.cellulare && (
                                <section className='info-child'>
                                    <RiSmartphoneFill />
                                    <p>{this.state.cellulare}</p>
                                </section>
                            )}
                        </div>
                    </div>
                </div>
                
                <div className='description'>
                    {ReactHtmlParser(this.state.descrizione)}
                </div>
                
                { this.props.pagina.show_servizi === '1' && (
                    <>  
                        <div style={{ height: 1, width: '80%', marginLeft: '10%', marginTop: 25, backgroundColor: 'lightgrey' }} />
                        <div style={{ marginTop: 40 }}>
                            <h5 style={{ textAlign: 'left', width: '100%', marginBottom: 20 }}>Le mie specializzazioni</h5>
                            { this.state.servizi.map((s) => {

                                if (s.selezionato) 
                                    return(
                                        <section className="svgPaginaPersonale" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingRight: 20, marginTop: -10 }}>
                                            {s.icon}
                                            <p style={{color:'black', marginLeft: 10, marginTop: 15 }}>{s.servizio}</p>
                                        </section>
                                    );
                                else return <></>;
                            })}
                        </div>
                    </>
                )}

                {this.state.show_piani === '1' && (
                    <div className='pagina-section'>
                        
                    </div>
                )}
                <div className='footer-buttons'>
                    <section className='button-container' onClick={() => window.location.href = '/#/main/prenota?professionista=' + this.state.id}>
                        <div className='pagina-buttons'>
                            <BsCalendar3Event style={{ height: 50, width: 50, marginLeft: 45 }} />
                            <p>{t('prenota_visita')}</p>
                        </div>
                    </section>
                    { this.state.mail && 
                        <section className='button-container' onClick={() => window.open('mailto:' + this.state.mail)}>
                            <div className='pagina-buttons'>
                                <AiOutlineMail style={{ height: 50, width: 50, marginLeft: 45 }} />
                                <p>{t('invia_mail')}</p>
                            </div>
                        </section>
                    }
                </div>
            </div>
        );
    }
}