import React from 'react';
import { MeteorRainLoading } from 'react-loadingg';

export default class Loading extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            
        }
    }

    render() {

        return(
            <div style={{ position: 'absolute', top: 0, width: '70%', height: '100%' }}>
                <MeteorRainLoading />
            </div>
        );
    }
}