import Constants from '../utils/consts';

export default async function updateProfessionista(username, token, professionista) {
    return new Promise(function (resolve, reject) {

        fetch(Constants.defaultProtocol + Constants.mainUrl + 'updateProfessionista.php', {
            method: 'POST', 
            mode: 'cors', 
            body: JSON.stringify({
                'nome': professionista.nome, 
                'cognome': professionista.cognome, 
                'codice_fiscale': professionista.codice_fiscale,
                'mail': professionista.mail, 
                'indirizzo': professionista.indirizzo, 
                'citta': professionista.citta, 
                'cap': professionista.cap,
                'provincia': professionista.provincia,
                'piva': professionista.piva,
            }), 
            headers: { 
                'Authorization': 'Bearer ' + username + '.' + token, 
                'Content-Type': 'application/json'
            }
        })
        .then((res) => res.json())
        .then((data) => {
            resolve(data);
        })
        
    });
}
