import React from "react";
import Cookies from "universal-cookie";
import getStatusFatture from "../services/getStatusFatture";

export default class FattureProgress extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
        };
    }

    async componentDidMount() {
        const cookies = new Cookies();
        const username = cookies.get('username', { path: '/' });
        const token = cookies.get('token', { path: '/' });

        const statusFatture = await getStatusFatture(username, token);
        if (statusFatture.result === 'KO') return;

        const totale = parseInt(statusFatture.total, 10);
        const used = parseInt(statusFatture.used, 10);
        const percentuale = ((used / totale) * 100).toFixed(1);
        const remaining = totale - used;

        this.setState({ isLoading: false, statusFatture, percentuale, remaining });
    }

    render() {

        if (this.state.isLoading) return <></>;
        return (
            <div style={{ width: '90%', marginLeft: '5%', marginTop: 10 }}>
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                    <p>{this.state.statusFatture.title}</p>
                    {this.state.statusFatture.action && (
                        <p style={{ color: '#199289', fontSize: 12, cursor: 'pointer' }} onClick={() => {
                            if (this.props.onExit) this.props.onExit();
                            window.location.href = this.state.statusFatture.action_destination;
                        }}>{this.state.statusFatture.action}</p>
                    )}
                </div>
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', marginBottom: -12, marginTop: -5 }}>
                    <p style={{ fontSize: 12 }}>{this.state.statusFatture.used} emesse</p>
                    <p style={{ fontSize: 12 }}>{this.state.statusFatture.total}</p>
                </div>
                <div style={{ backgroundColor: 'lightgrey', height: 15, width: '100%', borderRadius: 5, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    {this.state.percentuale > 0.9 && (
                        <div style={{ backgroundColor: '#199289', height: 15, width: this.state.percentuale + '%', borderRadius: 5, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', paddingRight: 5 }}>
                            {this.state.percentuale >= 15 && (
                                <p style={{ color: 'white', fontSize: 11 }}>{this.state.percentuale} %</p>
                            )}
                        </div>
                    )}
                    {this.state.percentuale < 15 && (
                        <p style={{ fontSize: 11, marginLeft: 5 }}>{this.state.percentuale} %</p>
                    )}
                </div>
                {this.state.statusFatture.footer ? (
                    <p style={{ fontSize: 10, color: 'grey', marginTop: 5 }}>{this.state.statusFatture.footer}</p>
                ) : (
                    <section style={{ height: 15 }} />
                )}
            </div>  
        );
    }

}