
import React from 'react';
import { getServiceName } from '../../utils/consts';
import { NineCellLoading } from 'react-loadingg';
import regioni from '../../utils/regioni_province.json';
import Icon from '../../images/logo.png';

// Styles 
import './style.css';
import './bootstrap.min.css';
import './owl.carousel.css';
import './owl.theme.css';
import './owl.transitions.css';
import './animate.css';
import './preloader.css';

import translateString from '../../utils/Locales';
import SelectInput from '../../widgets/SelectInput';
import { ArrowBack } from '@material-ui/icons';
import checkSiteAddress from '../../services/checkSiteAddress';
import signUpPaziente from '../../services/signUpPaziente';
import { Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';
import checkEmailUsername from '../../services/checkEmailUsername';
import checkCodiceFiscale from '../../services/checkCodiceFiscale';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

let t = translateString;

export default class Signup extends React.Component {

    constructor(props) {
        super(props);

        let url = window.location.href;
        let info = null;
        if (url.includes('?email=')) {
            info = {}
            url = url.split('?email=')[1];
            info.email = url.split('&')[0];
            url = url.split('&name=')[1];
            info.name = url.split('&')[0];
            url = url.split('&surname=')[1];
            info.surname = url.split('&')[0];
            url = url.split('&accessToken=')[1];
            info.accessToken = url.split('&')[0];
            url = url.split('&refreshToken=')[1];
            info.refreshToken = url.split('&')[0];
            url = url.split('&idToken=')[1];
            info.idToken = url.split('&')[0];
        }
        
        this.state = {
            password: '', 
            nome: info.name || '',
            cognome: info.surname || '',
            codiceFiscale: '',
            email: info.email || '', 
            r_password: '', 
            error: false,
            errorMessage: '',
            isLoading: true,
            step: 1,
            firstNext: false, 
            secondNext: false, 
            thirdNext: false,
            indirizzo: '', 
            username: '', 
            cap: '',
            citta: '', 
            error: '',
            changed: false,
            provincia: { value: '', label: t('provincia') },
            regione: { value: '', label: t('regione') },
            googleInfo: info,
        };
    }

    async componentDidMount() {
        document.title = getServiceName() + ' - Registrazione';

        this.provincie = [];

        this.regioni = [{ value: '', label: t('regione') }];
        regioni['regioni'].forEach((regione) => {
            this.regioni.push({ value: regione, label: regione });

            regioni[regione].forEach((provincia) => {
                this.provincie.push({ value: provincia.sigla, label: provincia.nome });
            });
        });

        this.provincie = this.provincie.sort((p1, p2) => (p1.label).localeCompare(p2.label));

        this.provinceAlbo = [];

        window.addEventListener('resize', this.handleResize.bind(this));
        this.handleResize();
        this.setState({ isLoading: false });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {

        this.setState({ bannerHeight: window.innerHeight });

        if (window.innerWidth < 767) {
            this.setState({ isMobile: true });
            return;
        }

        this.setState({ isMobile: false });
    }

    async handleLogin(event) {

        event.preventDefault();

        if (this.state.username == '' || this.state.password == '') {
            this.setState({ username: '', password: '', error: true });

            setTimeout(() => {
                this.setState({ error: false });
            }, 2000);
        } else {
            this.setState({ isLoading: true });

        }    
    }

    async handleSignup() {

        this.setState({ isLoading: true });

        let utente = {
            "nome": this.state.nome, 
            "cognome": this.state.cognome, 
            "username": this.state.username, 
            "password": this.state.password, 
            "mail": this.state.email,
            "indirizzo": this.state.indirizzo, 
            "citta": this.state.citta, 
            "cap": this.state.cap, 
            "provincia": this.state.provincia.value, 
            "codice_fiscale": this.state.codiceFiscale,
            "google_info": this.state.googleInfo,
        }

        let res = await signUpPaziente(utente);
        if (!res) {
            this.setState({ isLoading: false, mainError: true, errorMessage: t('errore_imprevisto') });
            window.alert(t('errore_imprevisto'))
            return;
        }
        
        if (res.result === 'KO') {
            this.setState({ isLoading: false, mainError: true, errorMessage: res.description });
            window.alert(res.description)
            return;
        }

        if (res.result === 'ok') {
            window.location.href = '/#/main/login';
        }
    } 

    async checkCodice(event) {
        
        const res = await checkCodiceFiscale(event.target.value);
        if (!res || res.result === 'KO') {
            this.setState({ codiceFiscaleError: true });
            window.alert('Codice fiscale già utilizzato');
            return;
        }

        if (event.target.value.length !== 16) {
            this.setState({ codiceFiscaleError: true });
            return;
        }

        this.setState({ codiceFiscaleError: false });
    } 

    checkEmail(event) {

        if (this.isValidEmail(this.state.email)) {
            this.setState({ errorEmail: false });
            return;
        }

        this.setState({ errorEmail: true});
    }

    isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    checkPartitaIva(event) {

        if (event.target.value.length !== 11) {
            this.setState({ piva: event.target.value, partitaIvaError: true });
            return;
        }

        this.setState({ piva: event.target.value, partitaIvaError: false });
    }

    loadProvincieForRegione(regione) {
        if (regione !== t('regione')) {
            let p = [];
            let reg = regioni[regione.label];
            
            reg.forEach((provincia) => {
                p.push({ value: provincia.sigla, label: provincia.nome });
            });

            p.sort((p1, p2) => p1.label.localeCompare(p2.label));
            p.unshift({ value: '', label: t('provincia') });

            this.provinceAlbo = [];
            this.provinceAlbo = p;
            this.setState({ regione: regione });
        }
    }

    removeError(err) {
        let e = this.state.error.split(',');
        while (e.indexOf(err) !== -1) {
            var found = false;
            e.forEach((errore, i) => {
                if (errore === err && !found)
                    e.splice(i, 1);
            });
        }
        this.setState({ error: e.join(',') });
    }

    addError(err) {
        this.removeError(err);
        let e = this.state.error.split(',');
        e.push(err);
        this.setState({ error: e.join(',') });
    }

    async checkUsernameInserted() {
        let res = await checkEmailUsername(this.state.username, this.state.email);
        if (res.result === 'KO') {
            if (res.description.includes('Username')) {
                this.setState({alertUsername: true})
                // Username in uso
            } else {
                this.setState({alertEmail: true})
                // Email in uso
            }
            return;
        }

        this.setState({ step: 2})
    }

    stepValidation() {
        
        if (this.state.step === 1) {
            this.setState(() => ({ error: '' }), () => {
                let error = false;
                if (!this.state.nome) {
                    this.addError('nome');
                    error = true;
                }
    
                if (!this.state.cognome) {
                    this.addError('cognome');
                    error = true;
                }
    
                if (!this.state.email) {
                    this.addError('email');
                    error = true;
                }
    
                if (!this.state.username) {
                    this.addError('username');
                    error = true;
                }

                if(!this.state.username || this.state.username.includes('.') || this.state.username.includes(' ')) {
                    this.setState({errorUsername: true})
                    error = true;
                }
    
                if (error) return;
                
                if(!this.state.errorEmail) {
                    this.checkUsernameInserted();
                }
            });
        }
        if (this.state.step === 2) {
            this.setState(() => ({ error: '' }), () => {
                let error = false;
                if (!this.state.indirizzo) {
                    this.addError('indirizzo');
                    error = true;
                }
    
                if (!this.state.citta) {
                    this.addError('citta');
                    error = true;
                }
    
                if (!this.state.cap) {
                    this.addError('cap');
                    error = true;
                }
    
                if (!this.state.provincia.value) {
                    this.addError('provincia');
                    error = true;
                }
    
                if (!this.state.codiceFiscale) {
                    this.addError('codiceFiscale');
                    error = true;
                }
    
                if (error) return;

                if(!this.state.codiceFiscaleError) {
                    this.setState({step: this.state.googleInfo === null ? 3 : 4})
                }
            });
        }
        if (this.state.step === 3) {
            this.setState(() => ({ error: '' }), () => {
                let error = false;
                
                if (!this.state.password) {
                    this.addError('password');
                    error = true;
                }
                if (!this.state.r_password) {
                    this.addError('r_password');
                    error = true;
                }

                if (this.state.password !== this.state.r_password) {
                    this.addError('r_password');
                    error = true;
                }
    
                if (error) return;
                
                this.setState({step: 4})
            });
        }
        if (this.state.step === 4) {
            this.setState(() => ({ error: '' }), () => {
                let error = false;
                
                if (!this.state.privacy) {
                    //this.addError('condizioni');
                    error = true;
                }
                if (!this.state.condizioni) {
                    //this.addError('privacy');
                    error = true;
                }

                if (!this.state.dati) {
                    //this.addError('dati');
                    error = true;
                }
    
                if (error) {
                    window.alert('Controlla di aver selezionato tutti i campi')
                    return
                };
                
                this.handleSignup()
            });
        }
    }

    async checkSite(event) {

        let address = event.target.value;

        if (!address) {
            this.setState({ pageAddress: address, siteError: true, fourthNext: false });
            return;
        }

        let res = await checkSiteAddress(address);
        if (!res || res.result === 'KO') {
            this.setState({ pageAddress: address, siteError: true, fourthNext: false });
            return;
        }

        this.setState({ pageAddress: address, siteError: false, fourthNext: true });
    }

    render () {

        return (
            <div className='mainBody' style={{width: '100%', height: '100%'}}>
                
                <header id='home' style={{height: '100%'}}>
                <div className="new-banner" style={{position: 'absolute', top: 0, left: 0, width: '100vw', flex: 1, height: this.state.bannerHeight }}>
                    {!this.state.isMobile &&
                        <img alt="" className='navbar-brand' style={{ cursor: 'pointer', height: 154, position: 'absolute', top: 20, left: 20 }} onClick={() => window.location.href = '/#/main/home'} src={Icon} />
                    }  
                        <div className="container" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div className="row" style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                            <div className="col-md-10">
                                <div className="caption d-flex justify-content-center">
                                    { this.state.isLoading ? <div className="loginContainer">
                                        <NineCellLoading />
                                    </div> :
                                    <div className="loginContainer" style={this.state.isMobile ? { height: 700, flexDirection: 'column', alignItems: 'flex-start' } : { height: 700 }}>
                                        { this.state.step !== 1 && <div style={{cursor:"pointer", zIndex: 1000, marginRight: 5 }} onClick={() => this.setState({ step: this.state.step !== 4 ? this.state.step - 1 : this.state.googleInfo !== null ? this.state.step - 2 : this.state.step - 1 })}><ArrowBack /></div> }
                                        <p style={{ marginTop: 1 }} className="step">Step {this.state.step}</p>
                                        { this.state.step === 1 ? 
                                            <div className='loginColumn' style={this.state.isMobile ? { width: '100%' } : {marginRight: "61px", marginTop: 30}}>
                                                <h1 style={{ fontFamily: `url(${require('../../fonts/Nexa.ttf')})`, fontWeight: 'bold', fontSize: 30, alignSelf: 'flex-start' }}>{t('registrati').toUpperCase()}</h1>
                                                <p style={{ alignSelf: 'flex-start', marginTop: -20, textAlign: 'left' }}>{t('paz_signup')}</p>
                                                <TextField 
                                                    error={this.state.error.includes('nome')}
                                                    variant="outlined"
                                                    InputLabelProps={{style: {color:'white', border:"none"}}}
                                                    sx={{
                                                        marginTop: "20px",
                                                        "& .MuiOutlinedInput-root.Mui-focused": {
                                                            "& > fieldset": {
                                                                borderColor: "white"
                                                            }
                                                        }
                                                    }}
                                                    fullWidth type={'text'} value={this.state.nome} label={t('nome') + '*'} onChange={(e) => this.setState({ nome: e.target.value })} 
                                                />
                                                <TextField 
                                                    error={this.state.error.includes('cognome')}
                                                    variant="outlined"
                                                    InputLabelProps={{style: {color:'white', border:"none"}}}
                                                    sx={{
                                                        marginTop: "20px",
                                                        "& .MuiOutlinedInput-root.Mui-focused": {
                                                            "& > fieldset": {
                                                                borderColor: "white"
                                                            }
                                                        }
                                                    }}
                                                    fullWidth type={'text'} value={this.state.cognome} label={t('cognome') + '*'} onChange={(e) => this.setState({ cognome: e.target.value })} 
                                                />
                                                <TextField 
                                                    helperText={this.state.alertEmail ? "Email già in uso" : this.state.errorEmail ? "Email errata" : ""}
                                                    error={this.state.error.includes('email')}
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        style: {color:(this.state.alertEmail || this.state.errorEmail)  ? '#ffae9b' : 'white'}
                                                    }}
                                                    sx={{
                                                        marginTop: "20px",
                                                        "& .MuiOutlinedInput-root.Mui-focused": {
                                                            "& > fieldset": {
                                                                borderColor: "white"
                                                            }
                                                        }
                                                    }}
                                                    fullWidth type={'text'} value={this.state.email} label="Email*" 
                                                    onBlur={(e) => this.checkEmail()}
                                                    onChange={(e)=>this.setState({email: e.target.value, alertEmail: false, errorEmail: false})}
                                                />
                                                <TextField 
                                                    helperText={this.state.alertUsername ? "Username già in uso" : this.state.errorUsername ? "Username non valido" : ""}
                                                    error={this.state.error.includes('username')}
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        style: {color:this.state.alertUsername ?'#ffae9b' : 'white'}
                                                    }}
                                                    sx={{
                                                        marginTop: "20px",
                                                        "& .MuiOutlinedInput-root.Mui-focused": {
                                                            "& > fieldset": {
                                                                borderColor: "white"
                                                            }
                                                        }
                                                    }}
                                                    fullWidth type={'text'} value={this.state.username} label="Username (senza punti, ...) *" onChange={(e) => this.setState({username: e.target.value, alertUsername: false, errorUsername:false}) } 
                                                />
                                                <div className='loginButton' onClick={() => this.stepValidation()}>{t('avanti').toUpperCase()}</div>
                                            </div>
                                        : this.state.step === 2 ?
                                            <div className='loginColumn' style={this.state.isMobile ? { width: '100%' } : {marginRight: "61px"}}>
                                                <TextField 
                                                    error={this.state.error.includes('indirizzo')}
                                                    variant="outlined"
                                                    InputLabelProps={{style: {color:'white', border:"none"}}}
                                                    sx={{
                                                        marginTop: "20px",
                                                        "& .MuiOutlinedInput-root.Mui-focused": {
                                                            "& > fieldset": {
                                                                borderColor: "white"
                                                            }
                                                        }
                                                    }}
                                                    fullWidth type={'text'} value={this.state.indirizzo} label={t('indirizzo') + '*'} onChange={(e) => this.setState({ indirizzo: e.target.value })} 
                                                />
                                                <TextField 
                                                    error={this.state.error.includes('citta')}
                                                    variant="outlined"
                                                    InputLabelProps={{style: {color:'white', border:"none"}}}
                                                    sx={{
                                                        marginTop: "20px",
                                                        "& .MuiOutlinedInput-root.Mui-focused": {
                                                            "& > fieldset": {
                                                                borderColor: "white"
                                                            }
                                                        }
                                                    }}
                                                    fullWidth type={'text'} value={this.state.citta} label={t('citta') + '*'} onChange={(e) => this.setState({ citta: e.target.value })} 
                                                />
                                                <TextField 
                                                    error={this.state.error.includes('cap')}
                                                    variant="outlined"
                                                    InputLabelProps={{style: {color:'white', border:"none"}}}
                                                    sx={{
                                                        marginTop: "20px",
                                                        "& .MuiOutlinedInput-root.Mui-focused": {
                                                            "& > fieldset": {
                                                                borderColor: "white"
                                                            }
                                                        }
                                                    }}
                                                    fullWidth type={'text'} value={this.state.cap} label={t('cap') + '*'} onChange={(e) => this.setState({ cap: e.target.value })} 
                                                />
                                                {!this.state.isMobile ? (
                                                    <SelectInput
                                                        error={this.state.error.includes('provincia')}
                                                        login
                                                        value={this.state.provincia}
                                                        hidable={false}
                                                        isMulti={false}
                                                        options={this.provincie}
                                                        onChange={(option) => {
                                                            this.setState({ provincia: option })
                                                        }}
                                                    />
                                                ) : (
                                                    <FormControl fullWidth>
                                                        <TextField 
                                                            select
                                                            error={this.state.error.includes('provincia')}
                                                            variant="outlined"
                                                            InputLabelProps={{ style: { color: 'white', border: "none" } }}
                                                            sx={{
                                                                marginTop: "20px",
                                                                "& .MuiOutlinedInput-root.Mui-focused": {
                                                                    "& > fieldset": {
                                                                        borderColor: "white"
                                                                    }
                                                                }
                                                            }}
                                                            fullWidth
                                                            value={this.state.provincia ? this.state.provincia.value : null}
                                                            label={'Provincia*'}
                                                            onChange={(e) => {
                                                                const provincia = this.provincie.find(p => p.value === e.target.value);
                                                                this.setState({ provincia })
                                                            }}
                                                        >
                                                            {
                                                                this.provincie.map( (d, i) => {
                                                                    return(
                                                                        <MenuItem style={this.state.isMobile ? { whiteSpace: 'unset', wordBreak: 'break-all' } : {}} key={i} value={d.value}>{d.label}</MenuItem>
                                                                    )
                                                                })
                                                            }
                                                        </TextField>
                                                    </FormControl>
                                                )}
                                                <TextField 
                                                    helperText={this.state.codiceFiscaleError ? "Codice fiscale errato" : ""}
                                                    error={this.state.error.includes('codiceFiscale')}
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        style: {color: this.state.codiceFiscaleError ? "#ffae9b" : 'white', zIndex:0},
                                                        shrink: this.state.codiceFiscale,  
                                                    }}
                                                    sx={{
                                                        marginTop: "20px",
                                                        "& .MuiOutlinedInput-root.Mui-focused": {
                                                            "& > fieldset": {
                                                                borderColor: "white"
                                                            }
                                                        }
                                                    }}
                                                    fullWidth type={'text'} value={this.state.codiceFiscale} label={t('codice_fiscale') + '*'} 
                                                    onBlur={(e) => this.checkCodice(e)}
                                                    onChange={(e)=>this.setState({codiceFiscale: e.target.value.toUpperCase()})}
                                                />
                                                <div className='loginButton' onClick={() => this.stepValidation()}>{t('avanti').toUpperCase()}</div>
                                            </div>
                                        : this.state.step === 3 ? 
                                            <div className='loginColumn' style={this.state.isMobile ? { width: '100%' } : {marginRight: "61px"}}>
                                                <TextField 
                                                    error={this.state.error.includes('password')}
                                                    variant="outlined"
                                                    InputLabelProps={{style: {color:'white', border:"none"}}}
                                                    sx={{
                                                        marginTop: "20px",
                                                        "& .MuiOutlinedInput-root.Mui-focused": {
                                                            "& > fieldset": {
                                                                borderColor: "white"
                                                            }
                                                        }
                                                    }}
                                                    fullWidth type={'password'} value={this.state.password} label={t('password') + '*'} onChange={(e) => this.setState({ password: e.target.value })} 
                                                />
                                                <TextField 
                                                    error={this.state.error.includes('r_password')}
                                                    variant="outlined"
                                                    InputLabelProps={{style: {color:'white', border:"none"}}}
                                                    sx={{
                                                        marginTop: "20px",
                                                        "& .MuiOutlinedInput-root.Mui-focused": {
                                                            "& > fieldset": {
                                                                borderColor: "white"
                                                            }
                                                        }
                                                    }}
                                                    fullWidth type={'password'} value={this.state.r_password} label={t('r_password') + '*'} onChange={(e) => this.setState({ r_password: e.target.value })} 
                                                />
                                                <div className='loginButton' style={{ marginTop: 50 }} onClick={() => this.stepValidation()}>{t('avanti').toUpperCase()}</div>
                                                { this.state.mainError && <p style={{ color: 'red', marginTop: 10 }}>{this.state.errorMessage}</p>}
                                        </div>
                                        : this.state.step === 4 ?
                                            <div style={this.state.isMobile ? { width: '100%' } : {marginRight: 0, marginLeft: -60, marginTop: 30}} className='loginColumn'>
                                                <p style={{ textAlign: 'left', width: '100%' }}>Ti invitiamo a leggere attentamente i documenti riportati qui sotto e di accettarli prima di premere 'REGISTRATI'.</p>
                                                <FormGroup style={{ width: '100%' }}>
                                                    <FormControlLabel control={<Checkbox sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} onChange={(val) => { this.setState({ condizioni: val.target.checked }) }} />} label={<p style={{ textAlign: 'left', width: '100%', marginTop: 20 }}>Accetto le <a style={{ color: 'white', textDecoration: 'underline' }} href="/#/main/terms-and-conditions/" target='_blank'>condizioni generali di servizio</a></p>} />
                                                    <FormControlLabel style={{ marginTop: -30 }} control={<Checkbox sele sx={{  color: 'white', '&.Mui-checked': { color: 'white' } }} onChange={(val) => this.setState({ privacy: val.target.checked })} />} label={<p style={{ textAlign: 'left', width: '100%', marginTop: 20 }}>Accetto la <a style={{ color: 'white', textDecoration: 'underline' }} href="/#/main/privacy/" target='_blank'>privacy policy</a></p>} />
                                                    <FormControlLabel style={{ marginTop: -30 }} control={<Checkbox sele sx={{  color: 'white', '&.Mui-checked': { color: 'white' } }} onChange={(val) => this.setState({ dati: val.target.checked })} />} label={<p style={{ textAlign: 'left', width: '100%', marginTop: 20 }}>Sono consapevole che questa applicazione necessita di gestire i miei dati sanitari e che tali dati saranno trattati secondo normativa vigente.</p>} />
                                                </FormGroup>
                                                <div className='loginButton' style={{ marginTop: 10 }} onClick={() => this.stepValidation()}>{t('registrati').toUpperCase()}</div>
                                                { (this.state.error.includes('privacy') || this.state.error.includes('condizioni')) || this.state.error.includes('dati') && <p style={{ color: 'red', marginTop: 10, padding: 20, borderRadius: 10, backgroundColor: '#F9CCCCB3' }}>È necessario accettare tutti i punti prima di poter proseguire.</p>}
                                            </div>
                                        :    <div />
                                        }
                                    </div> 
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </header>
            </div>
        );
    }
}
