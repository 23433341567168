import React from "react";
import Select, { createFilter } from 'react-select';
import makeAnimated from 'react-select/animated';

import '../index.css';

export default class SelectInput extends React.Component {

    constructor (props){
        super(props);

        this.selectRef = React.createRef();
        this.state = {
            value: this.props.value ? this.props.value : [],
            hide: true
        }
        this.selectRef = React.createRef();

        this.options = []
    }

    getValue(){
        let value = this.selectRef.current.state.value;
        if (Array.isArray(value) == false) return [value];
        return value;
    }

    componentDidMount(){
        this.options = this.props.options;
        this.setState({});
    }


    handleChange (selectedOption) {

        this.setState({value: selectedOption });
        this.props.onChange(selectedOption);
      };

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
          this.setState({value: this.props.value})
        }
      }

    render (){

        const animatedComponents = makeAnimated();
        const arrow = this.props.hidable == true ? '  ᐁ' : '';

        const customStyles = {
            multiValueLabel: (provided, state) => ({
            ...provided,
            width: '100%',
            'white-space': 'break-spaces',
            borderBottom: '1px dotted pink',
            backgroundColor: "#c1c1c166"
            })
        };
        const customStylesLogin = {
            control: base => ({
                ...base,
                background: "#c1c1c166",
                border: this.props.error ? "1px solid red" : 0
            }),
            singleValue: base => ({
                ...base,
                color: "white"
            }),
            input: base => ({
                ...base,
                color: "#fff"
            }),
            menu: (base, state) => ({
                ...base,
                background: state.isSelected ? "black" : "#7a7979",
            }),
            option: (base, { data, isDisabled, isFocused, isSelected }) => {
                return {
                ...base,
                backgroundColor: isFocused ? "#363434" : null,
                color: "#dbd9d9"
                };
            }
        };

        const orariCustomStyles = {
            multiValueLabel: (provided, state) => ({
            ...provided,
            width: '100%',
            'white-space': 'break-spaces',
            borderBottom: '1px dotted pink',
            backgroundColor: "#c1c1c166"
            }), 
            control: (provided, state) => ({
                ...provided,
                backgroundColor: 'transparent',
                minWidth: '80px',
                borderRadius: 10, 
                maxWidth: '120px',
            }), 
            input: base => ({
                ...base,
                color: "#ffffff"
            }),
            singleValue: (base) => {
                return {
                ...base,
                color: "white"
                };
            }, 
        };

        return ( 
        <div style={{ ...mainClass, ...this.props.style }}>
            <div style={{display: this.props.hidable == true && this.state.hide == true ? 'none' : 'block'}}>
                <Select ref={this.selectRef} key={Math.random()}
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    styles={this.props.orari ? orariCustomStyles : this.props.login ? customStylesLogin : customStyles}
                    onChange={this.handleChange.bind(this)}
                    defaultValue={[]}
                    filterOption={createFilter({ ignoreCase: true, matchFrom: 'start' })}
                    isDisabled={this.props.readOnly}
                    classNamePrefix={ this.props.small ? "smallSelect" : (this.props.list ? "listSelect" : (this.props.orari ? "orariSelect" : "mySelect"))}
                    value={this.state.value}
                    isMulti={this.props.isMulti}
                    options={this.options}
                    />
            </div>
        </div>
        )
    }


}

const mainClass = {
    width: '100%',
    marginTop: '20px',
    textAlign: 'left',
}

const name = {
    textAlign: 'left',
}
