import Constants from '../utils/consts';

export default async function updateCliente(username, token, cliente) {
    return new Promise(function (resolve, reject) {

        fetch(Constants.defaultProtocol + Constants.mainUrl + 'updateCliente.php', {
            method: 'POST', 
            mode: 'cors', 
            body: JSON.stringify({
                'codice_cliente': cliente.id, 
                'ragione_sociale': cliente.rag, 
                'piva': cliente.piva, 
                'codice_fiscale': cliente.fiscale,
                'indirizzo': cliente.indirizzo, 
                'citta': cliente.citta, 
                'cap': cliente.cap, 
                'stato': cliente.stato, 
                'provincia': cliente.provincia, 
                'telefono': cliente.telefono, 
                'mail': cliente.mail, 
                'codice_univoco': cliente.codice
            }), 
            headers: { 
                'Authorization': 'Bearer ' + username + '.' + token, 
                'Content-Type': 'application/json'
            }
        })
        .then((res) => res.json())
        .then((data) => {
            resolve(data);
        })
        
    });
}
