import Constants from '../utils/consts';

export default async function requestNewStripeUrl(acct, user) {
    return new Promise(function (resolve, reject) {

        fetch(Constants.defaultProtocol + Constants.mainUrl + 'requestNewStripeLink.php', {
            method: 'POST', 
            mode: 'cors', 
            body: JSON.stringify({
                'account_id': acct,  
                'user_id': user,
            }), 
            headers: { 'Content-Type': 'application/json' }
        })
        .then((res) => res.json())
        .then((data) => {
            resolve(data);
        })
        
    });
}
