import React from 'react';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { AiOutlineMenu } from 'react-icons/ai';
import { BsChatLeft } from 'react-icons/bs';
import { RiNotification3Line } from 'react-icons/ri';
import { MdKeyboardArrowDown } from 'react-icons/md';

import avatar from '../../images/user.png';
import translateString from '../../utils/Locales';
import './style.css';
import Menu from '../Menu';
import getAccountInfo from '../../services/getAccountInfo';
import Cookies from 'universal-cookie';
import Chat from '../Chat';
import getAllMessaggi from '../../services/getAllMessaggi';
import getAllChats from '../../services/getAllChats';

let t = translateString;
const cookies = new Cookies();

export default class NavBarPazienti extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isClicked: 0,
        };
    }

    async componentDidMount() {

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        this.user = await getAccountInfo(username, token, "paziente");
        if (!this.user) {
            this.user = { nome: "" };
        }

        if (this.user.type === '0') {
            return;
        }

        var count = 0;
        let messaggi = await getAllChats(username, token, 'paziente');

        messaggi.forEach((m) => {
            if (m.mittente === '1' && m.letto === '0') {
                count++;
            }
        });

        this.checkMessaggi();

        this.setState({ isLoading: false, messaggiNonLetti: count });
    }

    async checkMessaggi() {

        // Ogni 15 secondi
        this.interval = setInterval(async () => {
            
            var count = 0;
            
            let username = cookies.get('username', { path: '/' });
            let token = cookies.get('token', { path: '/' });
            let messaggi = await getAllChats(username, token, 'paziente');

            if (!messaggi) return;
            
            try {
                messaggi.forEach((m) => {
                    if (m.mittente === '1' && m.letto === '0') {
                        count++;
                    }
                });
            } catch (error) {
                console.error(error);
            }   

            this.setState({ messaggiNonLetti: count });
        }, 15000);
    }


    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleActiveMenu() {
        this.props.onMenuChange();
    }
    
    handleClick(detail) {
        if (detail === 'userProfile') {
            if (this.state.isClicked === 3) {
                this.setState({ isClicked: 0 });
            } else {
                this.setState({ isClicked: 3 });
            }
        } else if (detail === 'chat') {
            if (this.state.isClicked === 2) {
                this.setState({ isClicked: 0 });
            } else {
                this.setState({ isClicked: 2 });
            }
        }
    }

    render() {

        if (this.state.isLoading) return <div/>;
        
        return(
            <div className='flex justify-between p-2 md:ml-6 md:mr-6 relative' style={{ minWidth: this.props.menu ? 'calc(100vw - 380px' : 'calc(100vw - 92px)' }}>
                <NavButton title="Menu" customFunc={this.handleActiveMenu.bind(this)} color={'white'} icon={<AiOutlineMenu />} />
                <div className="flex">
                    <NavButton title="Messaggi" customFunc={() => this.handleClick('chat')} color={'white'} dotColor={this.state.messaggiNonLetti > 0 ? '#DF4F2F' : ''} icon={<BsChatLeft style={{ height: 15, width: 15, marginTop: 3, marginRight: -10 }} />} />
                    <TooltipComponent content="Profile" position="BottomCenter" style={{ marginLeft: 10 }}>
                    <div
                        className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
                        onClick={() => this.handleClick('userProfile')}
                        style={{ marginTop: -6 }}
                    >
                        <img
                        className="rounded-full w-8 h-8"
                        src={avatar}
                        alt="user-profile"
                        />
                        <p style={{ marginTop: 20 }} >
                        <span className="text-light text-gray-400 text-14">{t('ciao')},</span>{' '}
                        <span className="text-light text-gray-400 font-bold ml-1 text-14">
                            {this.user.nome}
                        </span>
                        </p>
                        <MdKeyboardArrowDown className="text-light text-gray-400 text-14" />
                    </div>
                    </TooltipComponent>
                    
                    {this.state.isClicked === 2 && (<Chat paziente onClose={() => this.setState({ isClicked: 0 })} />)}
                    {this.state.isClicked === 3 && (<Menu onClose={() => this.setState({ isClicked: 0 })} paziente />)}
                </div>
            </div>
        );
    }
}

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
    <TooltipComponent content={title} position="BottomCenter">
      <button
        type="button"
        onClick={() => customFunc()}
        style={{ color }}
        className="relative text-xl rounded-full p-3 hover:bg-light-gray"
      >
        <span
          style={{ background: dotColor, marginTop: 7, marginRight: 8 }}
          className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
        />
        {icon}
      </button>
    </TooltipComponent>
  );
