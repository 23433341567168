import React from "react";
import Cookies from "universal-cookie";
import getPaginaPersonale from "../../services/getPaginaPersonale";
import translateString from "../../utils/Locales";

import '../../pages/landingPage/style.css';
import '../../pages/landingPage/bootstrap.min.css';
import '../../pages/landingPage/animate.css';
import '../../pages/landingPage/owl.carousel.css';
import '../../pages/landingPage/owl.theme.css';
import '../../pages/landingPage/owl.transitions.css';
import '../../pages/landingPage/preloader.css';
import './style.css';
import { MeteorRainLoading } from 'react-loadingg';
import MostraPaginaPersonale from "./PaginaPersonale";
import getInfoPsicologo from "../../services/getInfoPsicologo";
import getServiziPubblici from "../../services/getServiziPubblici";
import { getServiceName } from "../../utils/consts";

import Icon from '../../images/logo.png';
import Error from '../../images/error.jpg';
import NavbarMain from "../NavbarMain";
import moment from "moment";

let t = translateString;
const cookies = new Cookies();

class ProfessioniLayout extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: true, 
            isInvalid: false, 
        }
    }

    async componentDidMount(){
        
        let indirizzo = window.location.href.split('/')[5].replace(/#/g, '');
        if (!indirizzo || indirizzo === 'info') {
            window.location.href = '/#/main/home';
            return;
        }

        this.res = await getPaginaPersonale(indirizzo);
        if (this.res.result) {
            this.setState({ isLoading: false, isInvalid: true });
            return;
        } 

        this.psicologo = await getInfoPsicologo(this.res.id_psicologo);

        window.document.title = getServiceName() + ' - ' + this.psicologo.cognome + ' ' + this.psicologo.nome;

        if (this.res.show_servizi === '1') {

            let servizi = await getServiziPubblici(parseInt(this.res.id_psicologo));
            if (!servizi || !JSON.parse(servizi.servizi)) {
                this.res.servizi = null;
            } else {
                this.res.servizi = JSON.parse(servizi.servizi);
            }
        } else {
            this.res.servizi = null;
        }
        this.setState({ isLoading: false, isInvalid: false });
    }

    render (){
        return (
            <div id='main_scroll' className='mainBody bg-info' ref={this.homeRef} style={{ width: '100%', height: '100vh', overflowY: 'scroll', overflowX: 'hidden' }}>
                <link rel="stylesheet" href="bootstrap-4.1.1-dist/css/bootstrap.min.css" />
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous" />

                <div style={{ backgroundColor: 'black' }}>
                    <NavbarMain noButtons blackBg />
                </div>

                <div style={this.state.isInvalid ? { width: '90%', marginLeft: '5%', marginRight: '5%', marginTop: 150, minHeight: 'calc(100vh - 150px)', display: 'flex', justifyContent: 'center', alignItems: 'center' } : { width: '90%', marginLeft: '5%', marginRight: '5%', marginTop: 150 }} className={this.state.isInvalid ? "" : "page-container"}>
                    { this.state.isLoading ? (<MeteorRainLoading />) : (
                    this.state.isInvalid ? (
                        <div>
                            {/* INVALID PAGE REQUESTED */}
                            <h3 style={{ color: 'white', fontWeight: 'bold', fontFamily: `url(${require('../../fonts/Nexa.ttf')})`, textAlign: 'center' }}>{t('pagina_n_trovata')}</h3>
                        </div>
                    ) : (<MostraPaginaPersonale pagina={this.res} psicologo={this.psicologo} />)
                    )}
                </div>

                {!this.state.isInvalid && (
                    <footer id="contact">
                        <div className="container">
                            <p>&copy; <a target='_blank' rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }} href="https://www.zanc.it">ZANC Srl </a> {moment().format('yyyy')} - {t('diritti')}.</p>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <a href="https://www.iubenda.com/privacy-policy/39158023" target='_blank' style={{ color: 'white', fontWeight: 'bold' }} title="Privacy Policy ">Privacy Policy</a>
                                <a href="https://www.iubenda.com/privacy-policy/39158023/cookie-policy" target='_blank' style={{ color: 'white', fontWeight: 'bold', marginLeft: 30 }} title="Cookie Policy ">Cookie Policy</a>
                            
                            </div>
                        </div>
                    </footer>
                )}
            </div>
        );
    }
    
}

export default ProfessioniLayout;
