import React from "react";
import { animated, useSpring } from "react-spring";

const ScrollableSelection = ({ children, ...props }) => {

    const [styles] = useSpring(() => ({
        from: {
            paddingLeft: '5%',
        },
        to: {
            paddingLeft: '15%',
        },
        delay: 1000,
        config: {
            duration: 1000,
        },
    }))

    if (props.isMobile) return (
        <animated.div style={{ overflowX: 'scroll', ...styles }}>
            {children}
        </animated.div>
    )
    return (
        <div style={{ paddingLeft: '15%' }}>
            {children}
        </div>
    )
}

export default ScrollableSelection;