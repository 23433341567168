import Constants from '../utils/consts';

export default async function checkEmailUsername(username, email) {
    return new Promise(function (resolve, reject) {

        fetch(Constants.defaultProtocol + Constants.mainUrl + 'checkEmailUsername.php', {
            method: 'POST', 
            mode: 'cors', 
            body: JSON.stringify({
                'username': username, 
                'mail': email,
            }), 
            headers: { 'Content-Type': 'application/json' }
        })
        .then((res) => res.json())
        .then((data) => {
            resolve(data);
        })
        
    });
}
