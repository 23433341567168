import moment from 'moment';
import React from 'react';
import Cookies from 'universal-cookie';
import ButtonCustom from '../components/Button';
import CustomDatePicker from '../components/DatePicker';
import { MeteorRainLoading } from 'react-loadingg';
import translateString from '../utils/Locales';
import SelectInput from './SelectInput';
import getPazienti from '../services/getPazienti';
import CloseImg from '../images/close.png';

import '../index.css';
import getSeduta from '../services/getSeduta';
import updateSeduta from '../services/updateSeduta';
import deleteSeduta from '../services/deleteSeduta';
import { Box, Checkbox, Collapse, List, ListItemIcon, ListItemText, TextField } from '@material-ui/core';
import { ListItemButton, Typography, Button } from '@mui/material';
import { EuroSymbol, ExpandLess, ExpandMore, LocalHospital } from '@material-ui/icons';
import { Stack } from '@mui/system';
import inviaTSManuale from '../services/inviaTSManuale';
import dayjs from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MultiSelect from './MultiSelect';
import getPacchettiSedute from '../services/getPacchettiSedute';
import getImpostazioniFatturazione from '../services/getImpostazioniFatturazione';
import modificaTSManuale from '../services/modificaTSManuale';
import eliminaTSManuale from '../services/eliminaTSManuale';

let t = translateString;
const cookies = new Cookies();

export default class EditSedutaWidget extends React.Component {
    constructor(props) {
        super(props);

        this.mainRef = React.createRef();
        let margin = ((window.innerHeight * 5) / 100) + window.scrollY;

        this.state = {
            isLoading: true, 
            margin: margin,
            pazienti: [{ value: '', label: t('sel_paziente') }],
            paziente: { value: undefined, label: t('sel_paziente') }, 
            startDate: props.seduta.start, 
            endDate: props.seduta.end,
            error: false, 
            errorMessage: '',
            note: '', 
            isOnline: false,
            openFatturazione: false,
            openTS: false, 
            numeroAutomatico: true,
            showPagamentoAnticipato: false, 
            pagamentoAnticipato: false, 
        };
    }

    handleScroll() {
        this.setState({ margin: 'calc(5vh + ' + window.scrollY + 'px)' });
    }

    async reload() {
        let pazienti = [{ value: '', label: t('sel_paziente') }];

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        let res = await getPazienti(username, token);
        if (!res || res.result === 'KO') {
            this.props.onClose();
            return;
        }

        res.forEach((p) => {
            pazienti.push({ value: p.id, label: p.cognome + ' ' + p.nome });
        });

        let seduta = await getSeduta(username, token, 'psicologo', this.props.seduta.id);
        if (!seduta || seduta.result === 'KO') {
            this.props.onClose();
            return;
        }

        const pacchetti = await getPacchettiSedute(username, token);
        const finalPacchetti = [];
        const completePacchetti = [];
        let tipo = null;

        if (pacchetti && pacchetti.pacchetti) {
            let pack = JSON.parse(pacchetti.pacchetti);
            pack.forEach(p => {
                completePacchetti.push({ tipo: p.tipo, nome: p.nome, costo: p.costo });
                finalPacchetti.push(p.nome ? p.nome + ` (${p.costo} €)` : p.tipo.charAt(0).toUpperCase() + p.tipo.slice(1) + ` (${p.costo} €)`);
            });

            if (seduta.tipo) {
                completePacchetti.forEach(p => {
                    let nome = p.nome ? p.nome : p.tipo;
                    if (nome === seduta.tipo) tipo = nome.charAt(0).toUpperCase() + nome.slice(1) + ` (${p.costo} €)`;
                });
            }
        }

        const impostazioniFatturazione = await getImpostazioniFatturazione(username, token);
        
        window.addEventListener('scroll', this.handleScroll.bind(this));

        const imp = seduta.importo_pagato ? parseFloat(seduta.importo_pagato) : undefined;
        if (!isNaN(imp)) {
            const impBollo = imp >= 77.47 ? 2.0 : 0.0;
            const percentualeTasse = parseFloat(impostazioniFatturazione.tassa);
            const denominatore = 1 + (percentualeTasse/100); 
            const importo = imp/denominatore;
            const tasse = importo * (percentualeTasse/100);

            this.setState({ importo: imp, importoTasse: tasse.toFixed(2), subtotale: importo.toFixed(2), bollo: impostazioniFatturazione.bollo === "paziente" ? impBollo.toFixed(2) : 0.00, showDetail: true });
        }

        let selPaziente = res.find(p => p.id === seduta.id_paziente);
        
        this.setState({
            appuntamentoOriginale: seduta,
            id: seduta.id,
            isLoading: false,
            pazienti,
            note: seduta.note,
            paziente: { value: seduta.id_paziente, label: seduta.cognome + ' ' + seduta.nome },
            isOnline: seduta.online === '1',
            start_link: seduta.start_link,
            join_link: seduta.join_link,
            sedutaId: seduta.id, 
            pagata: seduta.pagata === '1', 
            inviataTS: seduta.inviata_ts === '1', 
            dataTS: seduta.data_ts, 
            importo: imp, 
            showDetail: seduta.importo_pagato ? !isNaN(parseFloat(seduta.importo_pagato)) : false,
            protocolloTS: seduta.protocollo_ts,
            dataFattura: seduta.data_fattura ? seduta.data_fattura : null, 
            dataPagamento: seduta.data_pagata ? seduta.data_pagata : null, 
            metodoPagamento: seduta.metodo_pagamento,
            fatturata: seduta.fatturata === '1',  
            metodoPagamento: seduta.metodo_pagamento,
            numeroFattura: seduta.numero_fattura, 
            tassaPrevidenziale: parseFloat(impostazioniFatturazione.tassa),
            bolloToPaziente: impostazioniFatturazione.bollo === "paziente", 
            pacchetti: finalPacchetti, 
            completePacchetti,
            tipo, 
            urlFattura: seduta.link_fattura,
            numeroAutomatico: seduta.numerazione_automatica === '1',
            pagamentoAnticipato: seduta.pagamento_anticipato === '1', 
            showPagamentoAnticipato: selPaziente ? selPaziente.utente === '1' : seduta.pagamento_anticipato === '1'
        });
    }

    async componentDidMount() {
        await this.reload();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    showError(message) {
        alert(message);
    }

    isFatturaValida() {
        return this.state.fatturata && this.state.importo && this.state.numeroFattura && this.state.dataFattura;
    }

    componentDidUpdate() {
        if (this.state.error) {
            if (this.mainRef && this.mainRef.current) this.mainRef.current.scrollTo(0,0);
        }
    }

    async save(openF) {

        if (!this.state.paziente.value) {
            this.setState({ error: true, errorMessage: t('no_pz')});
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 3000);
            return false;
        }

        if (this.state.startDate.getTime() >= this.state.endDate.getTime()) {
            this.setState({ error: true, errorMessage: t('controlla_date')});
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 3000);
            return false;
        }

        if (this.state.fatturata) {
            if (!this.state.dataFattura) {
                this.showError("L'appuntamento risulta fatturato, ma la data di fatturazione non è stata inserita");
                return false;
            }

            if (!this.state.numeroAutomatico && !this.state.numeroFattura) {
                this.showError("L'appuntamento risulta fatturato, ma il numero di fattura non è stato inserito");
                return false;
            }
        }

        if (this.state.pagata) {
            if (!this.state.metodoPagamento) {
                this.showError("L'appuntamento risulta pagato, ma il metodo di pagamento non è stato inserito");
                return false;
            }

            if (!this.state.dataPagamento) {
                this.showError("L'appuntamento risulta pagato, ma la data di pagamento non è stata inserita");
                return false;
            }

            if (!this.state.importo) {
                this.showError("L'appuntamento risulta pagato, ma l'importo non è stato inserito");
                return false;
            }
        }

        var renderFattura = false;
        if (this.state.appuntamentoOriginale.fatturata === '1') {
            var err = false;
            if (!this.state.fatturata) err = true;
            if (this.state.numeroFattura !== this.state.appuntamentoOriginale.numero_fattura) err = true;
            if (this.state.importo !== parseFloat(this.state.appuntamentoOriginale.importo_pagato)) err = true;
            if (this.state.dataFattura !== this.state.appuntamentoOriginale.data_fattura) err = true;
            if (!this.state.pagata && this.state.appuntamentoOriginale.pagata === '1') err = true;

            if (this.state.pagata && this.state.appuntamentoOriginale.pagata === '1') {
                if (this.state.dataPagamento !== this.state.appuntamentoOriginale.data_pagata) err = true;
                if (this.state.metodoPagamento !== this.state.appuntamentoOriginale.metodo_pagamento) err = true;
            } else if (this.state.pagata && this.state.appuntamentoOriginale.pagata === '0') {
                renderFattura = true;
            }

            if (err && !window.confirm("Risulta che siano stati modificati i dati di fatturazione di una fattura già pagata. Sei sicuro di voler salvare? Verrà creato un nuovo PDF per la fattura con i nuovi dati inseriti.")) {
                return;
            } else if (err) {
                renderFattura = true;
            }
        } else if (this.state.fatturata) {
            renderFattura = true;
        }

        let tipo = "";
        if (this.state.tipo !== tipo) {
            let type = this.state.tipo.split(' (');
            type.splice(tipo.length - 1, 1);
            tipo = type.join(" (").toLowerCase();
        }

        let seduta = {
            id: this.props.seduta.id,
            inizio: moment(this.state.startDate).format('yyyy-MM-DDTHH:mm'), 
            fine: moment(this.state.endDate).format('yyyy-MM-DDTHH:mm'), 
            id_paziente: this.state.paziente.value, 
            note: this.state.note, 
            durata: moment(this.state.endDate).diff(moment(this.state.startDate), 'minutes'),
            pagata: this.state.pagata ? 1 : 0, 
            fatturata: this.state.fatturata ? 1 : 0, 
            importo: this.state.importo ? this.state.importo : "",
            dataFattura: this.state.dataFattura ? this.state.dataFattura : "", 
            dataPagamento: this.state.dataPagamento ? this.state.dataPagamento : "",
            metodoPagamento: this.state.metodoPagamento ? this.state.metodoPagamento : "", 
            numeroFattura: this.state.numeroFattura ? this.state.numeroFattura : "", 
            tipo,
            isFatturaOkay: this.isFatturaValida() ? 1 : 0,
            hasFattura: this.state.urlFattura ? 1 : 0, 
            editFattura: renderFattura ? 1 : 0,
            numeroAutomatico: this.state.numeroAutomatico ? 1 : 0,
            pagamentoAnticipato: this.state.pagamentoAnticipato ? 1 : 0, 
        }

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        let res = await updateSeduta(username, token, seduta);
        if (!res || res.result === 'KO') {
            this.setState({ error: true, errorMessage: res.message || t('errore_imprevisto')});
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 3000);
            return false;
        }

        if (!openF && openF != "ts") this.props.onClose(0);
        else return true;
    }
    
    handleTipo(tipo) {

        if (!tipo) return;

        const pacchetto = this.state.completePacchetti.find(p => {
            if (p.nome) return p.nome + ` (${p.costo} €)` === tipo;
            return p.tipo.charAt(0).toUpperCase() + p.tipo.slice(1) + ` (${p.costo} €)` === tipo
        });
        if (!pacchetto) return;

        if (!this.state.importo || (parseFloat(pacchetto.costo) !== this.state.importo && window.confirm("Desideri sovrascrivere l'importo presente per questo appuntamento con l'importo standard impostato per questa tipologia?"))) {
            this.setState({ importo: parseFloat(pacchetto.costo)});
        }
    }

    async delete() {

        if (this.state.pagata || this.state.fatturata) {
            alert('Non è possibile eliminare un appuntamento fatturato o pagato.');
            return;
        }

        if (window.confirm(t('elimina_seduta'))) {

            let username = cookies.get('username', { path: '/' });
            let token = cookies.get('token', { path: '/' });

            let res = await deleteSeduta(username, token, this.state.sedutaId);
            if (!res || res.result === 'KO') {
                this.setState({ error: true, errorMessage: t('errore_imprevisto')});
                setTimeout(() => {
                    this.setState({ error: false, errorMessage: '' });
                }, 3000);
                return;
            }

            this.props.onClose(0);

        }
    }

    async sendTS() {

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        await this.save("ts");
        const res = await inviaTSManuale(username, token, this.state.id);

        if (!res || res.result === 'KO') {
            alert(res.messaggio);
            return;
        }

        await this.reload();
    }

    async editTS() {

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        await this.save("ts");
        const res = await modificaTSManuale(username, token, this.state.id);

        if (!res || res.result === 'KO') {
            alert(res.messaggio);
            return;
        }

        alert(res.messaggio);
        await this.reload();
    }

    async deleteTS() {

        if (!window.confirm("Sei davvero sicuro di voler eliminare questo documento dal Sistema Tessera Sanitaria?")) return;

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        await this.save("ts");
        const res = await eliminaTSManuale(username, token, this.state.id);

        if (!res || res.result === 'KO') {
            alert(res.messaggio);
            return;
        }

        await this.reload();
    }

    async openFattura() {
        if (!this.isFatturaValida()) return;
        const saved = await this.save(true);
        if (!saved) return;

        cookies.set('id_appuntamento', this.props.seduta.id, { path: '/' });
        window.location.href = "/#/app/pdfFattura";
    }

    render() {

        if (this.state.isLoading) return(
            <div style={backView} onClick={() => this.props.onClose && this.props.onClose()}>
                <div style={{ ...mainView, marginTop: this.state.margin }} onClick={(e) => e.stopPropagation()}>
                    <MeteorRainLoading />
                </div>
            </div>
        );

        var isToday = false;
        if (moment(this.state.startDate).format('DD/MM/yyyy') === moment().format('DD/MM/yyyy')) isToday = true;

        return(
            <div style={backView} onClick={() => this.props.onClose && this.props.onClose()}>
                <div style={{ ...mainView, marginTop: this.state.margin }} ref={this.mainRef} onClick={(e) => e.stopPropagation()}>
                    <img src={ CloseImg } style={{ position: 'absolute', top: 20, right: 20, width: 20, height: 20, cursor: 'pointer' }} onClick={() => this.props.onClose && this.props.onClose()} />
                    <h3>Modifica appuntamento</h3>
                    { this.state.error && (
                        <div className='error-div'>
                            {this.state.errorMessage}
                        </div>
                    )}
                    <div style={{ marginTop: 20 }} />
                    <MultiSelect fullWidth single label={'Tipo *'} names={this.state.pacchetti} onChangeListener={(tipi) => {
                        this.setState({ tipo: tipi ? tipi[0] : null });
                        this.handleTipo(tipi ? tipi[0] : null);
                    }} sel={this.state.tipo ? [this.state.tipo] : null} />
                    <SelectInput
                        value={this.state.paziente}
                        small={true} 
                        style={{ width: '100%', marginTop: 30 }}
                        hidable={false}
                        isMulti={false}
                        options={this.state.pazienti} 
                        onChange={(option) => 
                            this.setState({ paziente: option })} 
                    />
                    <label style={{ marginTop: 10, marginLeft: 5 }}>
                        <span>{this.state.isOnline ? t('lez_online') : t('sed_presenza') }</span>
                    </label>

                    { this.state.isOnline && (
                        <section style={{ display: 'flex', flexDirection: 'row' }}>
                            { isToday && <ButtonCustom style={{ boxShadow: 'none', }} button={"Inizia riunione online"} onClick={() => window.open(this.state.start_link, '_blank')} /> }
                            { isToday && <section style={{ height: 10, width: 10 }} /> }
                            <ButtonCustom style={{ boxShadow: 'none', }} button={"Copia link paziente"} onClick={() => navigator.clipboard.writeText(this.state.join_link)} />
                        </section>
                    )}

                    <h5 style={{ marginTop: 30 }}>{t('date_s')}</h5>
                    <CustomDatePicker 
                        date={this.state.startDate}
                        onChange={(date) => this.setState({ startDate: date })}
                        title="Inizio"
                    />
                    <br/>
                    <CustomDatePicker 
                        date={this.state.endDate}
                        onChange={(date) => this.setState({ endDate: date })}
                        title="Fine"
                    />

                    <h5 style={{ marginTop: 30 }}>{t('note_s')}</h5>
                    <textarea style={{ resize: 'none', height: 100, width: '100%', border: '1px solid lightgrey', borderRadius: 10, marginTop: 10, padding: 10, marginBottom: 10 }} value={this.state.note} placeholder={t('note')} onChange={(e) => this.setState({ note: e.target.value })} />
                    { this.state.showPagamentoAnticipato && (
                        <Stack direction='row' style={{ marginLeft: -10, marginTop: -10, marginBottom: 0 }}>
                            <Checkbox key={Math.random()} checked={this.state.pagamentoAnticipato} onChange={() => this.setState({ pagamentoAnticipato: !this.state.pagamentoAnticipato })} />
                            <p style={{ marginTop: 18 }}>Pagamento anticipato</p>
                        </Stack>
                    )}
                    <List
                        sx={{ width: '100%', flex: 1, bgcolor: 'background.paper' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader">
                    
                    <ListItemButton onClick={() => this.setState({ openFatturazione: !this.state.openFatturazione})}>
                        <ListItemIcon>
                        <EuroSymbol />
                        </ListItemIcon>
                        <ListItemText primary="Fatturazione" />
                        {this.state.openFatturazione ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={this.state.openFatturazione} timeout="auto" unmountOnExit>
                        <Stack spacing={2} sx={{ pb: 2 }}>
                            <Stack direction='row' style={{ marginLeft: -10, marginTop: 20, marginBottom: -20 }}>
                                <Checkbox key={Math.random()} checked={this.state.fatturata} onChange={() => this.setState({ fatturata: !this.state.fatturata })} />
                                <p style={{ marginTop: 18 }}>Appuntamento fatturato</p>
                            </Stack>
                            <Stack direction='row' style={{ marginLeft: -10, marginTop: 5, marginBottom: this.state.fatturata ? -20 : 20 }}>
                                <Checkbox key={Math.random()} checked={this.state.pagata} onChange={() => this.setState({ pagata: !this.state.pagata })} />
                                <p style={{ marginTop: 18 }}>Appuntamento pagato</p>
                            </Stack>
                            {this.state.fatturata && (
                                <>
                                        <Stack direction='row' style={{ marginLeft: -10, marginTop: 5, marginBottom: -30 }}>
                                            <Checkbox key={Math.random()} checked={this.state.numeroAutomatico} onChange={() => this.setState({ numeroAutomatico: !this.state.numeroAutomatico })} />
                                            <p style={{ marginTop: 18 }}>Numero di fattura automatico</p>
                                        </Stack>
                                        { !this.state.numeroAutomatico && <TextField fullWidth value={this.state.numeroFattura} label="Numero fattura *" onChange={(e) => this.setState({ numeroFattura: e.target.value })} /> }
                                        <TextField fullWidth value={this.state.importo} type='number' label="Importo (€) *" onChange={(e) => {
                                        
                                        const imp = parseFloat(e.target.value);
                                        if (isNaN(imp)) {
                                            this.setState({ importo: imp, showDetail: false });
                                            return;
                                        }
                                        const impBollo = imp >= 77.47 ? 2.0 : 0.0;
                                        const percentualeTasse = this.state.tassaPrevidenziale;
                                        const denominatore = 1 + (percentualeTasse/100); 
                                        const importo = imp/denominatore;
                                        const tasse = importo * (percentualeTasse/100);

                                        this.setState({ importo: imp, importoTasse: tasse.toFixed(2), subtotale: importo.toFixed(2), bollo: this.state.bolloToPaziente ? impBollo.toFixed(2) : 0.00, showDetail: true });
                                    }} />   
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            label={'Data fattura'}
                                            inputFormat={"DD/MM/YYYY"}
                                            value={this.state.dataFattura ? dayjs(this.state.dataFattura, 'DD/MM/YYYY') : null}
                                            onChange={(value) => this.setState({ dataFattura: (value && value.isValid()) ? value.format('DD/MM/YYYY') : null })}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                        <DesktopDatePicker
                                            label={'Data pagamento'}
                                            inputFormat={"DD/MM/YYYY"}
                                            value={this.state.dataPagamento ? dayjs(this.state.dataPagamento, 'DD/MM/YYYY') : null}
                                            onChange={(value) => this.setState({ dataPagamento: (value && value.isValid()) ? value.format('DD/MM/YYYY') : null })}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                    <MultiSelect fullWidth single label={'Metodo di pagamento *'} names={[ "Contanti", "Carta di credito", "Bancomat", "Pagamento elettronico", "Bonifico", "Assegno" ]} onChangeListener={(metodi) => this.setState({ metodoPagamento: metodi ? metodi[0] : null })} sel={this.state.metodoPagamento ? [this.state.metodoPagamento] : null} />

                                    { this.state.showDetail && (
                                        <Stack spacing={0}>
                                            <h6 style={{ marginTop: 18 }}>Dettagli di pagamento</h6>
                                            <Stack direction="row" sx={{ justifyContent: 'space-between', width: '100%' }}>
                                                <p style={{ marginTop: 10, fontWeight: 'bold' }}>Subtotale:</p>
                                                <p style={{ marginTop: 10 }}>€ {this.state.subtotale}</p>
                                            </Stack>
                                            <Stack direction="row" sx={{ justifyContent: 'space-between', width: '100%' }}>
                                                <p style={{ marginTop: 0, fontWeight: 'bold' }}>Tasse ({this.state.tassaPrevidenziale.toFixed(0)} %):</p>
                                                <p style={{ marginTop: 0 }}>€ {this.state.importoTasse}</p>
                                            </Stack>
                                            <Stack direction="row" sx={{ justifyContent: 'space-between', width: '100%' }}>
                                                <p style={{ marginTop: 0, fontWeight: 'bold' }}>Marca da bollo:</p>
                                                <p style={{ marginTop: 0 }}>€ {this.state.bollo}</p>
                                            </Stack>
                                            <Stack direction="row" sx={{ justifyContent: 'space-between', width: '100%' }}>
                                                <p style={{ marginTop: 5, fontWeight: 'bold', fontSize: 16 }}>Totale:</p>
                                                <p style={{ marginTop: 5, fontSize: 16, fontWeight: 'bold' }}>€ {parseFloat(this.state.importo) + parseFloat(this.state.bollo)}</p>
                                            </Stack>
                                        </Stack>
                                    )}

                                    {(this.isFatturaValida() && this.state.urlFattura) &&
                                        <ButtonCustom button={"Visualizza fattura"} style={{ width: '100%', boxShadow: 'none', flex: 1 }} onClick={() => this.openFattura()} />
                                    }
                                </>
                            )}
                            </Stack>
                    </Collapse>
                    
                    <ListItemButton onClick={() => this.setState({ openTS: !this.state.openTS})}>
                        <ListItemIcon>
                        <LocalHospital />
                        </ListItemIcon>
                        <ListItemText primary="Tessera sanitaria" />
                        {this.state.openTS ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={this.state.openTS} timeout="auto" unmountOnExit>
                        <Stack spacing={2} sx={{ mx: 3 }}>
                            <Typography sx={{ mt: 2 }} variant="body1" color={this.state.inviataTS ? "success" : "error"}>{this.state.inviataTS ? 'Questa spesa è stata inviata al Sistema TS in data ' + this.state.dataTS + ' e le è stato assegnato il numero di protocollo: ' + this.state.protocolloTS : 'Spesa non ancora inviata al Sistema TS'}</Typography>
                            { !this.state.inviataTS && (
                                <ButtonCustom button={"Invia ora"} style={{ width: '100%', boxShadow: 'none', flex: 1 }} onClick={() => this.sendTS()} />
                            )}
                            { this.state.inviataTS && (
                                <Stack spacing={0}>
                                    <ButtonCustom button={"Rettifica documento"} style={{ width: '100%', boxShadow: 'none', flex: 1 }} onClick={() => this.editTS()} />
                                    <ButtonCustom button={"Elimina documento dal Sistema TS"} style={{ width: '100%', boxShadow: 'none', flex: 1 }} onClick={() => this.deleteTS()} />
                                </Stack>
                            )}
                            <Box style={{ backgroundColor: '#FFE2E2', flex: 1, borderRadius: 5, padding: 20 }}>
                                <p style={{ fontSize: 12, color: '#FF6666', textAlign: 'justify', marginBottom: -10 }}>Si ricorda che è possibile effettuare solo operazioni di invio, modifica ed eliminazione dei documenti. Per le operazioni di rimborso si rimanda direttamente al <a href="https://sistemats4.sanita.finanze.it/simossHome/login.jsp" target="_blank" >portale</a> del Sistema Tessera Sanitaria.<br /><b>Attenzione:</b> PersonalDoc declina ogni responsabilità in caso di eventuali errori. Consigliamo di accedere spesso al portale per poter verificare correttamente i dati inseriti.</p>
                            </Box>
                        </Stack>
                    </Collapse>
                    </List>
                    
                    <section style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <ButtonCustom button={"SALVA"} style={{ marginTop: 20, width: 160, boxShadow: 'none', flex: 1 }} onClick={() => this.save()} />
                        <ButtonCustom button={"ELIMINA"} style={{ marginTop: 20, width: 160, boxShadow: 'none', flex: 1, backgroundColor: '#FF7474' }} onClick={() => this.delete()} />
                    </section>
                </div>
            </div>
        );
    }
}

const backView = {
    position: 'absolute', 
    top: 0, 
    left: 0,
    minWidth: '100vw', 
    minHeight: '100%', 
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#00000066', 
    zIndex: 10,
    textAlign: 'center',
}

const mainView = {
    position: 'relative',
    width: 400,
    maxHeight: '90vh', 
    minHeight: 400,
    marginLeft: 'calc(((100vw - 400px)/2) + 15px)',
    backgroundColor: 'white', 
    borderRadius: 10, 
    overflow: 'scroll',
    padding: 30,
    textAlign: 'left',
}