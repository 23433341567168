import moment from 'moment';
import React from 'react';
import Cookies from 'universal-cookie';
import Button from '../components/Button';
import CustomDatePicker from '../components/DatePicker';
import { MeteorRainLoading } from 'react-loadingg';
import translateString from '../utils/Locales';
import SelectInput from './SelectInput';
import Switch from 'react-switch';
import CloseImg from '../images/close.png';

import '../index.css';
import getClienti from '../services/getClienti';
import addEvento from '../services/addEvento';
import organizzaOnlineMeeting from '../services/organizzaOnlineMeeting';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleLoginModale from './GoogleLoginModale';
import isGoogleLogged from '../services/isGoogleLogged';

let t = translateString;
const cookies = new Cookies();

export default class AddEventoWidget extends React.Component {
    constructor(props) {
        super(props);

        this.mainRef = React.createRef();
        let margin = ((window.innerHeight * 5) / 100) + window.scrollY;

        this.state = {
            isLoading: true, 
            margin: margin,
            titolo: '',
            clienti: [{ value: '', label: t('sel_cliente') }],
            cliente: { value: '', label: t('sel_cliente') }, 
            startDate: props.target.start, 
            endDate: props.target.end,
            error: false, 
            errorMessage: '',
            note: '', 
            luogo: '', 
            isOnline: false,
            zoomId: null, 
            startLink: null, 
            joinLink: null, 
        };
    }

    handleScroll() {
        this.setState({ margin: 'calc(5vh + ' + window.scrollY + 'px)' });
    }

    async componentDidMount() {

        var clienti = [{ value: '', label: t('sel_cliente') }];

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        let res = await getClienti(username, token);
        if (!res || res.result === 'KO') {
            this.props.onClose();
            return;
        }

        res.forEach((c) => {
            clienti.push({ value: c.id, label: c.ragione_sociale });
        });
        
        window.addEventListener('scroll', this.handleScroll.bind(this));

        this.setState({ isLoading: false, clienti });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate() {
        if (this.state.error) {
            if (this.mainRef && this.mainRef.current) this.mainRef.current.scrollTo(0,0);
        }
    }

    async checkOnline() {

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        let check = await isGoogleLogged(username, token);
        if (check.result === 'KO') {
            this.setState({ googleLogin: true });
            return;
        }

        this.setState({ isOnline: true });
    }

    async crea() {

        if (!this.state.titolo.trim()) {
            this.setState({ error: true, errorMessage: t('no_titolo')});
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 3000);
            return;
        }

        if (this.state.startDate.getTime() >= this.state.endDate.getTime()) {
            this.setState({ error: true, errorMessage: t('controlla_date')});
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 3000);
            return;
        }

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });

        if (this.state.isOnline) {

            let meeting = {
                titolo: 'Evento PersonalDoc', 
                data: moment(this.state.startDate).format('yyyy-MM-DDTHH:mm:ssZ'), 
                durata: moment(this.state.endDate).diff(moment(this.state.startDate), 'minutes'), 
                oggetto: '',
            }

            await organizzaOnlineMeeting(username, token, meeting).then((res) => {
                
                this.caricaEvento(username, token, res.id, res.start_url, res.join_url);
            });
        } else {

            await this.caricaEvento(username, token, 0, '', '')
        }

    }

    async caricaEvento(username, token, zoomId, startLink, joinLink) {
        let evento = {
            titolo: this.state.titolo,
            luogo: this.state.luogo,
            start: moment(this.state.startDate).format('yyyy-MM-DDTHH:mm'), 
            end: moment(this.state.endDate).format('yyyy-MM-DDTHH:mm'), 
            id_cliente: this.state.cliente.value ? this.state.cliente.value : null, 
            note: this.state.note, 
            durata: moment(this.state.endDate).diff(moment(this.state.startDate), 'minutes'), 
            isOnline: this.state.isOnline ? 1 : 0,
            zoom_id: String(zoomId), 
            start_link: startLink, 
            join_link: joinLink
        }

        
        let res = await addEvento(username, token, evento);
        if (!res || res.result === 'KO') {
            this.setState({ error: true, errorMessage: t('errore_imprevisto')});
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 3000);
            return;
        }

        this.props.onClose(0);
    }

    render() {

        if (this.state.isLoading) return(
            <div style={backView} onClick={() => this.props.onClose && this.props.onClose()}>
                <div style={{ ...mainView, marginTop: this.state.margin }} onClick={(e) => e.stopPropagation()}>
                    <MeteorRainLoading />
                </div>
            </div>
        );

        return(
            <div style={backView} onClick={() => this.props.onClose && this.props.onClose()}>
                <div style={{ ...mainView, marginTop: this.state.margin }} ref={this.mainRef} onClick={(e) => e.stopPropagation()}>
                    <img src={ CloseImg } style={{ position: 'absolute', top: 20, right: 20, width: 20, height: 20, cursor: 'pointer' }} onClick={() => this.props.onClose && this.props.onClose()} />
                    <h3>{t('nuovo_evento')}</h3>
                    { this.state.error && (
                        <div className='error-div'>
                            {this.state.errorMessage}
                        </div>
                    )}
                    <SelectInput
                        value={this.state.cliente}
                        small={true} 
                        style={{ width: '100%', marginTop: 30 }}
                        hidable={false}
                        isMulti={false}
                        options={this.state.clienti} 
                        onChange={(option) => 
                            this.setState({ cliente: option })} 
                    />
                    { false &&
                        <label style={{ marginTop: 10, marginLeft: 5 }}>
                            <span>{t('ev_online')} </span>
                            <Switch uncheckedIcon={false} checkedIcon={false} className="switch" onColor='#7B90C4' width={40} height={20} onChange={(checked) => !checked ? this.setState({ isOnline: false }) : this.checkOnline()} checked={this.state.isOnline} />
                        </label>
                    }

                    <h5 style={{ marginTop: 30 }}>{t('titolo').replace('...', '') + ' *'}</h5>
                    <input style={{ width: '100%', border: '1px solid lightgrey', borderRadius: 10, marginTop: 10, padding: 10, marginBottom: 10 }} type={'text'} value={this.state.titolo} onChange={(e) => this.setState({ titolo: e.target.value })} />    

                    <h5 style={{ marginTop: 30 }}>{t('date_s')}</h5>
                    <CustomDatePicker 
                        date={this.state.startDate}
                        onChange={(date) => this.setState({ startDate: date })}
                        title="Inizio"
                    />
                    <br/>
                    <CustomDatePicker 
                        date={this.state.endDate}
                        onChange={(date) => this.setState({ endDate: date })}
                        title="Fine"
                    />

                    { !this.state.isOnline && (
                        <section>
                            <h5 style={{ marginTop: 30 }}>{t('dove')}</h5>
                            <input style={{ width: '100%', border: '1px solid lightgrey', borderRadius: 10, marginTop: 10, padding: 10, marginBottom: 10 }} type={'text'} value={this.state.luogo} onChange={(e) => this.setState({ luogo: e.target.value })} />
                        </section>
                    )}
                    <h5 style={{ marginTop: 30 }}>{t('note_s')}</h5>
                    <textarea style={{ resize: 'none', height: 100, width: '100%', border: '1px solid lightgrey', borderRadius: 10, marginTop: 10, padding: 10, marginBottom: 10 }} value={this.state.note} placeholder={t('note')} onChange={(e) => this.setState({ note: e.target.value })} />
                    <Button button={"CREA"} style={{ marginTop: 20 }} onClick={() => this.crea()} />
                </div>

                { this.state.googleLogin && (
                    <GoogleOAuthProvider clientId="574939206422-ouvhde4ttktrcohr5jbaiqelrffc54q0.apps.googleusercontent.com">
                        <GoogleLoginModale open={this.state.googleLogin} handleClose={(res) => {
                            this.setState({ googleLogin: false });
                            if (res === 1)
                                this.checkOnline();
                            else 
                                this.setState({ googleLogin: false });
                        }} />
                    </GoogleOAuthProvider>
                )}
            </div>
        );
    }
}

const backView = {
    position: 'absolute', 
    top: 0, 
    left: 0,
    minWidth: '100vw', 
    minHeight: '100%', 
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#00000066', 
    textAlign: 'center',
    zIndex: 999999999, 
}

const mainView = {
    position: 'relative',
    width: 400,
    maxHeight: '90vh', 
    marginLeft: 'calc((100vw - 400px)/2)',
    backgroundColor: 'white', 
    borderRadius: 10, 
    overflow: 'scroll',
    padding: 30,
    zIndex: 1000000000,
    textAlign: 'left',
}