import React from 'react';
import Cookies from 'universal-cookie';
import Button from '../components/Button';
import translateString from '../utils/Locales';
import { HiOutlineIdentification } from 'react-icons/hi';
import { BiRename, BiCurrentLocation } from 'react-icons/bi';
import { FaLocationArrow, FaCity } from 'react-icons/fa';
import { AiOutlineBarcode, AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import states from '../utils/states.json';
import province from '../utils/regioni_province.json';
import SelectInput from '../widgets/SelectInput';
import { GiSouthAmerica } from 'react-icons/gi';
import { Si1Password } from 'react-icons/si';
import getCliente from '../services/getCliente';
import Loading from '../components/Loading';
import updateCliente from '../services/updateCliente';
import { getServiceName } from '../utils/consts';

const cookies = new Cookies();
let t = translateString;

export default class ModificaCliente extends React.Component {
    constructor(props) {
        super(props);

        this.saveRef = React.createRef();

        this.lista = [{ value: '', label: 'Stato...'}];
        states.forEach((stato) => {
            if (stato.iso3361_2_characters !== '') {
                this.lista.push({ value: stato.iso3361_2_characters, label: stato.italian_country_name_1 ? stato.italian_country_name_1.toUpperCase() : stato.italian_country_name_2 ? stato.italian_country_name_2 : stato.english_country_name.toUpperCase() });
            }
        });

        this.province = [{ value: '', label: 'Provincia...'}];
        province['regioni'].forEach((regione) => {
            province[regione].forEach((provincia) => {
                this.province.push({ value: provincia.sigla, label: provincia.nome });
            });
        });

        this.province.sort((p1, p2) => p1.label.localeCompare(p2.label));

        this.state = {
            isLoading: true, 
            codice_fiscale: '', 
            piva: '', 
            ragione_sociale: '',
            indirizzo: '', 
            citta: '',
            cap: '', 
            stato: { value: '', label: 'Stato...'},
            provincia: { value: '', label: 'Provincia...'},
            mail: '', 
            telefono: '',
            codice: '', 
            error: false, 
            errorMessage: '',
        };
    }

    async componentDidMount() {

        window.document.title = getServiceName() + ' - Modifica cliente';

        let currentCliente = cookies.get('currentCliente', { path: '/' });
        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });

        let cliente = await getCliente(username, token, currentCliente);
        var stato = {};
        var provincia = {};

        this.lista.forEach((s) => {
            if (s.value === cliente.stato) {
                stato = s;
            }
        });

        this.province.forEach((p) => {
            if (p.value === cliente.provincia) {
                provincia = p;
            }
        });

        this.setState({ isLoading: false, id: cliente.id, ragione_sociale: cliente.ragione_sociale, codice_fiscale: cliente.codice_fiscale, piva: cliente.piva, indirizzo: cliente.indirizzo, citta: cliente.citta, cap: cliente.cap, stato, provincia, mail: cliente.mail, telefono: cliente.telefono, codice: cliente.codice_univoco });
    }

    checkPiva() {
    
        if (isNaN(this.state.piva) && this.state.piva.length === 13) {
            return true;
        }

        return false;
    }

    checkCodiceFiscale() {
        
        if (isNaN(this.state.codice_fiscale) && this.state.codice_fiscale.length === 16) {
            return true;
        }

        return false;
    }

    checkCodiceDestinatario() {

        if (this.state.codice.length === 7) {
            return true;
        }

        return false;
    }

    async save() {

        if (!this.state.ragione_sociale) {
            this.setState({ error: true, errorMessage: t('verifica_dati') });
            setTimeout(() => {
                this.saveRef.current.stopAnimating();
            }, 200);
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 3000);
            return;
        }

        if (this.state.piva && !this.checkPiva()) {
            this.setState({ error: true, errorMessage: t('verifica_piva') });
            setTimeout(() => {
                this.saveRef.current.stopAnimating();
            }, 200);
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 5000);
            return;
        }

        if (this.state.codice_fiscale && !this.checkCodiceFiscale()) {
            this.setState({ error: true, errorMessage: t('verifica_codice_fiscale') });
            setTimeout(() => {
                this.saveRef.current.stopAnimating();
            }, 200);
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 5000);
            return;
        }

        if (this.state.codice && !this.checkCodiceDestinatario()) {
            this.setState({ error: true, errorMessage: t('verifica_codice_destinatario') });
            setTimeout(() => {
                this.saveRef.current.stopAnimating();
            }, 200);
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 5000);
            return;
        }

        const cliente = {
            id: this.state.id, 
            rag: this.state.ragione_sociale, 
            piva: this.state.piva, 
            fiscale: this.state.codice_fiscale, 
            indirizzo: this.state.indirizzo, 
            citta: this.state.citta, 
            cap: this.state.cap, 
            stato: this.state.stato.value, 
            provincia: this.state.provincia.value, 
            mail: this.state.mail, 
            telefono: this.state.telefono,
            codice: this.state.codice
        }
        
        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        let res = await updateCliente(username, token, cliente);
        if (!res || res.result === 'KO') {
            
            // Error
            this.setState({ error: true, errorMessage: t('errore_imprevisto') });
            setTimeout(() => {
                this.saveRef.current.stopAnimating();
            }, 200);
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 5000);
            return;
        }

        window.location.href = '/#/app/clienti';

    }

    render() {

        if (this.state.isLoading) return <Loading />;
        
        return(
            <div style={{ marginBottom: 100 }} className="pageCliente">
                <div className='top-with-btn' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 20 }}>
                    <div style={{ marginRight: 20, marginTop: 10 }}>
                        <h3 style={{ color: 'white' }}>{this.state.ragione_sociale}</h3>
                    </div>
                    <Button ref={this.saveRef} button={t('salva')} animated={true} onClick={() => this.save()} />
                </div>
                { this.state.error && (
                    <div className='error-div'>
                        {this.state.errorMessage}
                    </div>
                )}
                <div style={{ marginTop: 50, padding: 30 }} className='dashview'>
                    <h6 style={{ color: 'white', marginBottom: 30 }}>{t('dati')}</h6>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <BiRename color='white' />
                        <input style={{ marginLeft: 10, border: 0, borderBottom: '1px solid lightgrey', height: '20px', color: 'grey', paddingLeft: 10, paddingRight: 10, minWidth: 200 }} type={'text'} placeholder={t('ragione_sociale') + ' *'} value={this.state.ragione_sociale} onChange={(e) => this.setState({ ragione_sociale: e.target.value })} />
                    </div>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <HiOutlineIdentification color='white' />
                        <section>
                            <input style={{ marginLeft: 10, border: 0, borderBottom: '1px solid lightgrey', height: '20px', color: 'grey', paddingLeft: 10, paddingRight: 10, minWidth: 200 }} type={'text'} placeholder={t('codice_fiscale')} value={this.state.codice_fiscale} onChange={(e) => this.setState({ codice_fiscale: e.target.value.toUpperCase() })} />
                            <input style={{ marginLeft: 10, border: 0, borderBottom: '1px solid lightgrey', height: '20px', color: 'grey', paddingLeft: 10, paddingRight: 10, minWidth: 200 }} type={'text'} placeholder={t('piva')} value={this.state.piva} onChange={(e) => this.setState({ piva: e.target.value })} />
                        </section>
                    </div>
                </div>
                <div style={{ marginTop: 20, padding: 30 }} className='dashview'>
                    <h6 style={{ color: 'white', marginBottom: 30 }}>{t('indirizzo')}</h6>
                    <section className='horizontal'>
                        <section>
                            <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <FaLocationArrow color='white' />
                                <input style={{ marginLeft: 10, border: 0, borderBottom: '1px solid lightgrey', height: '20px', color: 'grey', paddingLeft: 10, paddingRight: 10, minWidth: 200 }} type={'text'} placeholder={t('indirizzo')} value={this.state.indirizzo} onChange={(e) => this.setState({ indirizzo: e.target.value })} />
                            </div>
                            <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <FaCity color='white' />
                                <input style={{ marginLeft: 10, border: 0, borderBottom: '1px solid lightgrey', height: '20px', color: 'grey', paddingLeft: 10, paddingRight: 10, minWidth: 200 }} type={'text'} placeholder={t('citta')} value={this.state.citta} onChange={(e) => this.setState({ citta: e.target.value })} />
                            </div>
                            <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <AiOutlineBarcode color='white' />
                                <input style={{ marginLeft: 10, border: 0, borderBottom: '1px solid lightgrey', height: '20px', color: 'grey', paddingLeft: 10, paddingRight: 10, minWidth: 200 }} type={'text'} placeholder={t('cap')} value={this.state.cap} onChange={(e) => this.setState({ cap: e.target.value })} />
                            </div>
                        </section>
                        <section className='margin-l-horizontal mt-10'>
                            <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <GiSouthAmerica color='white' />
                                <SelectInput 
                                    value={this.state.stato} 
                                    hidable={false}
                                    isMulti={false}
                                    list={true}
                                    options={this.lista} 
                                    style={{ marginLeft: 10, marginTop: 5 }}
                                    onChange={(option) => this.setState({ stato: option })} 
                                />
                            </div>
                            { this.state.stato.value === 'IT' && <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <BiCurrentLocation color='white' />
                                <SelectInput 
                                    value={this.state.provincia} 
                                    hidable={false}
                                    isMulti={false}
                                    list={true}
                                    options={this.province} 
                                    style={{ marginLeft: 10, marginTop: 5 }}
                                    onChange={(option) => this.setState({ provincia: option })} 
                                /> 
                            </div> }
                        </section>
                    </section>
                </div>
                <div style={{ marginTop: 20, padding: 30 }} className='dashview'>
                    <h6 style={{ color: 'white', marginBottom: 30 }}>{t('contatti')}</h6>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <AiOutlineMail color='white' />
                        <input style={{ marginLeft: 10, border: 0, borderBottom: '1px solid lightgrey', height: '20px', color: 'grey', paddingLeft: 10, paddingRight: 10, minWidth: 200 }} type={'text'} placeholder={t('email')} value={this.state.mail} onChange={(e) => this.setState({ mail: e.target.value })} />
                    </div>
                    <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <AiOutlinePhone color='white' />
                        <input style={{ marginLeft: 10, border: 0, borderBottom: '1px solid lightgrey', height: '20px', color: 'grey', paddingLeft: 10, paddingRight: 10, minWidth: 200 }} type={'text'} placeholder={t('telefono')} value={this.state.telefono} onChange={(e) => this.setState({ telefono: e.target.value })} />
                    </div>
                </div>
                <div style={{ marginTop: 20, padding: 30 }} className='dashview'>
                    <h6 style={{ color: 'white' }}>{t('fatturazione')}</h6>
                    <p style={{ color: 'white', marginBottom: 30 }}>Se il cliente è un privato senza codice destinatario inserire 0000000 (7 volte 0). Se il cliente è una società estera inserire XXXXXXX (7 volte X)</p>
                    <h6 style={{ color: 'white', marginBottom: 30 }}>{t('fatturazione')}</h6>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Si1Password color='white' />
                        <input style={{ marginLeft: 10, border: 0, borderBottom: '1px solid lightgrey', height: '20px', color: 'grey', paddingLeft: 10, paddingRight: 10, minWidth: 200 }} type={'text'} placeholder={t('codice_univoco')} value={this.state.codice} onChange={(e) => this.setState({ codice: e.target.value })} />
                    </div>
                </div>
            </div>  
        );
    }
}