import React from 'react';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { MdOutlineCancel } from 'react-icons/md';
import { GoDashboard } from 'react-icons/go';

import './style.css';
import Icon from '../../images/logo.png';
import { HiUsers } from 'react-icons/hi';

export default class SidebarAdmin extends React.Component {
    constructor(props) {
        super(props);

        this.links = [
            {
                links: [
                    {
                        name: 'dashboard',
                        to: '/#/admin/dashboard',
                        icon: <GoDashboard style={{color:"white"}} />
                    }, 
                    {
                        name: 'professionisti',
                        to: '/#/admin/utenti',
                        icon: <HiUsers style={{color:"white"}} />
                    }, 
                    {
                        name: 'pazienti',
                        to: '/#/admin/pazienti',
                        icon: <HiUsers style={{color:"white"}} />
                    }
                ]
            },
        ];

        this.state = {

        };
    }

    componentDidMount() {
        
        this.setState({ });
    }

    setActiveMenu() {
        this.props.onMenuChange();
    }

    handleCloseSideBar() {

    }

    render() {
        if (this.links.length === 0) return <div />;
        return(
            <div className="sidebar ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
                { this.props.menu && (
                    <>
                    <div className="flex justify-between items-center">
                        <section id="logo" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'/*, marginBottom: 50*/ }}>
                            <img src={Icon} style={{ height: 140, width: 'auto', marginTop: 30, marginLeft: 20 }} />
                        </section>
                        <TooltipComponent content="Menu" position="BottomCenter">
                        <button
                            type="button"
                            onClick={() => this.setActiveMenu(!this.props.activeMenu)}
                            style={{ color: '#7B90C4' }}
                            className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
                        >
                            <MdOutlineCancel />
                        </button>
                        </TooltipComponent>
                    </div>
                    <div className="mt-10 overflow-scroll">
                        {this.links.map((item) => (
                        <div key={item.title}>
                            <h6 style={{textDecoration: "underline"}} className="text-light m-3 mt-4 uppercase">{item.title}</h6>
                            {item.links.map((link) => (
                                <section key={link.to} className='side-item' style={{ backgroundColor: window.location.href.includes(link.to) ? '#818181' : '', color: window.location.href.includes(link.to) ? 'white' : '#4f5866' }} onClick={() => window.location.href = link.to}>
                                    {link.icon}
                                    <p className='side-text'>{link.name}</p>
                                </section>
                            ))}
                        </div>
                        ))}
                    </div>
                </>
                )}
            </div>
        );
    }
}
