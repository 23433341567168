import React from 'react';
import { AiFillSchedule } from 'react-icons/ai';
import { BsPersonFill, BsFillPeopleFill } from 'react-icons/bs';
import { HiUserGroup } from 'react-icons/hi';
import SelectInput from './SelectInput';
import { FaTrash } from 'react-icons/fa';

export default class PacchettoWidget extends React.Component {
    constructor(props) {
        super(props);

        this.persone = [
            { value: 1, label: "1 paziente" },
            { value: 2, label: "2 pazienti" },
            { value: 3, label: "Gruppo" },
            { value: 4, label: "Altro" }
        ];

        this.state = {
            tipo: props.tipo ? props.tipo : "singola", 
            pazienti: props.pazienti ? props.pazienti : 0, 
            costo: props.costo ? parseFloat(props.costo) : '',
            pax: props.tipo ? props.tipo === 'singola' ? this.persone[0] : props.tipo === 'doppia' ? this.persone[1] : props.tipo === 'gruppo' ? this.persone[2] : props.tipo === 'altro' ? this.persone[3] : this.persone[0] : this.persone[0], 
            insert: false,
            descrizione: props.descrizione ? props.descrizione : '', 
            nome: props.nome ? props.nome : '', 
        }
    }

    getPacchetto() {
        
        if (!this.state.costo) return null;

        let pacchetto = {
            tipo: this.state.tipo, 
            nome: this.state.nome, 
            costo: this.state.costo, 
            editable: this.props.editable, 
            descrizione: this.state.descrizione
        }; 

        return pacchetto;
    }

    getPacchettoParziale() {
                
        let pacchetto = {
            id: this.props.index.id, 
            tipo: this.state.tipo, 
            nome: this.state.nome, 
            costo: this.state.costo, 
            editable: this.props.editable, 
            descrizione: this.state.descrizione
        }; 

        return pacchetto;
    }

    render() {

        return(
            <section className='pacchetto'>
                
                { this.state.tipo === 'singola' ? (
                    <BsPersonFill color='#FFFFFF80' size={30} />
                ) : this.state.tipo === 'doppia' ? (
                    <BsFillPeopleFill color='#FFFFFF80' size={30} />
                ) : this.state.tipo === 'gruppo' ? (
                    <HiUserGroup color='#FFFFFF80' size={30} />
                ) : (
                    <AiFillSchedule color='#FFFFFF80' size={30} />
                )}

                <section>

                    <SelectInput 
                        value={this.state.pax} 
                        hidable={false}
                        isMulti={false}
                        list={true}
                        readOnly={!this.props.editable}
                        options={this.persone} 
                        style={{ marginLeft: 20, marginTop: -5, width: 200 }}
                        onChange={(option) => {

                            switch (option.value) {
                                case 1:
                                    this.setState({ tipo: 'singola', pax: option });
                                    break;
                                case 2: 
                                    this.setState({ tipo: 'doppia', pax: option });
                                    break;
                                case 3: 
                                    this.setState({ tipo: 'gruppo', pax: option });
                                    break;
                                default:
                                    this.setState({ tipo: 'altro', pax: option });
                                    break;
                            }
                        }} 
                    />

                    <h5 style={{ marginLeft: 20, marginTop: 10, color: 'white' }}>{ this.state.tipo === 'singola' ? (
                        'App.to individuale'
                    ) : this.state.tipo === 'doppia' ? (
                        'App.to di coppia'
                    ) : this.state.tipo === 'gruppo' ? (
                        'App.to di gruppo'
                    ) : (
                        <input className='w-placeholder' type={'text'} placeholder={'Nome pacchetto'} maxLength={40} value={this.state.nome} onChange={(e) => this.setState({ nome: e.target.value })} style={{ border: 0, borderBottom: '1px solid white', paddingLeft: 10, paddingRight: 10, width: '100%', paddingTop: 5, paddingBottom: 5, color: 'white', fontSize: 14, backgroundColor: 'transparent' }} />
                    )}</h5>
                    
                    <section style={{ display: 'flex', flexDirection: 'row' }}>
                        <label style={{ marginTop: 25, marginLeft: 20, color: 'white', width: 50 }}>Costo:</label><input className='w-placeholder' type={ this.state.insert ? 'number' : 'text' } step={0.01} style={{ border: 0, marginLeft: 5, marginTop: 15, borderBottom: '1px solid white', paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5, color: 'white', fontSize: 14, backgroundColor: 'transparent', width: '88%' }} value={ this.state.costo } readOnly={this.props.editable === false && this.state.tipo === 'singola'} placeholder={'Prezzo (in €)'} onFocus={() => this.setState({ insert: true })} onChange={(e) => this.setState({ costo: e.target.value })} />
                    </section>
                    <textarea className='w-placeholder' style={{ resize: 'none', backgroundColor: '#FFFFFF33', borderRadius: 10, border: 0, padding: 15, marginTop: 15, marginLeft: 20, maxHeight: 100, width: '90%', color: 'white' }} placeholder={'Descrizione'} value={ this.state.descrizione } onChange={(e) => this.setState({ descrizione: e.target.value }) } />
                </section>

                {this.props.editable && (
                    <FaTrash color="white" size={20} style={{ position: 'absolute', bottom: 10, left: 10, cursor: 'pointer' }} onClick={() => this.props.rimuoviPacchetto(this.props.index)} />
                )}  

            </section>
        );
    }
}