import React, { useEffect, useRef, useState } from 'react';
import Cookies from 'universal-cookie';
import translateString from '../utils/Locales';
import { AiOutlineLogout } from 'react-icons/ai';

import './style.css';
import getAllMessaggi from '../services/getAllMessaggi';

import ChatImg from '../images/chat.png';
import NewMessage from '../images/new-message.png';
import NoMessage from '../images/no_message.png';
import getAllChats from '../services/getAllChats';

const cookies = new Cookies();
let t = translateString;

const MessageItem = (props) => {

    const [messaggio, setMessaggio] = useState(props.messaggio);

    useEffect(() => {

        if (messaggio.length > 60) {
            setMessaggio(messaggio.substring(0, 59) + '...');
        }

        if (messaggio.includes('file:///')) {
            setMessaggio('File');
        }
    }, []);

    return(
        <section className='chatButton' onClick={() => { if (window.location.href.includes('messaggi')) { window.location.href = props.isPaziente ? '/#/paziente/messaggi?professionista=' + props.professionista  : '/#/app/messaggi?paziente=' + props.paziente; window.location.reload(); } else { window.location.href = props.isPaziente ? '/#/paziente/messaggi?professionista=' + props.professionista : '/#/app/messaggi?paziente=' + props.paziente; } props.onSend(); }}>
            <section style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
                { props.letto && ( 
                    <span style={{ background: '#DF4F2F', width: 5, height: 5, borderRadius: 2.5, paddingBottom: 10, marginBottom: 17 }} />
                )}
                <p className='messageTitle' style={{ marginLeft: props.letto ? 10 : 0 }}>{props.nome}</p>
            </section>
            <p className='messageText'>{messaggio}</p>
        </section>
    );
}

export default class Chat extends React.Component {
    constructor(props) {
        super(props);

        this.bottomRef = React.createRef();

        this.state = {
            isLoading: true, 
            messaggi: [],
        };
    }

    async componentDidMount() {

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        let messaggi = await getAllChats(username, token, this.props.paziente ? 'paziente' : 'psicologo');

        this.setState({ isLoading: false, messaggi });
    }

    render() {

        if (this.state.isLoading) return <div />;

        return(
            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100vh' }} onClick={() => this.props.onClose && this.props.onClose()}>
                <div style={menuView} onClick={(e) => e.stopPropagation()}>
                    <div>
                        <section style={account}>
                            <img src={ ChatImg } style={{ width: 40, height: 40 }} alt="Chat image" />
                            <h5 style={{ color: 'white', marginTop: 10, width: '90%', textAlign: 'center' }}>{t('tuoi_messaggi')}</h5>
                        </section>
                        <section style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingTop: 5, paddingBottom: 5 }}>
                            <img src={ NewMessage } title="Nuovo messaggio" style={{ height: 30, width: 30, marginRight: 10, cursor: 'pointer' }} alt="New" />
                        </section>
                        { this.state.messaggi.length === 0 && (
                            <section style={no_messages}>
                                <img src={ NoMessage } style={{ width: 40, height: 40, opacity: 0.5 }} />
                                <p>{t('no_messaggi')}</p>
                            </section>
                        )}
                        { this.state.messaggi.length > 0 && (
                            <section style={messageView}>
                                {this.state.messaggi.map((msg, ind) => {

                                    var letto = false;
                                    if (this.props.paziente ? (msg.mittente === '1' && msg.letto === '0') : (msg.mittente === '2' && msg.letto === '0')) letto = true;

                                    return(
                                        <div key={ind}>
                                            <MessageItem nome={msg.cognome + ' ' + msg.nome} messaggio={msg.messaggio} letto={letto} paziente={parseInt(msg.id_paziente)} professionista={parseInt(msg.id_psicologo)} isPaziente={this.props.paziente} onSend={() => this.props.onClose()} />
                                            { ind !== this.state.messaggi.length - 1 && <div style={separator} /> }
                                        </div>
                                    );
                                })}
                                
                            </section>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const menuView = {
    backgroundColor: 'white',
    width: '300px', 
    maxHeight: '600px',
    position: 'absolute',
    top: 60, 
    right: 85,
    boxShadow: '0px 2px 10px #313131',
    overflowX: 'hidden', 
    overflowY: 'hidden',
    padding: 5,
};

const account = {
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#636465',
    padding: 20,
    width: '100%',
};

const separator = {
    height: '1px',
    backgroundColor: '#F0F0F0',
    widht: '90%',
};

const no_messages = {
    padding: 20, 
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center', 
    justifyContent: 'center', 
    color: '#BBBBBB', 
}; 

const messageView = {
    maxHeight: 300, 
    overflowX: 'hidden', 
    overflowY: 'scroll', 
}