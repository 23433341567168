import React from 'react';
import translateString from '../utils/Locales';
import Cookies from 'universal-cookie';
import Button from '../components/Button';
import Loading from '../components/Loading';
import CalendarComponent from '../components/Calendar';
import moment from 'moment';
import getSedute from '../services/getSedute';
import { getServiceName } from '../utils/consts';
import EditSedutaPazienteWidget from '../widgets/EditSedutaPazienteWidget';

let t = translateString;
const cookies = new Cookies();
let username = cookies.get('username', { path: '/' });
let token = cookies.get('token', { path: '/' });

export default class AgendaSedute extends React.Component {
    constructor(props) {
        super(props);

        this.calendarRef = React.createRef();

        this.state = {
            isLoading: true,  
            scheduleObj: null,
            sedute: [],
            location: undefined,
            calendarDate: moment().toDate()
        };
    }

    onCalendarChange(args) {
        this.setState({ scheduleObj: { ...this.state.scheduleObj, selectedDate: args.value } });
    }

    onDragStart(arg) {
        // eslint-disable-next-line no-param-reassign
        arg.navigation.enable = true;
    }

    async reload() {
        this.setState({ isLoading: true });

        var sedute = [];

        let res = await getSedute(username, token, 'paziente');
        if (!res || res.result === 'KO') {
            return;
        }
        
        res.forEach((s) => {

            sedute.push({
                id: parseInt(s.id), 
                start: moment(s.inizio, 'yyyy-MM-DDTHH:mm').toDate(), 
                end: moment(s.fine, 'yyyy-MM-DDTHH:mm').toDate(), 
                title: s.cognome + ' ' + s.nome,
                type: 'seduta',
            });
        });
        
        this.setState({ isLoading: false, sedute });
    }

    async componentDidMount() {
        window.document.title = getServiceName() + ' - Agenda';
        username = cookies.get('username', { path: '/' });
        token = cookies.get('token', { path: '/' });
        await this.reload();
    }

    changeDates(dates) {
        this.setState({ calendarDate: dates[0] });
    }

    render() {

        if (this.state.isLoading) return <Loading />;

        return(
            <>
                <div className='top-with-btn' style={{ marginBottom: 40 }}>
                    <div style={{ marginRight: 20 }}>
                        <h3 style={{ color: 'white' }}>{t('calendario_sedute')}</h3>
                        <p style={{ color: 'white' }}>Qui troverai tutti i tuoi appuntamenti. Clicca su di uno per vederne i dettagli e l'eventuale ricevuta.</p>
                    </div>
                    <Button ref={this.saveRef} button={t('nuovo').toUpperCase()} animated={false} onClick={() => window.location.href = '/#/main/home'} />
                </div>
                <div className="dashview" style={{ marginBottom: 50, backgroundColor: 'white' }}>
                   <CalendarComponent paziente defaultDate={this.state.calendarDate} events={this.state.sedute} step={60} datesChange={(dates) => this.changeDates(dates)}
                   onSelect={(evento) => {

                        if (evento.type === 'seduta') {
                            this.setState({ editSeduta: true, evento });
                        }
                   }}
                   />
                </div>
                { this.state.editSeduta && (
                    <EditSedutaPazienteWidget seduta={this.state.evento} onClose={(result) => {

                        if (result === 0) {
                            this.reload();
                        }
                        this.setState({ editSeduta: false, evento: undefined });
                    }} />
                )}
            </>
        );
    }
}

