
const initialState = {
    activeMenu: true,
};

function rootReducer(state = initialState, action) {
    
    if (action.type === "SET_MENU") {
        state.activeMenu = !state.activeMenu;
    }
    
    return state;
};

export default rootReducer;