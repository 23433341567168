import Constants from '../utils/consts';

export default async function editTS(documento) {
    return new Promise(function (resolve, reject) {

        fetch(Constants.defaultProtocol + Constants.mainUrl + 'modificaTS.php', {
            method: 'POST', 
            mode: 'cors', 
            body: JSON.stringify({
                'cf_paziente': documento.cfPaziente, 
                'pincode': documento.pincode,
                'cf_proprietario': documento.cfMedico, 
                'user': documento.username, 
                'password': documento.password, 
                'partita_iva': documento.partitaIVA, 
                'data_emissione': documento.dataEmissione, 
                'numero_documento': documento.numeroDocumento, 
                'data_pagamento': documento.dataPagamento, 
                'importo': documento.importo, 
                'iva': documento.iva, 
                'is_tracciato': documento.isTracciato, 
                'tipo_documento': documento.tipo, 
                'flag_opposizione': documento.flagOpposizione,
            }), 
            headers: { 
                'Content-Type': 'application/json'
            }
        })
        .then((res) => res.json())
        .then((data) => {
            resolve(data);
        })
        
    });
}
