import Constants from '../utils/consts';

export default async function signUpPaziente(paziente) {
    return new Promise(function (resolve, reject) {

        fetch(Constants.defaultProtocol + Constants.mainUrl + 'signup.php', {
            method: 'POST', 
            mode: 'cors', 
            body: JSON.stringify({
                'type': 'utente',
                'username': paziente.username, 
                'password': paziente.password, 
                'mail': paziente.mail, 
                'nome': paziente.nome, 
                'cognome': paziente.cognome, 
                'indirizzo': paziente.indirizzo, 
                'citta': paziente.citta, 
                'cap': paziente.cap, 
                'provincia': paziente.provincia, 
                'codice_fiscale': paziente.codice_fiscale,
                "google_info": paziente.google_info,
            }), 
            headers: { 'Content-Type': 'application/json' }
        })
        .then((res) => res.json())
        .then((data) => {
            resolve(data);
        })
        
    });
}
