import React from 'react';
import resetPassword from '../services/resetPassword';

import '../pages/landingPage/style.css';

export default class ResetPasswordWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '', 
        };
    }

    async reset() {
        if (this.state.username.trim()) {

            let res = await resetPassword(this.state.username);
            if (!res || res.result === 'KO') {
                this.setState({ error: true, errorMessage: 'Si è verificato un errore durante il reset della password. Per favore, riprova.'});
                setTimeout(() => {
                    this.setState({ error: false });
                }, 3000);
                return;
            }

            this.setState({ error: false, success: true, successMessage: 'Ti abbiamo inviato un\'email all\'indirizzo con cui ti sei registrato con una password temporanea da utilizzare per l\'accesso.'});

        } else {
            this.setState({ error: true, errorMessage: 'Inserire un nome utente valido'});
            setTimeout(() => {
                this.setState({ error: false });
            }, 3000);
        }
    }

    render() {

        return(
            <div style={backView} onClick={() => this.props.onEnd && this.props.onEnd()}>
                <div style={{ ...mainView, marginTop: this.state.margin }} onClick={(e) => e.stopPropagation()}>
                    <h3 style={{ fontFamily: `url(${require('../fonts/Nexa.ttf')})`, fontWeight: 'bold', fontSize: 30, alignSelf: 'flex-start' }}>Password dimenticata</h3>
                    <p>Inserisci il tuo nome utente o l'indirizzo email per ricevere una password temporanea.</p>

                    { this.state.error && (
                        <p style={error}>{this.state.errorMessage}</p>
                    )}

                    { this.state.success && (
                        <p style={success}>{this.state.successMessage}</p>
                    )}

                    <input style={{ backgroundColor: '#E0E0E0', paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10, borderRadius: 10, width: '100%', marginTop: 25 }} placeholder={'Username o Email *'} type={'text'} value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })} />

                    <div className='loginButton' onClick={() => this.reset()}>{'PROCEDI'}</div>
                </div>
            </div>
        );
    }
}

const backView = {
    position: 'absolute', 
    top: 0, 
    left: 0,
    minWidth: '100vw', 
    minHeight: '100%', 
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#00000066', 
    textAlign: 'center',
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    zIndex: 999999999, 
}

const mainView = {
    position: 'relative',
    width: '80%',
    minHeight: 300,
    backgroundColor: 'white', 
    borderRadius: 10, 
    overflow: 'scroll',
    padding: 30,
    zIndex: 1000000000,
    textAlign: 'left',
}

const error = {
    color: 'red', 
    fontWeight: 'bold', 
    width: '100%', 
    textAlign: 'left',
}

const success = {
    color: 'green', 
    fontWeight: 'bold', 
    width: '100%', 
    textAlign: 'left',
}