import React from "react";
import { FilePicker } from 'react-file-picker';
import { ImAttachment } from 'react-icons/im';

export default class ChatFilePicker extends React.Component {

    constructor (props){
        super(props);

        this.state = {
            base64: this.props.value
        }
    }

    render (){
        return ( 
        <div style={mainClass}>
            <div style={name}>{this.props.name}</div>

            <FilePicker
                extensions={['jpg', 'jpeg', 'png', 'pdf']}
                onChange={file => {
                    this.getBase64(file, this.props.onChange);
                }}
                maxSize={1}
                onError={errMsg => {
                    if (errMsg.includes('size')) alert('Il file selezionato ha una dimensione maggiore di 1MB');
                    if (errMsg.includes('type')) alert('Si possono selezionare solo file con estensione: jpg, jpeg, png e pdf');
                }} >
                    <div style={{ width: 30, height: 30, borderRadius: 15, backgroundColor: 'grey', color: 'white', cursor: 'pointer', marginLeft: 10, marginBottom: -10, marginTop: -5, paddingTop: 8, paddingBottom: 7, paddingRight: 8.5, paddingLeft: 6.5 }} title="Invia allegato" onClick={() => this.send()}>
                        <ImAttachment color="white" size={15} style={{ color: 'white' }} />
                    </div>
            </FilePicker>
        </div>
        )
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

}

const mainClass = {
    padding: '10px',
}

const name = {
    marginBottom: '10px',
}

const picture = {


}

const inputText = {
    width: '300px'
}