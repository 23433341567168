import React from 'react';
import { getServiceName } from '../utils/consts';
import Loading from '../components/Loading';
import Cookies from 'universal-cookie';
import { inviiTable } from '../utils/tables';
import SearchInput from '../components/Search';
import getInviiTesseraSanitaria from '../services/getInviiTesseraSanitaria';
import moment from 'moment';
import EditSedutaWidget from '../widgets/EditSedutaWidget';
import { Box } from '@material-ui/core';
import getDatiTS from '../services/getDatiTS';
import AbbonamentoError from './AbbonamentoError';
import { DataGrid } from '@mui/x-data-grid';

const cookies = new Cookies();

export default class TesseraSanitaria extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,  
            data: undefined,
            editSeduta: false,
            evento: null, 
        };
    }

    async reload() {
        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });

        let invii = await getInviiTesseraSanitaria(username, token);
        this.allAppuntamenti = invii;

        let datiTS = await getDatiTS(username, token);

        if (!datiTS) {
            this.setState({ errorImpostazioni: true, isLoading: false });
            return;
        }
        
        let data = [];
        invii.forEach(invio => {
            data.push({
                id: invio.id, 
                cognome: invio.cognome, 
                nome: invio.nome, 
                inizio: invio.inizio, 
                fine: invio.fine, 
                data: `${moment(invio.inizio.split('T')[0], 'yyyy-MM-DD').format('DD/MM/yyyy')} ${invio.inizio.split('T')[1]}-${invio.fine.split('T')[1]}`, 
                stato: invio.inviata_ts === '1' ? "Inviata" : invio.errore_ts === '1' ? "Errore invio" : "In attesa di invio", 
                numero: invio.numero_fattura, 
                importo: `${invio.importo_pagato ? parseFloat(invio.importo_pagato).toFixed(2) : (0.00).toFixed(2)} €`, 
                protocollo: invio.protocollo_ts, 
                pdfFattura: invio.link_fattura,
            })
        });

        this.invii = data;
        this.setState({ data, isLoading: false, autoSend: datiTS.invio_auto === '1' });
    }

    async componentDidMount() {

        window.document.title = getServiceName() + ' - Sistema Tessera Sanitaria';
        await this.reload();
    }

    handleRowClick(event) {
        let appuntamento = event.row;

        let app = {
            id: parseInt(appuntamento.id), 
            start: moment(appuntamento.inizio, 'yyyy-MM-DDTHH:mm').toDate(), 
            end: moment(appuntamento.fine, 'yyyy-MM-DDTHH:mm').toDate(), 
            title: appuntamento.cognome + ' ' + appuntamento.nome,
            type: 'seduta',
        }
        this.setState({ evento: app, editSeduta: true });
    }

    cerca(testo) {

        if (!testo.trim()) {
            this.setState({ data: this.invii });
            return;
        }

        var data = [];
        this.invii.forEach((documento) => {
            
            if (this.checkFieldPresence(documento.nome, testo) ||
                this.checkFieldPresence(documento.cognome, testo) || 
                this.checkFieldPresence(documento.numero, testo) || 
                this.checkFieldPresence(documento.data, testo) || 
                this.checkFieldPresence(documento.stato, testo)) {
                    data.push(documento);
                }
        });

        this.setState({ data });
    }

    checkFieldPresence(field, needle) {
        if (!field || !needle) return false;
        if (field.toLowerCase().includes(needle.toLowerCase())) return true;
        else return false;
    }

    render() {

        if (this.state.isLoading) return <Loading />;
        if (this.state.errorImpostazioni) return <AbbonamentoError impostazioniTS />;

        return(
            <div>
                <div className="top-with-btn" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: 30 }}>
                    <div style={{ marginRight: 20 }}>
                        <h3 style={{ color: 'white' }}>Invii al Sistema TS</h3>
                        { this.state.autoSend ? (
                            <p style={{ color: 'white' }}>Tutte le fatture nello stato "In attesa di invio" che risultano correttamente pagate verranno inviate al Sistema TS automaticamente durante il weekend. È sempre possibile inviare manualmente la singola fattura all'interno della relativa scheda.</p>
                        ) : (
                            <p style={{ color: 'white' }}>Avendo disattivato l'invio automatico delle fatture pagate al Sistema TS tutti i documenti regolarmente pagati dovranno essere inviati manualmente al Sistema.</p>  
                        )}
                    </div>
                </div>

                <Box style={{ backgroundColor: '#FFE2E2', flex: 1, borderRadius: 5, padding: 30, marginBottom: 20 }}>
                    <p style={{ fontSize: 12, color: '#FF6666', textAlign: 'justify', marginBottom: 0 }}>Si ricorda che è possibile effettuare solo operazioni di invio, modifica ed eliminazione dei documenti. Per le operazioni di rimborso si rimanda direttamente al <a href="https://sistemats4.sanita.finanze.it/simossHome/login.jsp" target="_blank" >portale</a> del Sistema Tessera Sanitaria.<br /><b>Attenzione:</b> PersonalDoc declina ogni responsabilità in caso di eventuali errori. Consigliamo di accedere spesso al portale per poter verificare correttamente i dati inseriti.</p>
                </Box>

                <SearchInput onCerca={(testo) => {
                    this.cerca(testo);
                }} />
                <DataGrid
                    sx={{
                        ".MuiTablePagination-displayedRows": {
                            "margin-top": "1em",
                            "margin-bottom": "1em"
                        },
                        "#mui-2.MuiTablePagination-selectLabel": {
                            "margin-top": "1em",
                        }
                    }}
                    rows={this.state.data}
                    columns={inviiTable}
                    style={{ backgroundColor: 'white' }}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[5, 10, 20]}
                    onRowClick={(event) => this.handleRowClick(event)}
                />

                { this.state.editSeduta && (
                    <EditSedutaWidget seduta={this.state.evento} onClose={(result) => {
                        if (result === 0) this.reload();
                        this.setState({ editSeduta: false, evento: null });
                    }} />
                )}
            </div>
        );
    }
}