import Constants from '../utils/consts';

export default async function savePaginaPersonale(username, token, pagina) {
    return new Promise(function (resolve, reject) {

        fetch(Constants.defaultProtocol + Constants.mainUrl + 'updatePaginaPersonale.php', {
            method: 'POST', 
            mode: 'cors', 
            body: JSON.stringify({
                'foto': pagina.foto, 
                'titolo': pagina.titolo, 
                'descrizione': pagina.descrizione, 
                'email': pagina.mail, 
                'telefono': pagina.telefono, 
                'cellulare': pagina.cellulare, 
                'indirizzo': pagina.indirizzo, 
                'show_piani': pagina.show_piani,
                'show_servizi': pagina.show_servizi, 
                'pubblicata': pagina.pubblicata
            }), 
            headers: { 
                'Authorization': 'Bearer ' + username + '.' + token, 
                'Content-Type': 'application/json'
            }
        })
        .then((res) => res.json())
        .then((data) => {
            resolve(data);
        })
        
    });
}
