import React from 'react';

export default class ChatWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {

        let daLeggere = !this.props.paziente ? (this.props.messaggio.letto === '0' && this.props.messaggio.mittente === '2') : (this.props.messaggio.letto === '0' && this.props.messaggio.mittente === '1');
        var letto = undefined;

        return(
            <section className='chat' style={{ backgroundColor: this.props.selected ? '#E3E9F9' : '', marginTop: this.props.localKey === 0 ? 0 : 1 }} onClick={() => { this.props.onSelect(parseInt(this.props.messaggio.id_psicologo), parseInt(this.props.messaggio.id_paziente)); }}>
                <section style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
                    { (daLeggere && !letto) && ( 
                        <span style={{ background: '#DF4F2F', width: 5, height: 5, borderRadius: 2.5, paddingBottom: 10, marginBottom: 17 }} />
                    )}
                    <p className='messageSender' style={{ marginLeft: daLeggere ? 10 : 0 }}>{this.props.messaggio.cognome} {this.props.messaggio.nome}</p>
                </section>
                <p className='messageBody'>{this.props.messaggio.messaggio.includes('file:///') ? 'File' : this.props.messaggio.messaggio.length <= 60 ? this.props.messaggio.messaggio : this.props.messaggio.messaggio.substring(0, 59) + '...'}</p>
                <div className='list-separator' />
            </section>
        );
    }
}