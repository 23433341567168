import React from 'react';
import { getServiceName } from '../utils/consts';
import Cookies from 'universal-cookie';
import { Button, Card, Container, TextField } from '@material-ui/core';
import { Stack } from '@mui/system';
import getAbbonamento from '../services/getAbbonamento';
import Loading from '../components/Loading';
import isFreePeriodActive from '../services/isFreePeriodActive';
import getProfiloProfessionista from '../services/getProfiloProfessionista';
import changePassword from '../services/changePassword';
import updateProfessionista from '../services/updateProfessionista';
import addPromemoria from '../services/addPromemoria';
import moment from 'moment';

const cookies = new Cookies();
let username = cookies.get('username', { path: '/' });
let token = cookies.get('token', { path: '/' });

export default class Account extends React.Component {
    constructor(props) {
        super(props);

        this.abbonamento = {};

        this.state = {
            isLoading: true,  
            pwdAttuale: '', 
            pwdNuova: '', 
            pwdConfirm: '',
            error: '',
        };
    }

    async componentDidMount() {
        window.document.title = getServiceName() + " - Account";

        username = cookies.get('username', { path: '/' });
        token = cookies.get('token', { path: '/' });

        await this.reload();
    }

    async reload() {
        this.abbonamento = await getAbbonamento(username, token);
        this.provaGratuita = await isFreePeriodActive(username, token);
        this.account = await getProfiloProfessionista(username, token);

        this.setState({ isLoading: false, professionista: this.account });
    }

    async changePasswordRequest() {

        if ((!this.state.professionista.is_google && !this.state.pwdAttuale.trim()) || !this.state.pwdNuova.trim() || !this.state.pwdConfirm.trim()) {
            alert('Inserire tutti i valori richiesti prima di procedere');
            return;
        }

        if (this.state.pwdNuova.trim() !== this.state.pwdConfirm.trim()) {
            alert('Le password non coincidono');
            return;
        }

        if (this.state.pwdNuova.includes(' ')) {
            alert('La password non può contenere spazi');
            return;
        }

        const res = await changePassword(username, token, this.state.pwdAttuale.trim(), this.state.pwdNuova.trim());
        if (res.result === 'KO') {
            alert(res.message);
            return;
        }

        alert('Password modificata con successo');
        this.setState({ pwdAttuale: '', pwdNuova: '', pwdConfirm: '' });
    }

    removeError(err) {
        let e = this.state.error.split(',');
        while (e.indexOf(err) !== -1) {
            var found = false;
            e.forEach((errore, i) => {
                if (errore === err && !found)
                    e.splice(i, 1);
            });
        }
        this.setState({ error: e.join(',') });
    }

    addError(err) {
        this.removeError(err);
        let e = this.state.error.split(',');
        e.push(err);
        this.setState({ error: e.join(',') });
    }

    validateProfessionista() {

        this.setState(() => ({ error: '' }), () => {
            let error = false;
            if (!this.state.professionista.nome) {
                this.addError('nome');
                error = true;
            }

            if (!this.state.professionista.cognome) {
                this.addError('cognome');
                error = true;
            }

            if (!this.state.professionista.mail) {
                this.addError('mail');
                error = true;
            }

            if (!this.state.professionista.piva) {
                this.addError('piva');
                error = true;
            }

            if (!this.state.professionista.codice_fiscale) {
                this.addError('codice_fiscale');
                error = true;
            }

            if (!this.state.professionista.indirizzo) {
                this.addError('indirizzo');
                error = true;
            }

            if (!this.state.professionista.citta) {
                this.addError('citta');
                error = true;
            }

            if (!this.state.professionista.provincia) {
                this.addError('provincia');
                error = true;
            }

            if (!this.state.professionista.cap) {
                this.addError('cap');
                error = true;
            }

            if (error) return;
            
            this.saveProfessionista();
        });
    }

    async saveProfessionista() {

        let res = await updateProfessionista(username, token, this.state.professionista);
        alert(res.description);
    }

    async insertPromemoria() {

        if (!this.state.testoPromemoria) {
            alert('Inserire un testo per il promemoria');
            return;
        }

        if (!this.state.dataPromemoria) {
            alert('Inserire la data in cui ricevere il promemoria');
            return;
        }

        let res = await addPromemoria(username, token, this.state.testoPromemoria.trim(), moment(this.state.dataPromemoria).format('DD/MM/yyyy'));
        if (res.result === 'KO') {
            alert('Si è verificato un errore');
            return;
        }

        this.setState({ testoPromemoria: null, dataPromemoria: null });
        alert('Promemoria inserito con successo');
        window.location.reload();
    }

    render() {

        if (this.state.isLoading) return <Loading />;

        return(
            <Container style={{ width: '100%', marginBottom: 100 }}>
                <h3 style={{ color: 'white', width: '100%', textAlign: 'left', marginTop: 20, marginBottom: 30 }}>Impostazioni profilo</h3>
                <Stack spacing={2}>
                    <Card style={{ backgroundColor: 'white', borderRadius: 5, padding: 30 }}>
                        <h6>Il tuo account</h6>
                        <p style={{ marginTop: 5 }}>Al momento stai usando un account di tipo: <b>{this.abbonamento.nome}</b></p>
                        { this.abbonamento.id === '0' ? (
                            <>
                                <p>Durante la prova gratuita ti sono concesse 30 fatture per i tuoi colloqui. Al momento ti restano: <b>{30 - parseInt(this.provaGratuita.fatture_emesse)}</b> fatture.</p>
                            </>
                        ) : (
                            <>
                                { this.account.in_scadenza === '1' ? (
                                    <p>Il tuo abbonamento scadrà in data <b>{this.account.data_rinnovo}</b>. In quel momento tornerai ad usufruire del piano FREE.</p>
                                ) : (
                                    <p>Il tuo abbonamento si rinnoverà al prezzo di <b>€ {this.abbonamento.prezzo}</b> in data <b>{this.account.data_rinnovo}</b>.</p>
                                )}
                            </>
                        )}
                        <Button size="large" type="submit" variant="contained" onClick={() => window.location.href = '/#/app/pacchetti' }>
                            Gestisci piani di abbonamento
                        </Button>
                    </Card>
                    
                    <Card style={{ backgroundColor: 'white', borderRadius: 5, padding: 30 }}>
                        <h6>Cambio password</h6>
                        <Stack spacing={2}>
                            {!this.state.professionista.is_google && ( <TextField fullWidth type={'password'} value={this.state.pwdAttuale} label="Password attuale *" onChange={(e) => this.setState({ pwdAttuale: e.target.value })} /> )}
                            <TextField fullWidth type={'password'} value={this.state.pwdNuova} label="Nuova password *" onChange={(e) => this.setState({ pwdNuova: e.target.value })} />
                            <TextField fullWidth type={'password'} value={this.state.pwdConfirm} label="Conferma nuova password *" onChange={(e) => this.setState({ pwdConfirm: e.target.value })} />
                            <Button fullWidth size="large" type="submit" variant="contained" onClick={() => this.changePasswordRequest()}>
                                Cambia password
                            </Button>
                        </Stack>
                    </Card>

                    <Card style={{ backgroundColor: 'white', borderRadius: 5, padding: 30 }}>
                        <h6>Le tue informazioni</h6>
                        <Stack spacing={2}>
                            <Stack direction="row" spacing={2}>
                                <TextField error={this.state.error.includes(',nome')} fullWidth value={this.state.professionista.nome} label="Nome *" onChange={(e) => {
                                    let p = this.state.professionista;
                                    p.nome = e.target.value;
                                    this.setState({ professionista: p });
                                }} />
                                <TextField error={this.state.error.includes('cognome')} fullWidth value={this.state.professionista.cognome} label="Cognome *" onChange={(e) => {
                                    let p = this.state.professionista;
                                    p.cognome = e.target.value;
                                    this.setState({ professionista: p });
                                }} />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <TextField error={this.state.error.includes('mail')} fullWidth value={this.state.professionista.mail} label="Email *" onChange={(e) => {
                                    let p = this.state.professionista;
                                    p.mail = e.target.value;
                                    this.setState({ professionista: p });
                                }} />
                                <TextField error={this.state.error.includes('codice_fiscale')} fullWidth value={this.state.professionista.codice_fiscale} label="Codice fiscale *" onChange={(e) => {
                                    let p = this.state.professionista;
                                    p.codice_fiscale = e.target.value;
                                    this.setState({ professionista: p });
                                }} />
                                <TextField error={this.state.error.includes('piva')} fullWidth type={'number'} value={this.state.professionista.piva} label="Partita IVA *" onChange={(e) => {
                                    let p = this.state.professionista;
                                    p.piva = e.target.value;
                                    this.setState({ professionista: p });
                                }} />
                            </Stack>
                            <Stack direction="row" spacing={2} style={{ marginTop: 40 }}>
                                <TextField error={this.state.error.includes('indirizzo')} fullWidth value={this.state.professionista.indirizzo} label="Indirizzo *" onChange={(e) => {
                                    let p = this.state.professionista;
                                    p.indirizzo = e.target.value;
                                    this.setState({ professionista: p });
                                }} />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <TextField error={this.state.error.includes('citta')} fullWidth value={this.state.professionista.citta} label="Città *" onChange={(e) => {
                                    let p = this.state.professionista;
                                    p.citta = e.target.value;
                                    this.setState({ professionista: p });
                                }} />
                                <TextField error={this.state.error.includes('provincia')} fullWidth value={this.state.professionista.provincia} inputProps={{ maxLength: 3 }} label="Provincia (sigla) *" onChange={(e) => {
                                    let p = this.state.professionista;
                                    p.provincia = e.target.value.toUpperCase();
                                    this.setState({ professionista: p });
                                }} />
                                <TextField error={this.state.error.includes('cap')} fullWidth value={this.state.professionista.cap} label="CAP *" onChange={(e) => {
                                    let p = this.state.professionista;
                                    p.cap = e.target.value;
                                    this.setState({ professionista: p });
                                }} />
                            </Stack>
                            <Button fullWidth size="large" type="submit" variant="contained" onClick={() => this.validateProfessionista()}>
                                Salva
                            </Button>
                        </Stack>
                    </Card>

                    <Card style={{ backgroundColor: 'white', borderRadius: 5, padding: 30 }}>
                        <h6>Inserisci un promemoria</h6>
                        <Stack spacing={2}>
                            <TextField fullWidth type={'text'} inputProps={{ maxLength: 150 }} value={this.state.testoPromemoria} label="Testo del promemoria (max 150 caratteri) *" onChange={(e) => this.setState({ testoPromemoria: e.target.value })} />
                            <TextField fullWidth type={'date'} InputLabelProps={{ shrink: true }} value={this.state.dataPromemoria} label="Data del promemoria *" onChange={(e) => this.setState({ dataPromemoria: e.target.value })} />
                            <Button fullWidth size="large" type="submit" variant="contained" onClick={() => this.insertPromemoria()}>
                                Aggiungi
                            </Button>
                        </Stack>
                    </Card>
                </Stack>
            </Container>
        );
    }
}