import React from 'react';
import { MeteorRainLoading} from 'react-loadingg';
import Cookies from 'universal-cookie';
import getAllMessaggi from '../services/getAllMessaggi';
import getChat from '../services/getSingolaChat';
import setMessaggiLetti from '../services/setMessaggiLetti';
import { getServiceName } from '../utils/consts';
import translateString from '../utils/Locales';
import ChatBubble from '../widgets/ChatBubble';
import ChatWidget from '../widgets/ChatWidget';
import LoadingMessages from '../widgets/LoadingMessages';
import { FiSend } from 'react-icons/fi';
import sendMessaggio from '../services/sendMessaggio';
import moment from 'moment';
import NuovoMessaggioWidget from '../widgets/nuovoMessaggioWidget';
import ChatFilePicker from '../components/FilePicker';
import FileBubble from '../widgets/FileBubble';

import ChatImg from '../images/no-chat.png';
import NewMessage from '../images/new-message.png';
import NoChat from '../images/no-chat.png';
import NuovoMessaggioPazienteWidget from '../widgets/nuovoMessaggioPazienteWidget';
import getAllChats from '../services/getAllChats';

const qs = require('querystring');
const cookies = new Cookies();
let t = translateString;

export default class MessaggiPaziente extends React.Component {
    constructor(props) {
        super(props);

        this.bottomRef = React.createRef();

        this.state = { 
            isLoading: true, 
            completeMessaggi: [], 
            messaggi: [],
            selectedPsicologo: undefined,
            nuovoMessaggio: '',
            nuovaChat: false,
        };
    }

    async componentDidMount() {

        window.document.title = getServiceName() + ' - Messaggi';

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        let messaggi = await getAllChats(username, token, 'paziente');
        let chunks = qs.parse(window.location.hash);
         
        var p = undefined;
        if (chunks['#/paziente/messaggi?professionista']) {
            p = chunks['#/paziente/messaggi?professionista'];
        }

        if (p) {
            await this.getChatMessages(p, messaggi[0].id_paziente);
            await setMessaggiLetti(username, token, p, 'paziente');

            messaggi.forEach((mess, i) => {
                if (mess.id_psicologo === p)
                    messaggi[i].letto = '1'
            })
        }

        setTimeout(() => {
            this.setState({ isLoading: false, messaggi });
        }, 2000);

    } 
    
    async getChatMessages(id_psicologo, id_paziente) {

        this.setState({ loading: true });
        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        let msg = await getChat(username, token, id_psicologo, id_paziente);

        if (msg.length === 0) {
            // Nuova chat
            this.setState({ nuovaChat: true, prefilledProfessionista: id_psicologo });
        }

        var msgs = this.state.messaggi;
        msgs.forEach((m, ind) => {
            if (parseInt(m.id_psicologo) === parseInt(id_psicologo)) {
                var ms = m;
                ms.letto = '1';
                msgs[ind] = ms;
            }
        });

        this.setState({ loading: false, selectedPsicologo: parseInt(id_psicologo), completeMessaggi: msg, messaggi: msgs });
    }

    async send() {
        if (!this.state.nuovoMessaggio.trim()) {
            return;
        }


        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        let res = await sendMessaggio(username, token, this.state.selectedPsicologo, 'paziente', this.state.nuovoMessaggio);
        if (!res || res.result === 'KO') {
            alert(t('errore_imprevisto'));
            return;
        }

        let msgs = this.state.completeMessaggi;
        msgs.push({
            id_psicologo: this.state.selectedPsicologo, 
            mittente: '2', 
            messaggio: this.state.nuovoMessaggio, 
            data: moment().format('yyyy-MM-DDTHH:mm')
        });

        let chats = this.state.messaggi;
        chats.forEach((c, ind) => {

            if (parseInt(c.id_psicologo) === this.state.selectedPsicologo) {
                chats.splice(ind, 1);
                let chat = c;
                c.messaggio = this.state.nuovoMessaggio;
                chats.splice(0, 0, chat);
            }
        });

        this.setState({ completeMessaggi: msgs, messaggi: chats, nuovoMessaggio: '' });
    }

    componentDidUpdate() {

        if (this.state.selectedPsicologo && this.bottomRef.current) {
            this.bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    async sendFile(file) {

        if (!file) {
            return;
        }

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        let res = await sendMessaggio(username, token, this.state.selectedPsicologo, 'paziente', 'file:///' + file);
        if (!res || res.result === 'KO') {
            alert(t('errore_imprevisto'));
            return;
        }

        let msgs = this.state.completeMessaggi;
        msgs.push({
            id_paziente: this.state.selectedPsicologo, 
            mittente: '2', 
            messaggio: 'file:///' + file, 
            data: moment().format('yyyy-MM-DDTHH:mm')
        });

        let chats = this.state.messaggi;
        chats.forEach((c, ind) => {

            if (parseInt(c.id_paziente) === this.state.selectedPsicologo) {
                chats.splice(ind, 1);
                let chat = c;
                c.messaggio = 'file:///' + file;
                chats.splice(0, 0, chat);
            }
        });

        this.setState({ completeMessaggi: msgs, messaggi: chats });
    }

    render() {

        return(
            <div className='message-page'>
                <section className='chat-list'>
                    { this.state.isLoading ? (
                        <LoadingMessages />
                    ) : (
                        <>
                            <div style={{ paddingTop: 15 }}>
                                <section style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingTop: 5, paddingBottom: 5 }}>
                                    <img src={ NewMessage } title="Nuovo messaggio" style={{ height: 30, width: 30, marginRight: 10, cursor: 'pointer' }} alt="New" onClick={() => this.setState({ nuovaChat: true })} />
                                </section>
                                { this.state.messaggi.length === 0 && (
                                    <section style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={ NoChat } alt="No chat" style={{ width: 50, height: 50, opacity: 0.6 }} />
                                        <p style={{ marginTop: 20, width: '90%', textAlign: 'center', color: 'white', fontWeight: 'bold', opacity: 0.7 }}>{t('no_messaggi')}</p>
                                    </section>
                                )}
                                { this.state.messaggi.map((msg, ind) => {
                                    
                                    return(
                                        <ChatWidget paziente key={ind} localKey={ind} messaggio={msg} selected={this.state.selectedPsicologo === parseInt(msg.id_psicologo)} onSelect={async (psicologo, paziente) => {
                                            
                                            this.getChatMessages(psicologo, paziente);
                                            
                                            let username = cookies.get('username', { path: '/' });
                                            let token = cookies.get('token', { path: '/' });
                                            await setMessaggiLetti(username, token, psicologo, 'paziente');
                                        }} />
                                    );
                                })}
                            </div>
                        </>
                    )}
                </section>
                <section className='conversation-view'>
                    { (this.state.isLoading || this.state.loading) ? (
                        <div style={{ position: 'absolute', left: '40%', top: '50%' }}>
                            <MeteorRainLoading />
                        </div>
                    ) : (
                        <>
                            { (!this.state.selectedPsicologo && this.state.messaggi.length > 0) && (
                                <section style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={ ChatImg } alt="No chat" style={{ width: 50, height: 50, opacity: 0.6 }} />
                                    <p style={{ marginTop: 20, width: '90%', textAlign: 'center', color: '#5B5B5B', fontWeight: 'bold', opacity: 0.7 }}>{t('no_conversazioni')}</p>
                                </section>
                            )}
                            { this.state.selectedPsicologo && (
                                <section style={{ position: 'relative', height: '100%', width: '100%' }}>
                                    <section style={{ position: 'absolute', top: 20, left: 20, right: 20, bottom: 120, overflowX: 'hidden', overflowY: 'scroll', display: 'inherit' }}>
                                        { this.state.completeMessaggi.map((messaggio, ind) => {
                                            
                                            if (messaggio.messaggio.includes('file:///')) { 
                                                return <FileBubble key={ind} messaggio={messaggio} tipo={'paziente'} />
                                            } else return(
                                                <ChatBubble key={ind} messaggio={messaggio} tipo={'paziente'} />
                                            );
                                        })}
                                        <div id="dummy_div_bottom" ref={this.bottomRef} /> 
                                    </section>
                                    <section style={{ position: 'absolute', bottom: 20, left: 20, right: 20, height: 95, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <textarea style={{ resize: 'none', borderRadius: 10, border: '1px solid #F0F0F0', height: 95, maxHeight: 95, width: 'calc(100% - 50px)', paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10 }} placeholder={t('nuovo_msg')} value={this.state.nuovoMessaggio} onChange={(e) => this.setState({ nuovoMessaggio: e.target.value })} />
                                        <section>
                                            <ChatFilePicker onChange={(base64) => this.sendFile(base64)} />
                                            <div style={{ width: 30, height: 30, borderRadius: 15, backgroundColor: '#7B90C4', color: 'white', marginLeft: 20, cursor: 'pointer', paddingTop: 8, paddingBottom: 7, paddingRight: 8.5, paddingLeft: 6.5, marginTop: 5 }} title="Invia messaggio" onClick={() => this.send()}>
                                                <FiSend color="white" size={15} />
                                            </div>
                                        </section>
                                    </section>
                                </section>
                            )}
                        </>
                    )}
                </section>

                { this.state.nuovaChat && (
                    <NuovoMessaggioPazienteWidget prefilledProfessionista={this.state.prefilledProfessionista} onClose={(result) => {

                        this.setState({ nuovaChat: false });
                        if (result === 0) window.location.reload();
                    }} />
                )}

            </div>
        );
    }
}