import Constants from '../utils/consts';

export default async function organizzaOnlineMeeting(username, token, meeting) {
    return new Promise(function (resolve, reject) {

        fetch(Constants.defaultProtocol + Constants.mainUrl + 'organizzaOnlineMeeting.php', {
            method: 'POST', 
            mode: 'cors', 
            body: JSON.stringify({
                'durata': meeting.durata, 
                'titolo': meeting.titolo, 
                'data': meeting.data, 
                'oggetto': meeting.oggetto
            }), 
            headers: { 
                'Authorization': 'Bearer ' + username + '.' + token, 
                'Content-Type': 'application/json'
            }
        })
        .then((res) => res.json())
        .then((data) => {
            resolve(data);
        })
        
    });
}
