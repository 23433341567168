import Constants from '../utils/consts';

export default async function searchProfessionista(query, type) {
    return new Promise(function (resolve, reject) {

        fetch(Constants.defaultProtocol + Constants.mainUrl + 'searchProfessionista.php', {
            method: 'POST', 
            mode: 'cors', 
            body: JSON.stringify({
                'query': query, 
                'type': type
            }), 
            headers: { 
                'Content-Type': 'application/json'
            }
        })
        .then((res) => res.json())
        .then((data) => {
            resolve(data);
        })
    });
}
