import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

type MultiSelectProps = {
  names: string[], 
  onChangeListener: Function,
  sel: string[], 
  label: string, 
  single: boolean, 
  noSmall: boolean, 
  fullWidth: boolean, 
  oneSelected: boolean, 
  medium: boolean, 
}

const MultiSelect: React.FunctionComponent<MultiSelectProps> = (props) => {

  const { names, onChangeListener, sel, label, single, noSmall, fullWidth, oneSelected, medium } = props;
  const theme = useTheme();
  const [personName, setPersonName] = React.useState<string[]>([]);

  React.useEffect(() => {
      if (sel) setPersonName(sel);
  }, [sel]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;

    if (oneSelected && ((typeof value === 'string' && !value) || (typeof value !== 'string' && value.length === 0))) return;

    const final = typeof value === 'string' ? value.split(',') : value;
    let change: string | string[] = [];
    if (single) {
      if (typeof value === 'string' && final.length > 0)
        change = [final[final.length - 1]];
      else if (final.length > 0)
        change = [final[final.length - 1]];
    } else {
      change = final;
    }
    setPersonName(single ? change : (typeof value === 'string' ? final : value));

    if (onChangeListener) {
      onChangeListener(single ? change : (typeof value === 'string' ? final : value));
    }
  };

  return (
    <div>
      <FormControl sx={{ width: noSmall ? medium ? 150 : 250 : fullWidth ? '100%' : 215 }}>
        <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label={label} />}
          renderValue={(selected) => (
            <Box sx={single ? { display: 'flex', flexWrap: 'wrap', gap: 0.5, height: 20, mt: -0.5 } : { display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: -0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default MultiSelect;