import React from 'react';
import { getServiceName } from '../utils/consts';
import Cookies from 'universal-cookie';
import { Button, Card, Container } from '@material-ui/core';
import { Stack } from '@mui/system';
import Loading from '../components/Loading';
import getProfiloProfessionista from '../services/getProfiloProfessionista';
import getAbbonamenti from '../services/getAbbonamenti';
import getAbbonamento from '../services/getAbbonamento';

const cookies = new Cookies();
let username = cookies.get('username', { path: '/' });
let token = cookies.get('token', { path: '/' });

export default class ManageSubscription extends React.Component {
    constructor(props) {
        super(props);

        this.abbonamento = {};
        this.payPalRefs = [];

        this.state = {
            isLoading: true, 
        };
    }

    async componentDidMount() {
        window.document.title = getServiceName() + " - Pacchetti";
        await this.reload();
    }

    async reload() {

        username = cookies.get('username', { path: '/' });
        token = cookies.get('token', { path: '/' });
        
        this.abbonamenti = await getAbbonamenti(username, token);
        this.abbonamento = await getAbbonamento(username, token);

        this.abbonamenti.forEach((a, i) => {
            this.payPalRefs.push(React.createRef());
        });

        this.setState(() => ({ isLoading: false }), () => {
            // this.setPayPalButton();
        });
    }

    render() {

        if (this.state.isLoading) return <Loading />;

        return(
            <Container style={{ width: '100%', marginBottom: 100 }}>
                <h3 style={{ color: 'white', width: '100%', textAlign: 'left', marginTop: 20, marginBottom: 30 }}>I pacchetti PersonalDoc</h3>
                <Stack spacing={2}>
                    { this.abbonamenti.map((a, i) => (
                        <Card key={Math.random()} style={{ backgroundColor: 'white', borderRadius: 5, padding: 30 }}>
                            <h4>{a.nome}</h4>
                            { this.abbonamento.id === a.id && ( <p style={{ marginTop: 5, paddingLeft: 8, paddingTop: 5, paddingBottom: 5, backgroundColor: '#DBFFD2', borderRadius: 3, color: '#418A2F', width: 210 }}>Piano attualmente sottoscritto</p> )}
                            <p style={{ marginTop: 5 }}>{a.descrizione}</p>
                            { (this.abbonamento.id === '0' && a.id !== '0') && (
                                <Button size="large" type="submit" variant="contained" onClick={() => {
                                    cookies.set('selectedPlan', a.id, { path: '/' });
                                    window.location.href = '/#/app/checkout';
                                }}>
                                    Sottoscrivi questo piano
                                </Button>
                            )}
                        </Card>
                    ))}
                </Stack>
            </Container>
        );
    }
}