import Constants from '../utils/consts';

export default async function getIdPersonale(username, token) {
    return new Promise(function (resolve, reject) {

        fetch(Constants.defaultProtocol + Constants.mainUrl + 'getIdPersonale.php', {
            method: 'POST', 
            mode: 'cors', 
            body: JSON.stringify({ }), 
            headers: { 
                'Authorization': 'Bearer ' + username + '.' + token, 
                'Content-Type': 'application/json'
            }
        })
        .then(async (res) => resolve(await res.json()))
        
    });
}
