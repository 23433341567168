import Constants from '../utils/consts';

export default async function checkStripeSuccess(acct) {
    return new Promise(function (resolve, reject) {

        fetch(Constants.defaultProtocol + Constants.mainUrl + 'checkStripeAccountCreation.php', {
            method: 'POST', 
            mode: 'cors', 
            body: JSON.stringify({
                'account_id': acct
            }), 
            headers: { 'Content-Type': 'application/json' }
        })
        .then((res) => res.json())
        .then((data) => {
            resolve(data);
        })
        
    });
}
