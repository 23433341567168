import React from "react";
import {
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import { withStyles } from '@material-ui/styles';

import LandingPage from "../../pages/landingPage/landingPage";
import VerifyEmail from "../../pages/landingPage/VerifyEmail";
import ComingSoonPage from "../../pages/landingPage/comingSoon";
import Login from "../../pages/landingPage/Login";
import PsicologiSignUp from "../../pages/landingPage/PsicologiSignUp";
import Signup from "../../pages/landingPage/Signup";
import StripeSuccess from "../../pages/landingPage/StripeSuccess";
import StripeError from "../../pages/landingPage/StripeError";
import Prenota from "../../pages/landingPage/Prenota";
import Riepilogo from "../../pages/landingPage/Riepilogo";
import TSTest from "../../pages/landingPage/TSTest";
import SucessPage from "../../pages/landingPage/SucessPage";
import CancelPage from "../../pages/landingPage/CancelPage";
import Terms from "../../pages/landingPage/Terms";
import Privacy from "../../pages/landingPage/Privacy";
import GoogleDisclosure from "../../pages/landingPage/GoogleDisclosure";

const makeStyles = theme => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    overflowY: 'scroll',
    overflowX: "hidden",
  }
});

class MainLayout extends React.Component {

  constructor(props){
    super(props)
    this.state = { }
  }

  async componentDidMount(){

  }


  render (){

    // global
    const { classes } = this.props;

    return (
      <div className={classes.root} style={{ overflowY: 'scroll' }}>
          <>
              <Switch>
                <Route path='/main/working' component={ComingSoonPage} />
                <Route path='/main/home' component={LandingPage} />
                <Route path='/main/verify' component={VerifyEmail} />
                <Route path='/main/login' component={Login} />
                <Route path='/main/psicologoSignup' component={PsicologiSignUp} />
                <Route path='/main/signup' component={Signup} />
                <Route path='/main/stripeSuccess' component={StripeSuccess} />
                <Route path='/main/stripeError' component={StripeError} />
                <Route path='/main/prenota' component={Prenota} />
                <Route path='/main/riepilogo' component={Riepilogo} />
                <Route path='/main/success' component={SucessPage} />
                <Route path='/main/cancel' component={CancelPage} />
                { /*<Route path='/main/tstest' component={TSTest} />*/ }
                <Route path='/main/terms-and-conditions' component={Terms} />
                <Route path='/main/privacy' component={Privacy} />
                <Route path='/main/google-disclosure' component={GoogleDisclosure} />
              </Switch>
          </>
      </div>
    );
  }
  
}

export default withStyles(makeStyles)(withRouter(MainLayout));
