import moment from 'moment';
import React from 'react';
import translateString from '../utils/Locales';
import SelectInput from './SelectInput';

let t = translateString;
 
export default class OrariWidget extends React.Component {
    constructor(props) {
        super(props);

        this.slots = [
            { value: 0, label: '00:00' },
            { value: 0.5, label: '00:30' },
            { value: 1, label: '01:00' },
            { value: 1.5, label: '01:30' },
            { value: 2, label: '02:00' },
            { value: 2.5, label: '02:30' },
            { value: 3, label: '03:00' },
            { value: 3.5, label: '03:30' },
            { value: 4, label: '04:00' },
            { value: 4.5, label: '04:30' },
            { value: 5, label: '05:00' },
            { value: 5.5, label: '05:30' },
            { value: 6, label: '06:00' },
            { value: 6.5, label: '06:30' },
            { value: 7, label: '07:00' },
            { value: 7.5, label: '07:30' },
            { value: 8, label: '08:00' },
            { value: 8.5, label: '08:30' },
            { value: 9, label: '09:00' },
            { value: 9.5, label: '09:30' },
            { value: 10, label: '10:00' },
            { value: 10.5, label: '10:30' },
            { value: 11, label: '11:00' },
            { value: 11.5, label: '11:30' },
            { value: 12, label: '12:00' },
            { value: 12.5, label: '12:30' },
            { value: 13, label: '13:00' },
            { value: 13.5, label: '13:30' },
            { value: 14, label: '14:00' },
            { value: 14.5, label: '14:30' },
            { value: 15, label: '15:00' },
            { value: 15.5, label: '15:30' },
            { value: 16, label: '16:00' },
            { value: 16.5, label: '16:30' },
            { value: 17, label: '17:00' },
            { value: 17.5, label: '17:30' },
            { value: 18, label: '18:00' },
            { value: 18.5, label: '18:30' },
            { value: 19, label: '19:00' },
            { value: 19.5, label: '19:30' },
            { value: 20, label: '20:00' },
            { value: 20.5, label: '20:30' },
            { value: 21, label: '21:00' },
            { value: 21.5, label: '21:30' },
            { value: 22, label: '22:00'},
            { value: 22.5, label: '22:30'},
            { value: 23, label: '23:00' },
            { value: 23.5, label: '23:30' },
        ];

        this.orari = [];

        this.slots.forEach((s) => {
            this.orari.push({ value: s.label, label: s.label });
        });

        let dalle = { value: null, label: 'Dalle...' };
        let alle = { value: null, label: 'Alle...' };

        this.state = {
            dalle1: this.props.orari ? this.props.orari.slot1.dalle : dalle,
            alle1: this.props.orari ? this.props.orari.slot1.alle : alle,
            dalle2: this.props.orari ? this.props.orari.slot2.dalle : dalle,
            alle2: this.props.orari ? this.props.orari.slot2.alle : alle,
        };
    }

    getOrari() {

        if (!this.state.dalle1.value && !this.state.alle1.value) {
            let orari = {
                slot1: { dalle: { value: null, label: 'Dalle...' }, alle: { value: null, label: 'Alle...' } },
                slot2: { dalle: { value: null, label: 'Dalle...' }, alle: { value: null, label: 'Alle...' } }
            };
    
            return orari;
        }

        if (!this.state.dalle1.value || !this.state.alle1.value) {
            alert('Errore negli orari di ' + this.props.giorno + ': Se inizi a compilare il primo slot orario, devi terminarlo prima di salvare.');
            return null;
        }

        if (this.state.dalle1.value === this.state.alle1.value) {
            alert('Errore negli orari di ' + this.props.giorno + ': La durata del primo slot orario non può essere nulla.');
            return null;
        }

        if (moment(this.state.alle1.value, 'HH:mm').isBefore(moment(this.state.dalle1.value, 'HH:mm'))) {
            alert('Errore negli orari di ' + this.props.giorno + ': Verifica la correttezza degli orari inseriti nel primo slot.');
            return null;
        }
        
        if (!(!this.state.dalle2.value && !this.state.alle2.value)) {
            if (!this.state.dalle2.value || !this.state.alle2.value ) {
                alert('Errore negli orari di ' + this.props.giorno + ': Se inizi a compilare il secondo slot orario, devi terminarlo prima di salvare.');
                return null;
            }

            if (!this.state.dalle1.value && this.state.dalle2.value) {
                alert('Errore negli orari di ' + this.props.giorno + ': Il secondo slot può essere compilato solo se il primo è già stato utilizzato. Se si vuole utilizzare un solo intervallo, utilizzare il primo.');
                return null;
            }

            if (this.state.dalle2.value === this.state.alle2.value) {
                alert('Errore negli orari di ' + this.props.giorno + ': La durata del secondo slot orario non può essere nulla.');
                return null;
            }
    
            if (moment(this.state.alle2.value, 'HH:mm').isBefore(moment(this.state.dalle2.value, 'HH:mm'))) {
                alert('Errore negli orari di ' + this.props.giorno + ': Verifica la correttezza degli orari inseriti nel secondo slot.');
                return null;
            }

            if (moment(this.state.dalle2.value, 'HH:mm').isSameOrBefore(moment(this.state.alle1.value, 'HH:mm'))) {
                alert('Errore negli orari di ' + this.props.giorno + ': Il secondo slot orario non può iniziare prima (o a cavallo) del termine del primo.');
                return null;
            }
        }

        let orari = {
            slot1: { dalle: this.state.dalle1, alle: this.state.alle1 },
            slot2: { dalle: this.state.dalle2, alle: this.state.alle2 }
        };

        return orari;
    }

    render() {

        return(
            <section className='orari' style={{ marginTop: this.props.giorno !== t('lun') ? -20 : 0 }}>
                <p style={{ color: 'white' }} className='giorno-label'>{this.props.giorno}</p>
                <section className='first-orari'>
                    <div className='orari-select-30 orari-select-top'>
                        <SelectInput 
                            value={this.state.dalle1} 
                            hidable={false}
                            isMulti={false}
                            orari={true}
                            options={[{ value: undefined, label: 'Dalle...' }, ...this.orari]} 
                            style={{ marginLeft: 10, marginTop: 5, width: 200, color: 'grey' }}
                            onChange={(option) => this.setState({ dalle1: option })} 
                        />
                    </div>
                    <div className='orari-select-80 orari-select-top'>
                        <SelectInput 
                            value={this.state.alle1} 
                            hidable={false}
                            isMulti={false}
                            orari={true}
                            options={[{ value: undefined, label: 'Alle...' }, ...this.orari]} 
                            style={{ marginLeft: 10, marginTop: 5, width: 200, color: 'grey' }}
                            onChange={(option) => this.setState({ alle1: option })} 
                        />
                    </div>
                </section>
                <div className='separator-select-top' />
                <section className='first-orari second'>
                    <div className='orari-select-10 orari-select-top'>
                        <SelectInput 
                            value={this.state.dalle2} 
                            hidable={false}
                            isMulti={false}
                            orari={true}
                            options={[{ value: undefined, label: 'Dalle...' }, ...this.orari]} 
                            style={{ marginLeft: 10, marginTop: 5, width: 200, color: 'grey' }}
                            onChange={(option) => this.setState({ dalle2: option })} 
                        />
                    </div>
                    <div className='orari-select-80 orari-select-top'>
                        <SelectInput 
                            value={this.state.alle2} 
                            hidable={false}
                            isMulti={false}
                            orari={true}
                            options={[{ value: undefined, label: 'Alle...' }, ...this.orari]} 
                            style={{ marginLeft: 10, marginTop: 5, width: 200, color: 'grey' }}
                            onChange={(option) => this.setState({ alle2: option })} 
                        />
                    </div>
                </section>
            </section>
        );
    }
}