import Constants from '../utils/consts';

export default async function signUpPsicologo(psicologo) {
    return new Promise(function (resolve, reject) {

        fetch(Constants.defaultProtocol + Constants.mainUrl + 'signup.php', {
            method: 'POST', 
            mode: 'cors', 
            body: JSON.stringify({
                'type': 'cliente',
                'username': psicologo.username, 
                'password': psicologo.password, 
                'mail': psicologo.mail, 
                'nome': psicologo.nome, 
                'cognome': psicologo.cognome, 
                'indirizzo': psicologo.indirizzo, 
                'citta': psicologo.citta, 
                'cap': psicologo.cap, 
                'provincia': psicologo.provincia, 
                'codice_fiscale': psicologo.codice_fiscale, 
                'p_iva': psicologo.piva, 
                'numero_albo': psicologo.numero, 
                'provincia_albo': psicologo.provincia_albo, 
                'indirizzo_pagina': psicologo.indirizzo_pagina, 
                'professione': psicologo.professione,
                "google_info": psicologo.google_info,
            }), 
            headers: { 'Content-Type': 'application/json' }
        })
        .then((res) => res.json())
        .then((data) => {
            resolve(data);
        })
    });
}
