import React from 'react';
import Cookies from 'universal-cookie';
import Button from '../components/Button';
import Loading from '../components/Loading';
import getAnamnesi from '../services/getAnamnesi';
import { getServiceName } from '../utils/consts';
import translateString from '../utils/Locales';
import ReactHtmlParser from 'react-html-parser';
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import updateAnamnesi from '../services/updateAnamnesi';

let t = translateString;
const cookies = new Cookies();

export default class AnamnesiPaziente extends React.Component {
    constructor(props) {
        super(props);

        this.textRef = React.createRef();
        this.saveRef = React.createRef();

        this.state = {
            isLoading: true, 
            error: false, 
            errorMessage: '', 
            success: false, 
            successMessage: '', 
        };
    }

    async componentDidMount() {

        window.document.title = getServiceName() + ' - Anamnesi';

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        let paziente = cookies.get('currentPaziente', { path: '/' });

        if (!paziente) {
            window.location.href = "/#/app/pazienti";
            return;
        }
        
        let res = await getAnamnesi(username, token, paziente);

        this.setState({ isLoading: false, anamnesi: res ? res.testo : undefined });
    }

    componentWillUnmount() {
        cookies.remove('currentPaziente');
    }

    async save() {

        let htmlString = encodeURI(this.textRef.current.getHtml().toString());

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        let res = await updateAnamnesi(username, token, cookies.get('currentPaziente', { path: '/' }), htmlString ? htmlString : '');

        if (!res || res.result === 'KO') {
            setTimeout(() => {
                this.saveRef.current.stopAnimating();
            }, 200);

            this.setState({ error: true, errorMessage: 'Si è verificato un errore imprevisto durante il salvataggio. Per favore, riprova.' });
        
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 4000);
            return;
        }

        setTimeout(() => {
            this.saveRef.current.stopAnimating();
        }, 200);

        this.setState({ success: true, successMessage: 'Anamnesi aggiornata con successo.' });
        
        setTimeout(() => {
            this.setState({ success: false, successMessage: '' });
        }, 4000);

    }

    render() {

        if (this.state.isLoading) return <Loading />;

        return(
            <>
                <div className='top-with-btn' >
                    <div style={{ marginRight: 20, color: 'white' }}>
                        <h3>{t('anamnesi_paziente')}</h3>
                        <p>{t('info_anamnesi')}</p>
                    </div>
                    <Button ref={this.saveRef} button={t('salva')} animated={true} onClick={() => this.save()} />
                </div>

                { this.state.error && (
                    <div className='error-div'>
                        {this.state.errorMessage}
                    </div>
                )}
                { this.state.success && (
                    <div className='success-div'>
                        {this.state.successMessage}
                    </div>
                )}

                <RichTextEditorComponent ref={this.textRef} style={{ marginTop: 40, marginBottom: 200 }} height={700} enableAutoUrl={true} pasteCleanupSettings={{ keepFormat: true }} toolbarSettings={{ items: ['Bold', 'Italic', 'Underline', '|', 'Formats', 'Alignments', 'OrderedList','UnorderedList', '|', 'CreateLink', '|', 'Undo', 'Redo'] }} backgroundColor={'white'} >
                    <Inject services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]} />
                    {this.state.anamnesi && ReactHtmlParser(decodeURI(this.state.anamnesi))}
                </RichTextEditorComponent>
            </>
        );
    }
    
}