import React from 'react';
import './style.css';
import ReactLoading from 'react-loading';

export default class Button extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isAnimating: false,
        };
    }

    startAnimating() {
        this.setState({ isAnimating: true });
    }

    stopAnimating() {
        this.setState({ isAnimating: false });
    }

    handleClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }

        if (this.props.animated) {
            this.startAnimating();
        }
    }

    disableMessage() {
        
    }

    render() {

        return(
            <div className='btn-container'>
                <div title={this.props.disabled ? this.props.disabledMessage : ""} className='main-btn' onClick={() => this.props.disabled ? this.disableMessage() : this.handleClick()} style={this.props.style ? this.props.style : {}}>
                    {this.state.isAnimating ? <ReactLoading type='spin' color='#353535' width={'15px'} height={'15px'} /> : this.props.button}
                </div>
            </div>
        );
    }
}