import { Box, Modal } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { Button } from "@material-ui/core";

export default function RegisterGoogleModale({ open, handleClose, onChoice, isMobile }) {

    const onClose = () => {
        if (handleClose) {
            handleClose();
        }
    }

    const save = async (tipo) => {
        onChoice(tipo);
        onClose();
    }
    
    return (
        <Modal
            style={{ zIndex: 10000000 }}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={{ flex: 1, height: '100%', backgroundColor: '0000004D', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ backgroundColor: 'white', borderRadius: 2, width: '80%', px: 5, py: 10 }} onClick={e => e.stopPropagation()}>
                    <Stack spacing={2}>
                        <h3 style={{ width: '100%', textAlign: 'center', paddingLeft: 30, paddingRight: 30 }}>Non esiste un utente con questa email. Vuoi registrarti?</h3>
                        <Stack spacing={isMobile ? 1 : 3} direction={isMobile ? "column" : "row"}>
                            <Button fullWidth size="large" type="submit" variant="contained" onClick={() => save('professionista')}>
                                Registrati come professionista
                            </Button>
                            <Button fullWidth size="large" type="submit" variant="contained" onClick={() => save('paziente')}>
                                Registrati come paziente
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Box>
        </Modal>
    );
}