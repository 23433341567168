import React from "react";
import translateString from '../utils/Locales';
import Icon from '../images/logo.png';
import IconNoPad from '../images/logoNoPad.png';
import Cookies from "universal-cookie";
import getRichiestaPrenotazione from "../services/getRichiestaPrenotazione";

let t = translateString;
const cookies = new Cookies();
let username = cookies.get('username', { path: '/'  });
let token = cookies.get('token', { path: '/' });
let type = cookies.get('type', { path: '/' });

export default class NavbarMain extends React.Component{
  
  constructor(props) {
    super(props);

    this.handleProgettoClick = this.handleProgettoClick.bind(this);
    this.handleFunzionalitaClick = this.handleFunzionalitaClick.bind(this);
    this.handleAbbonamentiClick = this.handleAbbonamentiClick.bind(this);
    this.handleAppClick = this.handleAppClick.bind(this);

    this.state = { 
      isLoading: false, 
      isLogged: false,
      isScrolled: false,
      isMobile: false,
      isMenuOpen: false,
    };
  }

  handleResize() {
    if (document.getElementById('navbar')) {
      if (window.innerWidth < 992) {
        this.setState({ isMobile: true });
      } else {
        this.setState({ isMobile: false, isMenuOpen: false });
      }
    }
  }

  handleScroll() {
    if (document.getElementById('navbar')) {
      if (window.scrollY > 150) {
          this.setState({ isScrolled: true });
          document.getElementById('navbar').classList.add('is-sticky');
          return;
      } 
      this.setState({ isScrolled: false });
      if (!this.state.isMenuOpen)
          document.getElementById('navbar').classList.remove('is-sticky');
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  }

  handleProgettoClick() {
    this.props.progettoRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  handleFunzionalitaClick() {
    this.props.funzionalitaRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  handleAbbonamentiClick() {
    this.props.abbonamentiRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  handleAppClick() {
    this.props.appsRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  async componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this));
    this.handleScroll();
    window.addEventListener('resize', this.handleResize.bind(this));
    this.handleResize();

    username = cookies.get('username', { path: '/' });
    token = cookies.get('token', { path: '/' });
    type = cookies.get('type', { path: '/' });

    let path = window.location.href.split('#')[1];

    if (username && token && type === '0') {
      let res = await getRichiestaPrenotazione(username, token);
      if (res && !res.expired) this.setState({ richiesta: true });

      this.interval = setInterval(async () => {
        let res = await getRichiestaPrenotazione(username, token);
        
        if (res && !res.expired) this.setState({ richiesta: true });
        else this.setState({ richiesta: false });
      }, 60000);
    }
    
    this.setState({ isLoading: false, isLogged: (username && token), path });
  }

  async handleButtonToggle() {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  }

  render() {
      return (
        <nav style={{ zIndex: -1 }} id="navbar" class={`navbar navbar-expand-lg fixed-top ${(this.state.isMobile && this.state.isMenuOpen) ? 'navbar-dark is-sticky' : "navbar-dark"}`}>
          {this.props.blackBg && (
            <div style={{ zIndex: -1, backgroundColor: 'black', position: 'fixed', top: 0, left: 0, right: 0, height: '140px', width: '100vw' }}></div>
          )}
          <img alt="" className='navbar-brand' style={{ cursor: 'pointer', height: (!this.state.isMenuOpen && this.state.isScrolled) ? 84 : 154 }} onClick={() => window.location.href='/#/main/home'} src={!(!this.state.isMenuOpen && this.state.isScrolled) ? Icon : IconNoPad}/>
          {
            this.state.path === '/main/home' && (
              this.props.isPaziente ? (
              <>
                <button onClick={() => this.handleButtonToggle()} style={{ marginTop: (this.state.isMenuOpen && this.state.isScrolled && this.state.isMobile) ? -70 : 0 }} class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div style={this.state.isMobile ? {} : {marginTop: this.state.isScrolled ? 40 : 0}} className="collapse navbar-collapse" id="navbarNav">
                  <ul className="nav navbar-nav ml-auto">
                    <li className={`nav-item d-flex align-items-center`}>
                      <p className="nav-link page-scroll text-light" style={{cursor: 'pointer'}} onClick={() => {
                        cookies.set('type_chosen', 'professionista', { path: '/' });
                        window.location.reload();
                      }}>Sono un professionista</p>
                    </li>
                    <li className={`mt-3`} >
                        <div className="btn btn-light" onClick={() => window.location.href = this.state.isLogged ? type === '1' ? '/#/app/dashboard' : type === '0' ? '/#/paziente/dashboard' : '/#/admin/dashboard' : '/#/main/login'} style={{marginLeft: this.state.isMobile ? 12 : 20}}>{this.state.isLogged ? 'Dashboard' : t('accedi')}</div>
                    </li>
                    { this.state.richiesta && (
                      <li>
                        <img alt="" src={require('../images/calendar_notification.png')} style={{ width: 30, height: 30, cursor: 'pointer', marginLeft: this.state.isMobile ? 10 : 20, marginTop: this.state.isMobile ? 15 : 5 }} onClick={() => window.location.href = '/#/main/riepilogo' } />
                      </li>
                    )}
                  </ul>
                </div>
              </>
              ) : (
                <>
                <button onClick={() => this.handleButtonToggle()} style={{ marginTop: (this.state.isMenuOpen && this.state.isScrolled && this.state.isMobile) ? -70 : 0 }} class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div style={this.state.isMobile ? {} : {marginTop: this.state.isScrolled ? 40 : 0}} className="collapse navbar-collapse" id="navbarNav">
                  <ul className="nav navbar-nav ml-auto">
                    <li className={`nav-item d-flex align-items-center`}>
                      <p className="nav-link page-scroll text-light" style={{cursor: 'pointer'}} onClick={this.handleProgettoClick}>Progetto</p>
                    </li>
                    <li className={`nav-item d-flex align-items-center text-light`}>
                      <p className="nav-link page-scroll text-light" style={{cursor: 'pointer'}} onClick={this.handleFunzionalitaClick}>Funzionalit&aacute;</p>
                    </li>
                    <li className={`nav-item d-flex align-items-center text-light`}>
                      <p className="nav-link page-scroll text-light" style={{cursor: 'pointer'}} onClick={this.handleAbbonamentiClick}>Abbonamenti</p>
                    </li>
                    <li className={`nav-item d-flex align-items-center text-light`}>
                      <p className="nav-link page-scroll text-light" style={{cursor: 'pointer'}} onClick={this.handleAppClick}>App mobili</p>
                    </li>
                    <li className={`nav-item d-flex align-items-center`}>
                      <p className="nav-link page-scroll text-light" style={{cursor: 'pointer'}} onClick={() => {
                        cookies.set('type_chosen', 'paziente', { path: '/' });
                        window.location.reload();
                      }}>Sono un paziente</p>
                    </li>
                    <li className={`mt-3`} >
                        <div className="btn btn-light" onClick={() => window.location.href = this.state.isLogged ? type === '1' ? '/#/app/dashboard' : type === '0' ? '/#/paziente/dashboard' : '/#/admin/dashboard' : '/#/main/login'} style={{marginLeft: this.state.isMobile ? 12 : 20}}>{this.state.isLogged ? 'Dashboard' : t('accedi')}</div>
                    </li>
                    { this.state.richiesta && (
                      <li>
                        <img alt="" src={require('../images/calendar_notification.png')} style={{ width: 30, height: 30, cursor: 'pointer', marginLeft: this.state.isMobile ? 10 : 20, marginTop: this.state.isMobile ? 15 : 5 }} onClick={() => window.location.href = '/#/main/riepilogo' } />
                      </li>
                    )}
                  </ul>
                </div>
              </>
              )
            )
          }
        </nav>
      );
      
    }
  }


