import React from 'react';
import { getServiceName } from '../utils/consts';
import Cookies from 'universal-cookie';
import { Button, Card, Container } from '@material-ui/core';
import Loading from '../components/Loading';
import getAbbonamenti from '../services/getAbbonamenti';
import getAbbonamento from '../services/getAbbonamento';
import paySubscription from '../services/paySubscription';

const cookies = new Cookies();
let username = cookies.get('username', { path: '/' });
let token = cookies.get('token', { path: '/' });
export default class Checkout extends React.Component {
    constructor(props) {
        super(props);

        this.newAbbonamento = {};
        this.abbonamento = {};
        this.payPalRef = React.createRef();

        this.state = {
            isLoading: true, 
            successful: false,
            error: false, 
        };
    }

    async componentDidMount() {
        username = cookies.get('username', { path: '/' });
        token = cookies.get('token', { path: '/' });
        window.document.title = getServiceName() + " - Checkout";
        await this.reload();
    }

    async reload() {
        this.abbonamenti = await getAbbonamenti(username, token);
        this.abbonamento = await getAbbonamento(username, token);

        const idAbbonamento = cookies.get('selectedPlan', { path: '/' });
        this.newAbbonamento = this.abbonamenti.find(a => a.id === idAbbonamento);

        if (!idAbbonamento || !this.newAbbonamento) {
            window.location.href = '/#/app/pacchetti';
            return;
        }

        console.log(this.newAbbonamento.codice_paypal);
        this.setState(() => ({ isLoading: false }), () => {
            this.setPayPalButton(this.newAbbonamento.codice_paypal);
        });
    }

    setPayPalButton(codice) {
        if (this.abbonamento.id === '0') {
            window.paypal.Buttons({
                style: {
                    layout: 'vertical',
                    color:  'silver',
                    shape:  'rect',
                    label:  'subscribe'
                },
                createSubscription: function(data, actions) {
                    return actions.subscription.create({
                        'plan_id': codice
                    });
                },
                onApprove: async (data, actions) => { 
                    this.setState({ isLoading: true });
                    // Update account type
                    const paymentId = data.subscriptionID;
                    const response = await paySubscription(username, token, paymentId, codice);
                    if (response === null || response.result === 'KO') {
                        this.setState({ isLoading: false, error: true });
                        return;
                    }

                    this.setState({ isLoading: false, successful: true });
                }, 
                onError: (err) => {
                    console.error(err);
                    this.setState({ error: true });
                }
            }).render(this.payPalRef.current);
        }
    }

    changePlan() {

    }

    render() {

        if (this.state.isLoading) return <Loading />;
        if (this.state.successful) return (
            <Container style={{ width: '100%', height: '100%', marginBottom: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card style={{ backgroundColor: 'white', borderRadius: 5, padding: 30 }}>
                    <img src={require('../images/tick.png')} style={{ width: 50, height: 50, margin: 'auto', marginBottom: 20 }} alt="tick" />
                    <h4 style={{ textAlign: 'center' }}>Grazie per l'acquisto.<br/>Il tuo account è stato aggiornato.</h4>
                </Card>
            </Container>
        );
        if (this.state.error) return (
            <Container style={{ width: '100%', height: '100%', marginBottom: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card style={{ backgroundColor: 'white', borderRadius: 5, padding: 30 }}>
                    <img src={require('../images/error.jpg')} style={{ width: 100, margin: 'auto', marginBottom: 20 }} alt="tick" />
                    <h4 style={{ textAlign: 'center' }}>Si è verificato un errore durante il pagamento.<br/>Riprova più tardi.</h4>
                </Card>
            </Container>
        );

        return(
            <Container style={{ width: '100%', height: '100%', marginBottom: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card style={{ backgroundColor: 'white', borderRadius: 5, padding: 30 }}>
                    <h4>Sottoscrizione abbonamento: {this.newAbbonamento.nome}</h4>
                    <p style={{ marginTop: 5 }}>{this.newAbbonamento.descrizione}</p>
                    { this.abbonamento.id === '0' && (
                        <div style={{ marginTop: 50 }} ref={this.payPalRef} />
                    )}
                </Card>
            </Container>
        );
    }
}