import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
//import 'react-big-calendar/lib/css/react-big-calendar.css';
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import './calendar.css';
import moment from 'moment';
import 'moment/locale/it';

require('globalize/lib/cultures/globalize.culture.it');
moment.locale('it');

const mLocalizer = momentLocalizer(moment)
const lang = {
    'it': {
        week: 'Settimana',
        work_week: 'Settimana lavorativa',
        day: 'Giorno',
        month: 'Mese',
        previous: 'Precedente',
        next: 'Successivo',
        today: `Oggi`,
        agenda: 'Agenda',

        showMore: (total) => `+${total} più`,
    }
}

const CalendarComponent = (props) => {
    const [culture, setCulture] = useState('it');
    const [startTime, setStartTime] = useState(moment('00:00', 'HH:mm').toDate());
    const [endTime, setEndTime] = useState(moment('23:59', 'HH:mm').toDate());

    useEffect(() => {

        let orari = props.orari;
        if (orari) {

            var min = moment('8:00', 'HH:mm');
            var max = moment('20:00', 'HH:mm');

            orari.forEach((o) => {
                if (o.slot1.dalle.value) {
                    
                    if (moment(o.slot1.dalle.value, 'HH:mm').isBefore(min)) { min = moment(o.slot1.dalle.value, 'HH:mm'); }
                    if (o.slot2.dalle.value) {
                        if (moment(o.slot2.alle.value, 'HH:mm').isAfter(max)) {
                            max = moment(o.slot2.alle.value, 'HH:mm');
                        } else if (moment(o.slot1.alle.value, 'HH:mm').isAfter(max)) {
                            max = moment(o.slot1.alle.value, 'HH:mm');
                        }
                    } else {
                        if (moment(o.slot1.alle.value, 'HH:mm').isAfter(max)) {
                            max = moment(o.slot1.alle.value, 'HH:mm');
                        }
                    }
                }
            });

            setStartTime(min.toDate());
            setEndTime(max.toDate());
        }

    }, []);

    const { messages } = useMemo(
        () => ({
          defaultDate: new Date(),
          messages: lang[culture],
    }), []);

    const handleSelectSlot = useCallback((p) => {
        if (props.onCreate) props.onCreate(p);
    }, []);
    
    const handleSelectEvent = useCallback((event) => {
        if (event.type === 'google') return;
        if (props.onSelect) {
            props.onSelect(event);
        }
    }, []);

    const closedDay = (data) => {

        return {};
    }
    
    const closedSlot = (data) => {
        
        if (props.paziente) return {};

        let indisponibilita = props.indisponibilita;
        var show = false;
        indisponibilita.forEach(i => {
            if (data.getTime() < i.fine.getTime() && data.getTime() >= i.inizio.getTime()) show = true;
        });

        if (show) return { className: 'closed-slot'};
        else {

            let orari = props.orari;
            if (!orari) {
                return {};
            }

            let giorno = moment(data).format('ddd');
            let giorni = ['lun', 'mar', 'mer', 'gio', 'ven', 'sab', 'dom'];
            
            let ind = giorni.indexOf(giorno);

            let o = orari[ind];

            if (o.slot1.dalle.value === null) { return {}; }

            let time = moment(moment(data).format('HH:mm'), 'HH:mm');
            let expectedStart = moment(o.slot1.dalle.value, 'HH:mm').subtract(1, 'hour');
            let expectedEnd = moment(o.slot1.alle.value, 'HH:mm');

            var expectedStart1 = expectedStart;
            var expectedEnd1 = expectedEnd;
            
            if (o.slot2.dalle.value !== null) {
                expectedStart1 = moment(o.slot2.dalle.value, 'HH:mm').subtract(1, 'hour');
                expectedEnd1 = moment(o.slot2.alle.value, 'HH:mm');
            }

            if (time.isBetween(expectedStart, expectedEnd) || time.isBetween(expectedStart1, expectedEnd1)) {
                return { className: 'available-slot' };
            }

            return {};
        }
    }

    const eventComponent = (props) => {

        if (props.type === 'evento') {
            var style = {
                backgroundColor: '#CAA415', 
                border: '1px solid white',
            };
            
            return { style: style };
        } else if (props.type === 'google') {
            style = {
                backgroundColor: '#9EC17680', 
                border: '1px solid transparent',
                cursor: 'default', 
                width: '50px', 
                maxWidth: '50px'
            };

            return { style };
        } else {
            return { };
        }
    }

    return(
        <Calendar
            defaultDate={props.defaultDate}
            defaultView="week"
            events={props.events}
            culture={culture}
            localizer={mLocalizer}
            step={props.step}
            selectable
            startAccessor="start"
            endAccessor="end"
            onSelectEvent={handleSelectEvent}
            onSelectSlot={handleSelectSlot}
            messages={messages}
            timeslots={1}
            onClick={(e) => props.clickEvent(e)}
            min={startTime}
            max={endTime}
            onRangeChange={(dates) => props.datesChange(dates)}
            views={{ week: true, day: true, agenda: true }}
            dayPropGetter={closedDay}
            slotPropGetter={closedSlot}
            eventPropGetter={eventComponent}
        />
    );
};

export default CalendarComponent;