import React from 'react';
import { getServiceName, provaGratuitaCheck } from '../utils/consts';
import Loading from '../components/Loading';
import Cookies from 'universal-cookie';
import { fattureTable } from '../utils/tables';
import SearchInput from '../components/Search';
import moment from 'moment';
import getFattureProfessionista from '../services/getFattureProfessionista';
import EditEventoWidget from '../widgets/EditEventoWidget';
import AbbonamentoError from './AbbonamentoError';
import { DataGrid } from '@mui/x-data-grid';

const cookies = new Cookies();
let username = cookies.get('username', { path: '/' });
let token = cookies.get('token', { path: '/' });

export default class Fatture extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,  
            data: undefined,
            editSeduta: false,
            evento: null, 
        };
    }

    async reload() {
        username = cookies.get('username', { path: '/' });
        token = cookies.get('token', { path: '/' });

        let check = await provaGratuitaCheck();
        if (check) {
            this.setState({ isLoading: false, hidePage: true });
            return;
        }

        let fatture = await getFattureProfessionista(username, token);
        this.allAppuntamenti = fatture;
        
        let data = [];
        fatture.forEach(fattura => {

            const payload = JSON.parse(fattura.payload);

            data.push({
                id: fattura.id, 
                uuid: fattura.uuid,
                ragioneSociale: payload.fattura_elettronica_header.cessionario_committente.dati_anagrafici.anagrafica.denominazione, 
                partitaIva: payload.fattura_elettronica_header.cessionario_committente.dati_anagrafici.anagrafica.cod_eori,
                data: payload.fattura_elettronica_body[0].dati_generali.dati_generali_documento.data, 
                inizio: fattura.inizio, 
                fine: fattura.fine,
                titolo: fattura.titolo,
                stato: fattura.marking === 'sent' ? "Inviata" : fattura.marking === 'quarantena' ? "Errore invio" : fattura.marking, 
                numero: payload.fattura_elettronica_body[0].dati_generali.dati_generali_documento.numero,
                importo: parseFloat(payload.fattura_elettronica_body[0].dati_generali.dati_generali_documento.importo_totale_documento).toFixed(2), 
                protocollo: fattura.protocollo_ts, 
            })
        });

        this.invii = data;
        this.setState({ data, isLoading: false, hidePage: check });
    } 

    async componentDidMount() {

        window.document.title = getServiceName() + ' - Fatture';
        await this.reload();
    }

    handleRowClick(event) {
        let appuntamento = event.row;
        console.log(appuntamento);

        let app = {
            id: parseInt(appuntamento.id), 
            start: moment(appuntamento.inizio, 'yyyy-MM-DDTHH:mm').toDate(), 
            end: moment(appuntamento.fine, 'yyyy-MM-DDTHH:mm').toDate(), 
            title: appuntamento.titolo,
            type: 'seduta',
        }
        this.setState({ evento: app, editSeduta: true });
    }

    cerca(testo) {

        if (!testo.trim()) {
            this.setState({ data: this.invii });
            return;
        }

        var data = [];
        this.invii.forEach((documento) => {
            
            if (this.checkFieldPresence(documento.ragioneSociale, testo) ||
                this.checkFieldPresence(documento.partitaIva, testo) || 
                this.checkFieldPresence(documento.data, testo) || 
                this.checkFieldPresence(documento.stato, testo) || 
                this.checkFieldPresence(documento.numero, testo)) {
                    data.push(documento);
                }
        });

        this.setState({ data });
    }

    checkFieldPresence(field, needle) {
        if (!field || !needle) return false;
        if (field.toLowerCase().includes(needle.toLowerCase())) return true;
        else return false;
    }

    render() {

        if (this.state.isLoading) return <Loading />;
        if (this.state.hidePage) return <AbbonamentoError />;

        return(
            <div style={{ width: '100%' }}>
                <div className='top-with-btn' style={{ marginBottom: 30 }}>
                    <h3 style={{ color: 'white', width: '100%', textAlign: 'left' }}>Fatture inviate all'Agenzia delle Entrate</h3>
                </div>

                <SearchInput onCerca={(testo) => {
                    this.cerca(testo);
                }} />
                <DataGrid
                    sx={{
                        ".MuiTablePagination-displayedRows": {
                            "margin-top": "1em",
                            "margin-bottom": "1em"
                        },
                        "#mui-2.MuiTablePagination-selectLabel": {
                            "margin-top": "1em",
                        }
                    }}
                    rows={this.state.data}
                    columns={fattureTable}
                    style={{ backgroundColor: 'white' }}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[5, 10, 20]}
                    onRowClick={(event) => this.handleRowClick(event)}
                />

                { this.state.editSeduta && (
                    <EditEventoWidget evento={this.state.evento} onClose={(result) => {
                        this.reload();
                        this.setState({ editSeduta: false, evento: null });
                    }} />
                )}
            </div>
        );
    }
}