import React from "react";
import "./style.css";
import "./bootstrap.min.css";
import "./owl.carousel.css";
import "./owl.theme.css";
import "./owl.transitions.css";
import "./animate.css";
import "./preloader.css";
import NavbarMain from "../../components/NavbarMain";

export default class Privacy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
    };
  }

  handleResize() {
    this.setState({ bannerHeight: window.innerHeight });
    if (document.getElementById("navbar")) {
      if (window.innerWidth < 992) {
        this.setState({ isMobile: true });
      } else {
        this.setState({ isMobile: false, isMenuOpen: false });
      }
    }
  }

  componentDidMount() {
    window.document.title = "PersonalDoc - Privacy Policy";
    window.addEventListener("resize", this.handleResize.bind(this));
    this.handleResize();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize.bind(this));
  }

  render() {
    return (
      <div
        id="main_scroll"
        className="mainBody"
        style={{ width: "100%", height: "100%" }}
      >
        <NavbarMain noButtons />
        <link
          rel="stylesheet"
          href="bootstrap-4.1.1-dist/css/bootstrap.min.css"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
          integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
          crossOrigin="anonymous"
        />

        <header id="home">
          <div
            className="banner"
            style={{ paddingTop: this.state.isMobile ? 100 : 0, height: 300 }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                height: 300,
                right: 0,
                backgroundColor: "black",
                opacity: 0.55,
              }}
            />
            <div
              className="container position-relative"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {!this.state.isMobile && (
                <div
                  style={{
                    position: "absolute",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1100,
                    backgroundColor: "black",
                    borderRadius: 10,
                    right: 30,
                    bottom: 30,
                    paddingTop: 5,
                    paddingLeft: 30,
                    paddingRight: 30,
                    boxShadow: "0px 2px 4px black",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(
                      "https://www.personaldoc-app.com/docs/privacy.pdf",
                      "_blank"
                    );
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      marginBottom: 5,
                    }}
                  >
                    Scarica
                  </p>
                </div>
              )}
              <div
                className="row"
                style={{
                  zIndex: 1000,
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  widht: "100%",
                }}
              >
                <div
                  className="col-md-8 offset-md-2"
                  style={{
                    width: "100%",
                    paddingLeft: this.state.isMobile ? "7%" : 0,
                  }}
                >
                  <div className="caption">
                    <h1
                      style={{
                        paddingTop: 100,
                        paddingBottom: 80,
                        color: "white",
                        fontWeight: "bold",
                        fontFamily: `url(${require("../../fonts/Nexa.ttf")})`,
                        fontSize: this.state.isMobile ? 20 : 30,
                      }}
                    >
                      Informativa sul trattamento dei dati personali e
                      sull’utilizzo dei cookie mediante il sito web e il portale
                      “PersonalDoc”
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div
          style={{
            width: "85%",
            paddingLeft: "15%",
            paddingTop: 50,
            paddingBottom: 100,
          }}
        >
          <div className="WordSection1">
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                &nbsp;
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  1.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;{" "}
                  </span>
                </span>
              </b>
              <b>
                <u>
                  <span
                    lang="IT"
                    style={{
                      fontSize: "9.0pt",
                      fontFamily: '"Lucida Sans Unicode",sans-serif',
                    }}
                  >
                    Titolare del trattamento{" "}
                  </span>
                </u>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{ marginBottom: "12.0pt", textAlign: "justify" }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                La informiamo, ai sensi del Regolamento (UE) 2016/679 (d’ora in
                avanti, “<b>GDPR</b>”), che i Suoi dati personali saranno
                trattati da Zanc S.r.l., con sede legale in Viale Nino Bixio n.
                95, 31100, Treviso, C.F., P.IVA e n. di iscrizione al Registro
                delle Imprese di Treviso-Belluno 05371470260 in qualità di
                titolare del trattamento (di seguito, “<b>Società</b>” o “
                <b>Titolare</b>”), raggiungibile all’indirizzo e-mail
                info@zanc.it. In particolare, la presente informativa Le è
                fornita in relazione ai dati che raccogliamo quando naviga sul
                sito web ‘www.personaldoc-app.com’ (“
                <b>Sito</b> <b>Web</b>”) e/o utilizza il nostro portale
                “PersonalDoc” e le sue funzionalità (“<b>PersonalDoc</b>”).{" "}
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  2.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;{" "}
                  </span>
                </span>
              </b>
              <b>
                <u>
                  <span
                    lang="IT"
                    style={{
                      fontSize: "9.0pt",
                      fontFamily: '"Lucida Sans Unicode",sans-serif',
                    }}
                  >
                    Finalità del trattamento
                  </span>
                </u>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{ marginBottom: "3.0pt", textAlign: "justify" }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                I Suoi dati personali{" "}
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                saranno trattati dal Titolare per le seguenti finalità, a
                seconda della Sua interazione con il Sito Web, PersonalDoc o il
                Titolare stesso:
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "3.0pt",
                marginLeft: ".5in",
                textAlign: "justify",
                textIndent: "-.5in",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                (i)
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                per permetterle di utilizzare il Sito Web;
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "3.0pt",
                marginLeft: ".5in",
                textAlign: "justify",
                textIndent: "-.5in",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                (ii)
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                per fornire riscontro alle Sue richieste pervenute mediante
                l’utilizzo delle informazioni di contatto presenti sul Sito Web;
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "3.0pt",
                marginLeft: ".5in",
                textAlign: "justify",
                textIndent: "-.5in",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
                (iii)
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                per consentirle la registrazione a PersonalDoc;
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "12.0pt",
                marginLeft: ".5in",
                textAlign: "justify",
                textIndent: "-.5in",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
                (iv)
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                per fornirle i servizi offerti attraverso PersonalDoc.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{ marginBottom: "12.0pt", textAlign: "justify" }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                Inoltre, utilizzeremo alcuni dei Suoi dati personali per
                elaborare statistiche aggregate e anonime (<i>e.g.</i>, numero
                di pazienti per singolo professionista, distribuzione
                geografica, fascia d'età, numero di sedute) che condivideremo
                con terzi (<i>e.g.</i>, centri di ricerca) per fini statistici,
                di ricerca e sviluppo.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  3.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;{" "}
                  </span>
                </span>
              </b>
              <b>
                <u>
                  <span
                    lang="IT"
                    style={{
                      fontSize: "9.0pt",
                      fontFamily: '"Lucida Sans Unicode",sans-serif',
                    }}
                  >
                    Categorie e tipologie di dati personali oggetto del
                    trattamento
                  </span>
                </u>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{ marginBottom: "6.0pt", textAlign: "justify" }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                I dati personali oggetto del trattamento si riferiscono, a
                seconda dei casi, agli utenti che visitano il Sito nonché agli
                utenti che si registrano a PersonalDoc in qualità di pazienti (“
                <b>Paziente/i</b>”) o professionisti sanitari (“
                <b>Professionista/i</b>”) e appartengono alle seguenti
                categorie:
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".5in",
                textAlign: "justify",
                textIndent: "-.25in",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                a)
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <u>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  dati identificativi, di contatto e altri dati forniti
                  volontariamente dall’utente
                </span>
              </u>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                : quali nome, cognome, genere, C.F., data di nascita, indirizzo,
                e-mail, numero di telefono;
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".5in",
                textAlign: "justify",
                textIndent: "-.25in",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                b)
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <u>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  dati di navigazione
                </span>
              </u>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                : si tratta di dati la cui trasmissione è implicita nell’uso dei
                protocolli di comunicazioni di internet e che i sistemi
                informatici e i software preposti al funzionamento del Sito Web
                e di PersonalDoc acquisiscono per garantirne il funzionamento.
                In questa categoria rientrano gli indirizzi IP o i nomi a
                dominio dei dispositivi utilizzati dagli utenti per accedere al
                Sito Web e/o a PersonalDoc, gli indirizzi URL delle risorse
                richieste, l’orario della richiesta, il metodo utilizzato per
                sottoporre la richiesta al server, ecc. I dati in questione
                vengono utilizzati per raccogliere informazioni statistiche
                anonime sull’utilizzo del Sito Web e/o di PersonalDoc (es. il
                numero di visitatori) e verificarne il corretto funzionamento.
                Per quanto attiene all’utilizzo dei <i>cookie</i>, si rimanda al
                par. 11 di seguito;
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".5in",
                textAlign: "justify",
                textIndent: "-.25in",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                c)
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <u>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  ulteriori dati dei Professionisti
                </span>
              </u>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                : immagine profilo, orario di lavoro, partita IVA, titolo,
                credenziali per l’accesso al “portale tessera sanitaria”, numero
                e provincia di iscrizione all’albo, prezzo degli appuntamenti e
                loro durata, pacchetti di appuntamento (se creati dal
                professionista), importi fatturati, dati condivisi mediante
                login con account Google. Tali ultimi dati sono trasmessi al
                Titolare direttamente da Google quando il Professionista scelga
                di avvalersi del relativo servizio di login e organizzare
                riunione in Google Meet.
                A <a href="/#/main/google-disclosure" target="_blank">questo</a> indirizzo è possibile vedere il disclaimer di utilizzo dei dati ottenuti.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".5in",
                textAlign: "justify",
                textIndent: "-.25in",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                d)
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <u>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  dati sull’attività sanitaria relativa ai Pazienti
                </span>
              </u>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                : date degli appuntamenti, anamnesi registrata dal
                Professionista, fatture.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{ marginBottom: "6.0pt", textAlign: "justify" }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                Si precisa, inoltre, che, all’interno di PersonalDoc:{" "}
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".5in",
                textAlign: "justify",
                textIndent: "-.25in",
              }}
            >
              <span
                lang="IT"
                style={{ fontSize: "9.0pt", fontFamily: "Symbol" }}
              >
                ·
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                è possibile usufruire del servizio chat, che consente anche la
                condivisione di immagini e documenti in formato .pdf tra
                Paziente e Professionista. Tale canale di comunicazione è
                protetto da tecniche di cifratura end-to-end che impediscono
                l’accesso a qualsiasi soggetto terzo, compreso il Titolare;{" "}
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".5in",
                textAlign: "justify",
                textIndent: "-.25in",
              }}
            >
              <span
                lang="IT"
                style={{ fontSize: "9.0pt", fontFamily: "Symbol" }}
              >
                ·
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                è possibile organizzare appuntamenti per video-consulti che
                avranno luogo su piattaforma Google Meet. Rispetto alle attività
                di trattamento svolte mediante tale piattaforma, che ricadono
                sotto la piena responsabilità di Google, La invitiamo a
                consultare le informazioni sul trattamento dei dati personali
                messe a disposizione da quest’ultima società (v., ad es.,{" "}
              </span>
              <a href="https://support.google.com/meet/answer/9852160?hl=it&ref_topic=10632347&sjid=9349024861313679337-EU">
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  https://support.google.com/meet/answer/9852160?hl=it&amp;ref_topic=10632347&amp;sjid=9349024861313679337-EU
                </span>
              </a>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                ).
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "12.0pt",
                marginLeft: ".5in",
                textAlign: "justify",
                textIndent: "-.25in",
              }}
            >
              <span
                lang="IT"
                style={{ fontSize: "9.0pt", fontFamily: "Symbol" }}
              >
                ·
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                i dati per i pagamenti dei Professionisti verso il Titolare e
                dei Pazienti verso i Professionisti sono gestiti direttamente da
                soggetti esterni, mediante reindirizzamento verso i rispettivi
                siti web.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  4.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;{" "}
                  </span>
                </span>
              </b>
              <b>
                <u>
                  <span
                    lang="IT"
                    style={{
                      fontSize: "9.0pt",
                      fontFamily: '"Lucida Sans Unicode",sans-serif',
                    }}
                  >
                    Basi giuridiche del trattamento
                  </span>
                </u>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{ marginBottom: "6.0pt", textAlign: "justify" }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                In relazione alle finalità indicate al par. 2 che precede (ad
                eccezione dell’attività di elaborazione di statistiche aggregate
                e anonime), la base giuridica del trattamento è costituita dalla
                necessità di dare esecuzione ad un contratto di cui
                l’interessato è parte o dall’adozione di misure precontrattuali
                adottate su richiesta dello stesso (art. 6, comma 1, lett. a),
                GDPR). Atteso che il Professionista potrebbe registrare dati di
                anamnesi all’interno di PersonalDoc nonché concordare
                appuntamenti con Lei per consulti di carattere medico e gestire
                le relative fatture – che costituiscono dati relativi alla
                salute –, il Titolare raccoglierà il Suo consenso, qualora sia
                registrato in qualità di Paziente (art. 9, comma 2, lett. a).{" "}
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{ marginBottom: "12.0pt", textAlign: "justify" }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                L’attività di elaborazione di dati aggregati e anonimi per
                finalità statistica, di ricerca e sviluppo è giustificata dal
                legittimo interesse della Società a condividere con terzi dati
                anonimizzati e, pertanto, non riconducibili a nessun individuo
                identificato o identificabile. A tal riguardo, il Titolare
                ritiene che l’anonimizzazione costituisca misura idonea a
                limitare l’impatto del trattamento su di Lei e che non vi sia il
                rischio di un pregiudizio nei Suoi confronti o la frustrazione
                delle Sue aspettative con riguardo al trattamento dei Suoi dati
                personali.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  5.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;{" "}
                  </span>
                </span>
              </b>
              <b>
                <u>
                  <span
                    lang="IT"
                    style={{
                      fontSize: "9.0pt",
                      fontFamily: '"Lucida Sans Unicode",sans-serif',
                    }}
                  >
                    Natura del conferimento dei dati
                  </span>
                </u>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{ marginBottom: "12.0pt", textAlign: "justify" }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                Il mancato conferimento dei Suoi dati personali per le finalità
                su indicate impedirebbe alla Società di consentirle di
                utilizzare il Sito Web, di fornire riscontro alle Sue richieste
                o di permetterle di registrarsi e utilizzare PersonalDoc in
                tutte le sue funzionalità. In tal senso, pertanto, il
                conferimento dei Suoi dati riveste carattere necessario.{" "}
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  6.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;{" "}
                  </span>
                </span>
              </b>
              <b>
                <u>
                  <span
                    lang="IT"
                    style={{
                      fontSize: "9.0pt",
                      fontFamily: '"Lucida Sans Unicode",sans-serif',
                    }}
                  >
                    Destinatari dei dati personali
                  </span>
                </u>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{ marginBottom: "3.0pt", textAlign: "justify" }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                Nei limiti pertinenti alle finalità sopra indicate, i Suoi dati
                personali potranno essere comunicati alle seguenti categorie di
                destinatari:{" "}
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "3.0pt",
                marginLeft: ".5in",
                textAlign: "justify",
                textIndent: "-.25in",
              }}
            >
              <span
                lang="IT"
                style={{ fontSize: "9.0pt", fontFamily: "Symbol" }}
              >
                ·
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                consulenti incaricati per l’installazione, la manutenzione,
                l’aggiornamento e, in generale, la gestione degli hardware e
                software della Società;
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "3.0pt",
                marginLeft: ".5in",
                textAlign: "justify",
                textIndent: "-.25in",
              }}
            >
              <span
                lang="IT"
                style={{ fontSize: "9.0pt", fontFamily: "Symbol" }}
              >
                ·
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                tutti i soggetti pubblici o privati rispetto ai quali la
                comunicazione debba essere effettuata per adempiere ad obblighi
                contrattuali inerenti alla prestazione dei servizi relativi al
                Sito Web, di PersonalDoc (<i>e.g.</i>, invio delle fatture al
                sistema sanitario nazionale) o obblighi di legge;
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "3.0pt",
                marginLeft: ".5in",
                textAlign: "justify",
                textIndent: "-.25in",
              }}
            >
              <span
                lang="IT"
                style={{ fontSize: "9.0pt", fontFamily: "Symbol" }}
              >
                ·
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                ogni soggetto che abbia diritto di accedere ai dati in forza di
                provvedimenti normativi o ammnistrativi;
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "3.0pt",
                marginLeft: ".5in",
                textAlign: "justify",
                textIndent: "-.25in",
              }}
            >
              <span
                lang="IT"
                style={{ fontSize: "9.0pt", fontFamily: "Symbol" }}
              >
                ·
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                persone fisiche autorizzate dal Titolare ex artt. 29 del GDPR e
                2-
                <i>quaterdecies</i>
                del d.lgs. 196/2003 (c.d. “<b>Codice privacy</b>”), in ragione
                dell’espletamento delle loro mansioni lavorative;{" "}
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "35.7pt",
                textAlign: "justify",
                textIndent: "-17.85pt",
              }}
            >
              <span
                lang="IT"
                style={{ fontSize: "9.0pt", fontFamily: "Symbol" }}
              >
                ·
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                ulteriori soggetti il cui intervento sia necessario per il
                raggiungimento delle finalità richiamate al par. 2 di cui sopra
                (<i>e.g.</i>, fornitori di servizi per la comunicazione al
                sistema di interscambio della Agenzia delle Entrate per le
                fatture elettroniche), anche in qualità di responsabili del
                trattamento ex art. 28 GDPR.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{ marginBottom: "12.0pt", textAlign: "justify" }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                All’interno del Sito Web/PersonalDoc, mediante richiesta
                attraverso l’apposita barra di ricerca, i visitatori e Pazienti
                potranno visionare nome, cognome e titolo del Professionista e,
                in base alle scelte espresse all’interno di PersonalDoc da
                quest’ultimo, anche una descrizione del profilo nonché i servizi
                e i relativi pacchetti offerti.{" "}
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{ marginBottom: "12.0pt", textAlign: "justify" }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                I dati sanitari dei Pazienti non saranno in nessun caso oggetto
                di diffusione.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  7.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;{" "}
                  </span>
                </span>
              </b>
              <b>
                <u>
                  <span
                    lang="IT"
                    style={{
                      fontSize: "9.0pt",
                      fontFamily: '"Lucida Sans Unicode",sans-serif',
                    }}
                  >
                    Trasferimento dei dati al di fuori dell’Unione europea o
                    verso Organizzazioni internazionali
                  </span>
                </u>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{ marginBottom: "12.0pt", textAlign: "justify" }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                I Suoi dati personali non verranno trasferiti dal Titolare in
                paesi al di fuori dell’Unione europea o verso Organizzazioni
                internazionali. In proposito, si evidenzia che PersonalDoc è
                offerto in modalità SaaS con server localizzati in Italia.
                Tuttavia, laddove il Titolare avesse necessità di procedere con
                tali trasferimenti, il trattamento avverrà secondo una delle
                modalità consentite ai sensi degli artt. 44-49 del GDPR e Lei
                potrà chiedere copia delle garanzie appropriate per il
                trasferimento all’indirizzo e-mail di cui al par. 1 che precede.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  8.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;{" "}
                  </span>
                </span>
              </b>
              <b>
                <u>
                  <span
                    lang="IT"
                    style={{
                      fontSize: "9.0pt",
                      fontFamily: '"Lucida Sans Unicode",sans-serif',
                    }}
                  >
                    Modalità di trattamento dei dati
                  </span>
                </u>
              </b>
            </p>
            <p className="MsoNormal" style={{ textAlign: "justify" }}>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                I Suoi dati sono trattati in modo lecito e secondo correttezza,
                per il perseguimento delle finalità sopra indicate e nel
                rispetto dei principi fondamentali stabiliti dalla normativa
                applicabile.{" "}
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{ marginBottom: "12.0pt", textAlign: "justify" }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                Il trattamento dei dati personali può avvenire sia mediante
                strumenti manuali, che informatici e telematici, ma in ogni caso
                sotto il presidio di misure tecniche e organizzative idonee a
                garantirne la sicurezza e la riservatezza, soprattutto al fine
                di ridurre i rischi di distruzione, perdita o alterazione,
                illecite o accidentali, dei dati, di accesso non autorizzato, o
                di trattamento non consentito o non conforme alle finalità della
                raccolta.{" "}
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  9.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;{" "}
                  </span>
                </span>
              </b>
              <b>
                <u>
                  <span
                    lang="IT"
                    style={{
                      fontSize: "9.0pt",
                      fontFamily: '"Lucida Sans Unicode",sans-serif',
                    }}
                  >
                    Conservazione dei dati personali{" "}
                  </span>
                </u>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{ marginBottom: "12.0pt", textAlign: "justify" }}
            >
              <a name="_Hlk119593029">
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  I Suoi dati personali saranno conservati per il tempo
                  strettamente necessario al conseguimento delle finalità per
                  cui sono raccolti, nel rispetto del principio di limitazione
                  della conservazione di cui all’art. 5, par. 1, lett. e) del
                  GDPR
                </span>
              </a>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                . In particolare, La informiamo che i Suoi dati personali
                verranno trattati dal Titolare per il tempo strettamente
                necessario a fornire riscontro alla Sua richiesta pervenuta
                mediante l’utilizzo delle informazioni di contatto presenti sul
                Sito Web e, in relazione ai dati di navigazione e ai{" "}
                <i>cookie</i>, per un massimo di 6 mesi dalla raccolta. I dati
                raccolti in relazione ai servizi offerti da PersonalDoc saranno
                cancellati al termine del rapporto contrattuale con il Paziente
                o il Professionista e, in ogni caso, al massimo dopo 10 anni da
                tale momento per l’adempimento di obblighi di conservazione
                previsti dalla legge (<i>e.g.</i>, termine di conservazione di
                cui all’art. 2220 c.c.). Al termine dei predetti periodi, i Suoi
                dati personali saranno cancellati o, comunque, cesseranno di
                essere conservati in una forma che consenta la Sua
                identificazione o identificabilità.{" "}
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  10.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;{" "}
                  </span>
                </span>
              </b>
              <b>
                <u>
                  <span
                    lang="IT"
                    style={{
                      fontSize: "9.0pt",
                      fontFamily: '"Lucida Sans Unicode",sans-serif',
                    }}
                  >
                    I Suoi diritti{" "}
                  </span>
                </u>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{ marginBottom: "6.0pt", textAlign: "justify" }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                Lei potrà esercitare in ogni momento il Suo diritto di:
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".5in",
                textAlign: "justify",
                textIndent: "-.25in",
              }}
            >
              <span
                lang="IT"
                style={{ fontSize: "9.0pt", fontFamily: "Symbol" }}
              >
                ·
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                accedere ai Suoi dati personali, ottenendo evidenza delle
                finalità perseguite da parte del Titolare, delle categorie di
                dati coinvolti, dei destinatari a cui gli stessi possono essere
                comunicati, del periodo di conservazione applicabile,
                dell’esistenza di processi decisionali automatizzati;
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".5in",
                textAlign: "justify",
                textIndent: "-.25in",
              }}
            >
              <span
                lang="IT"
                style={{ fontSize: "9.0pt", fontFamily: "Symbol" }}
              >
                ·
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                ottenere senza ritardo la rettifica dei dati personali inesatti
                che La riguardano;
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".5in",
                textAlign: "justify",
                textIndent: "-.25in",
              }}
            >
              <span
                lang="IT"
                style={{ fontSize: "9.0pt", fontFamily: "Symbol" }}
              >
                ·
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                ottenere, nei casi previsti dalla legge, la cancellazione dei
                Suoi dati o la limitazione del trattamento;
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: ".5in",
                textAlign: "justify",
                textIndent: "-.25in",
              }}
            >
              <span
                lang="IT"
                style={{ fontSize: "9.0pt", fontFamily: "Symbol" }}
              >
                ·
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                revocare in qualsiasi momento il consenso prestato, senza
                pregiudicare la liceità del trattamento effettuato prima della
                revoca;
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "12.0pt",
                marginLeft: "35.7pt",
                textAlign: "justify",
                textIndent: "-17.85pt",
              }}
            >
              <span
                lang="IT"
                style={{ fontSize: "9.0pt", fontFamily: "Symbol" }}
              >
                ·
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                richiedere, se previsto dalla legge, la portabilità dei dati che
                Lei ha fornito al Titolare, ossia di riceverli in un formato
                strutturato, di uso comune e leggibile da dispositivo
                automatico, anche per trasmettere tali dati a un altro titolare,
                senza alcun impedimento da parte del Titolare stesso.
              </span>
            </p>
            <div
              style={{
                border: "solid windowtext 1.0pt",
                padding: "1.0pt 4.0pt 1.0pt 4.0pt",
                background: "#D9D9D9",
              }}
            >
              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  background: "#D9D9D9",
                  border: "none",
                  padding: "0in",
                }}
              >
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                    color: "black",
                  }}
                >
                  Lei ha anche il diritto di opporsi, in qualsiasi momento, per
                  motivi connessi alla sua situazione particolare, al
                  trattamento dei Suoi dati personali basato sul legittimo
                  interesse del Titolare (v. par. 4 che precede). In caso di
                  opposizione, il Titolare si asterrà dal trattare ulteriormente
                  i Suoi dati personali salvo che dimostri l'esistenza di motivi
                  legittimi cogenti per procedere al trattamento che prevalgono
                  sui Suoi interessi, diritti e libertà oppure per
                  l'accertamento, l'esercizio o la difesa di un diritto in sede
                  giudiziaria.
                </span>
              </p>
            </div>
            <p
              className="MsoNormal"
              style={{ marginBottom: "6.0pt", textAlign: "justify" }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                Per esercitare tali diritti, potrà contattare il Titolare ai
                recapiti indicati al par. 1 che precede.{" "}
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{ marginBottom: "12.0pt", textAlign: "justify" }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                Inoltre, qualora ritenga che il trattamento dei Suoi dati
                personali effettuato dal Titolare avvenga in violazione di
                quanto previsto dalla normativa in materia di protezione dei
                dati personali, Lei ha il diritto di proporre reclamo al Garante
                per la protezione dei dati personali, come previsto dall'art. 77
                GDPR.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginLeft: ".25in",
                textAlign: "justify",
                textIndent: "-.25in",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  11.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;{" "}
                  </span>
                </span>
              </b>
              <b>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  Cookie
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{ marginBottom: "6.0pt", textAlign: "justify" }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                Il presente Sito Web nonché PersonalDoc utilizzano i c.d.{" "}
                <i>cookie</i>.{" "}
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{ marginBottom: "6.0pt", textAlign: "justify" }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                I <i>cookie</i>
                sono stringhe di testo che i siti web (c.d. publisher o “prima
                parte”) visitati dall’utente ovvero siti o web server diversi
                (c.d. “terze parti”) posizionano e archiviano all’interno di un
                dispositivo terminale dell’utente.{" "}
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{ marginBottom: "6.0pt", textAlign: "justify" }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                I <i>cookie</i>
                possono permanere nel sistema per la durata di una sessione
                (cioè fino a che non si chiude il browser utilizzato per la
                navigazione sul web) o per lunghi periodi e possono contenere un
                codice identificativo unico.{" "}
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{ marginBottom: "6.0pt", textAlign: "justify" }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                I dati personali raccolti attraverso i <i>cookie </i>(es.
                indirizzo IP dell’utente) sono trattati dal Titolare
                conformemente ai paragrafi che precedono, nel rispetto del GDPR
                e del Codice Privacy.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginLeft: "21.3pt",
                textAlign: "justify",
                textIndent: "-7.1pt",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  a.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;{" "}
                  </span>
                </span>
              </b>
              <b>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  Tipologie di cookie
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "3.0pt",
                marginLeft: "14.2pt",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                In base alle caratteristiche e all'utilizzo dei <i>cookie</i> è
                possibile distinguere diverse categorie:
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "3.0pt",
                marginLeft: "42.55pt",
                textAlign: "justify",
                textIndent: "-14.15pt",
              }}
            >
              <span
                lang="IT"
                style={{ fontSize: "9.0pt", fontFamily: "Symbol" }}
              >
                ·
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <i>
                <u>
                  <span
                    lang="IT"
                    style={{
                      fontSize: "9.0pt",
                      fontFamily: '"Lucida Sans Unicode",sans-serif',
                    }}
                  >
                    cookie tecnici
                  </span>
                </u>
              </i>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                : usati per eseguire autenticazioni informatiche, monitoraggio
                di sessioni e memorizzazione di informazioni specifiche sugli
                utenti che accedono ad una pagina web;
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "3.0pt",
                marginLeft: "42.55pt",
                textAlign: "justify",
                textIndent: "-14.15pt",
              }}
            >
              <span
                lang="IT"
                style={{ fontSize: "9.0pt", fontFamily: "Symbol" }}
              >
                ·
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <i>
                <u>
                  <span
                    lang="IT"
                    style={{
                      fontSize: "9.0pt",
                      fontFamily: '"Lucida Sans Unicode",sans-serif',
                    }}
                  >
                    cookie analytics
                  </span>
                </u>
              </i>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                : utilizzati dai gestori dei siti web per raccogliere
                informazioni, in forma aggregata, sul numero degli utenti e su
                come questi visitano il sito stesso, e quindi elaborare
                statistiche generali sul servizio e sul suo utilizzo.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "42.55pt",
                textAlign: "justify",
                textIndent: "-14.15pt",
              }}
            >
              <span
                lang="IT"
                style={{ fontSize: "9.0pt", fontFamily: "Symbol" }}
              >
                ·
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <i>
                <u>
                  <span
                    lang="IT"
                    style={{
                      fontSize: "9.0pt",
                      fontFamily: '"Lucida Sans Unicode",sans-serif',
                    }}
                  >
                    cookie di profilazione
                  </span>
                </u>
              </i>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                : utilizzati per monitorare e profilare gli utenti durante la
                navigazione, studiare i loro movimenti e abitudini di
                consultazione del web o di consumo (cosa comprano, cosa leggono,
                ecc.), anche allo scopo di inviare pubblicità di servizi mirati
                e personalizzati (c.d. <i>behavioural advertising</i>).
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginLeft: "21.3pt",
                textAlign: "justify",
                textIndent: "-7.1pt",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  b.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;{" "}
                  </span>
                </span>
              </b>
              <b>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  Cookie utilizzati dal Sito Web
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "14.2pt",
                textAlign: "justify",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                Il presente Sito Web utilizza unicamente <i>cookie</i> c.d.
                tecnici (es. per la memorizzazione dei dati di login
                dell’utente) e <i>cookie</i> <i>analytics</i> di natura tecnica,
                al fine di consentire al visitatore di utilizzare ogni
                funzionalità del Sito Web e di PersonalDoc e raccogliere dati
                aggregati su base statistica per migliorare le relative
                funzionalità e i servizi offerti. Il Titolare si serve, a tal
                proposito, delle funzionalità offerte da Google Analytics 4 con
                IP anonimizzato e ha ricevuto garanzie in merito all’assenza di
                trasferimenti di dati personali verso paesi al di fuori dell’UE.{" "}
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginLeft: "21.3pt",
                textAlign: "justify",
                textIndent: "-7.1pt",
              }}
            >
              <b>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  c.
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;{" "}
                  </span>
                </span>
              </b>
              <b>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  Impostazione delle preferenze
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "14.2pt",
                textAlign: "justify",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                La maggior parte dei web browser è impostata per accettare i{" "}
                <i>cookie</i> in modo automatico. Tuttavia, l’utente può, in
                qualsiasi momento, impostare il proprio browser in modo da
                accettare tutti i cookie, solo alcuni, oppure rifiutarli,
                disabilitandone l’uso da parte dei siti web. Le preferenze
                relative ai cookie, in termini di autorizzazione, blocco o
                cancellazione (in tutto o in parte) devono essere impostate
                separatamente per ciascun browser, poiché ognuno di essi offre
                funzionalità e opzioni specifiche. Inoltre, è possibile, di
                norma, impostare le preferenze del browser in modo tale che
                l’utente venga informato ogni volta che un cookie viene
                memorizzato sul dispositivo utilizzato. Al termine di ogni
                sessione di navigazione, infine, è possibile cancellare dal
                disco fisso del dispositivo i <i>cookie</i>
                raccolti. La disabilitazione totale o parziale dei <i>
                  cookie
                </i>{" "}
                tecnici può compromettere, in tutto o in parte, l’utilizzo delle
                funzionalità dei siti, la visione dei contenuti e la fruibilità
                dei relativi servizi.{" "}
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "14.2pt",
                textAlign: "justify",
              }}
            >
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                Le proprie preferenze in riferimento ai <i>cookie</i> andranno
                reimpostate nel caso in cui si utilizzino diversi dispositivi o
                browser per accedere al Sito Web e/o a PersonalDoc. I browser
                consentono, inoltre, di definire impostazioni diverse per i
                cookie di “prime parti” e per quelli di “terze parti”.
                Selezionando i collegamenti sottostanti è possibile ricevere
                istruzioni specifiche per alcuni dei principali browser web:
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "42.55pt",
                textAlign: "justify",
                textIndent: "-10.9pt",
              }}
            >
              <span style={{ fontSize: "9.0pt", fontFamily: "Symbol" }}>
                ·
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <a href="https://support.microsoft.com/it-it/windows/eliminare-e-gestire-i-cookie-168dab11-0753-043d-7c16-ede5947fc64d">
                <span
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  Microsoft Windows Explorer
                </span>
              </a>
              <span
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                ;
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "42.55pt",
                textAlign: "justify",
                textIndent: "-10.9pt",
              }}
            >
              <span style={{ fontSize: "9.0pt", fontFamily: "Symbol" }}>
                ·
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <a href="https://support.microsoft.com/it-it/microsoft-edge/eliminare-i-cookie-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09#:~:text=In%20Edge%20seleziona%20Impostazioni%20e%20altre%20%3E%20Impostazioni%20%3E%20Cookie%20e%20autorizzazioni,cui%20vuoi%20eliminare%20i%20cookie.">
                <span
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  Microsof Edge
                </span>
              </a>
              <span
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                ;
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "42.55pt",
                textAlign: "justify",
                textIndent: "-10.9pt",
              }}
            >
              <span style={{ fontSize: "9.0pt", fontFamily: "Symbol" }}>
                ·
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <a href="https://support.google.com/chrome/answer/95647?hl=it">
                <span
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  Google Chrome
                </span>
              </a>
              <span
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                ;
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "42.55pt",
                textAlign: "justify",
                textIndent: "-10.9pt",
              }}
            >
              <span
                lang="IT"
                style={{ fontSize: "9.0pt", fontFamily: "Symbol" }}
              >
                ·
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <a href="http://support.mozilla.org/it/kb/Eliminare%20i%20cookie">
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  Mozilla Firefox
                </span>
              </a>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                ;
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "42.55pt",
                textAlign: "justify",
                textIndent: "-10.9pt",
              }}
            >
              <span
                lang="IT"
                style={{ fontSize: "9.0pt", fontFamily: "Symbol" }}
              >
                ·
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <a href="http://www.opera.com/help/tutorials/security/privacy">
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  Opera
                </span>
              </a>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                {" "}
                (non disponibile in italiano);
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "6.0pt",
                marginLeft: "42.55pt",
                textAlign: "justify",
                textIndent: "-10.9pt",
              }}
            >
              <span
                lang="IT"
                style={{ fontSize: "9.0pt", fontFamily: "Symbol" }}
              >
                ·
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <a href="https://support.apple.com/it-it/guide/safari/sfri11471/mac">
                <span
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  Apple
                </span>
                <span
                  lang="IT"
                  style={{
                    fontSize: "9.0pt",
                    fontFamily: '"Lucida Sans Unicode",sans-serif',
                  }}
                >
                  Safari
                </span>
              </a>
              <span
                lang="IT"
                style={{
                  fontSize: "9.0pt",
                  fontFamily: '"Lucida Sans Unicode",sans-serif',
                }}
              >
                .
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
