
import React from 'react';
import { getServiceName } from '../../utils/consts';
import { NineCellLoading } from 'react-loadingg';

// Styles 
import './style.css';
import './bootstrap.min.css';
import './owl.carousel.css';
import './owl.theme.css';
import './owl.transitions.css';
import './animate.css';
import './preloader.css';

import Cookies from 'universal-cookie';
import checkToken from '../../services/checkToken';
import getPacchettiProfessionista from '../../services/getPacchettiProfessionista';
import IconaPacchetto from '../../widgets/IconaPacchetto';
import getInfoPsicologo from '../../services/getInfoPsicologo';
import WeekPicker from '../../widgets/WeekPicker';
import getDisponibilita from '../../services/getDisponibilita';
import moment from 'moment';
import addTempIndisponibilita from '../../services/addTempIndisponibilita';
import addRichiestaPrenotazione from '../../services/addRichiestaPrenotazione';
import NavbarMain from '../../components/NavbarMain';
import { TiBookmark } from 'react-icons/ti';
import canReceivePayments from '../../services/canReceivePayments';
const qs = require('querystring');

const cookies = new Cookies();
let user = cookies.get('username', { path: '/' });
let tok = cookies.get('token', { path: '/' });
export default class Prenota extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            isLoading: true, 
            isMobile: window.innerWidth < 767,
            bannerHeight: window.innerHeight,
            isProfessionista: false, 
            selectedPacchetto: undefined, 
            professionista: undefined, 
            selectedSlot: { i: undefined, index: undefined }, 
            nextVisible: false,
            disponibilita: [], 
            idProfessionista: undefined,
        };
    }

    async componentDidMount() {
        document.title = getServiceName() + ' - Prenotazione';

        let parse = qs.parse(window.location.hash);
        let idProfessionista = parse['#/main/prenota?professionista'];

        if (!idProfessionista) {
            window.location.href = '/#/main/home';
            return;
        }

        user = cookies.get('username', { path: '/' });
        tok = cookies.get('token', { path: '/' });

        let username = parse['u'] || user;
        let token = parse['t'] || tok;

        user = username;
        tok = token;

        if (!username || !token) {
            this.redirectToLogin(idProfessionista);
            return;
        }

        let check = await checkToken(username, token, 0);
        if (check.result === 'KO') {
            let check1 = await checkToken(username, token, 1);

            if (check1.result === 'KO') {
                this.redirectToLogin(idProfessionista);
                return;
            }
            this.setState({ isProfessionista: true, isLoading: false });
            return;
        }

        const check1 = await canReceivePayments(username, token, idProfessionista);
        if (!check1 || check1.result === 'KO') {
            window.alert('Questo professionista non può ricevere prenotazioni al momento');
            window.location.href = "/#/main/home/";
            return;
        }

        let professionista = await getInfoPsicologo(idProfessionista);
        var pacchetti = await getPacchettiProfessionista(username, token, idProfessionista);
        try {
            pacchetti = JSON.parse(pacchetti.pacchetti);
        } catch {
            pacchetti = [];
        }

        let startDate = moment().startOf('week').format('yyyy-MM-DD');
        let endDate = moment().endOf('week').format('yyyy-MM-DD');
        let disponibilita = await getDisponibilita(username, token, startDate, endDate, idProfessionista);

        this.setState({ pacchetti, isLoading: false, professionista, idProfessionista, disponibilita });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    redirectToLogin(idProfessionista) {
        window.location.href = '/#/main/login?redirect=true&to=prenota&id=' + idProfessionista;
        return;
    }

    getPacchettoTitle(tipo, nome) {
        switch(tipo) {
            case 'singola':
                return 'App.to per 1 persona';
            case 'doppia': 
                return 'App.to per 2 persone';
            case 'gruppo':
                return 'App.to di gruppo';
            default: 
                return nome;
        }
    }

    async onWeekSelected(start, end) {
        let startDate = moment(start, 'DD/MM/yyyy').format('yyyy-MM-DD');
        let endDate = moment(end, 'DD/MM/yyyy').format('yyyy-MM-DD');
        
        let disponibilita = await getDisponibilita(user, tok, startDate, endDate, this.state.idProfessionista);
        this.setState({ disponibilita });
    }

    handleResize() {

        this.setState({ bannerHeight: window.innerHeight });

        if (window.innerWidth < 767) {
            this.setState({ isMobile: true });
            return;
        }

        this.setState({ isMobile: false });
    }

    componentDidUpdate() {
        if (this.state.selectedPacchetto !== undefined && this.state.selectedSlot.i !== undefined && this.state.selectedSlot.index !== undefined && !this.state.nextVisible) {
            this.setState({ nextVisible: true });
        } else if ((!this.state.selectedPacchetto === undefined || !this.state.selectedSlot.i === undefined || !this.state.selectedSlot.index === undefined) && this.state.nextVisible) {
            this.setState({ nextVisible: false });
        }
    }

    async requestNext() {

        let selectedSlotTime = this.state.disponibilita[this.state.selectedSlot.i][this.state.selectedSlot.index];
        let selectedPacchetto = this.state.pacchetti[this.state.selectedPacchetto];

        let res = await addTempIndisponibilita(user, tok, selectedSlotTime, this.state.idProfessionista);
        if (res.result === 'KO') {
            alert('Impossibile proseguire con la richiesta al momento. Per favore, verifica di non avere richieste in sospeso.');
            return;
        }

        let res1 = await addRichiestaPrenotazione(user, tok, this.state.idProfessionista, selectedPacchetto, selectedSlotTime);
        if (res1.result === 'KO') {
            alert('Impossibile proseguire con la richiesta al momento. Per favore, verifica di non avere richieste in sospeso.');
            return;
        }
        
        cookies.set('orario_selezionato', selectedSlotTime, { path: '/' });
        cookies.set('professionista_selezionato', this.state.idProfessionista, { path: '/' });
        cookies.set('tipo_selezionato', selectedPacchetto.tipo, { path: '/' });

        window.location.href = '/#/main/riepilogo';

    }

    render () {

        return (
            <div id="main_scroll" className='mainBody' style={{width: '100%', minHeight: '100vh'}}>
                
                <header id='home' style={{height: '100%'}}>
                <NavbarMain noButtons blackBg />

                <div style={{ width: '100vw', height:'100%' }}>
                    <img alt="" src={require('../../images/bg-rete.png')} style={{ width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0, objectFit: 'cover' }} />
                    <div className="container" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div className="row" style={{ zIndex: 1000, display: 'flex', alignItems: 'center', height: '100%' }}>
                            <div className="col-md-8 offset-md-2">
                                <div style={{marginTop: this.state.isMobile ? 100 : 150, overflow: this.state.isMobile ? 'scroll' : 'hidden' }} className="caption">
                                    <div className="prenotaContainer">
                                        { this.state.isLoading ? (
                                            <NineCellLoading />
                                        ) : (
                                            <>
                                                { this.state.isProfessionista ? (
                                                    <p style={{ color: 'lightgrey', marginTop: 10, fontWeight: 'bold', alignSelf: 'center' }}>È necessario effettuare l'accesso con un account di tipo paziente per poter accedere a questa sezione.</p>
                                                ) : (
                                                    <div style={ this.state.isMobile ? { maxHeight: 500, maxWidth: '100%', minWidth: '100%' } : {}}>
                                                        <h5 style={{textShadow:"1px 3px 2px #000000", color: 'white', marginBottom: 20, marginTop: 30 }}>Appuntamento con: <b>{this.state.professionista.cognome} {this.state.professionista.nome}</b></h5>
                                                        <div className='pacchettiContainer'>
                                                            <h6 style={{ color: 'white', alignSelf: 'flex-start' }}>Seleziona il pacchetto di tuo interesse</h6>
                                                            { this.state.pacchetti.map((p, i) => {
                                                                return(
                                                                    <section key={Math.random()} style={{ backgroundColor: this.state.selectedPacchetto === i ? '#202124' : 'rgba(0 0 0 / 25%)' }} onClick={() => this.setState({ selectedPacchetto: i })}>
                                                                        <IconaPacchetto tipo={p.tipo} />
                                                                        <div style={{ marginLeft: 20, flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                                            <p className='pacchettoTitle' style={{ marginBottom: 0 }}>{this.getPacchettoTitle(p.tipo, p.nome)}</p>
                                                                            { p.descrizione && <p className='pacchettoDescription'>{p.descrizione}</p> }
                                                                        </div>
                                                                        <h3>€ {p.costo}</h3>
                                                                    </section>
                                                                );
                                                            })}
                                                        </div>
                                                        { this.state.selectedPacchetto !== undefined && (
                                                            <div style={{ overflowX: this.state.isMobile ? 'scroll' : 'hidden' }}>
                                                                <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'flex-start', width: this.state.isMobile ? '80%' : '90%', marginBottom: 30 }}>
                                                                    <WeekPicker selected={new Date()} onSelect={this.onWeekSelected.bind(this)} />
                                                                </div>
                                                                <div style={{ display: 'table', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'flex-start', width: this.state.isMobile ? '80%' : '90%', overflowX: this.state.isMobile ? 'scroll' : 'hidden' }}>
                                                                    {this.state.disponibilita.map((d, i) => {
                                                                        
                                                                        if (d.length === 0) return <div key={Math.random()} />;
                                                                        let str = moment(d[0], 'yyyy-MM-DDTHH:mm').format('dddd DD MMM');
                                                                        return (
                                                                            <div key={Math.random()} style={{ flex: 1, borderRight: '1px solid lightgrey', paddingLeft: 10, paddingRight: 10, display: 'table-cell' }}>
                                                                                <p style={{ width: '100%', textAlign: 'center', fontWeight: 'bold' }}>{str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()}</p>
                                                                                {d.map((single, index) => (
                                                                                    <section key={Math.random()} style={{ backgroundColor: (this.state.selectedSlot.i === i && this.state.selectedSlot.index === index) ? '#202124' : 'transparent', boxShadow: (this.state.selectedSlot.i === i && this.state.selectedSlot.index === index) ? '0px 2px 4px #1D1D1D' : 'none', width: '100%', borderRadius: 5, cursor: 'pointer' }} onClick={() => this.setState({ selectedSlot: { i: i, index: index } })}>
                                                                                        <div style={{ width: '100%' }}>
                                                                                            <p style={{ width: '100%', paddingLeft: 10, paddingRight: 10, textAlign: 'center' }}>{moment(single, 'yyyy-MM-DDTHH:mm').format('HH:mm')}</p>
                                                                                        </div>
                                                                                    </section>
                                                                                ))}
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )}

                                                        { this.state.nextVisible && <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                                            <div className='signUpButton' onClick={this.requestNext.bind(this)}>Prosegui</div>
                                                        </div> }
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </header>
            </div>
        );
    }
}
