import React from 'react';
import { getServiceName } from '../utils/consts';
import Cookies from 'universal-cookie';
import { Box, Button, Card, Container } from '@material-ui/core';

const cookies = new Cookies();

export default class ProvaTerminata extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,  
            data: undefined,
            editSeduta: false,
            evento: null, 
        };
    }

    componentDidMount() {
        window.document.title = getServiceName() + ' - Errore';
    }

    render() {

        return(
            <Container style={{ width: '100%', height: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: -100 }}>
                <Card style={{ backgroundColor: 'white', borderRadius: 5, padding: 40, textAlign: 'center' }}>
                    <h3>Hai terminato la tua prova gratuita</h3>
                    <h6 style={{ marginTop: 20 }}>Hai già emesso le 30 fatture incluse nella tua prova gratuita di PersonalDoc. Per continuare a utilizzare il portale acquista un abbonamento.</h6>
                    <Button style={{ marginTop: 50 }} fullWidth size="large" type="submit" variant="contained" onClick={() => window.location.href = '/#/app/pacchetti'}>
                        Visualizza opzioni di abbonamento
                    </Button>
                </Card>
            </Container>
        );
    }
}