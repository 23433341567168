import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import JssProvider from 'react-jss/lib/JssProvider';
import { createGenerateClassName } from '@material-ui/core/styles';
import ProfessionistiLayout from '../components/ProfessionistiLayout/Layout';

// components
import Layout from "./Layout";
import MainLayout from './MainLayout/MainLayout';

// pages
import Error from "../pages/error";

// context
import Constants from "../utils/consts";

// Functions
import { getServiceName } from "../utils/consts";
import AdminLayout from "./AdminLayout/AdminLayout";
import PazienteLayout from "./PazienteLayout/PazienteLayout";
import Cookies from "universal-cookie";

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true, // won't minify CSS classnames when true
  productionPrefix: 'c', // 'jss' by default
});
export default class App extends React.Component{
  
  constructor() {
    super();
    this.state = { isLoading: true };
  }

  async componentDidMount(){

    this.client = undefined;
    document.title = getServiceName();

    const script = document.createElement("script");
    script.src = "iubenda.js";
    script.async = false;
    document.body.appendChild(script);

    const script2 = document.createElement("script");
    script2.src = "https://cdn.iubenda.com/cs/iubenda_cs.js";
    script2.async = true;
    script2.charset = "UTF-8";
    document.body.appendChild(script2);

    this.setState({ isLoading: false });
  }

  componentWillUnmount() {
    document.body.removeChild(document.getElementById('iubenda-cs-banner'));
  }

  render() {

    if (this.state.isLoading === true){
      return <div />;
    }
    
    var redirectUrl = '';

    if (window.location.href.split('/')[3].includes('app')) {
      redirectUrl = "/app/dashboard";
    } else if (window.location.href.split('/')[3].includes('paziente')) {
      redirectUrl = "/paziente/dashboard";
    } else if (window.location.href.split('/')[3].includes('admin')) {
      redirectUrl = "/admin/dashboard";
    } else {
      redirectUrl = "/main/home";
    }

      return (
        <JssProvider generateClassName={ generateClassName }>
        <HashRouter>
          <Switch>
            <Route exact path="/" render={() => <Redirect to={'/main/home'} />} />
            <PrivateRoute path={Constants.apisFolder} render={() => <Redirect to={redirectUrl} />} />
            <PrivateRoute path={Constants.apisFolder + 'pdf/'} render={() => <Redirect to={redirectUrl} />} />
            <PrivateRoute path="/app" component={Layout} />
            <PrivateRoute path="/main" component={MainLayout} />
            <PrivateRoute path="/admin" component={AdminLayout} />
            <PrivateRoute path="/paziente" component={PazienteLayout} />
            <PrivateRoute path="/info" component={ProfessionistiLayout} />
            <Route component={Error} />
          </Switch>
        </HashRouter>
        </JssProvider>
      );
      
    }
  }

  function PrivateRoute({ component, ...rest }) {
        
    return (
      <Route
        {...rest}
        render={props =>
          React.createElement(component, props)
        }
      />
    );
  }

