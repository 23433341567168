import React from "react";
import { AiOutlineSearch } from 'react-icons/ai';

export default class Search extends React.Component { 
    constructor(props) {
        super(props); 

        this.state = {
            ricerca: '', 
        };
    }

    render() {

        return(
            <div style={{ width: 250, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5, backgroundColor: 'lightgrey', borderRadius: 5, marginBottom: 15 }}>
                <AiOutlineSearch color="grey" size={15} />
                <input value={this.state.ricerca} type={'text'} placeholder={"Cerca"} style={{ paddingLeft: 5, paddingRight: 20, backgroundColor: 'transparent' }} onChange={(e) => {
                    this.setState({ ricerca: e.target.value });
                    this.props.onCerca(e.target.value);
                }}/>
            </div>
        );
    }
}