import React from "react";
import { FilePicker } from 'react-file-picker'
import translateString from "../utils/Locales";
import User from '../images/user.png';

let t = translateString;
var that = undefined;

export default class ImagePicker extends React.Component {

    constructor (props){
        super(props);

        that = this;

        this.state = {
            base64: this.props.value, 
        }
    }

    render (){
        return ( 
        <div style={{ ...mainClass, ...this.props.style }}>
            <FilePicker
                extensions={['jpg', 'jpeg', 'png', 'pdf']}
                onChange={file => this.getBase64(file, this.props.onChange)}
                onError={() => this.props.onError && this.props.onError()} >

                <img src={ this.state.base64 ? this.state.base64 : User } style={{ border: "2px solid white", width: 100, height: 100, borderRadius: 50, cursor: 'pointer', objectFit: 'cover' }} /> 
            </FilePicker>
        </div>
        )
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result);
            that.setState({ base64: reader.result });
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

}

const mainClass = {
    padding: '10px',
}
