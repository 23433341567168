import Constants from '../utils/consts';

export default async function checkCodiceFiscale(codice) {
    return new Promise(function (resolve, reject) {

        fetch(Constants.defaultProtocol + Constants.mainUrl + 'checkCodiceFiscale.php', {
            method: 'POST', 
            mode: 'cors', 
            body: JSON.stringify({
                'codice_fiscale': codice
            }), 
            headers: { 'Content-Type': 'application/json' }
        })
        .then((res) => res.json())
        .then((data) => {
            resolve(data);
        })
        
    });
}
