import Constants from '../utils/consts';

export default async function updateSeduta(username, token, seduta) {
    return new Promise(function (resolve, reject) {

        fetch(Constants.defaultProtocol + Constants.mainUrl + 'updateSeduta.php', {
            method: 'POST', 
            mode: 'cors', 
            body: JSON.stringify({
                'id': seduta.id, 
                'id_paziente': seduta.id_paziente, 
                'inizio': seduta.inizio, 
                'fine': seduta.fine, 
                'durata': seduta.durata,
                'note': seduta.note, 
                'pagata': seduta.pagata, 
                'fatturata': seduta.fatturata, 
                'importo': seduta.importo, 
                'data_fattura': seduta.dataFattura, 
                'data_pagamento': seduta.dataPagamento, 
                'metodo_pagamento': seduta.metodoPagamento, 
                'numero_fattura': seduta.numeroFattura,
                'tipo_appuntamento': seduta.tipo,
                'fattura_ok': seduta.isFatturaOkay,
                'has_fattura': seduta.hasFattura,
                'edit_fattura': seduta.editFattura,
                'numero_automatico': seduta.numeroAutomatico,
                'paga_prima': seduta.pagamentoAnticipato, 
            }), 
            headers: { 
                'Authorization': 'Bearer ' + username + '.' + token, 
                'Content-Type': 'application/json'
            }
        })
        .then((res) => res.json())
        .then((data) => {
            resolve(data);
        })
        
    });
}
