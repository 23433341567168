import React from "react";
import Cookies from "universal-cookie";
import UserImg from '../images/user.png';
import isFreePeriodActive from "../services/isFreePeriodActive";

export default class Constants extends React.Component {

    static apisFolder = '/apis/'; 
    
    static ipAddress = 'www.personaldoc-app.com';
    static mainUrl = 'www.personaldoc-app.com' + Constants.apisFolder;
    static basicUrl = 'www.personaldoc-app.com/';
    static defaultProtocol = 'https://';
    static PdfTitleFontSize = 11;
    static PdfNormalFontSize = 10;
    static PdfFooterFontSize = 9; 

    static contextMenuItems = [
        'Ord. ascendente',
        'Ord. discendente',
        'Elimina',
        'Salva',
        'Annulla',
        'PdfExport',
        'ExcelExport',
        'CsvExport',
        'Prima',
        'Precedente',
        'Ultima',
        'Prossima',
    ];

    static basicMenuItems = [
        'Ord. ascendente',
        'Ord. discendente',
        'Annulla',
        'ExcelExport',
        'CsvExport',
        'Prima',
        'Precedente',
        'Ultima',
        'Prossima',
    ]

}

export const gridOrderImage = (props) => (
    <div>
      <img
        className="rounded-xl h-20 md:ml-3"
        src={ props.foto ? props.foto : UserImg }
        style={{ width: 50, height: 50 }}
        alt="foto-profilo"
      />
    </div>
);

export function getServiceName() {
    return 'PersonalDoc';
}

export async function provaGratuitaCheck() {
    let cookies = new Cookies();
    let username = cookies.get('username', { path: '/' });
    let token = cookies.get('token', { path: '/' });

    let check = false;
    let provaGratuitaCheck = await isFreePeriodActive(username, token);
    if (provaGratuitaCheck.result === 'ok' && provaGratuitaCheck.is_prova_gratuita === 1) check = true;

    return check;
}

export async function refreshTokenSetup(res) {

    let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60)  * 1000;

    const refreshToken = async() => {
        const newAuthRes = await res.reloadAuthResponse();
        refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60)  * 1000;
        setTimeout(refreshToken, refreshTiming);
    }

    setTimeout(refreshToken, refreshTiming);
}