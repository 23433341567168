import React from 'react';
import { getServiceName } from '../utils/consts';
import Cookies from 'universal-cookie';
import { Card, Container } from '@material-ui/core';
import Loading from '../components/Loading';
import createPayPalOrder from '../services/createPayPalOrder';
import getPianiFatture from '../services/getPianiFatture';
import { Stack } from '@mui/material';
import payInvoicesPlan from '../services/payInvoicesPlan';

const cookies = new Cookies();
let username = cookies.get('username', { path: '/' });
let token = cookies.get('token', { path: '/' });
export default class FattureCheckout extends React.Component {
    constructor(props) {
        super(props);

        this.newAbbonamento = {};
        this.abbonamento = {};
        this.payPalRef = React.createRef();

        this.state = {
            isLoading: true, 
            successful: false,
            error: false,
            plans: [],
            selectedPlan: null,
            paypalOrderId: null,
        };
    }

    async componentDidMount() {
        username = cookies.get('username', { path: '/' });
        token = cookies.get('token', { path: '/' });
        window.document.title = getServiceName() + " - Acquista fatture";
        await this.reload();
    }

    async reload() {

        const plans = await getPianiFatture(username, token);
        if (plans.length === 0) {
            window.location.href = '/#/app/dashboard';
            return;
        }

        this.setState(() => ({
            plans,
            selectedPlan: plans[0].id,
            isLoading: false
        }), () => {
            this.setPayPalButton();
        });
    }

    setPayPalButton() {
        window.paypal.Buttons({
            style: {
                layout: 'vertical',
                color:  'silver',
                shape:  'rect',
                label:  'checkout'
            },
            createOrder: async function () {
                const resp = await createPayPalOrder(username, token, this.state.selectedPlan)
                if (!resp.id) return null;

                this.setState({ paypalOrderId: resp.id });
                return resp.id;
            }.bind(this),
            onApprove: async (data, actions) => { 
                this.setState({ isLoading: true });
                const resp = await payInvoicesPlan(username, token, this.state.paypalOrderId);
                if (resp.result === 'KO') {
                    this.setState({ isLoading: false, error: true });
                    return;
                }
                this.setState({ isLoading: false, successful: true });
            }, 
            onError: (err) => {
                console.error(err);
                this.setState({ error: true });
            }
        }).render(this.payPalRef.current);
    }

    render() {

        if (this.state.isLoading) return <Loading />;
        if (this.state.successful) return (
            <Container style={{ width: '100%', height: '100%', marginBottom: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card style={{ backgroundColor: 'white', borderRadius: 5, padding: 30 }}>
                    <img src={require('../images/tick.png')} style={{ width: 50, height: 50, margin: 'auto', marginBottom: 20 }} alt="tick" />
                    <h4 style={{ textAlign: 'center' }}>Grazie per l'acquisto.<br/>Il tuo account è stato aggiornato.</h4>
                </Card>
            </Container>
        );
        if (this.state.error) return (
            <Container style={{ width: '100%', height: '100%', marginBottom: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card style={{ backgroundColor: 'white', borderRadius: 5, padding: 30 }}>
                    <img src={require('../images/error.jpg')} style={{ width: 100, margin: 'auto', marginBottom: 20 }} alt="tick" />
                    <h4 style={{ textAlign: 'center' }}>Si è verificato un errore durante il pagamento.<br/>Riprova più tardi.</h4>
                </Card>
            </Container>
        );

        return(
            <Container style={{ width: '100%', height: '100%', marginBottom: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card style={{ backgroundColor: 'white', borderRadius: 5, padding: 30, justifyContent: 'center', alignItems: 'center', flexDirection: 'column', display: 'flex' }}>
                    <h3><b>Scegli un pacchetto</b></h3>
                    <p>Acquista altre fatture elettroniche per il tuo account</p>
                    
                    <Stack direction={'row'} spacing={2} style={{ marginTop: 20 }}>
                        {this.state.plans.map((plan) => {

                            const price = parseFloat(plan.price).toFixed(2);
                            const [firstPrice] = price.split('.');
                            const [,secondPrice] = price.split('.');

                            return (
                                <section className="boxFatture" style={{
                                        borderColor: this.state.selectedPlan === plan.id ? '#199289' : 'grey',
                                        backgroundColor: this.state.selectedPlan === plan.id ? '#1992891A' : 'white',
                                    }}
                                    onClick={() => this.setState({ selectedPlan: plan.id })}
                                    key={plan.id}>
                                    <h3 style={{ color: '#454545' }}><b>{plan.n_fatture}</b></h3>
                                    <p style={{ color: '#454545', fontSize: 12 }}>fatture elettroniche</p>
                                    <br />
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <p style={{ color: '#454545', fontSize: 12, height: 12 }}>€ <span style={{ fontSize: 18 }}><b>{firstPrice}</b></span>.<span style={{ fontSize: 12 }}>{secondPrice}</span></p>
                                        <section style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: 20, height: 20, borderRadius: 10, borderWidth: 1, borderColor: this.state.selectedPlan === plan.id ? '#199289' : 'grey' }}>
                                            {this.state.selectedPlan === plan.id && (
                                                <section style={{ width: 14, height: 14, borderRadius: 7, backgroundColor: '#199289' }} />
                                            )}
                                        </section>
                                    </div>
                                </section>
                            );
                        })}
                    </Stack>
                    
                    <div style={{ marginTop: 50 }} ref={this.payPalRef} />
                </Card>
            </Container>
        );
    }
}