import React from "react";
import {
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import { withStyles } from '@material-ui/styles';

import '../../pages/landingPage/style.css';
import '../../index.css';

import translateString from "../../utils/Locales";
import { ToastContainer } from 'react-toastify';
import './ReactToastify.css';
import Dashboard from "../../pages/Dashboard";
import Cookies from "universal-cookie";
import checkToken from "../../services/checkToken";

import NavBar from "../Navbar/NavBar";
import SideBar from "../Sidebar/Sidebar";

import { Provider } from 'react-redux';
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../../context/Reducer";
import Agenda from "../../pages/Agenda";
import Pazienti from "../../pages/Pazienti";
import PaginaPersonale from "../../pages/PaginaPersonale";
import EditPaziente from "../../pages/EditPaziente";
import Clienti from "../../pages/Clienti";
import AddCliente from "../../pages/AddCliente";
import ModificaCliente from "../../pages/ModificaCliente";
import AddPaziente from "../../pages/AddPaziente";
import Messaggi from "../../pages/Messaggi";
import Impostazioni from "../../pages/Impostazioni";
import AnamnesiPaziente from "../../pages/AnamnesiPaziente";
import PdfFattura from "../../pages/PdfFattura";
import TesseraSanitaria from "../../pages/TesseraSanitaria";
import Fatture from "../../pages/Fatture";
import Account from "../../pages/Account";
import ManageSubscription from "../../pages/ManageSubscription";
import Checkout from "../../pages/Checkout";
import ProvaTerminata from "../../pages/ProvaTerminata";
import canUse from "../../services/canUse";
import FattureCheckout from "../../pages/FattureCheckout";

const store = configureStore({ reducer: rootReducer });

let t = translateString;
const cookies = new Cookies();

const makeStyles = theme => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    overflowX: "hidden",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: `calc(100vw - 240px)`,
    minHeight: "100vh",
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px) `,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
});

class Layout extends React.Component {

  constructor(props){
    super(props);

    this.canUse = false;

    this.state = {
      menuItems: [],
      activeMenu: true, 
      isLoading: true,
      canUse: true,
    }
  }

  handleMenuChange() {
    this.setState({ activeMenu: !this.state.activeMenu });
  }

  async componentDidMount(){
    //window.document.title = getServiceName() + ' - ' + t('gestionale');

    let username = cookies.get('username', { path: '/' });
    let token = cookies.get('token', { path: '/' });

    let res = await checkToken(username, token, "1");
    if (!res || res.result === 'KO') {

        cookies.remove('username');
        cookies.remove('token');

        window.location.href = "/#/main/login";
        return;
    }

    this.setState({ isLoading: false });
    window.addEventListener('resize', this.handleResize.bind(this));
    this.handleResize();
    this.checkIfCanUse();
  }

  handleResize() {
    if (window.innerWidth < 768) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    clearInterval(this.checkInterval);
  }

  async checkIfCanUse() {

    this.checkInterval = setInterval(async () => {
      let username = cookies.get('username', { path: '/' });
      let token = cookies.get('token', { path: '/' });

      try {
        let res = await canUse(username, token);
        this.setState({ canUse: (!res || res.result === 'ok') });
      } catch (e) {
        console.error(e)
        this.setState({ canUse: true });
      }
    }, 30000)
  }
  
  render (){

    if (this.state.isLoading) return <div />;
    if (this.state.isMobile) return (
      <div style={{ width: '100%', height: '100vh', backgroundColor: 'black', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <img alt="" style={{ position: 'fixed', top: 20, left: 20, cursor: 'pointer', height: 84 }} onClick={() => {
          cookies.remove('username', { path: '/' })
          cookies.remove('token', { path: '/' })
          window.location.href='/#/main/home'}
          } src={require('../../images/logo.png')}/>
        <img style={{ width: 100, height: 100, objectFit: 'contain' }} src={require('../../images/mobile.png')} alt="Mobile icon" />
        <h4 style={{ textAlign: 'center', marginLeft: 30, marginRight: 30, marginTop: 30 }}>Utilizza il tuo dispositivo desktop o l'app mobile di PersonalDoc per accedere al gestionale</h4>
      </div>
    );

    // global
    const { classes } = this.props;

    return (
      <div  className="bg-rete">
          <>
            <Provider store={store}>
              <ToastContainer style={{ marginTop: 60 }} />
              <div style={{ marginLeft: -17 }} className="flex relative dark:bg-main-dark-bg">
              {this.state.activeMenu ? (
                  <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
                    <SideBar menu={this.state.activeMenu} onMenuChange={this.handleMenuChange.bind(this)} />
                  </div>
                ) : (
                  <div className="w-0 dark:bg-secondary-dark-bg">
                    <SideBar menu={this.state.activeMenu} onMenuChange={this.handleMenuChange.bind(this)} />
                  </div>
                )}
                <div className={this.state.activeMenu
                ? 'dark:bg-main-dark-bg bg-main-bg min-h-screen md:ml-72 w-full  '
                : 'bg-main-bg dark:bg-main-dark-bg w-full min-h-screen flex-2 '} style={{width: this.state.activeMenu ? (this.state.isMobile ? 'calc(100vw - 62px)' : 'calc(100vw - 350px') : 'calc(100vw - 62px)' }} >
                  <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
                    <NavBar onMenuChange={this.handleMenuChange.bind(this)} menu={this.state.activeMenu} />
                  </div>
                  
                  <div style={{ marginLeft: 65, height: 'calc(100% - 120px)' }}>
                    <Switch>
                        <Route path='/app/dashboard' component={this.state.canUse ? Dashboard : ProvaTerminata} />
                        <Route path='/app/messaggi' component={this.state.canUse ? Messaggi : ProvaTerminata} />
                        <Route path='/app/agenda' component={this.state.canUse ? Agenda : ProvaTerminata} />
                        <Route path='/app/pazienti' component={this.state.canUse ? Pazienti : ProvaTerminata} />
                        <Route path='/app/clienti' component={this.state.canUse ? Clienti : ProvaTerminata} />
                        <Route path='/app/aggiungi/cliente' component={this.state.canUse ? AddCliente : ProvaTerminata} />
                        <Route path='/app/visualizza/cliente' component={this.state.canUse ? ModificaCliente : ProvaTerminata} />
                        <Route path='/app/pagina' component={this.state.canUse ? PaginaPersonale : ProvaTerminata} />
                        <Route path='/app/visualizza/paziente' component={this.state.canUse ? EditPaziente : ProvaTerminata} />
                        <Route path='/app/paziente/anamnesi' component={this.state.canUse ? AnamnesiPaziente : ProvaTerminata} />
                        <Route path='/app/aggiungi/paziente' component={this.state.canUse ? AddPaziente : ProvaTerminata} />
                        <Route path='/app/impostazioni' component={this.state.canUse ? Impostazioni : ProvaTerminata} />
                        <Route path='/app/pdfFattura' component={this.state.canUse ? PdfFattura : ProvaTerminata} />
                        <Route path='/app/ts' component={this.state.canUse ? TesseraSanitaria : ProvaTerminata} />
                        <Route path='/app/fatture' component={this.state.canUse ? Fatture : ProvaTerminata} />
                        <Route path='/app/account' component={Account} />
                        <Route path='/app/pacchetti' component={ManageSubscription} />
                        <Route path='/app/checkout' component={Checkout} />
                        <Route path='/app/fattureCheckout' component={FattureCheckout} />
                    </Switch>
                  </div>
                </div>
              </div>
            </Provider>
          </>
      </div>
    );
  }
  
}

export default withStyles(makeStyles)(withRouter(Layout));
