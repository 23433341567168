
import React from 'react';
import { getServiceName } from '../../utils/consts';
import { NineCellLoading } from 'react-loadingg';

// Styles 
import './style.css';
import './bootstrap.min.css';
import './owl.carousel.css';
import './owl.theme.css';
import './owl.transitions.css';
import './animate.css';
import './preloader.css';

import { Input } from '@material-ui/core';
import addTS from '../../services/addTS';
import Cookies from 'universal-cookie';
import moment from 'moment';
import editTS from '../../services/editTS';
import deleteTS from '../../services/deleteTS';
import refundTS from '../../services/refundTS';

const cookies = new Cookies();

export default class TSTest extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            pin: '', 
            password: '', 
            mode: 'invio',
            isLoading: false, 
        };
    }

    async componentDidMount() {
        document.title = getServiceName() + ' - Test tessera sanitaria';

        let username = cookies.get('ts_username', { path: '/' });
        let password = cookies.get('ts_password', { path: '/' });
        let pin = cookies.get('ts_pin', { path: '/' });
        let partitaIva = cookies.get('piva_medico', { path: '/' });
        let cfDottore = cookies.get('cf_medico', { path: '/' });
        let iva = cookies.get('ts_iva', { path: '/' });

        this.setState({ 
            username, 
            password, 
            pin, 
            partitaIva, 
            iva, 
            cfDottore, 
        })
    }

    resetState() {
        this.setState({
            isLoading: false,
            cfPaziente: '', 
            dataEmissione: '', 
            dataPagamento: '', 
            numero: '', 
            importo: undefined, 
            tracciato: undefined, 
            isFattura: undefined, 
            opposizione: undefined,
        });
    }

    async sendRequest() {

        cookies.set('ts_username', this.state.username, { path: '/' });
        cookies.set('ts_password', this.state.password, { path: '/' });
        cookies.set('ts_pin', this.state.pin, { path: '/' });
        cookies.set('piva_medico', this.state.partitaIva, { path: '/' });
        cookies.set('cf_medico', this.state.cfDottore, { path: '/' });
        cookies.set('ts_iva', this.state.iva, { path: '/' });

        let documento = {
            username: this.state.username, 
            password: this.state.password, 
            pincode: this.state.pin, 
            cfPaziente: this.state.cfPaziente, 
            cfMedico: this.state.cfDottore, 
            partitaIVA: this.state.partitaIva, 
            dataEmissione: moment(this.state.dataEmissione, 'DD/MM/yyyy').format('yyyy-MM-DD'), 
            dataPagamento: moment(this.state.dataPagamento, 'DD/MM/yyyy').format('yyyy-MM-DD'), 
            numeroDocumento: this.state.numero, 
            importo: parseFloat(this.state.importo).toFixed(2), 
            iva: parseFloat(this.state.iva).toFixed(2), 
            isTracciato: this.state.tracciato, 
            tipo: this.state.isFattura, 
            flagOpposizione: this.state.opposizione,
        };

        let documentoDelete = {
            username: this.state.username, 
            password: this.state.password, 
            pincode: this.state.pin, 
            cfPaziente: this.state.cfPaziente, 
            cfMedico: this.state.cfDottore, 
            partitaIVA: this.state.partitaIva, 
            dataEmissione: moment(this.state.dataEmissione, 'DD/MM/yyyy').format('yyyy-MM-DD'), 
            numeroDocumento: this.state.numero,
        }

        this.setState({ isLoading: true });

        switch (this.state.mode) {
            case "invio":
                let res = await addTS(documento);
                console.log(res);
                if (res.result && res.result === 'ok') {
                    alert(res.messaggio + ' con numero di protocollo: ' + res.protocollo);
                    this.resetState();
                    return;
                }

                this.setState({ isLoading: false });
                alert(res.messaggio);
                break;
            case "modifica":
                let res1 = await editTS(documento);
                if (res1.result && res1.result === 'ok') {
                    alert('Documento modificato correttamente nel sistema TS');
                    this.resetState();
                    return;
                }

                this.setState({ isLoading: false });
                alert(res1.response);
                break;
            case "eliminazione":
                let res2 = await deleteTS(documentoDelete);
                if (res2.result && res2.result === 'ok') {
                    alert('Documento eliminato correttamente dal sistema TS');
                    this.resetState();
                    return;
                }

                this.setState({ isLoading: false });
                alert(res2.response);
                break;
            case "rimborsa":
                let res3 = await refundTS(documento);
                if (res3.result && res3.result === 'ok') {
                    alert('Documento rimborsato correttamente nel sistema TS');
                    this.resetState();
                    return;
                }

                this.setState({ isLoading: false });
                alert(res3.response);
                break;
            default: 
                break;
        }

    }

    render () {

        return (
            <div className='mainBody' style={{width: '100%', height: '100%'}}>
                
                <header id='home' style={{height: '100%', zIndex:9}}>
                    
                    <div className="new-banner" style={{position: 'absolute', top: 0, left: 0, width: '100vw', flex: 1, height: this.state.bannerHeight }}>
                        <div className="container" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div className="row" style={{ zIndex: 1000, display: 'flex', alignItems: 'center', height: '100%' }}>
                                <div className="col-md-8 offset-md-2">
                                    <div className="caption">
                                        { this.state.isLoading ? 
                                            <div className='loginContainer' style={{ marginTop: 70, marginBottom: 100, paddingRight: 30, flexDirection: 'column' }}>
                                                <NineCellLoading />
                                            </div>
                                        : 
                                            <div className="loginContainer" style={{ marginTop: 70, marginBottom: 100, paddingRight: 30, flexDirection: 'column' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                                                    <h6 style={{ color: this.state.mode === 'invio' ? 'orange' : 'white', cursor: 'pointer', borderBottom: '1px solid', borderBottomColor: this.state.mode === 'invio' ? 'orange' : 'transparent' }} onClick={() => this.setState({ mode: 'invio' })}>Invia</h6>
                                                    <h6 style={{ marginLeft: 15, color: this.state.mode === 'modifica' ? 'orange' : 'white', cursor: 'pointer', borderBottom: '1px solid', borderBottomColor: this.state.mode === 'modifica' ? 'orange' : 'transparent' }} onClick={() => this.setState({ mode: 'modifica' })}>Modifica</h6>
                                                    <h6 style={{ marginLeft: 15, color: this.state.mode === 'eliminazione' ? 'orange' : 'white', cursor: 'pointer', borderBottom: '1px solid', borderBottomColor: this.state.mode === 'eliminazione' ? 'orange' : 'transparent' }} onClick={() => this.setState({ mode: 'eliminazione' })}>Elimina</h6>
                                                    <h6 style={{ marginLeft: 15, color: this.state.mode === 'rimborsa' ? 'orange' : 'white', cursor: 'pointer', borderBottom: '1px solid', borderBottomColor: this.state.mode === 'rimborsa' ? 'orange' : 'transparent' }} onClick={() => this.setState({ mode: 'rimborsa' })}>Rimborsa</h6>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <Input type='text' placeholder='Codice identificativo' value={this.state.username} style={{ height: 40, width: 200, padding: 5 }} onChange={(e) => this.setState({ username: e.target.value })} />
                                                    <Input type='text' placeholder='Parola chiave' value={this.state.password} style={{ height: 40, width: 200, marginLeft: 10, padding: 5 }} onChange={(e) => this.setState({ password: e.target.value })} />
                                                    <Input type='text' placeholder='PIN' value={this.state.pin} style={{ height: 40, width: 200, marginLeft: 10, padding: 5 }} onChange={(e) => this.setState({ pin: e.target.value })} />
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 40 }}>
                                                    <Input type='text' placeholder='Codice fiscale pz. (*)' value={this.state.cfPaziente} style={{ height: 40, width: 300, padding: 5 }} onChange={(e) => this.setState({ cfPaziente: e.target.value })} />
                                                    <Input type='text' placeholder='Codice fiscale dr. (*)' value={this.state.cfDottore} style={{ height: 40, width: 300, marginLeft: 30, padding: 5 }} onChange={(e) => this.setState({ cfDottore: e.target.value })} />
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <Input type='text' placeholder='Data emissione (gg/mm/yyyy) (*)' value={this.state.dataEmissione} style={{ height: 40, width: 300, padding: 5 }} onChange={(e) => this.setState({ dataEmissione: e.target.value })} />
                                                    { this.state.mode !== 'eliminazione' && <Input type='text' placeholder='Data pagamento (gg/mm/yyyy) (*)' value={this.state.dataPagamento} style={{ height: 40, width: 300, marginLeft: 30, padding: 5 }} onChange={(e) => this.setState({ dataPagamento: e.target.value })} /> }
                                                </div>
                                                { this.state.mode !== 'eliminazione' && <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <Input type='text' placeholder='Importo (*)' value={this.state.importo} style={{ height: 40, width: 300, padding: 5 }} onChange={(e) => this.setState({ importo: e.target.value })} />
                                                    { this.state.mode !== 'rimborsa' && <Input type='text' placeholder='IVA (*)' value={this.state.iva} style={{ height: 40, width: 300, marginLeft: 30, padding: 5 }} onChange={(e) => this.setState({ iva: e.target.value })} /> }
                                                </div> }
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <Input type='text' placeholder='Numero documento (*)' value={this.state.numero} style={{ height: 40, width: this.state.mode === 'rimborsa' ? 265 : 300, padding: 5 }} onChange={(e) => this.setState({ numero: e.target.value })} /> {this.state.mode === 'rimborsa' ? <p style={{ fontSize: 18, fontWeight: 'bold', marginTop: 5, marginLeft: 5 }}>/R</p> : ''}
                                                    <Input type='text' placeholder='Partita IVA medico (*)' value={this.state.partitaIva} style={{ height: 40, width: 300, marginLeft: this.state.mode === 'rimborsa' ? 40 : 30, padding: 5 }} onChange={(e) => this.setState({ partitaIva: e.target.value })} />
                                                </div>
                                                { (this.state.mode !== 'eliminazione') && <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                                                    <Input name='fattura' title='Fattura' id='fattura' type='radio' placeholder='Fattura' aria-selected={this.state.isFattura} style={{ height: 40 }} onChange={(e) => this.setState({ isFattura: 'F' })} />
                                                    <p style={{ color: 'white', marginLeft: 15, marginTop: 7 }}>Fattura</p>
                                                    <Input name='fattura' type='radio' placeholder='Documento commerciale' style={{ height: 40, marginLeft: 30}} onChange={() => this.setState({ isFattura: 'D' })} />
                                                    <p style={{ color: 'white', marginLeft: 15, marginTop: 7 }}>Documento commerciale</p>
                                                </div> }
                                                { (this.state.mode !== 'eliminazione') && <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <Input name='pagamento_tracciato' title='Fattura' id='fattura' type='radio' placeholder='Fattura' aria-selected={this.state.isFattura} style={{ height: 40 }} onChange={() => this.setState({ tracciato: 'SI' })} />
                                                    <p style={{ color: 'white', marginLeft: 15, marginTop: 7 }}>Pagamento tracciato</p>
                                                    <Input name='pagamento_tracciato' type='radio' placeholder='Documento commerciale' style={{ height: 40, marginLeft: 30, border: 0 }} onChange={() => this.setState({ tracciato: 'NO' })} />
                                                    <p style={{ color: 'white', marginLeft: 15, marginTop: 7 }}>Pagamento NON tracciato</p>
                                                </div> }
                                                { (this.state.mode !== 'eliminazione') && <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <Input name='opposizione' title='Fattura' id='fattura' type='radio' placeholder='Fattura' aria-selected={this.state.isFattura} style={{ height: 40 }} onChange={() => this.setState({ opposizione: '1' })} />
                                                    <p style={{ color: 'white', marginLeft: 15, marginTop: 7 }}>Il pz si oppone al sistema TS</p>
                                                    <Input name='opposizione' type='radio' placeholder='Documento commerciale' style={{ height: 40, marginLeft: 30}} onChange={() => this.setState({ opposizione: '0' }) } />
                                                    <p style={{ color: 'white', marginLeft: 15, marginTop: 7 }}>Il pz NON si oppone al sistema TS</p>
                                                </div> }
                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: -10 }}>
                                                    <div className='signUpButton' onClick={this.sendRequest.bind(this)}>Invia</div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        );
    }
}
