import React from 'react';
import translateString from '../utils/Locales';
import { RiPsychotherapyLine } from 'react-icons/ri';
import { BsCalendarEvent } from 'react-icons/bs';
import { CgCloseO } from 'react-icons/cg';
import { TiTick } from 'react-icons/ti';
import moment from 'moment';

let t = translateString;

class SelectionSection extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            hovering: false,
        }
    }

    render() {

        return(
            <section style={{ ...selection, backgroundColor: this.state.hovering ? '#7B90C433' : 'white'}} onMouseEnter={() => this.setState({ hovering: true })} onMouseLeave={() => this.setState({ hovering: false })} onClick={() => this.props.onClick && (this.props.indisponibilita ? this.props.onClick(this.props.indisponibilita) : this.props.onClick())} >
                {this.props.icon}
                <span style={{ marginLeft: 10 }}>{this.props.text}</span>
            </section>
        );
    }
}

export default class ContextMenu extends React.Component {
    constructor(props) {
        super(props);

        var location = props.location;
        let width = window.innerWidth;
        
        if (location.x > width - 250) {
            location.x = location.x - 200;
        }

        this.state = {
            location: location, 
            deleteInd: false,
            ind: undefined, 
        }
    }

    componentDidMount() {

        let ind = this.props.indisponibilita;
        let inizio = this.props.target.start;
        let fine = this.props.target.end;

        ind.forEach((i) => {
            if (inizio.getTime() >= i.inizio.getTime() && fine.getTime() <= i.fine.getTime()) {
                this.setState({ deleteInd: true, ind: i });
            } else if (moment(i.inizio).isBetween(inizio, fine) || moment(i.fine).isBetween(inizio, fine)) {
                this.setState({ deleteInd: true, ind: i });
            }
        })
    }


    render() {

        return(
            <div style={backView} onClick={() => this.props.onClose && this.props.onClose()}>
                <div style={{ display: 'block', position: 'relative', left: this.state.location.x, top: this.state.location.y, ...contextMenu }} onClick={(e) => e.stopPropagation()} >
                    <SelectionSection icon={<RiPsychotherapyLine color={'#686868'} />} text={t('nuova_seduta')} onClick={this.props.onSeduta} />
                    <SelectionSection icon={<BsCalendarEvent color={'#686868'} />} text={t('nuovo_evento')} onClick={this.props.onEvento} />
                    {! this.state.deleteInd && (
                        <SelectionSection icon={<CgCloseO color={'#686868'} />} text={t('nuova_chiusura')} onClick={this.props.onIndisponibilita} />
                    )}
                    { this.state.deleteInd && (
                        <SelectionSection icon={<TiTick color={'#686868'} />} text={t('elimina_chiusura')} indisponibilita={this.state.ind} onClick={this.props.deleteIndisponibilita} />
                    )}
                </div>
            </div>
        );
    }
}

const backView = {
    position: 'absolute', 
    top: 0, 
    left: 0,
    minWidth: '100vw', 
    minHeight: '100%', 
    alignItems: 'center', 
    justifyContent: 'center',
    textAlign: 'center',
    zIndex: 999999999, 
}

const contextMenu = {
    backgroundColor: 'white', 
    borderRadius: 5, 
    boxShadow: '0px 2px 10px lightgrey',
    width: 200,
    paddingTop: 10, 
    paddingBottom: 10, 
    overflow: 'hidden',
}

const selection = {
    color: '#686868', 
    display: 'flex', 
    flexDirection: 'row', 
    justifyContent: 'flex-start',
    alignItems: 'center', 
    cursor: 'pointer',
    paddingLeft: 20,
    paddingRight: 20, 
    paddingTop: 5, 
    paddingBottom: 5,
}
