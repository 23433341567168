import React from 'react';
import Cookies from 'universal-cookie';
import Button from '../components/Button';
import Loading from '../components/Loading';
import getOrari from '../services/getOrari';
import getPacchettiSedute from '../services/getPacchettiSedute';
import getPersonalizzazioni from '../services/getPersonalizzazioni';
import getServizi from '../services/getServizi';
import personalizzaSedute from '../services/personalizzaSedute';
import setOrari from '../services/setOrari';
import setServizi from '../services/setServizi';
import { getServiceName } from '../utils/consts';
import translateString from '../utils/Locales';
import GridServizi from '../widgets/GridServizi';
import OrariWidget from '../widgets/OrariWidget';
import PacchettoWidget from '../widgets/PacchettoWidget';
import SelectInput from '../widgets/SelectInput';
import { Checkbox, Grid, TextField } from '@material-ui/core';
import updatePacchettiSedute from '../services/updatePacchettiSedute';
import checkStripeActive from '../services/checkStripeActive';
import Banner from '../widgets/Banner';
import setDatiTS from '../services/setDatiTS';
import getDatiTS from '../services/getDatiTS';
import { Stack } from '@mui/material';
import getImpostazioniFatturazione from '../services/getImpostazioniFatturazione';
import editImpostazioniFatturazione from '../services/editImpostazioniFatturazione';
import getProfiloProfessionista from '../services/getProfiloProfessionista';
import requestNewStripeUrl from '../services/requestNewStripeUrl';
import SelectCostoModale from '../widgets/SelectCostoModale';

const cookies = new Cookies();
let t = translateString;
var pacchettiCount = 0;

export default class Impostazioni extends React.Component {
    constructor(props) {
        super(props);

        this.lunediRef = React.createRef();
        this.martediRef = React.createRef();
        this.mercolediRef = React.createRef();
        this.giovediRef = React.createRef();
        this.venerdiRef = React.createRef();
        this.sabatoRef = React.createRef();
        this.domenicaRef = React.createRef();
        this.serviziRef = React.createRef();

        this.mainRef = React.createRef();

        this.durate = [
            { value: 180, label: '180 minuti' }, 
            { value: 120, label: '120 minuti' }, 
            { value: 90, label: '90 minuti' }, 
            { value: 60, label: '60 minuti' }, 
            { value: 45, label: '45 minuti' },
            { value: 30, label: '30 minuti' }
        ]

        this.regimi = [
            { value: "RF01", label: "Ordinario" },
            { value: "RF18", label: "Altro" },
            { value: "RF19", label: "Regime forfettario" },
        ];

        this.state = {
            isLoading: true,
            error: false, 
            errorMessage: '',
            success: false, 
            successMessage: '', 
            durataColloquio: { value: 60, label: '60 minuti' }, 
            costoColloquio: 70.0,
            pacchetti: [], 
            usernameTS: '', 
            passwordTS: '', 
            pincodeTS: '', 
        };
    }

    async componentDidMount() {

        window.document.title = getServiceName() + ' - Impostazioni';

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        var orari = await getOrari(username, token);
        if (!orari) {
            orari = { orari: null };
        }

        let personalizzazioni = await getPersonalizzazioni(username, token);
        if (!personalizzazioni) return;

        var ts = { }

        let servizi = await getServizi(username, token);
        let res = await getPacchettiSedute(username, token);
        ts = await getDatiTS(username, token);

        if (!ts || !ts.username) {
            ts = {
                username: '', 
                password: '', 
                pincode: '', 
                invio_auto: '1', 
            }
        }
        
        var pacchetti = [];
        var refs = [];

        if (res && res.pacchetti) {
            pacchetti = JSON.parse(res.pacchetti);
            
            pacchetti.forEach((_, ind) => {
                pacchettiCount++;
                pacchetti[ind].id = pacchettiCount;
            });
            this.setState({ pacchettiCustom: true });
        } else { 
            
            pacchetti = [
                {  
                    id: 1,
                    tipo: 'singola', 
                    editable: false, 
                    costo: parseFloat(personalizzazioni.costo), 
                    descrizione: '',
                    nome: '', 
                },
                {
                    id: 2,
                    tipo: 'doppia', 
                    editable: true, 
                    costo: undefined, 
                    descrizione: '', 
                    nome: '',
                }
            ];

            pacchettiCount = 2;
        }

        pacchetti.forEach(() => {
            refs.push(React.createRef());
        });

        var isStripeActive = await checkStripeActive(username, token);
        let stripeAccount = isStripeActive.account;
        let stripeAccountId = isStripeActive.id;
        isStripeActive = isStripeActive.result === 'ok';

        const impostazioniFatturazione = await getImpostazioniFatturazione(username, token);
        const imp = {
            showCodiceFiscale: impostazioniFatturazione.codice_fiscale === "1",
            showIndirizzo: impostazioniFatturazione.indirizzo === "1", 
            showTitolo: impostazioniFatturazione.titolo, 
            showEmail: impostazioniFatturazione.email === "1", 
            bolloDigitale: impostazioniFatturazione.bollo_digitale === "1", 
            tassa: parseFloat(impostazioniFatturazione.tassa).toFixed(2), 
            bolloToPaziente: impostazioniFatturazione.bollo === "paziente", 
        };

        let professionista = await getProfiloProfessionista(username, token);
        let tipologiaProfessionista = professionista.categoria;

        let selectedRegime = this.regimi.find(r => r.value === professionista.regime_fiscale);

        this.setState({ userId: professionista.id_utente, tipologiaProfessionista, isStripeActive, stripeAccount, stripeAccountId, isLoading: false, orari: JSON.parse(orari.orari), durataColloquio: JSON.parse(personalizzazioni.durata), costoColloquio: parseFloat(personalizzazioni.costo), servizi: JSON.parse(servizi.servizi), pacchetti, refs, usernameTS: ts.username, passwordTS: ts.password, pincodeTS: ts.pincode, autoSend: ts.invio_auto === '1', impostazioniFatturazione: imp, prefissoFattura: impostazioniFatturazione.prefisso_fattura ? impostazioniFatturazione.prefisso_fattura : "PD-", regimeFiscale: selectedRegime });
    }

    async salvaOrari() {

        var orari = [];
        let refs = [this.lunediRef, this.martediRef, this.mercolediRef, this.giovediRef, this.venerdiRef, this.sabatoRef, this.domenicaRef];

        var done = true;
        refs.forEach((r) => {
            if (done) {
                let o = r.current.getOrari();
                if (!o) done = false;
                orari.push(o);
            }
        });

        if (!done) return;

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        let res = await setOrari(username, token, JSON.stringify(orari));
        console.log(res);
        if (!res || res.result === 'KO') {
            this.setState({ error: true, errorMessage: t('orari_error') });
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 3000);
            return;
        }

        this.setState({ success: true, successMessage: t('orari_success')});
        setTimeout(() => {
            this.setState({ success: false, successMessage: '' });
        }, 5000);

    }

    async salvaImpostazioniColloquio() {

        if (!this.state.durataColloquio.value) {
            alert('Per favore, definisci prima una durata standard per i tuoi colloqui.');
            return;
        }

        if (!this.state.costoColloquio) {
            alert('Per favore, definisci prima un costo per i tuoi colloqui.');
            return;
        }

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        let res = await personalizzaSedute(username, token, JSON.stringify(this.state.durataColloquio), this.state.costoColloquio);
        if (!res || res.result === 'KO') {
            this.setState({ error: true, errorMessage: t('pers_error') });
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 5000);
            return;
        }  

        this.setState({ success: true, successMessage: t('pers_success')});
        setTimeout(() => {
            this.setState({ success: false, successMessage: '' });
        }, 5000);
    }

    async salvaImpostazioniTS() {

        if (!this.state.usernameTS || !this.state.passwordTS || !this.state.pincodeTS) {
            alert('Per favore, inserisci prima tutti i campi.');
            return;
        }

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        let res = await setDatiTS(username, token, {
            username: this.state.usernameTS, 
            password: this.state.passwordTS, 
            pincode: this.state.pincodeTS, 
            invio_auto: this.state.autoSend ? 1 : 0,
        });

        if (!res || res.result === 'KO') {
            this.setState({ error: true, errorMessage: res.description});
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 5000);
            return;
        }  

        this.setState({ success: true, successMessage: 'Dati impostati correttamente'});
        setTimeout(() => {
            this.setState({ success: false, successMessage: '' });
        }, 5000);
    }

    async salvaServizi() {

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });

        var servizi = this.serviziRef.current.getServizi();
        if (!servizi) {
            let res = await setServizi(username, token, null);
            if (!res || res.result === 'KO') {
                this.setState({ error: true, errorMessage: t('serv_error') });
                setTimeout(() => {
                    this.setState({ error: false, errorMessage: '' });
                }, 5000);
                return;
            }

            this.setState({ success: true, successMessage: t('serv_success')});
            setTimeout(() => {
                this.setState({ success: false, successMessage: '' });
            }, 5000);
        }

        servizi = JSON.stringify(servizi);
        let res = await setServizi(username, token, servizi);
        if (!res || res.result === 'KO') {
            this.setState({ error: true, errorMessage: t('serv_error') });
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 5000);
            return;
        }

        this.setState({ success: true, successMessage: t('serv_success')});
        setTimeout(() => {
            this.setState({ success: false, successMessage: '' });
        }, 5000);
    }

    async salvaPacchetti(reload) {

        var completed = true;
        var pacchetti = [];
        this.state.refs.forEach((r) => {

            let pacchetto = r.current.getPacchetto();
            if (!pacchetto) completed = false;
            else pacchetti.push(pacchetto);
        });

        if (!completed) {
            alert('Alcuni pacchetti non sono completi. Verifica di aver definito un costo per ognuno dei pacchetti creati.');
            return;
        }

        pacchetti.forEach((p, i) => {
            pacchetti[i].id = i + 1;
        });

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });

        let res = await updatePacchettiSedute(username, token, JSON.stringify(pacchetti));
        if (!res || res.result === 'KO') {
            alert('Impossibile salvare i tuoi pacchetti di appuntamenti al momento. Per favore, riprova');
            return;
        }

        this.setState({ success: true, successMessage: t('pacc_success')});
        setTimeout(() => {
            this.setState({ success: false, successMessage: '' });
        }, 5000);

        if (reload) window.location.reload();
    }

    async salvaImpostazioniFatturazione() {

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });

        if (this.state.impostazioniFatturazione.tassa === 0.00 && !window.confirm("Hai inserito lo 0% di tassa previdenziale. Sei sicuro della selezione?")) return;

        const opzioni = {
            indirizzo: this.state.impostazioniFatturazione.showIndirizzo ? "1" : "0", 
            codice_fiscale: this.state.impostazioniFatturazione.showCodiceFiscale ? "1" : "0", 
            titolo: this.state.impostazioniFatturazione.showTitolo, 
            email: this.state.impostazioniFatturazione.showEmail ? "1" : "0", 
            bollo_digitale: this.state.impostazioniFatturazione.bolloDigitale ? "1" : "0", 
            tassa: this.state.impostazioniFatturazione.tassa, 
            bollo: this.state.impostazioniFatturazione.bolloToPaziente ? "paziente" : "professionista", 
        }

        let res = await editImpostazioniFatturazione(username, token, JSON.stringify(opzioni), this.state.prefissoFattura, this.state.regimeFiscale.value);
        if (!res || res.result === 'KO') {
            this.setState({ error: true, errorMessage: res.description});
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 5000);
            return;
        }  

        this.setState({ success: true, successMessage: 'Dati impostati correttamente'});
        setTimeout(() => {
            this.setState({ success: false, successMessage: '' });
        }, 5000);
    }

    nuovoPacchetto() {

        var p = this.state.pacchetti;
        pacchettiCount++;

        p.push({
            id: pacchettiCount, 
            tipo: 'singola', 
            costo: undefined, 
            editable: true, 
            descrizione: '', 
            nome: '', 
        });
        
        var r = this.state.refs;
        r.push(React.createRef());

        this.setState({ pacchetti: p, refs: r });
    }

    rimuoviPacchetto(pack) {

        var p = [];
        var r = [];
        var pFinali = [];
        
        this.state.refs.forEach((ref) => {
            let pacchetto = ref.current.getPacchettoParziale();
            p.push(pacchetto);
        });

        p.forEach((pacchetto) => {
            if (pacchetto.id !== pack.id) {
                pFinali.push(pacchetto);
            }
        });

        pFinali.forEach(() => {
            r.push(React.createRef());
        });

        this.setState({ pacchetti: pFinali, refs: r });
    }

    render() {

        if (this.state.isLoading) return <Loading />;

        return(
            <div ref={this.mainRef} style={{ paddingBottom: 100 }}>
                <h3 style={{ color: 'white' }}>{t('impostazioni')}</h3>

                { this.state.error && (
                    <div className='error-div'>
                        {this.state.errorMessage}
                    </div>
                )}
                { this.state.success && (
                    <div className='success-div'>
                        {this.state.successMessage}
                    </div>
                )}

                <Banner
                    tipo={this.state.isStripeActive ? 'success' : 'error'}
                    testo={this.state.isStripeActive ? 'Il tuo account è correttamente connesso a Stripe e può ricevere pagamenti' : 'Risultano esserci dei problemi con il tuo account Stripe e, al momento, non puoi ricevere pagamenti. Verifica sulla tua dashboard Stripe se è necessario eseguire qualche azione.'}
                    buttonText={this.state.isStripeActive ? null : 'Apri Stripe'}
                    onOpen={async () => {
                        if (this.state.stripeAccount == 1) {
                            window.open('https://dashboard.stripe.com/login', '_blank');
                        } else {
                            let urlReq = await requestNewStripeUrl(this.state.stripeAccountId, this.state.userId);

                            if (urlReq.result !== 'ok') {
                                alert(`Il nostro partner Stripe ha risposto con un errore alla richiesta.\n\nErrore: ${urlReq.message}.\n\nModifica i dati errati e continua il processo.`);
                                return;
                            }
                
                            let account = urlReq.stripe_account;
                            let url = urlReq.stripe_url;
                
                            cookies.set('stripe_account', account, { path: '/' });
                            window.location.href = url;
                        }
                    }}
                />

                <section style={{ marginTop: 20 }} className='dashview'>
                    <h5 style={{ color: 'white' }}>Dati Sistema Tessera Sanitaria</h5>
                    <p style={{ color: 'white', opacity: 0.7 }}>Inserisci i tuoi dati per l'accesso al sistema tessera sanitaria. <a href="https://sistemats1.sanita.finanze.it/portale/info-sulle-modalita-di-accesso-associazioni-di-categoria" target='_blank' style={{ color: 'white', textDecoration: 'underline' }}>Qui</a> puoi trovare maggiori informazioni.</p>
                
                    <section className='orari' style={{ flexDirection: 'column', alignItems: 'flex-start', marginTop: -20 }}>
                        <section style={{ display: 'flex' }}>
                            <p style={{ width: 200, maxWidth: 200, color: 'white', opacity: 0.7 }} className='giorno-label'>Nome utente</p>
                            <input style={{ border: 0, borderBottom: '1px solid lightgrey', width: 200, marginTop: -10, marginLeft: -40, marginBottom: 15, backgroundColor: 'transparent', color: 'white', paddingLeft: 5 }} type={'text'} step={0.01} value={this.state.usernameTS} onChange={(e) => this.setState({ usernameTS: e.target.value })} />
                        </section>
                        <section style={{ display: 'flex' }}>
                            <p style={{ width: 200, maxWidth: 200, color: 'white', opacity: 0.7 }} className='giorno-label'>Password</p>
                            <input style={{ border: 0, borderBottom: '1px solid lightgrey', width: 200, marginTop: -10, marginLeft: -40, marginBottom: 15, backgroundColor: 'transparent', color: 'white', paddingLeft: 5 }} type={'password'} step={0.01} value={this.state.passwordTS} onChange={(e) => this.setState({ passwordTS: e.target.value })} />
                        </section>
                        <section style={{ display: 'flex' }}>
                            <p style={{ width: 200, maxWidth: 200, color: 'white', opacity: 0.7 }} className='giorno-label'>Pincode</p>
                            <input style={{ border: 0, borderBottom: '1px solid lightgrey', width: 200, marginTop: -10, marginLeft: -40, marginBottom: 15, backgroundColor: 'transparent', color: 'white', paddingLeft: 5 }} type={'text'} step={0.01} value={this.state.pincodeTS} onChange={(e) => this.setState({ pincodeTS: e.target.value })} />
                        </section>
                    </section>

                    <Stack direction='row' style={{ marginLeft: 10, marginTop: -30, marginBottom: 10, color: 'white' }}>
                        <Checkbox key={Math.random()} checked={this.state.autoSend} onChange={() => this.setState({ autoSend: !this.state.autoSend }) } />
                        <p style={{ marginTop: 18 }}>Invia automaticamente al Sistema Tessera Sanitaria le fatture regolarmente saldate durante il weekend</p>
                    </Stack>

                    <div style={{ width: 100 }}>
                        <Button button={"SALVA"} onClick={() => this.salvaImpostazioniTS()} />
                    </div>
                </section>

                <section style={{ marginTop: 20 }} className='dashview'>
                    <h5 style={{ color: 'white' }}>{t('orari_sedute')}</h5>
                    <p style={{ color: 'white' }}>{t('orari_sedute_info')}</p>

                    <OrariWidget key={t('lun')} ref={this.lunediRef} orari={this.state.orari ? this.state.orari[0] : null} giorno={t('lun')} />
                    <OrariWidget key={t('mar')} ref={this.martediRef} orari={this.state.orari ? this.state.orari[1] : null} giorno={t('mar')} />
                    <OrariWidget key={t('mer')} ref={this.mercolediRef} orari={this.state.orari ? this.state.orari[2] : null} giorno={t('mer')} />
                    <OrariWidget key={t('gio')} ref={this.giovediRef} orari={this.state.orari ? this.state.orari[3] : null} giorno={t('gio')} />
                    <OrariWidget key={t('ven')} ref={this.venerdiRef} orari={this.state.orari ? this.state.orari[4] : null} giorno={t('ven')} />
                    <OrariWidget key={t('sab')} ref={this.sabatoRef} orari={this.state.orari ? this.state.orari[5] : null} giorno={t('sab')} />
                    <OrariWidget key={t('dom')} ref={this.domenicaRef} orari={this.state.orari ? this.state.orari[6] : null} giorno={t('dom')} />

                    <div style={{ width: 100 }}>
                        <Button button={"SALVA"} onClick={() => this.salvaOrari()} />
                    </div>
                </section>

                <section style={{ marginTop: 20 }} className='dashview'>
                    <h5 style={{ color: 'white' }}>{t('impo_sedute')}</h5>
                    <p style={{ color: 'white' }}>{t('impo_sedute_info')}</p>
                
                    <section className='orari' style={{ flexDirection: 'column', alignItems: 'flex-start', marginTop: 0 }}>
                        <section style={{ display: 'flex' }}>
                            <p style={{ width: 200, maxWidth: 200, color: 'white', opacity: 0.7 }} className='giorno-label'>{t('durata_coll')}</p>
                            <div style={{ marginTop: -15, marginLeft: -50 }}>
                                <SelectInput 
                                    value={this.state.durataColloquio} 
                                    hidable={false}
                                    isMulti={false}
                                    options={this.durate} 
                                    style={{ marginLeft: 10, marginTop: 5, width: 200, color: 'grey' }}
                                    onChange={(option) => this.setState({ durataColloquio: option })} 
                                />
                            </div>
                        </section>
                    </section>
                    <section className='orari' style={{ flexDirection: 'column', alignItems: 'flex-start', marginTop: -20 }}>
                        <section style={{ display: 'flex' }}>
                            <p style={{ width: 200, maxWidth: 200, color: 'white', opacity: 0.7 }} className='giorno-label'>{t('costo_coll')}</p>
                            <input style={{ border: 0, borderBottom: '1px solid lightgrey', width: 120, marginTop: -10, marginLeft: -40, marginBottom: 15, backgroundColor: 'transparent', color: 'white', paddingLeft: 5 }} type={'number'} step={0.01} value={this.state.costoColloquio} onClick={() => this.setState({ selectCosto: true })} />
                        </section>
                    </section>

                    <div style={{ width: 100 }}>
                        <Button button={"SALVA"} onClick={() => this.salvaImpostazioniColloquio()} />
                    </div>
                </section>

                <section style={{ marginTop: 20 }} className='dashview'>
                    <h5 style={{ color: 'white' }}>Impostazioni di fatturazione</h5>
                    <p style={{ color: 'white' }}>Queste impostazioni saranno utilizzate sia per le fatture automatiche generate al pagamento degli appuntamenti sia all'emissione manuale. Le impostazioni NON sono retroattive.</p>
                
                    <Stack spacing={0}>
                        <Stack direction='row' style={{ marginLeft: -10, color: 'white' }}>
                            <Checkbox key={Math.random()} checked={this.state.impostazioniFatturazione.showCodiceFiscale} onChange={() => {
                                const impostazioni = this.state.impostazioniFatturazione;
                                impostazioni.showCodiceFiscale = !impostazioni.showCodiceFiscale;
                                this.setState({ impostazioniFatturazione: impostazioni });
                            }} />
                            <p style={{ marginTop: 18 }}>Mostra il tuo codice fiscale nella fattura</p>
                        </Stack>
                        <Stack direction='row' style={{ marginLeft: -10, marginTop: -20, color: 'white' }}>
                            <Checkbox key={Math.random()} checked={this.state.impostazioniFatturazione.showIndirizzo} onChange={() => {
                                const impostazioni = this.state.impostazioniFatturazione;
                                impostazioni.showIndirizzo = !impostazioni.showIndirizzo;
                                this.setState({ impostazioniFatturazione: impostazioni });
                            }} />
                            <p style={{ marginTop: 18 }}>Mostra il tuo indirizzo nella fattura</p>
                        </Stack>
                        <Stack direction='row' style={{ marginLeft: -10, marginTop: -20, color: 'white' }}>
                            <Checkbox key={Math.random()} checked={this.state.impostazioniFatturazione.showEmail} onChange={() => {
                                const impostazioni = this.state.impostazioniFatturazione;
                                impostazioni.showEmail = !impostazioni.showEmail;
                                this.setState({ impostazioniFatturazione: impostazioni });
                            }} />
                            <p style={{ marginTop: 18 }}>Mostra il tuo indirizzo email nella fattura</p>
                        </Stack>
                        <Stack direction='row' style={{ marginLeft: -10, marginTop: -20, color: 'white' }}>
                            <Checkbox key={Math.random()} checked={this.state.impostazioniFatturazione.bolloDigitale} onChange={() => {
                                const impostazioni = this.state.impostazioniFatturazione;
                                impostazioni.bolloDigitale = !impostazioni.bolloDigitale;
                                this.setState({ impostazioniFatturazione: impostazioni });
                            }} />
                            <p style={{ marginTop: 18 }}>Utilizza il bollo assolto digitalmente (con l'autorizzazione dell'AdE)</p>
                        </Stack>
                        <Stack direction='row' style={{ marginLeft: -10, marginTop: -20, color: 'white' }}>
                            <Checkbox key={Math.random()} checked={this.state.impostazioniFatturazione.bolloToPaziente} onChange={() => {
                                const impostazioni = this.state.impostazioniFatturazione;
                                impostazioni.bolloToPaziente = !impostazioni.bolloToPaziente;
                                this.setState({ impostazioniFatturazione: impostazioni });
                            }} />
                            <p style={{ marginTop: 18 }}>Addebita il costo della marca da bollo (2,00 €) al paziente</p>
                        </Stack>
                        <Stack direction='column' style={{ color: 'white', marginBottom: 30, maxWidth: 500 }} spacing={1}> 
                            <TextField value={this.state.impostazioniFatturazione.showTitolo} type={"text"} label="Titolo da mostrare (Dr, Dr.ssa, ...)" inputProps={{ style: {color: 'white'} }} onChange={(e) => {
                                const impostazioni = this.state.impostazioniFatturazione;
                                impostazioni.showTitolo = e.target.value;
                                this.setState({ impostazioniFatturazione: impostazioni });
                            }} />   
                            <TextField value={this.state.impostazioniFatturazione.tassa} type={"number"} label="Percentuale di tassa previdenziale *" inputProps={{ style: {color: 'white'} }} onChange={(e) => {
                                const impostazioni = this.state.impostazioniFatturazione;
                                impostazioni.tassa = e.target.value ? parseFloat(e.target.value) : 0.00;
                                this.setState({ impostazioniFatturazione: impostazioni });
                            }} />   
                            <TextField value={this.state.prefissoFattura} type={"text"} label="Prefisso fattura (se non definito: PD-)" inputProps={{ style: {color: 'white'} }} onChange={(e) => this.setState({ prefissoFattura: e.target.value ? e.target.value : "PD-" })} />   
                            <section style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 100,  color: 'white', opacity: 0.7, marginTop: 5 }} className='giorno-label'>Regime fiscale</p>
                                <div style={{ marginTop: -15, marginLeft: 5 }}>
                                    <SelectInput 
                                        value={this.state.regimeFiscale} 
                                        hidable={false}
                                        isMulti={false}
                                        login
                                        options={this.regimi} 
                                        style={{ marginLeft: 10, marginTop: 5, width: 200, color: 'grey' }}
                                        onChange={(option) => this.setState({ regimeFiscale: option })} 
                                    />
                                </div>
                            </section>
                        </Stack>
                    </Stack>

                    <div style={{ width: 100 }}>
                        <Button button={"SALVA"} onClick={() => this.salvaImpostazioniFatturazione()} />
                    </div>
                </section>

                { this.state.tipologiaProfessionista === 'psicologia' && (
                    <section style={{ marginTop: 20 }} className='dashview'>
                        <h5 style={{ color: 'white' }}>{t('gest_servizi')}</h5>
                        <p style={{ color: 'white' }}>{t('gest_servizi_info')}</p>

                        <GridServizi ref={this.serviziRef} servizi={this.state.servizi} />

                        <div style={{ width: 100, marginTop: 10 }}>
                            <Button button={"SALVA"} onClick={() => this.salvaServizi()} />
                        </div>
                    </section>
                )}

                <section style={{ marginTop: 20 }} className='dashview'>
                    <h5 style={{ color: 'white' }}>{t('pacchetti_s')}</h5>
                    <p style={{ color: 'white' }}>{t('pacchetti_s_info')}</p>

                    <section className='pacchetti-container' style={{ marginTop: 30, marginBottom: 20, }}>
                        <Grid container spacing={2} columns={{ xs: 6, sm: 12, md: 12 }} style={{ maxWidth: 620 }}>
                            { this.state.pacchetti.map((pacchetto, ind) => {

                                return(
                                    <Grid key={Math.random()} item xs={12} sm={6}>
                                        <PacchettoWidget ref={this.state.refs[ind]} index={pacchetto} tipo={pacchetto.tipo} nome={pacchetto.nome} editable={pacchetto.editable} costo={pacchetto.costo} descrizione={pacchetto.descrizione} rimuoviPacchetto={(pack) => this.rimuoviPacchetto(pack)}/>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </section>
                    
                    <section style={{ display: 'flex' }}>
                        <div style={{ width: 100, marginTop: 10 }}>
                            <Button button={"SALVA"} onClick={() => this.salvaPacchetti(true)} />
                        </div>
                        <div style={{ width: 200, marginTop: 10, marginLeft: 20 }}>
                            <Button button={"AGGIUNGI NUOVO"} onClick={() => this.nuovoPacchetto()} />
                        </div>
                    </section>
                </section>

                { this.state.selectCosto && (
                    <SelectCostoModale cost={this.state.costoColloquio} handleClose={() => this.setState({ selectCosto: false })} open={true} onUpdate={(costo) => {
                        this.mainRef.current.focus();
                        this.setState({ selectCosto: false, costoColloquio: costo });

                        let pacchetti = [ ...this.state.pacchetti];
                        pacchetti.forEach((p, i) => {
                            if (p.id === 1) {
                                pacchetti[i].costo = costo;
                            }
                        })

                        this.setState(() => ({ pacchetti }), () => {
                            this.salvaImpostazioniColloquio();
                            if (this.state.pacchettiCustom)
                                this.salvaPacchetti(false);
                        });
                    }} />
                )}
            </div>
        );
    }
}