import moment from 'moment';
import React from 'react';
import Cookies from 'universal-cookie';
import Button from '../components/Button';
import CloseImg from '../images/close.png';
import { MeteorRainLoading } from 'react-loadingg';
import translateString from '../utils/Locales';
import SelectInput from './SelectInput';
import Switch from 'react-switch';

import '../index.css';
import getClienti from '../services/getClienti';
import addEvento from '../services/addEvento';
import getPazientiUtenti from '../services/getPazientiUtenti';
import sendMessaggio from '../services/sendMessaggio';

let t = translateString;
const cookies = new Cookies();

export default class NuovoMessaggioWidget extends React.Component {
    constructor(props) {
        super(props);


        let margin = ((window.innerHeight * 5) / 100) + window.scrollY;

        this.state = {
            isLoading: true, 
            margin: margin,
            pazienti: [{ value: '', label: t('sel_paziente') }],
            paziente: { value: '', label: t('sel_paziente') }, 
            error: false, 
            errorMessage: '',
            testo: '', 
        };
    }

    handleScroll() {
        this.setState({ margin: 'calc(5vh + ' + window.scrollY + 'px)' });
    }

    async componentDidMount() {

        var pazienti = [{ value: '', label: t('sel_paziente') }];

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        let res = await getPazientiUtenti(username, token);
        if (res.result === 'KO') {
            this.props.onClose();
            alert('Nessun paziente trovato');
            return;
        }

        pazienti.push({ value: 0, label: 'Tutti' });

        res.forEach((c) => {
            pazienti.push({ value: c.id, label: c.cognome + ' ' + c.nome });
        });
        
        window.addEventListener('scroll', this.handleScroll.bind(this));

        this.setState({ isLoading: false, pazienti });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    async invia() {
        
        if (this.state.paziente.value === '') {
            this.setState({ error: true, errorMessage: t('sel_p_p') });
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 3000);
            return;
        }

        if (!this.state.testo.trim()) {
            this.setState({ error: true, errorMessage: t('inserisci_testo') });
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 3000);
            return;
        }

        this.setState({ isLoading: true });

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        
        if (this.state.paziente.value !== 0) {
            // Invio singolo
            let res = await sendMessaggio(username, token, this.state.paziente.value, 'psicologo', this.state.testo);
            if (!res || res.result === 'KO') {
                this.setState({ isLoading: false, error: true, errorMessage: t('errore_imprevisto') });
                setTimeout(() => {
                    this.setState({ error: false, errorMessage: '' });
                }, 3000);
                return;
            }
        } else {
            // Invio a tutti
            let pazienti = this.state.pazienti.filter(p => (p.value !== 0 && p.value !== ''));
            var errorMultiplo = false;
            for (const paziente of pazienti) {
                let res = await sendMessaggio(username, token, paziente.value, 'psicologo', this.state.testo);
                if (!res || res.result === 'KO') {
                    errorMultiplo = true;
                }
            }

            if (errorMultiplo) {
                this.setState({ isLoading: false, error: true, errorMessage: t('errore_imprevisto') });
                setTimeout(() => {
                    this.setState({ error: false, errorMessage: '' });
                }, 3000);
                return;
            } else {
                window.location.reload();
            }
        }
        
        this.props.onClose(0);

    }

    render() {

        if (this.state.isLoading) return(
            <div style={backView} onClick={() => this.props.onClose && this.props.onClose()}>
                <div style={{ ...mainView, marginTop: this.state.margin }} onClick={(e) => e.stopPropagation()}>
                    <MeteorRainLoading />
                </div>
            </div>
        );

        return(
            <div style={backView} onClick={() => this.props.onClose && this.props.onClose()}>
                <div style={{ ...mainView, marginTop: this.state.margin }} onClick={(e) => e.stopPropagation()}>
                    <img src={ CloseImg } style={{ position: 'absolute', top: 20, right: 20, width: 20, height: 20, cursor: 'pointer' }} onClick={() => this.props.onClose && this.props.onClose()} />
                    <h3>{t('new_messaggio')}</h3>
                    { this.state.error && (
                        <div className='error-div'>
                            {this.state.errorMessage}
                        </div>
                    )}
                    <label style={{ marginTop: 10, marginBottom: -25, color: 'lightgrey' }}>{t('nuovo_m_info')}</label>
                    <SelectInput
                        value={this.state.paziente}
                        small={true} 
                        style={{ width: '100%', marginTop: 30 }}
                        hidable={false}
                        isMulti={false}
                        options={this.state.pazienti} 
                        onChange={(option) => 
                            this.setState({ paziente: option })} 
                    />

                    <h5 style={{ marginTop: 30 }}>{t('mess_s')}</h5>
                    <textarea style={{ resize: 'none', height: 100, width: '100%', border: '1px solid lightgrey', borderRadius: 10, marginTop: 10, padding: 10, marginBottom: 10 }} value={this.state.testo} placeholder={t('nuovo_msg')} onChange={(e) => this.setState({ testo: e.target.value })} />
                    <Button button={"INVIA"} style={{ marginTop: 20, boxShadow: 'none', flex: 1 }} onClick={() => this.invia()} />
                </div>
            </div>
        );
    }
}

const backView = {
    position: 'absolute', 
    top: 0, 
    left: 0,
    minWidth: '100vw', 
    minHeight: '100%', 
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#00000066', 
    textAlign: 'center',
    zIndex: 999999999, 
}

const mainView = {
    position: 'relative',
    width: 400,
    maxHeight: '90vh', 
    minHeight: 300,
    marginLeft: 'calc((100vw - 400px)/2)',
    backgroundColor: 'white', 
    borderRadius: 10, 
    overflow: 'scroll',
    padding: 30,
    zIndex: 1000000000,
    textAlign: 'left',
}