import { Box, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import 'moment/locale/it';
import moment from "moment";
import { Stack } from "@mui/system";
import React from "react";
import { Button } from "@material-ui/core";
import addClick from "../services/addClick";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export default function TypeSelectorModale({ open, handleClose, onUpdate, onUser, isMobile }) {

    useEffect(() => {
        moment().locale('it');
    }, []);

    const onClose = () => {
        if (handleClose) {
            handleClose();
        }
    }

    const save = async (tipo) => {

        await addClick(tipo);     
        cookies.set('type_chosen', tipo, { path: '/' });
        onUser(tipo);
        onClose();
    }
    
    return (
        <Modal
            style={{ zIndex: 10000000 }}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={{ flex: 1, height: '100%', backgroundColor: '0000004D', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ backgroundColor: 'white', borderRadius: 2, width: '80%', px: 5, py: 10 }} onClick={e => e.stopPropagation()}>
                    <Stack spacing={2}>
                        <h3 style={{ width: '100%', textAlign: 'center', paddingLeft: 30, paddingRight: 30 }}>Vogliamo conoscerti un po' meglio. Che tipo di utente sei?</h3>
                        <Stack spacing={isMobile ? 1 : 3} direction={isMobile ? "column" : "row"}>
                            <Button fullWidth size="large" type="submit" variant="contained" onClick={() => save('professionista')}>
                                Sono un professionista
                            </Button>
                            <Button fullWidth size="large" type="submit" variant="contained" onClick={() => save('paziente')}>
                                Cerco un professionista
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Box>
        </Modal>
    );
}