import React from 'react';
import { getServiceName } from '../../utils/consts';
import translateString from '../../utils/Locales';

// Styles 
import './style.css';
import './bootstrap.min.css';
import './owl.carousel.css';
import './owl.theme.css';
import './owl.transitions.css';
import './animate.css';
import './preloader.css';
import MainSearch from '../../components/MainSearch';
import Cookies from 'universal-cookie';
import TypeSelectorModale from '../../widgets/TypeSelectorModale';
import NavbarMain from '../../components/NavbarMain';
import mail from '../../images/mail.svg';
import instagram from '../../images/instagram.svg';
import facebook from '../../images/facebook.svg';
import linkedin from '../../images/linkedin.svg';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import moment from 'moment';
import ScrollableSelection from './ScrollableSelection';

let t = translateString;
const cookies = new Cookies();
export default class LandingPage extends React.Component {
    constructor(props) {
        super(props);
        this.progettoRef = React.createRef();
        this.funzionalitaRef = React.createRef();
        this.abbonamentiRef = React.createRef();
        this.appRef = React.createRef();
        this.firstStepsRef = React.createRef();
    
        this.state = {
            playVideo: false,
            filter: 0,
            chooseType: false,
            expanded: "",
            isMobile: false,
            typeChosen: null,
        };
    }

    handleChange = (panel) => (event, newExpanded) => {
        this.setState({ expanded: newExpanded ? panel : false });
    }

    handleResize() {
        this.setState({ bannerHeight: window.innerHeight });
        if (document.getElementById('navbar')) {
            if (window.innerWidth < 992) {
            this.setState({ isMobile: true });
            } else {
            this.setState({ isMobile: false, isMenuOpen: false });
            }
        }
    }

    async componentDidMount() {

        window.addEventListener('resize', this.handleResize.bind(this));

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        let typeChosen = cookies.get('type_chosen', { path: '/' });
        if (!typeChosen && !username && !token) {
            this.setState({ chooseType: true });
        }

        this.handleResize();

        document.title = getServiceName() + ' - Homepage';
        
        this.setState({typeChosen });
        
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 800);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    render () {
        return (
            <div id='main_scroll' className='mainBody' ref={this.homeRef} style={{ width: '100%', height: '100%' }}>
                <link rel="stylesheet" href="bootstrap-4.1.1-dist/css/bootstrap.min.css" />
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossOrigin="anonymous" />
                { (this.state.typeChosen == 'professionista' && !this.state.isMobile) && (
                    <div style={{ position: 'absolute', backgroundColor: 'black', zIndex: 10000, borderRadius: 10, right: 30, bottom: 30, paddingTop: 10, paddingVertical: 10, paddingLeft: 50, paddingRight: 50, boxShadow: '0px 2px 4px black', cursor: 'pointer' }} onClick={() => {
                        this.firstStepsRef.current.scrollIntoView({ behavior: 'smooth' });
                    }}>
                        <p style={{ color: 'white', fontWeight: 'bold', marginTop: 5 }}>Da dove inizio?</p>
                    </div>
                )}
                <header id='home'>
                    <NavbarMain isPaziente={this.state.typeChosen !== 'professionista'} progettoRef={this.progettoRef} funzionalitaRef={this.funzionalitaRef} abbonamentiRef={this.abbonamentiRef} appsRef={this.appRef} />
                    <div className="banner" style={{ height: this.state.bannerHeight, paddingTop: this.state.isMobile ? 100 : 0 }}>
                        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, height: this.state.bannerHeight, backgroundColor: 'black', opacity: 0.55 }} />
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', width: '100%', position: 'absolute', bottom: 0, left: 0, right: 0, height: 100 }}>
                            <img src={require('../../images/scroll.gif')} alt="scroll_down" style={{ width: this.state.isMobile ? 20 : 30, height: 'auto', opacity: 0.5, rotate: '180deg' }} />
                        </div>
                        <div className="container position-relative" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: this.state.bannerHeight }}>
                            <div className="row" style={{ zIndex: 1000, display: 'flex', alignItems: 'center', height: '100%', widht: '100%' }}>
                                <div className="col-md-8 offset-md-2" style={{ width: '100%', paddingLeft: this.state.isMobile ? '7%' : 0 }}>
                                    <div className="caption">
                                        <h1 style={{ color: 'white', fontWeight: 'bold', fontFamily: `url(${require('../../fonts/Nexa.ttf')})`, fontSize: this.state.isMobile ? 30 : 48 }}>{this.state.typeChosen === 'professionista' ? "PersonalDoc" : t('trova_psic_home').toUpperCase()}</h1>
                                        <p style={{ color: 'white', fontWeight: 'bold', fontFamily: `url(${require('../../fonts/Nexa.ttf')})` }}>{this.state.typeChosen === 'professionista' ? "Il gestionale che si occupa della tua professione e crea spazio nella tua vita personale." : t('psic_sub')}</p>
                                        {(this.state.typeChosen !== 'professionista' && this.state.typeChosen !== null) && (
                                            <ScrollableSelection isMobile={this.state.isMobile}>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: '40px' }}>
                                                    <p style={{ color: 'white', fontWeight: this.state.filter === 0 ? 'bold' : 'normal', textDecoration: this.state.filter === 0 ? 'underline' : 'none', cursor: 'pointer' }} onClick={() => this.setState({ filter: 0 })}>PROFESSIONISTA</p>
                                                    <p style={{ marginLeft: '20px', color: 'white', fontWeight: this.state.filter === 1 ? 'bold' : 'normal', textDecoration: this.state.filter === 1 ? 'underline' : 'none', cursor: 'pointer'  }} onClick={() => this.setState({ filter: 1 })}>SPECIALIZZAZIONE</p>
                                                    <p style={{ marginLeft: '20px', color: 'white', fontWeight: this.state.filter === 3 ? 'bold' : 'normal', textDecoration: this.state.filter === 3 ? 'underline' : 'none', cursor: 'pointer'  }} onClick={() => this.setState({ filter: 3 })}>LUOGO</p>
                                                    <p style={{ marginLeft: '20px', color: 'white', fontWeight: this.state.filter === 2 ? 'bold' : 'normal', textDecoration: this.state.filter === 2 ? 'underline' : 'none', cursor: 'pointer'  }} onClick={() => this.setState({ filter: 2 })}>{t('online')}</p>
                                                </div>
                                            </ScrollableSelection>
                                        )}
                                        {this.state.typeChosen !== 'professionista' && <MainSearch type={this.state.filter} isMobile={this.state.isMobile} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                    {
                        this.state.typeChosen === 'professionista' ? (
                            <>
                                <section ref={this.progettoRef} className="dark" id='personalDoc'>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-12 my-5">
                                                <h1>{'personalDoc'.toUpperCase()}</h1>
                                                <div style={{height:'3px', width:'90px', backgroundColor:'white'}} />
                                            </div>
                                        </div>
                                        <div className="row py-4">
                                            <div className=" col-sm-12 col-md-8">
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>PersonalDoc &eacute; un portale studiato per tutti i professionisti sanitari!</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Continui a investire il tuo tempo libero, oltre l’orario di lavoro, per fare le fatture o per cercare di capire chi ha pagato, chi deve ancora pagare o se il pagamento è arrivato o deve arrivare?</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Questo è il gestionale pensato per te, per farti risparmiare tempo e soldi, facile, rapido e intuitivo.</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Registra un cliente o un paziente, inserisci un appuntamento in agenda e vi sarà la possibilità per il paziente di saldare immediatamente la prestazione, in automatico avverrà l’emissione e l’invio della fattura.</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>In contemporanea puoi anche scegliere di trasmettere i dati al Sistema Tessera Sanitaria.</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Non ti servirà più perdere tempo per compilare le fatture, ti basterà un unico semplice click!</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Il paziente potrà pagare in anticipo grazie alle notifiche che tu deciderai di far comparire nella sua app, così i tuoi clienti potranno pagare con carta o bonifico e PersonalDoc registrerà l’entrata del pagamento in automatico.</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Se decidi di far parte dei professionisti della nostra app sei visibile anche nel web perché ti pubblicizziamo noi!</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Promuovi la tua professionalità, non la tua professione!</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Dalla dashboard personale, potrai inoltre monitorare in tempo reale i tuoi appuntamenti e le tue fatturazioni, un resoconto immediato intuitivo e rapido sempre a portata di mano!</p>
                                            </div>
                                            <div className=" col-sm-12 col-md-4 w-100">
                                                <div className='inchiostro'/>
                                            </div>
                                        </div> 
                                    </div>
                                </section>

                                <section ref={this.funzionalitaRef} className="light" id='agenda'>
                                    <div className="container pb-4">
                                        <div className="row">
                                            <div className="col-md-12 my-5 d-flex align-items-end flex-column">
                                                <h1>{'agenda'.toUpperCase()}</h1>
                                                <div style={{height:'3px', width:'90px', backgroundColor:'white'}} />
                                            </div>
                                        </div>
                                        <div className="row py-4">
                                            <div className=" col-sm-12 col-md-4 position-relative">
                                                <div className='calendar' />
                                            </div>
                                            <div className="col-sm-12 col-md-8 d-flex flex-column justify-content-center">
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>I tuoi pazienti attraverso l’app di PersonalDoc, in modo del tutto gratuito, inseriscono i loro dati e prenotano un appuntamento con te, in base alle disponibilità che tu hai reso visibili dalla tua agenda.</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Puoi sincronizzare l’agenda con tutte le altre agende presenti nei tuoi device, non dovrai spostare o ricopiare niente, una sincronizzazione automatica che ti consentirà di monitorare i tuoi impegni e avere tutti i tuoi appuntamenti in un’unica app.</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Tu, deciderai di impostare le notifiche per te e i tuoi clienti, potrai inviare messaggi a tutti o a piccoli gruppi. Nessuno si dimenticherà più degli appuntamenti con te!</p>
                                            </div>
                                        </div> 
                                    </div>
                                </section>

                                <section className="dark" id='chat'>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-12 my-5">
                                                <h1>{'chat e videocall'.toUpperCase()}</h1>
                                                <div style={{height:'3px', width:'90px', backgroundColor:'white'}} />
                                            </div>
                                        </div>
                                        <div className="row py-4">
                                            <div className=" col-sm-12 col-md-8 d-flex align-items-end flex-column">
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Non ti servirà più il telefono di lavoro o un secondo numero, perché ogni comunicazione con il tuo paziente potrà avvenire con PersonalDoc.</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Troverai uno spazio per chattare, per la condivisione dei tuoi documenti, fogli di lavoro per ogni incontro e una finestra per sostenere gli incontri online in videocall, quindi le chiamate e le videochiamate non saranno più un problema.</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Potrai effettuare call e meeting, anche di gruppo, con il tuo paziente o i tuoi clienti direttamente da PersonalDoc.</p>
                                            </div>
                                            <div className=" col-sm-12 col-md-4">
                                                <div className='videocall'/>
                                            </div>
                                        </div> 
                                    </div>
                                </section>
                                
                                <section className="light" id='fattura'>
                                    <div className="container pb-4">
                                        <div className="row">
                                            <div className="col-md-12 my-5 d-flex align-items-end flex-column">
                                                <h1>{'fatturazione'.toUpperCase()}</h1>
                                                <div style={{height:'3px', width:'90px', backgroundColor:'white'}} />
                                            </div>
                                        </div>
                                        <div className="row py-4">
                                            <div className=" col-sm-12 col-md-4 position-relative">
                                                <div className='bill'/>
                                            </div>
                                            <div className=" d-flex flex-column col-sm-12 col-md-8">
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Scopri il sistema di fatturazione intuitivo e automatico.</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Basta perdere tempo per compilare e spedire le fatture!</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Con PersonalDoc è tutto automatizzato e di facile controllo perché consente l’emissione e l’invio delle Fatture Elettroniche P.A. e Business to business, in previsione del futuro obbligo normativo.</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Attraverso il pagamento digitale, inoltre, avrai un risparmio di tempo e un controllo immediato.</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Nelle fatture è previsto già il calcolo automatico della quota ENPAP, vi è inoltre la possibilità di escluderlo per le prestazioni verso i clienti dove sarà necessaria l’iva.</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Non dimenticare che le tue fatture sanitarie potranno essere soggette alla detrazione fiscale per il tuo paziente, con PersonalDoc l’invio dei dati al sistema Tessera Sanitaria sarà automatico.</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Oltretutto, puoi esportare ogni dato al tuo commercialista con facilità, un semplice file è sempre pronto per te.</p>
                                            </div>
                                        </div> 
                                    </div>
                                </section>
                                
                                <section className="dark" id='pagamenti'>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-12 my-5">
                                                <h1>{'pagamenti semplici e rapidi'.toUpperCase()}</h1>
                                                <div style={{height:'3px', width:'90px', backgroundColor:'white'}} />
                                            </div>
                                        </div>
                                        <div className="row py-4">
                                            <div className="col-sm-12 col-md-8 d-flex flex-column">
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Le disdette non saranno più un problema!</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Il vantaggio unico di questa app è la velocità di pagamento digitale con la quale i clienti possono regolare le proprie prestazioni sanitarie.</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Quindi il tuo paziente prenota dall’app o dal sito l'appuntamento e lo paga già o ha tempo fino a tre giorni prima dell’incontro, anche se sei tu professionista ad inserire l'appuntamento, al tuo cliente arriverà una notifica che deve pagare la prestazione prima che avvenga.</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Il sistema, ad ogni incasso, provvede ad eseguire la riconciliazione degli accrediti così avrai molto più tempo per te e non servirà più controllare costantemente il tuo conto corrente.</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>La riconciliazione bancaria automatica consiste nel verificare che le registrazioni contabili corrispondano ai dati riportati nell'estratto conto.</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>La modalità riconciliazione degli incassi abbina ogni singolo pagamento avvenuto da parte del tuo paziente alle entrate registrate dal tuo conto corrente, nel momento in cui l’app verifica l’associazione, emette automaticamente la fattura.</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Sarà facilissimo registrarsi, è sufficiente seguire le indicazioni e di fronte ad ogni difficoltà puoi contattare il nostro servizio clienti.</p>
                                            </div>
                                            <div className="col-sm-12 col-md-4">
                                                <div className='pagamenti'/>
                                            </div>
                                        </div> 
                                    </div>
                                </section>
                                
                                <section className="light" id='tesseraSanitaria'>
                                    <div className="container pb-4">
                                        <div className="row">
                                            <div className="col-md-12 my-5 d-flex align-items-end flex-column">
                                                <h1 style={{ textAlign: 'right' }}>{'sistema tessera sanitaria'.toUpperCase()}</h1>
                                                <div style={{height:'3px', width:'90px', backgroundColor:'white'}} />
                                            </div>
                                        </div>
                                        <div className="row py-4">
                                            <div className="col-sm-12 col-md-4 position-relative">
                                                <div className='tesseraSanitaria' />
                                            </div>
                                            <div className="col-sm-12 col-md-8 d-flex flex-column justify-content-center">
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>E come funziona la detrazione delle spese mediche?</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Il sistema tessera sanitaria, obbligatorio solo per le prestazioni sanitarie, sarà un altro automatismo di cui si occuperà la nostra app.</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>per te sarà sufficiente inserire le tue password e spuntare una casella per inviare i dati dei pazienti selezionati al portale del sistema tessera sanitaria.</p>
                                                <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Del resto se ne occupa PersonalDoc.</p>
                                            </div>
                                        </div> 
                                    </div>
                                </section>

                                <section style={{ paddingBottom: 50 }} ref={this.firstStepsRef} className="dark" id='info'>
                                    <div className="container d-flex flex-column align-items-center">
                                        <div className="row">
                                            <div className="col-md-12 my-5">
                                                <h1>{'Primi passi'.toUpperCase()}</h1>
                                                <div style={{height:'3px', width:'90px', backgroundColor:'white'}} />
                                            </div>
                                        </div>
                                        <div className="row py-4">
                                            <div className="col-lg-12">
                                                <Accordion expanded={this.state.expanded === 'panel1'} onChange={this.handleChange('panel1')}>
                                                    <AccordionSummary style={{ backgroundColor: '#171717', color: 'white' }} aria-controls="panel1d-content" id="panel1d-header">
                                                        <Typography color='white'>Modalità di gestione per i propri pazienti </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails color='white' style={{ backgroundColor: '#171717', color: 'white' }}>
                                                        <Typography color='white'>
                                                            <li>Vai nella sezione di registrazione, subito dopo scarica l’app PersonalDoc da Google Play o Apple Store.</li>
                                                            <li>Proponi al tuo paziente il download dell’app.</li>
                                                            <li>Calendarizza un appuntamento.</li>
                                                            <li>Il tuo paziente lo visualizzerà nella sua app e potrà procedere con il pagamento.</li>
                                                            <li>Nella sezione dedicata della tua app potrai visualizzare l'avvenuto pagamento, la conferma dell’appuntamento, l’emissione della fattura e in automatico potrai impostare anche la trasmissione dei dati al Sistema Tessera Sanitaria. Inoltre, PersonalDoc ti consentirà di emettere Fatture Elettroniche. La tua app visualizzerà il pagamento avvenuto con successo nel tuo conto e in automatico invierà la fattura e i dati al sistema tessera sanitaria. Per quanto concerne la fatturazione elettronica, i dati saranno inviati al sito dell’agenzia delle entrate.</li>
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion expanded={this.state.expanded === 'panel2'} onChange={this.handleChange('panel2')}>
                                                    <AccordionSummary color='white' style={{ backgroundColor: '#171717', color: 'white' }} aria-controls="panel2d-content" id="panel2d-header">
                                                        <Typography color='white'>Modalità di gestione per avere nuovi pazienti</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails style={{ backgroundColor: '#171717', color: 'white' }}>
                                                        <Typography color='white'>
                                                            <li>Vai nel sito di PersonalDoc e registrati. Grazie alla pagina web a te dedicata, nuovi pazienti potranno contattarti e in autonomia fissare un appuntamento in funzione delle tue disponibilità che hai reso visibili.</li>
                                                            <li>I pazienti ti troveranno online e potranno fissare subito un appuntamento con te, in base alle disponibilità che tu hai reso visibili. L’appuntamento sarà confermato subito dopo che il sistema avrà registrato il pagamento in entrata. A quel punto invierà la fattura e i dati al sistema tessera sanitaria. Per la fatturazione elettronica il sistema invierà i dati al SDI all’Agenzia delle Entrate.</li>
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                        </div> 
                                    </div>
                                </section>

                                <section className="light" id='abbonamenti' ref={this.abbonamentiRef}>
                                    <div className="container d-flex flex-column align-items-center">
                                        <div className="row">
                                            <div className="col-md-12 my-5">
                                                <h1>{'abbonamenti'.toUpperCase()}</h1>
                                                <div style={{height:'3px', width:'90px', backgroundColor:'white'}} />
                                            </div>
                                        </div>
                                        <div className="row py-4">
                                            <div className="col-lg-12">
                                                <h4 style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>3 mesi: 60 euro + IVA</h4>
                                                <h4 style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>6 mesi: 107 euro + IVA</h4>
                                                <h4 style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>12 mesi: 186 euro + IVA</h4>
                                                <div className='loginButton my-5' onClick={() => window.location.href = '/#/main/psicologosignup'}>PROVALO SUBITO</div>
                                            </div>
                                        </div> 
                                    </div>
                                </section>

                                 <section className="dark" id='app-mobili' ref={this.appRef}>
                                    <div className="container d-flex flex-column align-items-center pb-5">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h1>{'app mobili'.toUpperCase()}</h1>
                                                <div style={{ height: '3px', width: '90px', backgroundColor: 'white' }} />
                                            </div>
                                        </div>
                                        <p style={{ textAlign: 'center' }} className="mt-2 my-3">Scarica le nostre app mobili per avere PersonalDoc sempre a portata di mano.</p>
                                        <div className="row py-4">
                                            <Stack spacing={this.state.isMobile ? 0 : 2} direction={this.state.isMobile ? 'column' : 'row'} style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                <a className='appButton py-2 px-5' href='https://apps.apple.com/us/app/personaldoc/id6469307461' style={{ textDecoration: 'none', color: '#202124' }} target='_blank'>
                                                    <img alt="" src={require('../../images/appstore.svg')} style={{ width: 20, height: 20 }} />
                                                    <p style={{ marginLeft: 20, height: 20, marginTop: 10 }}>App Store</p>
                                                </a>
                                                <a className='appButton py-2 px-5'  href='https://play.google.com/store/apps/details?id=it.zancsrl.personaldoc' style={{ textDecoration: 'none', color: '#202124' }} target='_blank'>
                                                    <img alt="" src={require('../../images/playstore.svg')} style={{ width: 20, height: 20 }} />
                                                    <p style={{ marginLeft: 20, height: 20, marginTop: 10 }}>Play Store</p>
                                                </a>
                                            </Stack>
                                        </div> 
                                    </div>
                                </section>

                                <section className="light" id='contatti'>
                                    <div className="container d-flex flex-column align-items-center">
                                        <div className="row">
                                            <div className="col-md-12 my-5">
                                                <h1>{'contatti'.toUpperCase()}</h1>
                                                <div style={{height:'3px', width:'90px', backgroundColor:'white'}} />
                                            </div>
                                        </div>
                                        <Stack direction={'row'} style={{ marginBottom: 40, marginTop: -15 }} spacing={2}>
                                            <img style={{width:45, height:"auto",cursor:'pointer', transition:'0.3s'}} src={mail} alt="" onClick={()=> window.open('mailto:info@personaldoc-app.com', '_blank') }/>
                                            <img style={{width:45, height:"auto",cursor:'pointer', transition:'0.3s'}} src={instagram} alt="" onClick={()=> window.open('https://www.instagram.com/personaldoc.app/', '_blank') }/>
                                            <img style={{width:45, height:"auto",cursor:'pointer', transition:'0.3s'}} src={facebook} alt="" onClick={()=> window.open('https://www.facebook.com/app.personaldoc', '_blank') }/>
                                            <img style={{width:45, height:"auto",cursor:'pointer', transition:'0.3s'}} src={linkedin} alt="" onClick={()=> window.open('https://www.linkedin.com/company/personal-doc/', '_blank') }/>
                                        </Stack> 
                                        <div className="row">
                                            <p onClick={() => {
                                                window.location.href = '/#/main/terms-and-conditions';
                                            }} style={{ color: 'white', fontWeight: 'bold', marginBottom: 20, cursor: 'pointer' }}>Termini e Condizioni</p>
                                            <p onClick={() => {
                                                window.location.href = '/#/main/privacy';
                                            }} style={{ color: 'white', fontWeight: 'bold', marginBottom: 20, marginLeft: 30, cursor: 'pointer' }}>Privacy Policy</p>
                                        </div>
                                        <p>&copy; <a target='_blank' rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }} href="https://www.zanc.it">ZANC Srl </a> {moment().format('yyyy')} - {t('diritti')}.</p>
                                    </div>
                                </section>
                            </>
                        ) : (
                            <>
                            <section className="dark" id='personalDoc'>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12 my-5">
                                            <h1>{'personalDoc'.toUpperCase()}</h1>
                                            <div style={{height:'3px', width:'90px', backgroundColor:'white'}} />
                                        </div>
                                    </div>
                                    <div className="row py-4">
                                        <div className="col-md-8 col-sm-6">
                                            <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Ti basterà un clic per metterti in contatto con il miglior specialista per te!</p>
                                            <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Scegli immediatamente il giorno e l’ora, paga, e il tuo appuntamento è confermato.</p>
                                            <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Scegli il tuo personal doc online o più vicino a te.</p>
                                            <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Con questo dispositivo potrai trovare l’esperto più adatto a te, prenotare e trovare la disponibilità di un appuntamento in poche ore, pagare in modo sicuro, chattare con il tuo specialista mentre condividi cartelle o esami.</p>
                                            <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Tutti i tuoi dati e le tue fatture saranno in un’unica app che ti invierà delle notifiche per ricordarti appuntamenti e pagamenti.</p>
                                            <p style={{textShadow: 'rgba(206, 206, 206, 0.8) -1px 1px 4px'}}>Cerca, trova e scegli il professionista migliore!</p>
                                            
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <div className='mind'/>
                                        </div>
                                    </div> 
                                </div>
                            </section>
                            <section className="light" id='contatti'>
                                    <div className="container d-flex flex-column align-items-center">
                                        <div className="row">
                                            <p onClick={() => {
                                                window.location.href = '/#/main/terms-and-conditions';
                                            }} style={{ color: 'white', fontWeight: 'bold', marginBottom: 20, cursor: 'pointer' }}>Termini e Condizioni</p>
                                            <p onClick={() => {
                                                window.location.href = '/#/main/privacy';
                                            }} style={{ color: 'white', fontWeight: 'bold', marginBottom: 20, marginLeft: 30, cursor: 'pointer' }}>Privacy Policy</p>
                                        </div>
                                    </div>
                                </section>
                            </>
                        )
                    }

                { this.state.chooseType && (
                    <TypeSelectorModale isMobile={this.state.isMobile} open={this.state.chooseType} onUser={(e) => this.setState({ typeChosen: e })} handleClose={() => this.setState({ chooseType: false })} />
                )}

            </div>
        );
    }
}