import React from "react";
import "./style.css";
import "./bootstrap.min.css";
import "./owl.carousel.css";
import "./owl.theme.css";
import "./owl.transitions.css";
import "./animate.css";
import "./preloader.css";
import NavbarMain from "../../components/NavbarMain";

export default class GoogleDisclosure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
    };
  }

  handleResize() {
    this.setState({ bannerHeight: window.innerHeight });
    if (document.getElementById("navbar")) {
      if (window.innerWidth < 992) {
        this.setState({ isMobile: true });
      } else {
        this.setState({ isMobile: false, isMenuOpen: false });
      }
    }
  }

  componentDidMount() {
    window.document.title = "PersonalDoc - Privacy Policy";
    window.addEventListener("resize", this.handleResize.bind(this));
    this.handleResize();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize.bind(this));
  }

  render() {
    return (
      <div
        id="main_scroll"
        className="mainBody"
        style={{ width: "100%", height: "100%" }}
      >
        <NavbarMain noButtons />
        <link
          rel="stylesheet"
          href="bootstrap-4.1.1-dist/css/bootstrap.min.css"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
          integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
          crossOrigin="anonymous"
        />

        <header id="home">
          <div
            className="banner"
            style={{ paddingTop: this.state.isMobile ? 100 : 0, height: 300 }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                height: 300,
                right: 0,
                backgroundColor: "black",
                opacity: 0.55,
              }}
            />
            <div
              className="container position-relative"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              
              <div
                className="row"
                style={{
                  zIndex: 1000,
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  widht: "100%",
                }}
              >
                <div
                  className="col-md-8 offset-md-2"
                  style={{
                    width: "100%",
                    paddingLeft: this.state.isMobile ? "7%" : 0,
                  }}
                >
                  <div className="caption">
                    <h1
                      style={{
                        paddingTop: 100,
                        paddingBottom: 80,
                        color: "white",
                        fontWeight: "bold",
                        fontFamily: `url(${require("../../fonts/Nexa.ttf")})`,
                        fontSize: this.state.isMobile ? 20 : 30,
                      }}
                    >
                      Google API's data usage
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <h3 style={{ padding: 40, textAlign: 'center', marginTop: 100 }}>PersonalDoc use and transfer to any other app of information received from Google APIs will adhere to the <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank">Google API Services User Data Policy</a>, including the Limited Use requirements.</h3>
        
      </div>
    );
  }
}
