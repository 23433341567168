import Constants from '../utils/consts';

export default async function requestStripeUrl(psicologo) {
    return new Promise(function (resolve, reject) {

        fetch(Constants.defaultProtocol + Constants.mainUrl + 'createStripeUser.php', {
            method: 'POST', 
            mode: 'cors', 
            body: JSON.stringify({
                'email': psicologo.mail, 
                'nome': psicologo.nome, 
                'cognome': psicologo.cognome, 
                'indirizzo': psicologo.indirizzo, 
                'citta': psicologo.citta, 
                'zip': psicologo.cap, 
                'provincia': psicologo.provincia, 
                'codice_fiscale': psicologo.codice_fiscale, 
                'partita_iva': psicologo.piva, 
                'url_pagina': psicologo.indirizzo_pagina
            }), 
            headers: { 'Content-Type': 'application/json' }
        })
        .then((res) => res.json())
        .then((data) => {
            resolve(data);
        })
    });
}
