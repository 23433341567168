import React from 'react';
import Cookies from 'universal-cookie';
import translateString from '../utils/Locales';

import '../pages/landingPage/style.css';
import './style.css';
import getPagine from '../services/getPagine';
import searchProfessionista from '../services/searchProfessionista';

let t = translateString;
const cookies = new Cookies();

export default class MainSearch extends React.Component {
    constructor(props) {
        super(props);

        this.count = 0;

        this.state = {
            search: '',
            psicologi: [],
            showing: false,
            isLoading: true, 
            filteredPsicologi: [],
        };
    }

    async componentDidMount() {
        let path = window.location.href.includes("main/home");
        this.setState({ isLoading: false, path });
    }

    async loadResults(event) {

        clearInterval(this.interval);
        this.count = 0;
        let searchRegex = event.target.value.toLowerCase();
        this.setState({ search: searchRegex });

        if (searchRegex.trim() === '') {
            document.getElementById('main-input').classList.remove('input-active');
            this.setState({ showing: false });
            return;
        }

        this.interval = setInterval(() => {
            this.count++;
            if (this.count === 1) {
                this.requestSearch(searchRegex);
                this.count = 0;
                clearInterval(this.interval);
            }
        }, 500);
    }

    async requestSearch(searchRegex) {
        var filtered = await searchProfessionista(searchRegex, this.props.type);

        if (filtered.length > 0) {
            document.getElementById('main-input').classList.add('input-active');
            this.setState({ showing: true, filteredPsicologi: filtered });
            return;
        } else {
            document.getElementById('main-input').classList.remove('input-active');
            this.setState({ showing: false, filteredPsicologi: filtered });
            return;
        }
    }

    render() {

        if (this.state.isLoading) return <div />;

        return(
            <div style={{ position: 'relative', zIndex: 10000 }}>
                <input id="main-input" className='homeInput' type={'text'} placeholder={t('ricerca')} value={this.state.search} onChange={(e) => this.loadResults(e)} />
                { this.state.showing && (
                    <div className={this.state.path ? 'search-view' : 'search-view-dashboard'} style={this.props.isMobile ? { maxHeight: 100 } : {}}>
                        { this.state.filteredPsicologi.map((p) => {

                            return(
                                <section key={Math.random()} className='search-section' style={this.props.isMobile ? { paddingBottom: 0, paddingTop: 0, paddingLeft: 20 } : {}} onClick={() => window.location.href = '/#/info/' + p.indirizzo_pagina}>
                                    <div className='psicologo-view'>
                                        <h3 style={this.props.isMobile ? { fontSize: 13 } : {}}>{p.titolo === '0' ? '' : p.titolo === '1' ? 'Dott. ' : 'Dott.ssa '}{p.nome} {p.cognome}</h3>
                                        <p style={{ textTransform: 'uppercase', marginTop: -10, fontSize: this.props.isMobile ? 10 : 14 }}>{p.categoria}</p>
                                    </div>
                                </section>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }
}