import React from 'react';
import SingleLoading from './SingleLoading';

export default class LoadingMessages extends React.Component {
    constructor(props) {
        super(props);

        this.arr = [{}, {}, {}, {}, {}, {}, {}, {},]

        this.state = {

        };
    }

    render() {

        return(
            <div className='loading-list'>
                { this.arr.map((_, ind) => {

                    return(
                        <SingleLoading key={ind} />
                    );
                }) }
            </div>
        );
    }
}