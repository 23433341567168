
import React from 'react';
import Cookies from 'universal-cookie';
import Stacked from '../components/Charts/Stacked';
import translateString from '../utils/Locales';

import '../index.css';
import Loading from '../components/Loading';
import getSedute from '../services/getSedute';
import moment from 'moment';
import PieChart from '../components/Charts/PieChart';
import { getServiceName } from '../utils/consts';
import { Card, Typography, Box } from '@mui/material';
import { Stack } from '@mui/material'
import getHomepageStats from '../services/getHomepageStats';

const cookies = new Cookies();
let t = translateString;

export default class DashboardAdmin extends React.Component {
    constructor(props) {
        super(props);

        this.data = [
            {
                dataSource: [ ], 
                xName: 'x',
                yName: 'y', 
                name: 'Fatturato', 
                type: 'StackingColumn', 
                background: 'blue' 
            }     
        ];

        this.pieData = [
            { x: 'Appuntamenti', y: 0, text: '0%' },
            { x: 'Eventi/Fatture', y: 0, text: '0%' }, 
        ];

        this.state = {
            isLoading: true, 
        };
    }

    async componentDidMount() {

        window.document.title = getServiceName() + ' - Dashboard';

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        this.sedute = await getSedute(username, token, "psicologo");   

        this.sedute.forEach((seduta, ind) => {
            var sed = seduta;
            sed.data = moment(sed.inizio, 'yyyy-MM-DDTHH:mm').toDate();
            this.sedute[ind] = sed;
        });

        this.sedute = this.sedute.sort((s1, s2) => s1.data.getTime() < s2.data.getTime());
        this.sedute = this.sedute.filter((s) => (s.data.getTime() >= moment().toDate().getTime() && moment(s.data).format('yyyy-MM-DD') === moment().format('yyyy-MM-DD')));
    
        this.stats = await getHomepageStats(username, token);

        this.data = [
            {
                dataSource: [
                    { x: 'Gen', y: parseFloat(this.stats.fatturato[0])},
                    { x: 'Feb', y: parseFloat(this.stats.fatturato[1]) },
                    { x: 'Mar', y: parseFloat(this.stats.fatturato[2]) },
                    { x: 'Apr', y: parseFloat(this.stats.fatturato[3]) },
                    { x: 'Mag', y: parseFloat(this.stats.fatturato[4]) },
                    { x: 'Giu', y: parseFloat(this.stats.fatturato[5]) },
                    { x: 'Lug', y: parseFloat(this.stats.fatturato[6]) },
                    { x: 'Ago', y: parseFloat(this.stats.fatturato[7]) },
                    { x: 'Set', y: parseFloat(this.stats.fatturato[8]) },
                    { x: 'Ott', y: parseFloat(this.stats.fatturato[9]) },
                    { x: 'Nov', y: parseFloat(this.stats.fatturato[10]) },
                    { x: 'Dic', y: parseFloat(this.stats.fatturato[11]) }
                ], 
                xName: 'x',
                yName: 'y', 
                name: 'Fatturato', 
                type: 'StackingColumn', 
                background: 'blue' 
            }     
        ];

        const total = this.stats.suddivisione[0] + this.stats.suddivisione[1];
        const percent1 = ((this.stats.suddivisione[0] / total) * 100).toFixed(2);
        const percent2 = ((this.stats.suddivisione[1] / total) * 100).toFixed(2);

        this.pieData = [
            { x: 'Appuntamenti', y: percent1, text: percent1 + ' %' },
            { x: 'Eventi/Fatture', y: percent2, text: percent2 + ' %' }, 
        ];
        
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) return <Loading />;
        return(
            <>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
                    <section className='dashview' style={{ marginRight: 10 }}>
                        <h5>I tuoi utenti</h5><br/>
                        <PieChart height={'200px'} data={this.pieData} nome={t('entrate')} />
                    </section>
                    <section className='dashview' style={{ marginRight: 10 }}>
                        <h5>I tuoi visitatori</h5><br/>
                        <PieChart height={'200px'} data={this.pieData} nome={t('entrate')} />
                    </section>
                </div>
                
            </>
        );
    }
}