import React from 'react';
import { BsGenderAmbiguous, BsPersonFill } from 'react-icons/bs';
import { MdSportsBasketball, MdOutlineWork, MdSchool, MdFastfood, MdGroup } from 'react-icons/md';
import { FaChild, FaBrain, FaBookMedical } from 'react-icons/fa';
import { Grid } from '@material-ui/core';

export default class GridServizi extends React.Component {
    constructor(props) {
        super(props);

        var servizi = [
            { id: 0, icon: <MdFastfood color="#7B90C4" />, servizio: 'Disturbi alimentari', selezionato: false }, 
            { id: 1, icon: <BsGenderAmbiguous color="#7B90C4" />, servizio: 'Sessuologia', selezionato: false },
            { id: 2, icon: <BsPersonFill color="#7B90C4" />, servizio: 'Analisi della persona', selezionato: false },
            { id: 3, icon: <MdSportsBasketball color="#7B90C4" />, servizio: 'Psicologia dello sport', selezionato: false },
            { id: 4, icon: <MdOutlineWork color="#7B90C4" />, servizio: 'Psicologia del lavoro', selezionato: false },
            { id: 5, icon: <FaChild color="#7B90C4" />, servizio: 'Psicologia pediatrica', selezionato: false },
            { id: 6, icon: <MdSchool color="#7B90C4" />, servizio: 'Psicologia dell\'educazione', selezionato: false },
            { id: 7, icon: <FaBrain color="#7B90C4" />, servizio: 'Neuro psicologia', selezionato: false },
            { id: 8, icon: <FaBookMedical color="#7B90C4" />, servizio: 'Psicologia clinica', selezionato: false },
            { id: 9, icon: <MdGroup color="#7B90C4" />, servizio: 'Psicologia sociale', selezionato: false },
        ];

        if (props.servizi) {
            
            props.servizi.forEach((s) => {
                servizi[parseInt(s.id)].selezionato = true;
            });
        }

        this.state = {
            servizi,
        };
    }

    getServizi() {

        var servizi = [];

        var selezionati = this.state.servizi;
        selezionati = selezionati.filter((s) => s.selezionato);

        selezionati.forEach((s) => {
            servizi.push({ id: s.id, servizio: s.servizio });
        });

        if (servizi.length === 0) return null;

        return servizi;
    }

    setServizio(id, selected) {

        var count = 0;
        this.state.servizi.forEach((s) => {
            if (s.selezionato) count++;
        });

        if (selected && count === 5) return;

        let s = this.state.servizi;
        s[id].selezionato = selected;
        this.setState({ servizi: s });
    }

    render() {

        return(
            <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
                { this.state.servizi.map((s) => {

                    return(
                        <Grid key={s.id} item xs={6} sm={4}>
                            <section className='servizio' style={{ backgroundColor: s.selezionato ? '#424242' : 'lightgrey', border: '0px' }} onClick={() => this.setServizio(s.id, !s.selezionato)}>
                                {s.icon}
                                <p style={{ marginLeft: 10, color: s.selezionato ? 'white' : '#5C5C5C', marginTop: 15, wordBreak: 'break-all' }}>{s.servizio}</p>
                            </section>
                        </Grid>
                    );
                })}
            </Grid>
        );
    }
}