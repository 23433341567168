import React from 'react';
import Cookies from 'universal-cookie';
import Button from '../components/Button';
import CloseImg from '../images/close.png';
import { MeteorRainLoading } from 'react-loadingg';
import translateString from '../utils/Locales';
import SelectInput from './SelectInput';

import '../index.css';
import sendMessaggio from '../services/sendMessaggio';
import getProfessionistiForPaziente from '../services/getProfessionistiForPaziente';

let t = translateString;
const cookies = new Cookies();

export default class NuovoMessaggioPazienteWidget extends React.Component {
    constructor(props) {
        super(props);


        let margin = ((window.innerHeight * 5) / 100) + window.scrollY;

        this.state = {
            isLoading: true, 
            margin: margin,
            pazienti: [{ value: '', label: t('sel_paziente') }],
            paziente: { value: '', label: t('sel_paziente') }, 
            error: false, 
            errorMessage: '',
            testo: '', 
        };
    }

    handleScroll() {
        this.setState({ margin: 'calc(5vh + ' + window.scrollY + 'px)' });
    }

    async componentDidMount() {

        var professionisti = [{ value: '', label: 'Seleziona professionista...' }];

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        
        let res = await getProfessionistiForPaziente(username, token);
        if (res.length === 0) {
            this.props.onClose();
            alert('Nessun professionista trovato legato al tuo account.');
            return;
        }

        let professionista = null;
        if (this.props.prefilledProfessionista) {
            professionista = res.find(p => p.id_utente === this.props.prefilledProfessionista);
            if (professionista) professionista = { value: professionista.id_utente, label: professionista.cognome + ' ' + professionista.nome };
        }

        res.forEach((c) => {
            professionisti.push({ value: c.id_utente, label: c.cognome + ' ' + c.nome });
        });
        
        window.addEventListener('scroll', this.handleScroll.bind(this));

        this.setState({ isLoading: false, professionisti, professionista });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    async invia() {
        
        if (!this.state.professionista.value) {
            this.setState({ error: true, errorMessage: 'Seleziona un professionista' });
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 3000);
            return;
        }

        if (!this.state.testo.trim()) {
            this.setState({ error: true, errorMessage: t('inserisci_testo') });
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 3000);
            return;
        }

        this.setState({ isLoading: true });

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        let res = await sendMessaggio(username, token, this.state.professionista.value, 'paziente', this.state.testo);
        if (!res || res.result === 'KO') {
            this.setState({ isLoading: false, error: true, errorMessage: t('errore_imprevisto') });
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 3000);
            return;
        }
        
        this.props.onClose(0);

    }

    render() {

        if (this.state.isLoading) return(
            <div style={backView} onClick={() => this.props.onClose && this.props.onClose()}>
                <div style={{ ...mainView, marginTop: this.state.margin }} onClick={(e) => e.stopPropagation()}>
                    <MeteorRainLoading />
                </div>
            </div>
        );

        return(
            <div style={backView} onClick={() => this.props.onClose && this.props.onClose()}>
                <div style={{ ...mainView, marginTop: this.state.margin }} onClick={(e) => e.stopPropagation()}>
                    <img src={ CloseImg } style={{ position: 'absolute', top: 20, right: 20, width: 20, height: 20, cursor: 'pointer' }} onClick={() => this.props.onClose && this.props.onClose()} />
                    <h3>{t('new_messaggio')}</h3>
                    { this.state.error && (
                        <div className='error-div'>
                            {this.state.errorMessage}
                        </div>
                    )}
                    <label style={{ marginTop: 10, marginBottom: -25, color: 'lightgrey' }}>Seleziona il professionista a cui inviare il messaggio. Se non lo vedi all'interno di questa lista significa che non è associato ancora al tuo profilo PersonalDoc. Inviagli una mail invece che un messaggio diretto.</label>
                    <SelectInput
                        value={this.state.professionista}
                        small={true} 
                        style={{ width: '100%', marginTop: 30 }}
                        hidable={false}
                        isMulti={false}
                        options={this.state.professionisti} 
                        onChange={(option) => 
                            this.setState({ professionista: option })} 
                    />

                    <h5 style={{ marginTop: 30 }}>{t('mess_s')}</h5>
                    <textarea style={{ resize: 'none', height: 100, width: '100%', border: '1px solid lightgrey', borderRadius: 10, marginTop: 10, padding: 10, marginBottom: 10 }} value={this.state.testo} placeholder={t('nuovo_msg')} onChange={(e) => this.setState({ testo: e.target.value })} />
                    <Button button={"INVIA"} style={{ marginTop: 20, boxShadow: 'none', flex: 1 }} onClick={() => this.invia()} />
                </div>
            </div>
        );
    }
}

const backView = {
    position: 'absolute', 
    top: 0, 
    left: 0,
    minWidth: '100vw', 
    minHeight: '100%', 
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#00000066', 
    textAlign: 'center',
    zIndex: 999999999, 
}

const mainView = {
    position: 'relative',
    width: 400,
    maxHeight: '90vh', 
    minHeight: 300,
    marginLeft: 'calc((100vw - 400px)/2)',
    backgroundColor: 'white', 
    borderRadius: 10, 
    overflow: 'scroll',
    padding: 30,
    zIndex: 1000000000,
    textAlign: 'left',
}