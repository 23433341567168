import React from 'react';
import Cookies from 'universal-cookie';
import Button from '../components/Button';
import Loading from '../components/Loading';
import '../index.css';
import getPaziente from '../services/getPaziente';
import translateString from '../utils/Locales';
import { GridComponent, ColumnsDirective, ColumnDirective, Resize, Sort, Search, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';
import { L10n } from '@syncfusion/ej2-base';
import Constants, { getServiceName } from '../utils/consts';
import moment from 'moment';

import Nothing from '../images/nothing.png';

import { HiOutlineIdentification } from 'react-icons/hi';
import { BsCalendarDate } from 'react-icons/bs';
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { BsGenderAmbiguous, BsGenderFemale, BsGenderMale } from 'react-icons/bs';
import { GoLocation } from 'react-icons/go';
import regioni from '../utils/regioni_province.json';
import updatePaziente from '../services/updatePaziente';
import getSedutePaziente from '../services/getSedutePaziente';
import { seduteTable } from '../utils/tables';
import getIdPersonale from '../services/getIdPersonale';
import Switch from 'react-switch';
import { Checkbox, Stack } from '@mui/material';

L10n.load({
    'it_IT': {
        'grid': {
            'Item': 'Seduta', 
            'Items': 'sedute', 
            'CancelButton': 'Annulla',
            'Excelexport': 'Esporta Excel',
            'Csvexport': 'Esporta in CSV', 
            'Export': 'Esporta',
            'SortAscending': 'Ordine crescente', 
            'SortDescending': 'Ordine decrescente'
        }, 
        'pager': {
            'currentPageInfo': 'Pagina {0} di {1}', 
            'totalItemsInfo': '({0} sedute)',
        }
    }
})

let t = translateString;
const cookies = new Cookies();

export default class EditPaziente extends React.Component {
    constructor(props) {
        super(props);

        this.dataNascita = React.createRef();
        this.saveRef = React.createRef();

        this.state = {
            isLoading: true, 
            paziente: undefined,
            iniziale: undefined,
            error: false, 
            success: false,
        };
    }

    async componentDidMount() {

        window.document.title = getServiceName() + ' - Modifica paziente';

        let currentPaziente = cookies.get('currentPaziente', { path: '/' });
        if (!currentPaziente) {
            window.location.href = '/#/app/pazienti';
            return;
        }

        let token = cookies.get('token', { path: '/' });
        let username = cookies.get('username', { path: '/' });

        let paziente = await getPaziente(username, token, currentPaziente);
        let iniziale = await getPaziente(username, token, currentPaziente);
        let myId = await getIdPersonale(username, token);
        var res = await getSedutePaziente(username, token, currentPaziente);

        res.forEach((s) => {
            s.data = moment(s.inizio, 'yyyy-MM-DDTHH:mm').format('DD/MM/yyyy');
            s.orario = moment(s.inizio, 'yyyy-MM-DDTHH:mm').format('HH:mm') + ' - ' + moment(s.fine, 'yyyy-MM-DDTHH:mm').format('HH:mm');
            s.tipo = s.online === '0' ? 'IN STUDIO' : 'ONLINE'; 
            s.fatturata = s.fatturata === '0' ? '✗' : '✓'
        });

        let sedute = await res.sort((s1, s2) => moment(s1.inizio, 'yyyy-MM-DDTHH:mm').isBefore(moment(s2.inizio, 'yyyy-MM-DDTHH:mm')));

        paziente.invio_ts = paziente.invio_ts === '1';

        this.setState({ isLoading: false, paziente, iniziale, sedute, myId });
    }

    async save() {
        let p = this.state.paziente;

        if (!p.codice_fiscale) {
            this.setState({ error: true, errorMessage: t('verifica_dati') });
            setTimeout(() => {
                this.saveRef.current.stopAnimating();
            }, 200);
            return;
        }

        if (this.state.mailError || this.state.provinciaError) {
            this.setState({ error: true, errorMessage: t('verifica_dati') });
            setTimeout(() => {
                this.saveRef.current.stopAnimating();
            }, 200);
            setTimeout(() => {
                this.setState({ error: false });
            }, 3000);
            return;
        }

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        let res = await updatePaziente(username, token, p);
        if (!res || res.result === 'KO') {
            this.setState({ error: true, errorMessage: t('errore_imprevisto') });
            setTimeout(() => {
                this.saveRef.current.stopAnimating();
            }, 200);
            setTimeout(() => {
                this.setState({ error: false });
            }, 3000);
            return;
        }

        this.setState({ success: true, error: false });
        setTimeout(() => {
            this.saveRef.current.stopAnimating();
        }, 500);
    }

    handleDataDiNascita(data) {

        let p = this.state.paziente;
        p.data_nascita = data;
        this.setState({ paziente: p });
    }

    handleMail(mail) {

        let p = this.state.paziente;
        p.mail = mail;

        if (/^[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(mail)) {
            this.setState({ mailError: false, paziente: p });
            return;
        }

        this.setState({ mailError: true, paziente: p });
    }

    handleAnticipo() {
        let p = this.state.paziente;
        if (p.pagamento_anticipato === '1') p.pagamento_anticipato = '0';
        else p.pagamento_anticipato = '1';
        this.setState({ paziente: p });
    }

    handleProvincia(provincia) {

        let p = this.state.paziente;
        p.provincia = provincia;
        
        var found = false;
        regioni['regioni'].forEach((reg) => {
            regioni[reg].forEach((prov) => {
                if (prov.sigla === provincia) {
                    found = true;
                }
            });
        });

        if (found) {
            this.setState({ paziente: p, provinciaError: false });
            return;
        }

        this.setState({ paziente: p, provinciaError: true });
    }

    render() {

        if (this.state.isLoading) return <Loading />;

        const options = { allowDeleting: false };
        const pagingOptions = { pageSize: 25 };

        let regex = new RegExp(`${this.state.myId};`, 'g');
        var isReadOnly = this.state.paziente.id_psicologo.replace(regex, '') !== '' || this.state.paziente.utente === '1';

        return(
            <>
                <div style={{ marginBottom: 100 }} className="pageCliente">
                    <div className='top-with-btn'>
                        <div style={{ marginRight: 20, marginTop: 10 }}>
                            <h3 style={{ color: 'white' }}>{t('scheda_paziente')}</h3>
                            <p style={{ color: 'white' }}>{t('info_scheda_paziente')}</p>
                        </div>
                        <Button ref={this.saveRef} button={t('salva')} animated={true} onClick={() => this.save()} />
                        <section style={{ height: 10, width: 10 }}></section>
                        <Button button={t('anamnesi')} animated={true} onClick={() => window.location.href = "/#/app/paziente/anamnesi"} />
                    </div>
                    { this.state.error && (
                        <div className='error-div'>
                            {this.state.errorMessage}
                        </div>
                    )}
                    { this.state.success && (
                        <div className='success-div'>
                            {t('salvataggio_fatto')}
                        </div>
                    )}
                    <div style={{ marginTop: 50, padding: 30 }} className='dashview'>
                        <div style={{ marginTop: 0, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <h3 style={{ color: 'white' }}>{this.state.paziente.cognome} {this.state.paziente.nome}</h3>
                        { this.state.paziente.genere === 'M' ? <BsGenderMale color='white' style={{ width: 20, height: 20, marginLeft: 10, marginTop: -5 }} /> : this.state.paziente.genere === 'F' ? <BsGenderFemale color='white' style={{ width: 20, height: 20, marginLeft: 10, marginTop: -5 }} /> : <BsGenderAmbiguous color='white' style={{ width: 20, height: 20, marginLeft: 10, marginTop: -5 }} /> }
                        </div>
                        <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <HiOutlineIdentification color='white' />
                            <input readOnly={isReadOnly && this.state.iniziale.codice_fiscale} style={{ marginLeft: 10, border: 0, borderBottom: '1px solid lightgrey', height: '20px', color: '#5C5C5C', paddingLeft: 10, paddingRight: 10, minWidth: 200 }} type={'text'} placeholder={t('codice_fiscale')} value={this.state.paziente.codice_fiscale} onChange={(e) => this.setState({ paziente: { ...this.state.paziente, codice_fiscale: e.target.value.toUpperCase() } })} />
                        </div>
                        <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <BsCalendarDate color='white' />
                            <input readOnly={isReadOnly && this.state.iniziale.data_nascita} ref={this.dataNascita} style={{ marginLeft: 10, border: 0, borderBottom: '1px solid lightgrey', height: '20px', color: '#5C5C5C', paddingLeft: 10, paddingRight: 10, minWidth: 200 }} type={this.state.paziente.data_nascita ? 'date' : 'text'} placeholder={t('data_nascita')} onFocus={() => this.state.paziente.data_nascita ? { } : this.dataNascita.current.type = 'date' } value={this.state.paziente.data_nascita} onChange={(e) => this.handleDataDiNascita(e.target.value)} />
                        </div>
                        <label style={{ marginTop: 20, marginLeft: 5 }}>
                            <span style={{ color: 'white' }}>Il paziente richiede l'invio al Sistema Tessera Sanitaria</span>
                            <Switch uncheckedIcon={false} checkedIcon={false} className="switch" onColor='white' width={40} height={20} onChange={(checked) => this.setState({ paziente: { ...this.state.paziente, invio_ts: checked} })} checked={this.state.paziente.invio_ts} />
                        </label>
                        <Stack direction='row' style={{ marginLeft: -10, marginTop: -10, marginBottom: 0 }}>
                            <Checkbox style={{ color: 'white' }} key={Math.random()} checked={this.state.paziente.pagamento_anticipato === '1'} onChange={() => this.handleAnticipo()} />
                            <p style={{ marginTop: 18, color: 'white' }}>Richiedi il pagamento anticipato degli appuntamenti (fino a 3gg prima dell'inizio)</p>
                        </Stack>
                    </div>
                    <div style={{ marginTop: 10, padding: 30 }} className='dashview'>
                        <h5 style={{ color: 'white' }}>{t('contatti_pz')}</h5>
                        <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <AiOutlineMail color='white' />
                            <input readOnly={isReadOnly && this.state.iniziale.mail} 
                            style={{ marginLeft: 10, border: 0, borderBottom: this.state.mailError ? '1px solid red' : '1px solid lightgrey', height: '20px', color: 'grey', paddingLeft: 10, paddingRight: 10, minWidth: 200 }} type={'email'} placeholder={t('email')} value={this.state.paziente.mail} onChange={(e) => this.handleMail(e.target.value)} />
                        </div>
                        <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <AiOutlinePhone color='white' />
                            <input readOnly={isReadOnly && this.state.iniziale.telefono} style={{ marginLeft: 10, border: 0, borderBottom: '1px solid lightgrey', height: '20px', color: '#5C5C5C', paddingLeft: 10, paddingRight: 10, minWidth: 200 }} type={'text'} placeholder={t('telefono')} value={this.state.paziente.telefono} onChange={(e) => this.setState({ paziente: { ...this.state.paziente, telefono: e.target.value } })} />
                        </div>
                        <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <GoLocation color='white' />
                            <section style={{ flexDirection: 'column' }}>
                                <input readOnly={isReadOnly && this.state.iniziale.indirizzo} style={{ marginLeft: 10, border: 0, borderBottom: '1px solid lightgrey', height: '20px', color: '#5C5C5C', paddingLeft: 10, paddingRight: 10, minWidth: 200 }} type={'text'} placeholder={t('indirizzo')} value={this.state.paziente.indirizzo} onChange={(e) => this.setState({ paziente: { ...this.state.paziente, indirizzo: e.target.value } })} /><br/>
                                <input readOnly={isReadOnly && this.state.iniziale.citta} style={{ marginLeft: 10, border: 0, borderBottom: '1px solid lightgrey', height: '20px', color: 'grey', paddingLeft: 10, paddingRight: 10, minWidth: 200, marginTop: 5 }} type={'text'} placeholder={t('citta')} value={this.state.paziente.citta} onChange={(e) => this.setState({ paziente: { ...this.state.paziente, citta: e.target.value } })} /><br/>
                                <input readOnly={isReadOnly  && this.state.iniziale.cap} style={{ marginLeft: 10, border: 0, borderBottom: '1px solid lightgrey', height: '20px', color: 'grey', paddingLeft: 10, paddingRight: 10, minWidth: 200, marginTop: 5 }} type={'text'} placeholder={'CAP'} value={this.state.paziente.cap} onChange={(e) => this.setState({ paziente: { ...this.state.paziente, cap: e.target.value } })} /><br/>
                                <input readOnly={isReadOnly && this.state.iniziale.provincia} style={{ marginLeft: 10, border: 0, borderBottom: this.state.provinciaError ? '1px solid red' : '1px solid lightgrey', height: '20px', color: 'grey', paddingLeft: 10, paddingRight: 10, minWidth: 200, marginTop: 5 }} type={'text'} placeholder={t('provincia_s')} maxLength={2} value={this.state.paziente.provincia} onChange={(e) => this.handleProvincia(e.target.value)} /><br/>
                            </section>
                        </div>
                    </div>
                    <div style={{ marginTop: 10, padding: 30 }} className='dashview'>
                        <h5 style={{ color: 'white' }}>{t('sedute')}</h5>
                        { (!this.state.sedute || this.state.sedute.length === 0) && (
                            <section style={{ alignItems: 'center', justifyContent: 'center', width: '100%', padding: 50, textAlign: 'center' }}>
                                <img src={ Nothing } style={{ width: 150, height: 150, opacity: 0.6, marginLeft: 'calc((100% - 150px)/2)' }} />
                                <p style={{ textAlign: 'center', color: 'white', marginTop: 10, fontSize: 20 }}>{t('no_sedute_s')}</p>
                            </section>
                        )}
                        { this.state.sedute && this.state.sedute.length > 0 && (
                            <>
                                <p style={{ color: 'white', marginTop: -5, marginBottom: 20 }}>{t('multi_info')}</p>
                                <GridComponent
                                    id="sedute_table"
                                    dataSource={this.state.sedute}
                                    locale={'it_IT'}
                                    allowPaging
                                    allowSorting
                                    allowExcelExport
                                    allowPdfExport
                                    allowMultiSorting
                                    pageSettings={pagingOptions}
                                    recordClick={(e) => { }}
                                    contextMenuItems={Constants.basicMenuItems}
                                    editSettings={options}
                                    sortSettings={{ field: 'data', direction: 'Ascending'}}
                                >
                                    <ColumnsDirective>
                                        {seduteTable.map((item, index) => <ColumnDirective key={index} {...item} />)}
                                    </ColumnsDirective>
                                    <Inject services={[Resize, Search, Sort, ContextMenu, Filter, Page, Edit, ExcelExport, PdfExport]} />
                                </GridComponent>
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    }
}