
import it from './it_strings.json';

 export default function translateString(id) {

    if (id) {
        return JSON.stringify(it[id]).replace(/"/g, '');
    } else {
        return '';
    }
}
