import Constants from '../utils/consts';

export default async function addPaziente(username, token, paziente) {
    return new Promise(function (resolve, reject) {

        fetch(Constants.defaultProtocol + Constants.mainUrl + 'addPaziente.php', {
            method: 'POST', 
            mode: 'cors', 
            body: JSON.stringify({
                'nome': paziente.nome,
                'cognome': paziente.cognome,
                'genere': paziente.genere,
                'data_nascita': paziente.data, 
                'codice_fiscale': paziente.fiscale,
                'indirizzo': paziente.indirizzo, 
                'citta': paziente.citta, 
                'cap': paziente.cap, 
                'stato': paziente.stato, 
                'provincia': paziente.provincia, 
                'telefono': paziente.telefono, 
                'mail': paziente.mail, 
                'invio_ts': paziente.invio_ts, 
                'pagamento_anticipato': paziente.pagamentoAnticipato, 
            }), 
            headers: { 
                'Authorization': 'Bearer ' + username + '.' + token, 
                'Content-Type': 'application/json'
            }
        })
        .then((res) => res.json())
        .then((data) => {
            resolve(data);
        })
        
    });
}
