import moment from 'moment';
import React from 'react';
import Cookies from 'universal-cookie';
import Button from '../components/Button';
import CustomDatePicker from '../components/DatePicker';
import { MeteorRainLoading } from 'react-loadingg';
import translateString from '../utils/Locales';
import SelectInput from './SelectInput';
import getPazienti from '../services/getPazienti';
import addSeduta from '../services/addSeduta';
import Switch from 'react-switch';
import CloseImg from '../images/close.png';

import '../index.css';
import organizzaOnlineMeeting from '../services/organizzaOnlineMeeting';
import MultiSelect from './MultiSelect';
import getPacchettiSedute from '../services/getPacchettiSedute';
import { Checkbox, Stack } from '@mui/material';
import isGoogleLogged from '../services/isGoogleLogged';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleLoginModale from './GoogleLoginModale';

let t = translateString;
const cookies = new Cookies();

export default class AddSedutaWidget extends React.Component {
    constructor(props) {
        super(props);

        this.mainRef = React.createRef();
        let margin = ((window.innerHeight * 5) / 100) + window.scrollY;

        let difference = moment(props.target.end).diff(moment(props.target.start), 'minutes');

        this.state = {
            isCreating: false,
            isLoading: true, 
            margin: margin,
            pazienti: [{ value: '', label: t('sel_paziente') }],
            paziente: { value: undefined, label: t('sel_paziente') }, 
            startDate: props.target.start, 
            endDate: moment(props.target.end).add(difference, 'minutes').toDate(),
            error: false, 
            errorMessage: '',
            note: '', 
            isOnline: false,
            zoomId: null, 
            startLink: null, 
            joinLink: null, 
            pagamentoAnticipato: true, 
            showPagamentoAnticipato: true, 
        };
    }

    handleScroll() {
        this.setState({ margin: 'calc(5vh + ' + window.scrollY + 'px)' });
    }

    async componentDidMount() {

        let pazienti = [{ value: '', label: t('sel_paziente') }];

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        let res = await getPazienti(username, token);
        if (!res || res.result === 'KO') {
            this.props.onClose();
            return;
        }

        res.forEach((p) => {
            pazienti.push({ value: p.id, label: p.cognome + ' ' + p.nome });
        });
        
        window.addEventListener('scroll', this.handleScroll.bind(this));

        const pacchetti = await getPacchettiSedute(username, token);
        const finalPacchetti = [];
        const completePacchetti = [];

        if (pacchetti && pacchetti.pacchetti) {
            let pack = JSON.parse(pacchetti.pacchetti);
            pack.forEach(p => {
                completePacchetti.push({ tipo: p.tipo, nome: p.nome, costo: p.costo });
                finalPacchetti.push(p.nome ? p.nome + ` (${p.costo} €)` : p.tipo.charAt(0).toUpperCase() + p.tipo.slice(1) + ` (${p.costo} €)`);
            });
        }

        this.setState({
            isLoading: false,
            pazienti, 
            allPazienti: res, 
            completePacchetti,
            pacchetti: finalPacchetti,
        });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate() {
        if (this.state.error) {
            if (this.mainRef && this.mainRef.current) this.mainRef.current.scrollTo(0,0);
        }
    }

    async checkOnline() {

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        let check = await isGoogleLogged(username, token);
        if (check.result === 'KO') {
            this.setState({ googleLogin: true });
            return;
        }

        this.setState({ isOnline: true });
    }

    async crea() {

        if (!this.state.paziente.value) {
            this.setState({ error: true, errorMessage: t('no_pz')});
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 3000);
            return;
        }

        if (this.state.startDate.getTime() >= this.state.endDate.getTime()) {
            this.setState({ error: true, errorMessage: t('controlla_date')});
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 3000);
            return;
        }

        if (!this.state.tipo) {
            this.setState({ error: true, errorMessage: 'Selezionare un tipo di appuntamento valido'});
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 3000);
            return;
        }
        
        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        this.setState({ isCreating: true })
        if (this.state.isOnline) {

            let meeting = {
                titolo: 'Appuntamento PersonalDoc', 
                data: moment(this.state.startDate).format('yyyy-MM-DDTHH:mm:ssZ'), 
                durata: moment(this.state.endDate).diff(moment(this.state.startDate), 'minutes'), 
                oggetto: '',
            }
            
            await organizzaOnlineMeeting(username, token, meeting).then((res) => {
                this.caricaMeeting(username, token, res.id, res.start_url, res.join_url);
            })
        } else {
            await this.caricaMeeting(username, token, 0, '', '');
        }
    }

    async caricaMeeting(username, token, zoomId, startLink, joinLink) {

        let tipo = "";
        if (this.state.tipo) {
            let type = this.state.tipo.split(' (');
            type.splice(tipo.length - 1, 1);
            tipo = type.join(" (").toLowerCase();
        }

        let seduta = {
            start: moment(this.state.startDate).format('yyyy-MM-DDTHH:mm'), 
            end: moment(this.state.endDate).format('yyyy-MM-DDTHH:mm'), 
            id_paziente: this.state.paziente.value, 
            note: this.state.note, 
            importo: this.state.importo ? this.state.importo : "", 
            tipo, 
            durata: moment(this.state.endDate).diff(moment(this.state.startDate), 'minutes'), 
            isOnline: this.state.isOnline ? 1 : 0,
            zoom_id: String(zoomId), 
            start_link: startLink, 
            join_link: joinLink, 
            pagamentoAnticipato: this.state.pagamentoAnticipato ? 1 : 0, 
        }

        let res = await addSeduta(username, token, seduta);
        if (!res) {
            this.setState({ isCreating: false, error: true, errorMessage: t('errore_imprevisto')});
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 3000);
            return;
        }

        if (res.result === 'KO') {
            this.setState({ isCreating: false, error: true, errorMessage: res.description});
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 3000);
            return;
        }

        this.setState({ isCreating: false });

        this.props.onClose(0);
    }

    handleTipo(tipo) {

        if (!tipo) return;

        const pacchetto = this.state.completePacchetti.find(p => {
            if (p.nome) return p.nome + ` (${p.costo} €)` === tipo;
            return p.tipo.charAt(0).toUpperCase() + p.tipo.slice(1) + ` (${p.costo} €)` === tipo
        });
        if (!pacchetto) return;
        this.setState({ importo: parseFloat(pacchetto.costo)});
    }

    render() {

        if (this.state.isLoading) return(
            <div style={backView} onClick={() => this.props.onClose && this.props.onClose()}>
                <div style={{ ...mainView, marginTop: this.state.margin }} onClick={(e) => e.stopPropagation()}>
                    <MeteorRainLoading />
                </div>
            </div>
        );

        return(
            <div style={backView} onClick={() => this.props.onClose && this.props.onClose()}>
                <div style={{ ...mainView, marginTop: this.state.margin }} ref={this.mainRef} onClick={(e) => e.stopPropagation()}>
                    <img src={ CloseImg } style={{ position: 'absolute', top: 20, right: 20, width: 20, height: 20, cursor: 'pointer' }} onClick={() => this.props.onClose && this.props.onClose()} />
                    <h3>Nuovo appuntamento</h3>
                    { this.state.error && (
                        <div className='error-div'>
                            {this.state.errorMessage}
                        </div>
                    )}
                    <div style={{ marginTop: 20 }} />
                    <MultiSelect fullWidth single label={'Tipo *'} names={this.state.pacchetti} onChangeListener={(tipi) => {
                        this.setState({ tipo: tipi ? tipi[0] : null });
                        this.handleTipo(tipi ? tipi[0] : null);
                    }} sel={this.state.tipo ? [this.state.tipo] : null} />
                    <SelectInput
                        value={this.state.paziente}
                        small={true} 
                        style={{ width: '100%', marginTop: 30 }}
                        hidable={false}
                        isMulti={false}
                        options={this.state.pazienti} 
                        onChange={(option) => {
                            this.setState({ paziente: option });
                            let selected = this.state.allPazienti.find(p => p.id === option.value);
                            if (!selected) return;

                            this.setState({ pagamentoAnticipato: (selected.utente === '1' && selected.pagamento_anticipato === '1'), showPagamentoAnticipato: selected.utente === '1' });
                        }} 
                    />
                    <label style={{ marginTop: 10, marginLeft: 5 }}>
                        <span>{t('lez_online')} </span>
                        <Switch uncheckedIcon={false} checkedIcon={false} className="switch" onColor='#7B90C4' width={40} height={20} onChange={(checked) => !checked ? this.setState({ isOnline: false }) : this.checkOnline()} checked={this.state.isOnline} />
                    </label>
                    <h5 style={{ marginTop: 30 }}>{t('date_s')}</h5>
                    <CustomDatePicker 
                        date={this.state.startDate}
                        onChange={(date) => this.setState({ startDate: date })}
                        title="Inizio"
                    />
                    <br/>
                    <CustomDatePicker 
                        date={this.state.endDate}
                        onChange={(date) => this.setState({ endDate: date })}
                        title="Fine"
                    />

                    <h5 style={{ marginTop: 30 }}>{t('note_s')}</h5>
                    <textarea style={{ resize: 'none', height: 100, width: '100%', border: '1px solid lightgrey', borderRadius: 10, marginTop: 10, padding: 10, marginBottom: 10 }} value={this.state.note} placeholder={t('note')} onChange={(e) => this.setState({ note: e.target.value })} />
                    { this.state.showPagamentoAnticipato && (
                        <Stack direction='row' style={{ marginLeft: -10, marginTop: -10, marginBottom: 0 }}>
                            <Checkbox key={Math.random()} checked={this.state.pagamentoAnticipato} onChange={() => this.setState({ pagamentoAnticipato: !this.state.pagamentoAnticipato })} />
                            <p style={{ marginTop: 18 }}>Pagamento anticipato</p>
                        </Stack>
                    )}
                    {!this.state.isCreating && (<Button button={"CREA"} style={{ marginTop: 20, boxShadow: '0px 0px 0px transparent' }} onClick={() => this.crea()} />)}
                    {this.state.isCreating && (
                        <p style={{ marginTop: 25 }}>Creo appuntamento...</p>
                    )}
                </div>

                { this.state.googleLogin && (
                    <GoogleOAuthProvider clientId="574939206422-ouvhde4ttktrcohr5jbaiqelrffc54q0.apps.googleusercontent.com">
                        <GoogleLoginModale open={this.state.googleLogin} handleClose={(res) => {
                            this.setState({ googleLogin: false });
                            if (res === 1)
                                this.checkOnline();
                            else 
                                this.setState({ googleLogin: false });
                        }} />
                    </GoogleOAuthProvider>
                )}

            </div>
        );
    }
}

const backView = {
    position: 'absolute', 
    top: 0, 
    left: 0,
    minWidth: '100vw', 
    minHeight: '100%', 
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#00000066', 
    textAlign: 'center',
    zIndex: 10, 
}

const mainView = {
    position: 'relative',
    width: 400,
    maxHeight: '90vh', 
    marginLeft: 'calc((100vw - 400px)/2)',
    backgroundColor: 'white', 
    borderRadius: 10, 
    overflow: 'scroll',
    padding: 30,
    textAlign: 'left',
}