import Constants from '../utils/consts';

export default async function addSeduta(username, token, seduta) {
    return new Promise(function (resolve, reject) {

        fetch(Constants.defaultProtocol + Constants.mainUrl + 'addSeduta.php', {
            method: 'POST', 
            mode: 'cors', 
            body: JSON.stringify({
                'inizio': seduta.start, 
                'fine': seduta.end, 
                'id_paziente': seduta.id_paziente, 
                'note': seduta.note, 
                'importo_seduta': seduta.importo, 
                'tipo_seduta': seduta.tipo, 
                'durata': seduta.durata, 
                'start_link': seduta.start_link, 
                'join_link': seduta.join_link, 
                'zoom_id': seduta.zoom_id, 
                'online': seduta.isOnline, 
                'paga_prima': seduta.pagamentoAnticipato, 
            }), 
            headers: { 
                'Authorization': 'Bearer ' + username + '.' + token, 
                'Content-Type': 'application/json'
            }
        })
        .then((res) => res.json())
        .then((data) => {
            resolve(data);
        })
        
    });
}
