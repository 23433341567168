import moment from 'moment';
import React from 'react';
import { RiDownloadCloudFill } from 'react-icons/ri';
import { triggerBase64Download } from 'common-base64-downloader-react';
import getAllegato from '../services/getAllegato';
import Cookies from 'universal-cookie';

export default class FileBubble extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            messaggio: props.messaggio.messaggio.replace('file:///', '')
        };
    }

    async downloadFile() {
        this.setState({ isDownloading: true });

        let file = {};
        if (this.props.messaggio.id) {
            const cookies = new Cookies();
            const username = cookies.get('username', { path: '/' });
            const token = cookies.get('token', { path: '/' });

            file = await getAllegato(username, token, this.props.messaggio.id);
            if (!file) {
                alert('Errore durante il download del messaggio');
                this.setState({ isDownloading: false });
                return;
            }
        } else {
            file.messaggio = this.props.messaggio.messaggio;
        }

        let base64 = file.messaggio.replace('file:///', '');

        try {
            triggerBase64Download(base64, 'PersonalDoc-file');
        } catch(error) {
            alert('Errore durante il download del messaggio');
        }
        this.setState({ isDownloading: false });
    }

    render() {

        let altro = this.props.tipo === 'psicologo' ? '2' : '1'; 

        return(
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: this.props.messaggio.mittente === altro ? 'flex-start' : 'flex-end', alignItems: this.props.messaggio.mittente === altro ? 'flex-start' : 'flex-end' }}>
                <section style={{ padding: 10, borderRadius: 10, backgroundColor: this.props.messaggio.mittente === altro ? '#E5E5E5' : '#C9D4F0', maxWidth: '50%', wordBreak: 'break-word', display: 'flex', flexDirection: 'row' }}>
                    <a onClick={() => this.downloadFile()}><RiDownloadCloudFill size={30} color='white' style={{ cursor: 'pointer' }} /></a>
                    <h6 style={{ marginLeft: 10, marginTop: 5 }}>{this.state.isDownloading ? 'Scarico...' : 'File allegato'}</h6>
                </section>
                <p style={{ color: 'lightgrey', fontSize: 12, opacity: 0.6 }}>{moment(this.props.messaggio.data, 'yyyy-MM-DDTHH:mm').format('DD/MM/yyyy HH:mm')}</p>
            </div>
        );
    }
}