
import React from 'react';
import { getServiceName } from '../../utils/consts';
import { NineCellLoading } from 'react-loadingg';

// Styles 
import './style.css';
import './bootstrap.min.css';
import './owl.carousel.css';
import './owl.theme.css';
import './owl.transitions.css';
import './animate.css';
import './preloader.css';

import Cookies from 'universal-cookie';
import checkToken from '../../services/checkToken';
import getRichiestaPrenotazione from '../../services/getRichiestaPrenotazione';
import closeRichiestaPrenotazione from '../../services/closeRichiestaPrenotazione';
import NavbarMain from '../../components/NavbarMain';

const cookies = new Cookies();
let username = cookies.get('username', { path: '/' });
let token = cookies.get('token', { path: '/' });

export default class CancelPage extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            isLoading: true, 
            isMobile: window.innerWidth < 767,
            bannerHeight: window.innerHeight,
            isProfessionista: false, 
            selectedPacchetto: undefined, 
            professionista: undefined, 
            selectedSlot: { i: undefined, index: undefined }, 
            nextVisible: false,
            disponibilita: [], 
            idProfessionista: undefined,
        };
    }

    async componentDidMount() {
        document.title = getServiceName() + ' - Prenotazione annullata';

        username = cookies.get('username', { path: '/' });
        token = cookies.get('token', { path: '/' });

        let idProfessionista = cookies.get('professionista_selezionato', { path: '/' });

        if (!idProfessionista) {

            cookies.remove('orario_selezionato', { path: '/' });
            cookies.remove('professionista_selezionato', { path: '/' });
            cookies.remove('tipo_selezionato', { path: '/' });

            window.location.href = '/#/main/home';
            return;
        }

        if (!username || !token) {

            cookies.remove('orario_selezionato', { path: '/' });
            cookies.remove('professionista_selezionato', { path: '/' });
            cookies.remove('tipo_selezionato', { path: '/' });
            cookies.remove('username', { path: '/' });
            cookies.remove('token', { path: '/' });

            window.location.href = '/#/main/home';
            return;
        }

        let check = await checkToken(username, token, 0);
        if (check.result === 'KO') {

            cookies.remove('orario_selezionato', { path: '/' });
            cookies.remove('professionista_selezionato', { path: '/' });
            cookies.remove('tipo_selezionato', { path: '/' });
            cookies.remove('username', { path: '/' });
            cookies.remove('token', { path: '/' });

            window.location.href = '/#/main/home';
            return;
        }

        let richiesta = await getRichiestaPrenotazione(username, token);

        this.setState({ isLoading: false, richiesta });

        if (richiesta)
            await this.closeRequest();
        else this.setState({ closed: true });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    async closeRequest() {
        await closeRichiestaPrenotazione(username, token, this.state.richiesta.id);
        
        cookies.remove('orario_selezionato', { path: '/' });
        cookies.remove('professionista_selezionato', { path: '/' });
        cookies.remove('tipo_selezionato', { path: '/' });

        this.setState({ closed: true });
    }

    handleResize() {

        this.setState({ bannerHeight: window.innerHeight });

        if (window.innerWidth < 767) {
            this.setState({ isMobile: true });
            return;
        }

        this.setState({ isMobile: false });
    }

    render () {
        return (
            <div id="main_scroll" className='mainBody' style={{width: '100%', height: '100%'}}>
                
                <header id='home' style={{height: '100%'}}>
                <NavbarMain noButtons />

                <div className="bg-rete" style={{position: 'absolute', top: 0, left: 0, width: '100vw', flex: 1, height: this.state.bannerHeight, }}>
                    <div className="container" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div className="row justify-content-center" style={{ zIndex: 1000, display: 'flex', alignItems: 'center', height: '100vh' }}>
                            <div className="col-md-8">
                                <div className="caption">
                                    <div className="prenotaContainer" style={{ maxHeight: 300, minHeight: 100 }}>
                                        { this.state.isLoading ? (
                                            <NineCellLoading />
                                        ) : (
                                            <>
                                                <h5 style={{ color: 'white', }}><b>Si è verificato un errore durante il pagamento</b></h5>
                                                { this.state.closed && (
                                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 0 }}>
                                                        <div className='signUpButton' onClick={() => window.location.href = '/#/main/home'}>Torna alla home</div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </header>
            </div>
        );
    }
}
