import React from 'react';
import translateString from '../utils/Locales';
import Cookies from 'universal-cookie';
import Button from '../components/Button';
import Loading from '../components/Loading';
import CalendarComponent from '../components/Calendar';
import moment from 'moment';
import AddSedutaWidget from '../widgets/addSedutaWidget';
import getSedute from '../services/getSedute';
import ContextMenu from '../widgets/ContextMenu';
import addIndisponibilita from '../services/addIndisponibilita';
import getIndisponibilita from '../services/getIndisponibilita';
import deleteIndisponibilita from '../services/deleteIndisponibilita';
import AddEventoWidget from '../widgets/AddEventoWidget';
import getEventi from '../services/getEventi';
import EditSedutaWidget from '../widgets/EditSedutaWidget';
import EditEventoWidget from '../widgets/EditEventoWidget';
import { getServiceName } from '../utils/consts';
import getOrari from '../services/getOrari';
import getPersonalizzazioni from '../services/getPersonalizzazioni';
import Switch from 'react-switch';
import isGoogleLogged from '../services/isGoogleLogged';
import GoogleLoginModale from '../widgets/GoogleLoginModale';
import { GoogleOAuthProvider } from '@react-oauth/google';
import getGoogleCalendars from '../services/getGoogleCalendars';
import { Stack } from '@mui/system';
import SelectInput from '../widgets/SelectInput';
import getGoogleEvents from '../services/getGoogleEvents';
import { v4 as uuidv4 } from 'uuid';

let t = translateString;
const cookies = new Cookies();
let username = cookies.get('username', { path: '/' });
let token = cookies.get('token', { path: '/' });
export default class AgendaSedute extends React.Component {
    constructor(props) {
        super(props);

        this.calendarRef = React.createRef();

        this.state = {
            isLoading: true,  
            scheduleObj: null,
            sedute: [],
            creaNuovaSeduta: false,
            location: undefined,
            indisponibilita: [],
            calendarDate: moment().toDate(),
            showLogin: false,
            showGoogle: false,
            calendariGoogle: [], 
            calendario: undefined, 
            eventiGoogle: [],
        };
    }

    onCalendarChange(args) {
        this.setState({ scheduleObj: { ...this.state.scheduleObj, selectedDate: args.value } });
    }

    onDragStart(arg) {
        // eslint-disable-next-line no-param-reassign
        arg.navigation.enable = true;
    }

    async handleGoogle(checked) {
        if (checked) {
            let res = await isGoogleLogged(username, token);
            if (res.result === 'KO') {
                // Show google login
                this.setState({ showLogin: true });
                return;
            } else {
                // Load calendars
                let calendars = await getGoogleCalendars(username, token);
                if (!calendars || calendars.result !== 'ok') {
                    this.setState({ showLogin: true });
                    return;
                }
                calendars = calendars.calendars;
                var cal = [];
                calendars.forEach(c => {
                    cal.push({ value: c.id, label: c.summary });
                });
                this.setState({ calendariGoogle: cal });
                // Load events
                if (calendars.length === 0) return;

                let calendario =  { value: calendars[0].id, label: calendars[0].summary };
                setTimeout(async () => {
                    await this.changeCalendar(calendario);
                }, 1000);

                this.setState({ showGoogle: checked });
            }
        }

        this.setState({ showGoogle: checked, sedute: this.state.defaultSedute });
    }

    async changeCalendar(option) {

        this.setState({ sedute: [] })
        let eventiGoogle = await getGoogleEvents(username, token, option.value);
        let allEvents = [...this.state.defaultSedute];
        eventiGoogle.forEach(e => {
            const event = {
                id: uuidv4(), 
                start: moment(e.start.dateTime, 'YYYY-MM-DDTHH:mm:ssZ').toDate(), 
                end: moment(e.end.dateTime, 'YYYY-MM-DDTHH:mm:ssZ').toDate(), 
                title: e.summary,
                type: 'google',
            }
            if (!isNaN(event.start.getTime()) && !isNaN(event.end.getTime()))
                allEvents.push(event);
        })
        this.setState({ calendario: option, eventiGoogle, sedute: allEvents });
    }

    async reload() {
        this.setState({ isLoading: true });

        var sedute = [];

        let res = await getSedute(username, token, 'psicologo');
        if (!res || res.result === 'KO') {
            return;
        }
        
        res.forEach((s) => {

            sedute.push({
                id: parseInt(s.id), 
                start: moment(s.inizio, 'yyyy-MM-DDTHH:mm').toDate(), 
                end: moment(s.fine, 'yyyy-MM-DDTHH:mm').toDate(), 
                title: s.cognome + ' ' + s.nome,
                type: 'seduta',
            });
        });
        
        let ind = [];
        let res1 = await getIndisponibilita(username, token, 'psicologo', '');
        if (!res1 || res1.result === 'KO') {
            return;
        }

        res1.forEach((i) => {
            ind.push({ inizio: moment(i.inizio, 'yyyy-MM-DDTHH:mm').toDate(), fine: moment(i.fine, 'yyyy-MM-DDTHH:mm').toDate() })
        });

        let eventi = [];
        let res2 = await getEventi(username, token);
        if (!res2) {
            return;
        }

        res2.forEach((e) => {
            sedute.push({
                id: parseInt(e.id), 
                start: moment(e.inizio, 'yyyy-MM-DDTHH:mm').toDate(), 
                end: moment(e.fine, 'yyyy-MM-DDTHH:mm').toDate(), 
                title: e.titolo,
                type: 'evento',
            });
        });

        var res3 = await getOrari(username, token);
        if (!res3) {
            res3 = { orari: null };
        }

        let res4 = await getPersonalizzazioni(username, token);
        if (!res4) return;
        
        this.setState(() => ({ sedute, defaultSedute: sedute }), async () => {
            let res5 = await isGoogleLogged(username, token);
            if (res5.result === 'ok')
                this.handleGoogle(true);
        });

        this.setState({ isLoading: false, indisponibilita: ind, eventi, orari: JSON.parse(res3.orari), step: JSON.parse(res4.durata) });
    }

    async componentDidMount() {
        window.document.title = getServiceName() + ' - Agenda';
        username = cookies.get('username', { path: '/' });
        token = cookies.get('token', { path: '/' });
        await this.reload();
    }

    changeDates(dates) {
        this.setState({ calendarDate: dates[0] });
    }

    render() {

        if (this.state.isLoading) return <Loading />;

        return(
            <>
                <div className='top-with-btn' style={{ marginBottom: 40 }}>
                    <div style={{ marginRight: 20 }}>
                        <h3 style={{ color: 'white' }}>{t('calendario_sedute')}</h3>
                        <p style={{ color: 'white' }}>{t('info_calendario_sedute')}</p>
                    </div>
                    <Button ref={this.saveRef} button={t('nuovo').toUpperCase()} animated={false} onClick={() => this.setState({ creaNuovaSeduta: true, target: { start: new Date(), end: new Date() } })} />
                </div>
                <div className="dashview" style={{ marginBottom: 50, paddingTop: 20, backgroundColor: 'white' }}>
                    <Stack direction="row" justifyContent={'space-between'} sx={{ flex: 1 }} style={{ marginBottom: 30, marginTop: 20 }}>
                        <label style={{ marginBottom: 10, marginLeft: 5, width: '100%', marginRight: 5, justifyContent: 'flex-end' }}>
                            <span>Mostra eventi di GoogleCalendar</span>
                            <Switch uncheckedIcon={false} checkedIcon={false} className="switch" onColor='#7B90C4' width={40} height={20} onChange={(checked) => this.handleGoogle(checked)} checked={this.state.showGoogle} />
                        </label>
                        { this.state.showGoogle && (
                            <div style={{ marginTop: -35}}>
                                <SelectInput
                                    key={Math.random()}
                                    value={this.state.calendario}
                                    style={{ width: 300, zIndex:5 }}
                                    options={this.state.calendariGoogle} 
                                    onChange={(option) => {
                                        this.changeCalendar(option);
                                    }}
                                />
                            </div>
                        )}
                    </Stack>
                   <CalendarComponent defaultDate={this.state.calendarDate} events={this.state.sedute} step={parseInt(this.state.step.value)/2} indisponibilita={this.state.indisponibilita} orari={this.state.orari} datesChange={(dates) => this.changeDates(dates)}
                   onCreate={(props) => {

                        // Show selection widget
                        var location = undefined;
                        
                        if( props.box ){
                            location = { x: props.box.x, y: props.box.y };
                        } else {
                            if (!props.bounds) return;
                            location = { x: props.bounds ? props.bounds.x : 0, y: props.bounds ? props.bounds.y : 0 }
                        }
                         
                        this.setState({ location, target: props });
                        //
                   }}
                   onSelect={(evento) => {

                        if (evento.type === 'seduta') {
                            this.setState({ editSeduta: true, evento });
                        } else if (evento.type === 'evento') {
                            this.setState({ editEvento: true, evento });
                        }
                   }}
                   />
                </div>
                { this.state.creaNuovaSeduta && (
                    <AddSedutaWidget target={this.state.target} onClose={(result) => {

                        if (result === 0) {
                            this.reload();
                        }
                        this.setState({ creaNuovaSeduta: false, target: undefined, location: undefined });
                    }} />
                )}
                { this.state.location && (
                    <ContextMenu location={this.state.location} indisponibilita={this.state.indisponibilita} target={this.state.target}
                    
                        onClose={() => this.setState({ location: undefined, target: undefined })}
                        
                        onSeduta={() => {
                            this.setState({ creaNuovaSeduta: true, location: undefined });
                        }}
                        
                        onEvento={() => {
                            this.setState({ creaNuovoEvento: true });
                        }}

                        onIndisponibilita={async () => {

                            let slot = {
                                start: moment(this.state.target.start).format('yyyy-MM-DDTHH:mm'), 
                                end: moment(this.state.target.end).format('yyyy-MM-DDTHH:mm'), 
                                durata: moment(this.state.target.end).diff(moment(this.state.target.start), 'minutes')
                            };
                            
                            let username = cookies.get('username', { path: '/' });
                            let token = cookies.get('token', { path: '/' });
                            let res = await addIndisponibilita(username, token, slot);
                            if (!res || res.result === 'KO') {
                                alert('Errore');
                                return;
                            }

                            this.setState({ location: undefined });
                            this.reload();
                        }}

                        deleteIndisponibilita = { async (i) => {

                            let ind = {
                                inizio: moment(i.inizio).format('yyyy-MM-DDTHH:mm'), 
                                fine: moment(i.fine).format('yyyy-MM-DDTHH:mm')
                            }

                            let username = cookies.get('username', { path: '/' });
                            let token = cookies.get('token', { path: '/' });
                            await deleteIndisponibilita(username, token, ind);
                            
                            this.setState({ target: undefined, location: undefined });
                            this.reload();
                        }}
                    />
                )}
                { this.state.creaNuovoEvento && (
                    <AddEventoWidget target={this.state.target} onClose={(result) => {

                        if (result === 0) {
                            this.reload();
                        }
                        this.setState({ creaNuovoEvento: false, target: undefined, location: undefined });
                    }} />
                )}
                { this.state.editSeduta && (
                    <EditSedutaWidget seduta={this.state.evento} onClose={(result) => {

                        if (result === 0) {
                            this.reload();
                        }
                        this.setState({ editSeduta: false, evento: undefined });
                    }} />
                )}
                { this.state.editEvento && (
                    <EditEventoWidget evento={this.state.evento} onClose={(result) => {

                        if (result === 0) {
                            this.reload();
                        }
                        this.setState({ editEvento: false, evento: undefined });
                    }} />
                )}
                { this.state.showLogin && (
                    <GoogleOAuthProvider clientId="574939206422-ouvhde4ttktrcohr5jbaiqelrffc54q0.apps.googleusercontent.com">
                        <GoogleLoginModale open={this.state.showLogin} handleClose={(res) => {
                            this.setState({ showLogin: false });
                            if (res === 1)
                                this.handleGoogle(true);
                            else 
                                this.setState({ showGoogle: false });
                        }} />
                    </GoogleOAuthProvider>
                )}
            </>
        );
    }
}

