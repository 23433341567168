import Constants from '../utils/consts';

export default async function editImpostazioniFatturazione(username, token, impostazioni, prefisso, regime) {
    return new Promise(function (resolve, reject) {

        fetch(Constants.defaultProtocol + Constants.mainUrl + 'editImpostazioniFatturazione.php', {
            method: 'POST', 
            mode: 'cors', 
            body: JSON.stringify({
                'impostazioni_fatturazione': impostazioni, 
                'prefisso_fattura': prefisso,
                'regime_fiscale': regime
            }), 
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + username + '.' + token, 
            }
        })
        .then((res) => res.json())
        .then((data) => {
            resolve(data);
        })
        
    });
}
