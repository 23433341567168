import { Box, Modal } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect } from "react";
import Cookies from "universal-cookie";
import { useGoogleLogin } from '@react-oauth/google';
import registerGoogle from "../services/registerGoogle";

const cookies = new Cookies();

export default function GoogleLoginModale({ open, handleClose }) {

    const loginRef = React.useRef();

    useEffect(() => {
        //  createLoginButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCredentialResponse = async (response) => {
        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        let req = await registerGoogle(username, token, response.code);
        if (req.result === 'KO') {
            if (handleClose) handleClose(0);
            return;
        }

        handleClose(1);
    }

    const onSuccess = async (res) => {

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        let req = await registerGoogle(username, token, res.code);
        if (req.result === 'KO') {
            if (req.action === "Other user") {
                alert("Questo account Google è già legato ad un altro account su PersonalDoc. Per favore, utilizzane un altro.");
            }
            if (handleClose) handleClose(0);
            return;
        }

        handleClose(1);
    }

    const login = useGoogleLogin({
        onSuccess: (res) => onSuccess(res),
        flow: 'auth-code',
        scope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events'
    })
    
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={{ flex: 1, height: '100%', backgroundColor: '0000004D', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} onClick={() => handleClose && handleClose(0)}>
                <Box sx={{ backgroundColor: 'white', borderRadius: 2, width: 350, p: 3 }} onClick={e => e.stopPropagation()}>
                    <Stack spacing={2}>
                        <h6 style={{ width: '100%', textAlign: 'center', paddingLeft: 30, paddingRight: 30 }}>È richiesto l'accesso a Google per poter utilizzare questa funzione</h6>
                        <p style={{ width: '100%', textAlign: 'center', paddingLeft: 30, paddingRight: 30, marginTop: 5, marginBottom: 5 }}>Assicurati di garantire a PersonalDoc tutte le autorizzazioni del calendario richieste, altriment il sistema non potrà funzionare correttamente. Successivamente sarà possibile accedere a PersonalDoc anche utilizzando il Google Login.</p>
                        {/* <div ref={loginRef} /> */}
                        <img alt="Google Login" src={require('../images/google_login_btn.png')} style={{ width: '70%', marginLeft: '15%', cursor: 'pointer' }} onClick={login} />
                    </Stack>
                </Box>
            </Box>
        </Modal>
    );
}