import React from 'react';
import translateString from '../../utils/Locales';
import WaitingImage from '../../images/psicologi_banner_coming.png';

// Styles 
import './style.css';
import './bootstrap.min.css';
import './owl.carousel.css';
import './owl.theme.css';
import './owl.transitions.css';
import './animate.css';
import './preloader.css';

let t = translateString;

export default class ComingSoonPage extends React.Component {
 
    constructor(props) {
        super(props);
    
        this.state = {
            
        };
    }

    handleResize() {
        if (window.innerWidth < 768) {
            this.setState({ isMobile: true });
        } else {
            this.setState({ isMobile: false });
        }

        this.setState({ bannerHeight: window.innerHeight });
    }

    async componentDidMount() {

        window.addEventListener('resize', () => this.handleResize());
        this.handleResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => this.handleResize());
    }

    render () {

        return (
            <div className='mainBody' ref={this.homeRef} style={{ width: '100%', height: '100%' }}>
                <div style={{ width: '50%', marginLeft: '10%', marginTop: '10%', padding: 50 }}>
                    <h1 style={{ fontWeight: 'bold', fontFamily: require('../../fonts/Nexa.ttf'), fontSize: 50 }}>COMING SOON</h1>
                    <h4 style={{ marginTop: 30, fontWeight: 'bold', fontFamily: require('../../fonts/Nexa.ttf') }}>Il gestionale per gli psicologi, pensato da psicologi.</h4>
                </div>
                <img src={ WaitingImage } style={{ width: this.state.isMobile ? '80%' : '50%', height: 'auto', position: 'absolute', bottom: 40, right: 30 }} />
            </div>
        );
    }
}