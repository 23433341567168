
import React from 'react';
import { getServiceName } from '../../utils/consts';
import { NineCellLoading } from 'react-loadingg';
import StripeImg from '../../images/stripe_accept.png';
import Icon from '../../images/icon.png';

// Styles 
import './style.css';
import './bootstrap.min.css';
import './owl.carousel.css';
import './owl.theme.css';
import './owl.transitions.css';
import './animate.css';
import './preloader.css';

import Cookies from 'universal-cookie';
import checkToken from '../../services/checkToken';
import getPacchettiProfessionista from '../../services/getPacchettiProfessionista';
import getInfoPsicologo from '../../services/getInfoPsicologo';
import moment from 'moment';
import getRichiestaPrenotazione from '../../services/getRichiestaPrenotazione';
import closeRichiestaPrenotazione from '../../services/closeRichiestaPrenotazione';
import createPaymentRequest from '../../services/createPaymentRequest';
import NavbarMain from '../../components/NavbarMain';
import isBolloToPaziente from '../../services/isBolloToPaziente';

const cookies = new Cookies();
let username = cookies.get('username', { path: '/' });
let token = cookies.get('token', { path: '/' });

export default class Riepilogo extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            isLoading: true, 
            isMobile: window.innerWidth < 767,
            bannerHeight: window.innerHeight,
            isProfessionista: false, 
            selectedPacchetto: undefined, 
            professionista: undefined, 
            selectedSlot: { i: undefined, index: undefined }, 
            nextVisible: false,
            disponibilita: [], 
            idProfessionista: undefined,
        };
    }

    async componentDidMount() {
        document.title = getServiceName() + ' - Riepilogo prenotazione';

        username = cookies.get('username', { path: '/' });
        token = cookies.get('token', { path: '/' });

        if (!username || !token) {

            cookies.remove('orario_selezionato', { path: '/' });
            cookies.remove('professionista_selezionato', { path: '/' });
            cookies.remove('tipo_selezionato', { path: '/' });
            cookies.remove('username', { path: '/' });
            cookies.remove('token', { path: '/' });

            window.location.href = '/#/main/home';
            return;
        }

        let check = await checkToken(username, token, 0);
        if (check.result === 'KO') {

            cookies.remove('orario_selezionato', { path: '/' });
            cookies.remove('professionista_selezionato', { path: '/' });
            cookies.remove('tipo_selezionato', { path: '/' });
            cookies.remove('username', { path: '/' });
            cookies.remove('token', { path: '/' });

            window.location.href = '/#/main/home';
            return;
        }

        let richiesta = await getRichiestaPrenotazione(username, token);
        if (!richiesta) {

            cookies.remove('orario_selezionato', { path: '/' });
            cookies.remove('professionista_selezionato', { path: '/' });
            cookies.remove('tipo_selezionato', { path: '/' });

            window.location.href = '/#/main/home';
            return;
        }

        let idProfessionista = richiesta.id_psicologo; // cookies.get('professionista_selezionato', { path: '/' });

        let slotSelezionato = richiesta.slot;
        let professionista = await getInfoPsicologo(idProfessionista);
        var pacchetti = await getPacchettiProfessionista(username, token, idProfessionista);
        try {
            pacchetti = JSON.parse(pacchetti.pacchetti);
        } catch {
            pacchetti = [];
        }

        let pacchetto = pacchetti.find(p => p.id === JSON.parse(richiesta.pacchetto).id);
        if (!pacchetto) {
            cookies.remove('orario_selezionato', { path: '/' });
            cookies.remove('professionista_selezionato', { path: '/' });
            cookies.remove('tipo_selezionato', { path: '/' });

            window.location.href = '/#/main/home';
            return;
        }

        let startTime = parseInt(richiesta.created_at);
        let endTime = startTime + 900;
        this.startCountdown(endTime);

        let bolloToPaziente = await isBolloToPaziente(username, token, idProfessionista);
        this.totale = parseFloat(pacchetto.costo).toFixed(2);
        this.bollo = (this.totale > 77.47 && bolloToPaziente.bollo === '1') ? (2).toFixed(2) : (0).toFixed(2);
        this.finalTotale = (parseFloat(this.totale) + parseFloat(this.bollo)).toFixed(2);
        
        this.setState({ pacchetto, isLoading: false, professionista, idProfessionista, richiesta, slotSelezionato, bollo: bolloToPaziente.bollo === '1' });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    startCountdown(endTime) {

        let countdown = setInterval(async () => {
            let now = Date.now() / 1000;
            if (now > endTime) {
                cookies.remove('orario_selezionato', { path: '/' });
                cookies.remove('professionista_selezionato', { path: '/' });
                cookies.remove('tipo_selezionato', { path: '/' });

                await this.closeRequest(false)
                clearInterval(countdown);
                window.location.href = '/#/main/home';
                return;
            }

            let countDown = endTime - now;
            let minutes = Math.floor((countDown) / 60);
            let seconds = (countDown % 60).toFixed(0);
            
            this.setState({ countdown: minutes + ":" + (seconds < 10 ? '0' : '') + seconds })
        }, 1000);
    }

    async closeRequest(forceDelete) {
        await closeRichiestaPrenotazione(username, token, this.state.richiesta.id, forceDelete);
        
        cookies.remove('orario_selezionato', { path: '/' });
        cookies.remove('professionista_selezionato', { path: '/' });
        cookies.remove('tipo_selezionato', { path: '/' });

        window.location.href = '/#/main/home';
    }

    getPacchettoTitle(tipo, nome) {
        switch(tipo) {
            case 'singola':
                return 'App.to per 1 persona';
            case 'doppia': 
                return 'App.to per 2 persone';
            case 'gruppo':
                return 'App.to di gruppo';
            default: 
                return nome;
        }
    }

    handleResize() {

        this.setState({ bannerHeight: window.innerHeight });

        if (window.innerWidth < 767) {
            this.setState({ isMobile: true });
            return;
        }

        this.setState({ isMobile: false });
    }

    async requestNext() {

        let richiesta = {
            professionista: this.state.idProfessionista, 
            descrizione: this.getPacchettoTitle(this.state.pacchetto.tipo, this.state.pacchetto.nome) + ' il ' + this.state.slotSelezionato.split('T')[0] + ' alle ' + this.state.slotSelezionato.split('T')[1], 
            totale: this.finalTotale, 
            dataAppuntamento: this.state.slotSelezionato, 
            pacchetto: JSON.stringify(this.state.pacchetto)
        };

        let res = await createPaymentRequest(username, token, richiesta);
        if (res.result === 'ok') {
            let url = res.stripe_url;
            window.location.href = url;
            return;
        }

        alert(res.error || "Errore durante la richiesta di pagamento. Riprova più tardi.");
    }

    render () {
        return (
            <div id="main_scroll" className='mainBody' style={{width: '100%', height: '100%'}}>
                
                <header id='home' style={{height: '100%'}}>
                <NavbarMain noButtons blackBg />

                <div className="bg-rete" style={{position: 'absolute', top: 0, left: 0, width: '100vw', flex: 1, height: this.state.bannerHeight, }}>
                    <div className="container" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div className="row justify-content-center" style={{ zIndex: 1000, display: 'flex', alignItems: 'center', height: '100vh' }}>
                            <div className="col-md-8">
                                <div className="caption" style={{marginTop: this.state.isMobile ? 60 : 150, overflow: this.state.isMobile ? 'scroll' : 'hidden' }}>
                                    <div className="prenotaContainer" style={{ maxHeight: 'calc(100vh - 150px)' }}>
                                        { this.state.isLoading ? (
                                            <NineCellLoading />
                                        ) : (
                                            <>
                                                { this.state.isProfessionista ? (
                                                    <p style={{ color: 'lightgrey', marginTop: 30, fontWeight: 'bold', alignSelf: 'center' }}>È necessario effettuare l'accesso con un account di tipo paziente per poter accedere a questa sezione.</p>
                                                ) : (
                                                    <>
                                                        <div className="d-flex flex-column align-items-center" style={{backgroundColor:"rgba(28 27 27 / 79%)", padding:"15px", borderRadius:4, marginBottom: "30px", marginTop: this.state.isMobile ? 150 : 0 }}>

                                                            <p style={{margin:0}}>
                                                                Tempo rimanente per l'acquisto: 
                                                            </p>

                                                            <p key={Math.random()} style={{ color: '#B7DB9A', fontWeight: 'bold', fontSize: 16, margin:0 }}>{this.state.countdown}</p>
                                                        </div>
                                                        <h5 style={{textShadow:"3px 4px 3px #000000", color: 'white', marginBottom: 20 }}>Riepilogo appuntamento con: <b>{this.state.professionista.cognome} {this.state.professionista.nome}</b></h5>
                                                        <div style={{ display: 'table', flexDirection: 'row', width: '100%', overflow:'scroll' }}>
                                                            <div style={{ display: 'table-cell', flex: 1, justifyContent: 'flex-start', borderRight: '1px solid lightgrey' }}>
                                                                <p style={{ width: '100%', marginBottom: 0 }}>{this.getPacchettoTitle(this.state.pacchetto.tipo, this.state.pacchetto.nome)}</p>
                                                                <p style={{ width: '100%', marginTop: 5, marginLeft: 4 }}>Il {moment(this.state.slotSelezionato, 'yyyy-MM-DDTHH:mm').format('DD/MM/yyyy')} alle {moment(this.state.slotSelezionato, 'yyyy-MM-DDTHH:mm').format('HH:mm')}</p>
                                                            </div>
                                                            <div style={{ display: 'table-cell', flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end', paddingLeft: 20, paddingRight: 20 }}>
                                                                <p style={{ flex: 1, textAlign: 'right' }}>Totale (tasse incluse): <b>€ {this.totale}</b></p>
                                                                <p style={{ flex: 1, textAlign: 'right', marginTop: -10 }}>Imposta di bollo: <b>€ {this.bollo}</b></p>
                                                                <p style={{ fontWeight: 'bold', flex: 1, textAlign: 'right', marginTop: -10 }}>Totale: <b>€ {this.finalTotale}</b></p>
                                                            </div>
                                                        </div>

                                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 50 }}>
                                                            <div className='signUpButton' onClick={this.requestNext.bind(this)}>Vai al pagamento</div>
                                                        </div>
                                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: -10 }}>
                                                            <div className='signUpButton' onClick={() => this.closeRequest(true)}>Annulla</div>
                                                        </div>
                                                        <img src={StripeImg} alt="" style={{ height: 50, width: 'auto', marginTop: 40 }} />
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </header>
            </div>
        );
    }
}
