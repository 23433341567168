import React from 'react';
import { AccumulationChartComponent, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, AccumulationLegend, PieSeries, AccumulationDataLabel, Inject, AccumulationTooltip } from '@syncfusion/ej2-react-charts';

export default class PieChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = { };
    }

    render() {
        return(
            <>
                <AccumulationChartComponent
                    id={this.props.id}
                    legendSettings={{ visible: this.props.legendVisiblity, background: 'white' }}
                    height={this.props.height}
                    background={'transparent'}
                    tooltip={{ enable: true }}
                >
                    <Inject services={[AccumulationLegend, PieSeries, AccumulationDataLabel, AccumulationTooltip]} />
                    <AccumulationSeriesCollectionDirective>
                        <AccumulationSeriesDirective
                            name={this.props.nome}
                            dataSource={this.props.data}
                            xName="x"
                            yName="y"
                            innerRadius="40%"
                            startAngle={0}
                            endAngle={360}
                            radius="70%"
                            explode
                            explodeOffset="10%"
                            explodeIndex={2}
                            dataLabel={{
                                visible: true,
                                name: 'text',
                                position: 'Inside',
                                font: {
                                fontWeight: '600',
                                color: '#fff',
                                },
                            }}
                        />
                    </AccumulationSeriesCollectionDirective>
                </AccumulationChartComponent>
            </>
        );
    }
}