import moment from 'moment';
import React from 'react';
import Cookies from 'universal-cookie';
import ButtonCustom from '../components/Button';
import { MeteorRainLoading } from 'react-loadingg';
import translateString from '../utils/Locales';
import CloseImg from '../images/close.png';

import '../index.css';
import getSeduta from '../services/getSeduta';
import { Checkbox } from '@material-ui/core';
import { Stack } from '@mui/system';
import getPacchettiSedute from '../services/getPacchettiSedute';

let t = translateString;
const cookies = new Cookies();

export default class EditSedutaPazienteWidget extends React.Component {
    constructor(props) {
        super(props);

        let margin = ((window.innerHeight * 15) / 100) + window.scrollY;

        this.state = {
            isLoading: true, 
            margin: margin,
            pazienti: [{ value: '', label: t('sel_paziente') }],
            paziente: { value: undefined, label: t('sel_paziente') }, 
            startDate: props.seduta.start, 
            endDate: props.seduta.end,
            error: false, 
            errorMessage: '',
            note: '', 
            isOnline: false,
            openFatturazione: false,
            openTS: false, 
            numeroAutomatico: true,
            showPagamentoAnticipato: false, 
            pagamentoAnticipato: false, 
        };
    }

    handleScroll() {
        this.setState({ margin: 'calc(15vh + ' + window.scrollY + 'px)' });
    }

    async reload() {

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });

        let seduta = await getSeduta(username, token, 'paziente', this.props.seduta.id);
        if (!seduta || seduta.result === 'KO') {
            this.props.onClose();
            return;
        }
        
        window.addEventListener('scroll', this.handleScroll.bind(this));

        this.setState({
            appuntamentoOriginale: seduta,
            id: seduta.id,
            idProfessionista: seduta.id_psicologo,
            isLoading: false,
            isOnline: seduta.online === '1',
            join_link: seduta.join_link,
            sedutaId: seduta.id, 
            pagata: seduta.pagata === '1', 
            importo: seduta.importo_pagato, 
            dataFattura: seduta.data_fattura ? seduta.data_fattura : null, 
            fatturata: seduta.fatturata === '1',  
            tipo: seduta.tipo ? seduta.tipo.charAt(0).toUpperCase() + seduta.tipo.slice(1) : 'Non definito', 
            urlFattura: seduta.link_fattura,
            pagamentoAnticipato: seduta.pagamento_anticipato === '1', 
        });
    }

    async componentDidMount() {
        await this.reload();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    showError(message) {
        alert(message);
    }

    async openFattura() {

        cookies.set('id_appuntamento', this.props.seduta.id, { path: '/' });
        window.location.href = "/#/paziente/pdfFattura";
    }

    render() {

        if (this.state.isLoading) return(
            <div style={backView} onClick={() => this.props.onClose && this.props.onClose()}>
                <div style={{ ...mainView, marginTop: this.state.margin }} onClick={(e) => e.stopPropagation()}>
                    <MeteorRainLoading />
                </div>
            </div>
        );

        var isToday = false;
        if (moment(this.state.startDate).format('DD/MM/yyyy') === moment().format('DD/MM/yyyy')) isToday = true;

        return(
            <div style={backView} onClick={() => this.props.onClose && this.props.onClose()}>
                <div style={{ ...mainView, marginTop: this.state.margin }} onClick={(e) => e.stopPropagation()}>
                    <img src={ CloseImg } style={{ position: 'absolute', top: 20, right: 20, width: 20, height: 20, cursor: 'pointer' }} onClick={() => this.props.onClose && this.props.onClose()} />
                    <h3>Appuntamento</h3>
                    { this.state.error && (
                        <div className='error-div'>
                            {this.state.errorMessage}
                        </div>
                    )}
                    <div style={{ marginTop: 20 }} />
                    <p><b>Professionista:</b> {this.state.appuntamentoOriginale.cognome} {this.state.appuntamentoOriginale.nome}</p>
                    <p><b>Tipologia:</b> {this.state.tipo}</p>
                    <p><b>Data:</b> {moment(this.state.startDate).format('DD/MM/yyyy HH:mm')} - {moment(this.state.endDate).format('HH:mm')}</p>
                    <p><b>Luogo:</b> {this.state.isOnline ? t('lez_online') : t('sed_presenza') }</p>
                    { !isNaN(this.state.importo) && <p><b>Importo:</b> € {parseFloat(this.state.importo).toFixed(2)}</p> }
                    <p><b>Stato:</b> <span style={{ paddingTop: 3, paddingBottom: 3, paddingLeft: 10, paddingRight: 10, marginLeft: 10, borderRadius: 4, backgroundColor: this.state.pagata ? '#CDEAAC' : '#F5CECA', color: this.state.pagata ? '#518316' : '#CF4B47' }}>{this.state.pagata ? "Pagato" : "Da pagare"}</span></p>

                    { this.state.isOnline && (
                        <section style={{ display: 'flex', flexDirection: 'row' }}>
                            { isToday && <ButtonCustom style={{ boxShadow: 'none', }} button={"Apri riunione online"} onClick={() => window.open(this.state.join_link, '_blank')} /> }
                        </section>
                    )}
                    <div style={{ marginTop: 30 }} />
                    { false && <Stack direction='row' style={{ marginLeft: -10, marginTop: -10, marginBottom: 0 }}>
                        <Checkbox disabled key={Math.random()} checked={this.state.pagamentoAnticipato} onChange={() => this.setState({ pagamentoAnticipato: !this.state.pagamentoAnticipato })} />
                        <p style={{ marginTop: 18 }}>Pagamento anticipato</p>
                    </Stack> }
                    
                    {this.state.urlFattura &&
                        <ButtonCustom button={"Visualizza fattura"} style={{ width: '100%', boxShadow: 'none', flex: 1 }} onClick={() => this.openFattura()} />
                    }
                    <ButtonCustom button={"Contatta professionista"} style={{ width: '100%', boxShadow: 'none', flex: 1 }} onClick={() => window.location.href = '/#/paziente/messaggi?professionista=' + this.state.idProfessionista} />
                </div>
            </div>
        );
    }
}

const backView = {
    position: 'absolute', 
    top: 0, 
    left: 0,
    minWidth: '100vw', 
    minHeight: '100%', 
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#00000066', 
    zIndex: 10,
    textAlign: 'center',
}

const mainView = {
    position: 'relative',
    width: 400,
    maxHeight: '90vh', 
    minHeight: 200,
    marginLeft: 'calc(((100vw - 400px)/2) + 15px)',
    backgroundColor: 'white', 
    borderRadius: 10, 
    overflow: 'scroll',
    padding: 30,
    textAlign: 'left',
}