import Constants from '../utils/consts';

export default async function getProfiloPaziente(username, token) {
    return new Promise(function (resolve, reject) {

        fetch(Constants.defaultProtocol + Constants.mainUrl + 'getProfiloPaziente.php', {
            method: 'POST', 
            mode: 'cors', 
            body: JSON.stringify({ }), 
            headers: { 
                'Authorization': 'Bearer ' + username + '.' + token, 
                'Content-Type': 'application/json'
            }
        })
        .then((res) => res.json())
        .then((data) => {
            resolve(data);
        })
        
    });
}
