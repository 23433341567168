import moment from 'moment';
import React from 'react';

export default class ChatBubble extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {

        let altro = this.props.tipo === 'psicologo' ? '2' : '1'; 

        return(
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: this.props.messaggio.mittente === altro ? 'flex-start' : 'flex-end', alignItems: this.props.messaggio.mittente === altro ? 'flex-start' : 'flex-end' }}>
                <section style={{color:'black', padding: 10, borderRadius: 10, backgroundColor: this.props.messaggio.mittente === altro ? '#E5E5E5' : '#777778', maxWidth: '50%', wordBreak: 'break-word' }}>
                    <p>{this.props.messaggio.messaggio}</p>
                </section>
                <p style={{ color: 'lightgrey', fontSize: 12, opacity: 0.6 }}>{moment(this.props.messaggio.data, 'yyyy-MM-DDTHH:mm').format('DD/MM/yyyy HH:mm')}</p>
            </div>
        );
    }
}