import Constants from '../utils/consts';

export default async function updateEvento(username, token, evento) {
    return new Promise(function (resolve, reject) {

        fetch(Constants.defaultProtocol + Constants.mainUrl + 'updateEvento.php', {
            method: 'POST', 
            mode: 'cors', 
            body: JSON.stringify({
                'id': evento.id,
                'id_cliente': evento.id_cliente, 
                'inizio': evento.start, 
                'fine': evento.end, 
                'durata': evento.durata, 
                'note': evento.note, 
                'luogo': evento.luogo, 
                'titolo': evento.titolo, 
                'fatturata': evento.fatturata, 
                'pagata': evento.pagata,
                'importo': evento.importo, 
                'iva': evento.iva, 
                'data_fattura': evento.dataFattura, 
                'numero_fattura': evento.numeroFattura, 
                'data_pagamento': evento.dataPagamento, 
                'metodo_pagamento': evento.metodoPagamento, 
                'descrizione': evento.descrizione, 
                'numero_automatico': evento.numeroAutomatico,
            }), 
            headers: { 
                'Authorization': 'Bearer ' + username + '.' + token, 
                'Content-Type': 'application/json'
            }
        })
        .then((res) => res.json())
        .then((data) => {
            resolve(data);
        })
        
    });
}
