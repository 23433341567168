import moment from 'moment';
import React from 'react';
import Cookies from 'universal-cookie';
import Button from '../components/Button';
import CustomDatePicker from '../components/DatePicker';
import { MeteorRainLoading } from 'react-loadingg';
import translateString from '../utils/Locales';
import SelectInput from './SelectInput';
import CloseImg from '../images/close.png';

import '../index.css';
import getClienti from '../services/getClienti';
import getEvento from '../services/getEvento';
import updateEvento from '../services/updateEvento';
import deleteEvento from '../services/deleteEvento';
import { Checkbox, Collapse, List, ListItemIcon, ListItemText, TextField } from '@material-ui/core';
import { ListItemButton, Stack } from '@mui/material';
import { EuroSymbol, ExpandLess, ExpandMore } from '@material-ui/icons';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MultiSelect from './MultiSelect';
import dayjs from 'dayjs';
import getProfiloProfessionista from '../services/getProfiloProfessionista';
import inviaFatturaElettronica from '../services/inviaFatturaElettronica';
import clienteCanReceiveFatturaElettronica from '../services/clienteCanReceiveFatturaElettronica';
import { provaGratuitaCheck } from '../utils/consts';
import getAbbonamento from '../services/getAbbonamento';
import canSendElectronicInvoices from '../services/canSendElectronicInvoices';

let t = translateString;
const cookies = new Cookies();

export default class EditEventoWidget extends React.Component {
    constructor(props) {
        super(props);

        this.mainRef = React.createRef();

        let margin = ((window.innerHeight * 5) / 100) + window.scrollY;

        console.log(props.evento);

        this.state = {
            isLoading: true, 
            margin: margin,
            titolo: props.evento.title,
            clienti: [{ value: '', label: t('sel_cliente') }],
            cliente: { value: '', label: t('sel_cliente') }, 
            startDate: props.evento.start, 
            endDate: props.evento.end,
            error: false, 
            errorMessage: '',
            note: '', 
            luogo: '', 
            isOnline: false,
            showDetail: false,
        };
    }

    handleScroll() {
        this.setState({ margin: 'calc(5vh + ' + window.scrollY + 'px)' });
    }

    async reload() {
        var clienti = [{ value: '', label: t('sel_cliente') }];

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        let res = await getClienti(username, token);
        if (!res || res.result === 'KO') {
            this.props.onClose();
            return;
        }

        res.forEach((c) => {
            clienti.push({ value: c.id, label: c.ragione_sociale });
        });

        let evento = await getEvento(username, token, this.props.evento.id);
        if (!evento) {
            this.props.onClose();
            return;
        }

        let professionista = await getProfiloProfessionista(username, token);
        let abbonamento = await getAbbonamento(username, token);

        window.addEventListener('scroll', this.handleScroll.bind(this));

        let check = await provaGratuitaCheck();

        this.setState({
            isLoading: false,
            clienti,
            evento,
            cliente: evento.id_cliente ? clienti.find((c) => c.value === evento.id_cliente) : { value: '', label: t('sel_cliente') },
            note: evento.note ? evento.note : '', 
            luogo: evento.luogo ? evento.luogo : '', 
            isOnline: evento.online === '1', 
            start_link: evento.start_link, 
            join_link: evento.join_link, 
            eventoId: evento.id, 
            fatturata: evento.fatturata === '1', 
            numeroFattura: evento.numero_fattura, 
            importo: evento.importo_pagato ? parseFloat(evento.importo_pagato) : null, 
            pagata: evento.pagata === '1', 
            dataPagamento: evento.data_pagata, 
            dataFattura: evento.data_fattura,
            metodo: evento.metodo_pagamento, 
            linkFattura: evento.link_fattura,
            ivaCustom: parseFloat(evento.aliquota_iva),
            descrizione: evento.descrizione,  
            professionista,
            canDelete: evento.fatturata === "0", 
            numeroAutomatico: evento.numerazione_automatica === "1", 
            inviataAde: evento.inviata_ade !== "0", 
            uuid: evento.uuid || "",
            statoAde: evento.inviata_ade, 
            showFatturazione: !check,
            canUseFatturazione: abbonamento && abbonamento.id !== '0',
        });
    }

    async componentDidMount() {
        await this.reload();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevPorps, prevState, snapshot) {
        console.log(this.state.ivaCustom);
        if (prevState.importo !== this.state.importo || prevState.ivaCustom !== this.state.ivaCustom) {
            if (isNaN(this.state.importo) || !this.state.importo) {
                this.setState({ showDetail: false });
                return;
            }

            const impBollo = this.state.ivaCustom === 0 ? (this.state.importo >= 77.47 ? 2.0 : 0.0) : 0.0;
            const percentualeTasse = this.state.ivaCustom;
            const denominatore = 1 + (percentualeTasse/100); 
            const importo = this.state.importo/denominatore;
            const tasse = importo * (percentualeTasse/100);

            this.setState({ importoTasse: tasse.toFixed(2), subtotale: importo.toFixed(2), bollo: impBollo.toFixed(2), showDetail: true });                             
        }
    }

    isFatturaValida() {
        return this.state.fatturata && this.state.importo && this.state.numeroFattura;
    }

    showError(message) {
        this.setState({ error: true, errorMessage: message });
        if (this.mainRef.current) this.mainRef.current.scrollTo(0, 0);
        setTimeout(() => {
            this.setState({ error: false, errorMessage: '' });
        }, 3000);
    }

    async salva(fromInvio) {

        if (!this.state.titolo.trim()) {
            this.showError(t('no_titolo'));
            return;
        }

        if (this.state.startDate.getTime() >= this.state.endDate.getTime()) {
            this.showError(t('controlla_date'));
            return;
        }

        if (this.state.pagata && !this.state.fatturata) {
            this.showError("L'evento risulta pagato, ma non fatturato.");
            return;
        }

        if (this.state.fatturata) {
            if (!this.state.descrizione) {
                this.showError("L'evento risulta fatturato, ma non è stata inserita una descrizione.");
                return;
            }

            if (!this.state.importo) {
                this.showError("L'evento risulta fatturato, ma non è stato inserito l'importo.");
                return;
            }

            if (!this.state.dataFattura) {
                this.showError("L'evento risulta fatturato, ma non è stata inserita una data di emissione.");
                return;
            }
        }

        if (this.state.pagata) {
            if (!this.state.dataPagamento) {
                this.showError("L'evento risulta pagato, ma non è stato inserita la data di pagamento.");
                return;
            }

            if (!this.state.metodo) {
                this.showError("L'evento risulta pagato, ma non è stato inserito il metodo di pagamento.");
                return;
            }
        }

        let evento = {
            id: this.props.evento.id,
            titolo: this.state.titolo,
            luogo: this.state.luogo,
            start: moment(this.state.startDate).format('yyyy-MM-DDTHH:mm'), 
            end: moment(this.state.endDate).format('yyyy-MM-DDTHH:mm'), 
            id_cliente: this.state.cliente.value ? this.state.cliente.value : null, 
            note: this.state.note, 
            durata: moment(this.state.endDate).diff(moment(this.state.startDate), 'minutes'),
            pagata: this.state.pagata ? 1 : 0, 
            fatturata: this.state.fatturata ? 1 : 0, 
            importo: this.state.importo ? this.state.importo.toFixed(2) : "",
            dataFattura: this.state.dataFattura ? this.state.dataFattura : "", 
            dataPagamento: this.state.dataPagamento ? this.state.dataPagamento : "",
            metodoPagamento: this.state.metodo ? this.state.metodo : "", 
            numeroFattura: this.state.numeroFattura ? this.state.numeroFattura : "",
            iva: this.state.ivaCustom, 
            descrizione: this.state.descrizione,
            numeroAutomatico: this.state.numeroAutomatico ? 1 : 0,
        }

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        let res = await updateEvento(username, token, evento);
        if (!res || res.result === 'KO') {
            this.showError( res.message || t('errore_imprevisto') );
            return false;
        }

        if (!fromInvio) this.props.onClose(0);
        else return true

    }

    async invioElettronico() {

        let saved = await this.salva(true)
        if (!saved) return;
        if (!this.state.dataPagamento || !this.state.metodo) {
            this.showError("Per potere inviare la fattura elettronica è necessario inserire il metodo di pagamento e la data di pagamento.");
            return;
        }

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });

        let clienteCheck = await clienteCanReceiveFatturaElettronica(username, token, this.state.cliente.value);
        if (!clienteCheck || clienteCheck.result === 'KO') {
            this.showError("Il cliente selezionato non è autorizzato a ricevere fatture elettroniche. Verifica la sua anagrafica.");
            return;
        }

        const check = await canSendElectronicInvoices(username, token);
        if (check.result === 'KO') {
            this.showError("Non puoi inviare fatture elettroniche. Verifica di non aver terminato le fatture a tua disposizione.");
            return;
        }

        let res = await inviaFatturaElettronica(username, token, this.props.evento.id);
        if (!res || res.result === "KO") {
            if (res && res.messaggio) this.showError(res.messaggio);
            else this.showError("Errore nell'invio della fattura elettronica. Riprova più tardi.");
            return;
        }

        await this.reload();
    }

    async delete() {

        if (window.confirm(t('elimina_evento'))) {

            let username = cookies.get('username', { path: '/' });
            let token = cookies.get('token', { path: '/' });

            let res = await deleteEvento(username, token, this.state.eventoId);
            if (!res || res.result === 'KO') {
                this.setState({ error: true, errorMessage: t('errore_imprevisto')});
                setTimeout(() => {
                    this.setState({ error: false, errorMessage: '' });
                }, 3000);
                return;
            }

            this.props.onClose(0);

        }
    }

    render() {

        if (this.state.isLoading) return(
            <div style={backView} onClick={() => this.props.onClose && this.props.onClose()}>
                <div style={{ ...mainView, marginTop: this.state.margin }} onClick={(e) => e.stopPropagation()}>
                    <MeteorRainLoading />
                </div>
            </div>
        );

        var isToday = false;
        if (moment(this.state.startDate).format('DD/MM/yyyy') === moment().format('DD/MM/yyyy')) isToday = true;

        return(
            <div style={backView} onClick={() => this.props.onClose && this.props.onClose()}>
                <div ref={this.mainRef} style={{ ...mainView, marginTop: this.state.margin }} onClick={(e) => e.stopPropagation()}>
                    <img src={ CloseImg } style={{ position: 'absolute', top: 20, right: 20, width: 20, height: 20, cursor: 'pointer' }} onClick={() => this.props.onClose && this.props.onClose()} />
                    <h3>{t('modifica_evento')}</h3>
                    { this.state.error && (
                        <div className='error-div'>
                            {this.state.errorMessage}
                        </div>
                    )}
                    <SelectInput
                        value={this.state.cliente}
                        small={true} 
                        style={{ width: '100%', marginTop: 30 }}
                        hidable={false}
                        isMulti={false}
                        options={this.state.clienti} 
                        onChange={(option) => 
                            this.setState({ cliente: option })} 
                    />
                    <label style={{ marginTop: 10, marginLeft: 5 }}>
                        <span>{this.state.isOnline ? t('ev_online') : t('ev_presenza') }</span>
                    </label>

                    { this.state.isOnline && (
                        <section style={{ display: 'flex', flexDirection: 'row' }}>
                            { isToday && <Button button={"Inizia evento online"} onClick={() => window.open(this.state.start_link, '_blank')} /> }
                            { isToday && <section style={{ height: 10, width: 10 }} /> }
                            <Button button={"Copia link cliente"} onClick={() => navigator.clipboard.writeText(this.state.join_link)} />
                        </section>
                    )}

                    <h5 style={{ marginTop: 30 }}>{t('titolo').replace('...', '') + ' *'}</h5>
                    <input style={{ width: '100%', border: '1px solid lightgrey', borderRadius: 10, marginTop: 10, padding: 10, marginBottom: 10 }} type={'text'} value={this.state.titolo} onChange={(e) => this.setState({ titolo: e.target.value })} />    

                    <h5 style={{ marginTop: 30 }}>{t('date_s')}</h5>
                    <CustomDatePicker 
                        date={this.state.startDate}
                        onChange={(date) => this.setState({ startDate: date })}
                        title="Inizio"
                    />
                    <br/>
                    <CustomDatePicker 
                        date={this.state.endDate}
                        onChange={(date) => this.setState({ endDate: date })}
                        title="Fine"
                    />

                    { !this.state.isOnline && (
                        <section>
                            <h5 style={{ marginTop: 30 }}>{t('dove')}</h5>
                            <input style={{ width: '100%', border: '1px solid lightgrey', borderRadius: 10, marginTop: 10, padding: 10, marginBottom: 10 }} type={'text'} value={this.state.luogo} onChange={(e) => this.setState({ luogo: e.target.value })} />
                        </section>
                    )}
                    
                    { this.state.showFatturazione && (
                    <List
                        sx={{ width: '100%', flex: 1, bgcolor: 'background.paper', marginTop: 20 }}
                        component="nav"
                        aria-labelledby="nested-list-subheader">
                    
                        <ListItemButton onClick={() => this.setState({ openFatturazione: !this.state.openFatturazione})}>
                            <ListItemIcon>
                            <EuroSymbol />
                            </ListItemIcon>
                            <ListItemText primary="Fatturazione" />
                            {this.state.openFatturazione ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={this.state.openFatturazione} timeout="auto" unmountOnExit>
                            <Stack spacing={2} sx={{ pb: 2 }}>
                                <Stack direction='row' style={{ marginLeft: -10, marginTop: 20, marginBottom: -20 }}>
                                    <Checkbox key={Math.random()} checked={this.state.fatturata} onChange={() => this.setState({ fatturata: !this.state.fatturata })} />
                                    <p style={{ marginTop: 18 }}>Evento fatturato</p>
                                </Stack>
                                <Stack direction='row' style={{ marginLeft: -10, marginTop: 5, marginBottom: this.state.fatturata ? -20 : 20 }}>
                                    <Checkbox key={Math.random()} checked={this.state.pagata} onChange={() => this.setState({ pagata: !this.state.pagata })} />
                                    <p style={{ marginTop: 18 }}>Evento pagato</p>
                                </Stack>
                                {this.state.fatturata && (
                                    <>
                                        <TextField fullWidth value={this.state.descrizione} style={{ marginTop: 20 }} type='text' label="Descrizione *" onChange={(e) => this.setState({ descrizione: e.target.value })} />
                                        <Stack direction='row' style={{ marginLeft: -10, marginTop: 5, marginBottom: -30 }}>
                                            <Checkbox key={Math.random()} checked={this.state.numeroAutomatico} onChange={() => this.setState({ numeroAutomatico: !this.state.numeroAutomatico })} />
                                            <p style={{ marginTop: 18 }}>Numero di fattura automatico</p>
                                        </Stack>
                                        { !this.state.numeroAutomatico && <TextField fullWidth value={this.state.numeroFattura} label="Numero fattura *" onChange={(e) => this.setState({ numeroFattura: e.target.value })} /> }
                                        <TextField fullWidth value={this.state.importo} type='number' label="Importo (€) *" onChange={(e) => this.setState({ importo: parseFloat(e.target.value)})} />
                                        <TextField fullWidth value={this.state.ivaCustom} type='number' label="% IVA *" onChange={(e) => this.setState({ ivaCustom: isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value) })} />
                                        
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DesktopDatePicker
                                                label={'Data fattura'}
                                                inputFormat={"DD/MM/YYYY"}
                                                value={this.state.dataFattura ? dayjs(this.state.dataFattura, 'DD/MM/YYYY') : null}
                                                onChange={(value) => this.setState({ dataFattura: (value && value.isValid()) ? value.format('DD/MM/YYYY') : null })}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                            <DesktopDatePicker
                                                label={'Data pagamento'}
                                                inputFormat={"DD/MM/YYYY"}
                                                value={this.state.dataPagamento ? dayjs(this.state.dataPagamento, 'DD/MM/YYYY') : null}
                                                onChange={(value) => this.setState({ dataPagamento: (value && value.isValid()) ? value.format('DD/MM/YYYY') : null })}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                        <MultiSelect fullWidth single label={'Metodo di pagamento *'} names={[ "Contanti", "Carta di credito", "Bancomat", "Pagamento elettronico", "Bonifico", "Assegno" ]} onChangeListener={(metodi) => this.setState({ metodo: metodi ? metodi[0] : null })} sel={this.state.metodo ? [this.state.metodo] : null} />
                                        { this.state.showDetail && (
                                            <Stack spacing={0}>
                                                <h6 style={{ marginTop: 18 }}>Dettagli di pagamento</h6>
                                                <Stack direction="row" sx={{ justifyContent: 'space-between', width: '100%' }}>
                                                    <p style={{ marginTop: 10, fontWeight: 'bold' }}>Subtotale:</p>
                                                    <p style={{ marginTop: 10 }}>€ {this.state.subtotale}</p>
                                                </Stack>
                                                <Stack direction="row" sx={{ justifyContent: 'space-between', width: '100%' }}>
                                                    <p style={{ marginTop: 0, fontWeight: 'bold' }}>IVA ({this.state.ivaCustom.toFixed(0)} %):</p>
                                                    <p style={{ marginTop: 0 }}>€ {this.state.importoTasse}</p>
                                                </Stack>
                                                { this.state.bollo > 0 && ( <Stack direction="row" sx={{ justifyContent: 'space-between', width: '100%' }}>
                                                    <p style={{ marginTop: 0, fontWeight: 'bold' }}>Marca da bollo:</p>
                                                    <p style={{ marginTop: 0 }}>€ {this.state.bollo}</p>
                                                </Stack> )}
                                                <Stack direction="row" sx={{ justifyContent: 'space-between', width: '100%' }}>
                                                    <p style={{ marginTop: 5, fontWeight: 'bold', fontSize: 16 }}>Totale:</p>
                                                    <p style={{ marginTop: 5, fontSize: 16, fontWeight: 'bold' }}>€ {parseFloat(this.state.importo) + parseFloat(this.state.bollo)}</p>
                                                </Stack>
                                            </Stack>
                                        )}
                                        { !this.state.inviataAde && <Button disabled={!this.state.canUseFatturazione} disabledMessage="Funzione non disponibile durante la prova gratuita" button={"Invia fattura ad AdE"} style={{ width: '100%', boxShadow: 'none', flex: 1 }} onClick={() => this.invioElettronico()} /> }
                                        { this.state.statoAde === '1' && (
                                            <>
                                                <p style={{ marginTop: 5, fontSize: 16, textAlign: 'center', color: 'orange' }}>La fattura {this.state.numeroFattura} è stata presa in carico dal Sistema di Interscambio dell'Agenzia delle Entrate</p>
                                            </>
                                        )}
                                        { this.state.statoAde === '2' && (
                                            <>
                                                <p style={{ marginTop: 5, fontSize: 16, textAlign: 'center', color: 'green' }}>La fattura {this.state.numeroFattura} è stata accettata dal Sistema di Interscambio dell'Agenzia delle Entrate</p>
                                            </>
                                        )}
                                        { this.state.statoAde === '3' && (
                                            <>
                                                <p style={{ marginTop: 5, fontSize: 16, textAlign: 'center', color: 'red' }}>La fattura {this.state.numeroFattura} è stata rifiutata dal Sistema di Interscambio dell'Agenzia delle Entrate</p>
                                            </>
                                        )}
                                    </>
                                )}
                                </Stack>
                        </Collapse>
                    </List>
                    )}

                    <h5 style={{ marginTop: 20 }}>{t('note_s')}</h5>
                    <textarea style={{ resize: 'none', height: 100, width: '100%', border: '1px solid lightgrey', borderRadius: 10, marginTop: 10, padding: 10, marginBottom: 10 }} value={this.state.note} placeholder={t('note')} onChange={(e) => this.setState({ note: e.target.value })} />
                    <section style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Button button={"SALVA"} style={{ marginTop: 20, width: 160, boxShadow: 'none', flex: 1 }} onClick={() => this.salva()} />
                        { this.state.canDelete && (<Button button={"ELIMINA"} style={{ marginTop: 20, width: 160, boxShadow: 'none', flex: 1, backgroundColor: '#FF7474' }} onClick={() => this.delete()} />) }
                    </section>
                </div>
            </div>
        );
    }
}

const backView = {
    position: 'absolute', 
    top: 0, 
    left: 0,
    minWidth: '100vw', 
    minHeight: '100%', 
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#00000066', 
    textAlign: 'center',
    zIndex: 10, 
}

const mainView = {
    position: 'relative',
    width: 400,
    maxHeight: '90vh', 
    marginLeft: 'calc((100vw - 400px)/2)',
    backgroundColor: 'white', 
    borderRadius: 10, 
    overflow: 'scroll',
    padding: 30,
    textAlign: 'left',
}