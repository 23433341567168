import Constants from '../utils/consts';

export default async function addClick(tipo) {
    return new Promise(function (resolve, reject) {

        fetch(Constants.defaultProtocol + Constants.mainUrl + 'addClick.php', {
            method: 'POST', 
            mode: 'cors', 
            body: JSON.stringify({
                'tipo': tipo
            }), 
            headers: { 
                'Content-Type': 'application/json'
            }
        })
        .then(() => resolve())
        
    });
}
