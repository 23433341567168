import Constants from '../utils/consts';

export default async function deleteTS(documento) {
    return new Promise(function (resolve, reject) {

        fetch(Constants.defaultProtocol + Constants.mainUrl + 'eliminaTS.php', {
            method: 'POST', 
            mode: 'cors', 
            body: JSON.stringify({
                'pincode': documento.pincode,
                'cf_proprietario': documento.cfMedico, 
                'user': documento.username, 
                'password': documento.password, 
                'partita_iva': documento.partitaIVA, 
                'data_emissione': documento.dataEmissione, 
                'numero_documento': documento.numeroDocumento
            }), 
            headers: { 
                'Content-Type': 'application/json'
            }
        })
        .then((res) => res.json())
        .then((data) => {
            resolve(data);
        })
        
    });
}
