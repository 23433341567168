import React, { Component } from "react";
import { MdError } from "react-icons/md";
import { AiFillInfoCircle, AiFillCheckCircle } from "react-icons/ai";

export default class Banner extends Component {
  constructor(props) {
    super(props);

    this.backgroundColor = props.tipo === 'error' ? '#EFBBA6' : props.tipo === 'info' ? '#E8E8E8' : '#DAEEC4';
    this.textColor = props.tipo === 'error' ? 'red' : props.tipo === 'info' ? 'grey' : 'green';
    this.icon = props.tipo === 'error' ? <MdError size={30} color={this.textColor} /> : props.tipo === 'info' ? <AiFillInfoCircle size={30} color={this.textColor} /> : <AiFillCheckCircle size={30} color={this.textColor} />;
    
    this.state = {
    
    };
  }

  render() {

    return (
        <section style={{ marginTop: 20, borderRadius: 10, width: '100%', backgroundColor: this.backgroundColor, padding: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
            {this.icon}
            <p style={{ marginLeft: 20, flex: 1, fontWeight: 'bold', color: this.textColor, marginTop: 0, marginBottom: 0 }}>{this.props.testo}</p>
            { this.props.buttonText && <div style={{ padding: 10, borderRadius: 5, marginLeft: 10, backgroundColor: this.textColor, cursor: 'pointer' }} onClick={() => this.props.onOpen && this.props.onOpen()}>
                <p style={{ color: 'white', fontWeight: 'bold', fontSize: 14, marginBottom: 0, marginTop: 0 }}>{this.props.buttonText}</p>
            </div> }
        </section>
    );
  }
}

