import Cookies from "universal-cookie";
import { gridOrderImage } from "./consts";
import React from "react";

function chipTemplate(stato) {
  let invioStato = stato.row.stato;
  let bgColor = "darkgrey";
  let textColor = "black";

  switch (invioStato) {
    case "Inviata":
      bgColor = "#DCFFC0";
      textColor = "#7DB154";
      break;
    case "Errore invio":
      bgColor = "#FFE1E1";
      textColor = "#FF6262";
      break;
    default:
      bgColor = "#EEEEEE";
      textColor = "#A3A3A3";
      break;
  }

  return (
    <section
      style={{
        backgroundColor: bgColor,
        height: 20,
        borderRadius: 5,
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 10,
        marginBottom: 10,
      }}
    >
      <p style={{ fontSize: 14, fontWeight: "bold", color: textColor }}>
        {invioStato}
      </p>
    </section>
  );
}

function pdfTemplate(fattura, from) {
  if (from === 'ts' && !fattura.row.pdfFattura) {
    return null;
  }
  return (
    <a
      onClick={(e) => {
        e.stopPropagation()
        if (from === 'ts') {
          const cookies = new Cookies();
          cookies.set('id_appuntamento', fattura.row.id, { path: '/' });
        }
      }}
      style={{ height: 30 }}
      href={
        from === 'fat' ?
          "https://personaldoc-app.com/apis_test/viewPdf.php?u=" + fattura.row.uuid :
          "/#/app/pdfFattura/"
      }
      // eslint-disable-next-line react/jsx-no-target-blank
      target={from === 'ts' ? null : "_blank"}
    >
      <img
        alt='pdf_icon'
        src={require("../images/pdf_icon.png")}
        style={{ cursor: "pointer", width: 30 }}
      />
    </a>
  );
}

export const pazientiTable = [
  {
    headerText: "Account",
    template: gridOrderImage,
    textAlign: "Left",
    width: "100",
  },
  {
    field: "nome",
    headerText: "Nome",
    width: "150",
    textAlign: "Center",
  },
  {
    field: "cognome",
    headerText: "Cognome",
    width: "150",
    textAlign: "Center",
  },
  {
    field: "dataNascita",
    headerText: "Data di Nascita",
    width: "150",
    textAlign: "Center",
  },
];

export const seduteTable = [
  {
    field: "data",
    headerText: "Data",
    width: "200",
    textAlign: "Center",
  },
  {
    field: "orario",
    headerText: "Orario",
    width: "200",
    textAlign: "Center",
  },
  {
    field: "tipo",
    headerText: "Presenza / Online",
    width: "200",
    textAlign: "Center",
  },
  {
    field: "fatturata",
    headerText: "Fatturata",
    width: "150",
    textAlign: "Center",
  },
];

export const clientiTable = [
  {
    field: "ragione_sociale",
    headerText: "Ragione Sociale",
    width: "250",
    textAlign: "Center",
  },
  {
    field: "telefono",
    headerText: "Telefono",
    width: "250",
    textAlign: "Center",
  },
  { field: "mail", headerText: "Email", width: "250", textAlign: "Center" },
];

export const inviiTable = [
  {
    field: "numero",
    headerName: "Num",
    width: 100,
    textAlign: "Left",
  },
  {
    field: "nome",
    headerName: "Nome",
    width: 100,
    textAlign: "Left",
  },
  {
    field: "cognome",
    headerName: "Cognome",
    width: "80",
    textAlign: "Left"
  },
  {
    field: "data",
    headerName: "Data appuntamento",
    width: 200,
    textAlign: "Left",
  },
  {
    field: "importo",
    headerName: "Importo",
    width: 100,
    textAlign: "Right",
  },
  {
    field: "stato",
    headerName: "Stato documento",
    textAlign: "Center",
    width: 150,
    height: 50,
    renderCell: (st) => chipTemplate(st),
  },
  {
    field: "linkFattura",
    headerName: "PDF",
    textAlign: "Center",
    width: 30, 
    height: 50,
    renderCell: (st) => pdfTemplate(st, 'ts'),
  }
];

export const fattureTable = [
  {
    field: "numero",
    headerName: "Num",
    textAlign: "Left",
  },
  {
    field: "ragioneSociale",
    headerName: "Ragione Sociale",
    width: 200,
    textAlign: "Left",
  },
  {
    field: "data",
    headerName: "Data emissione",
    width: 150,
    textAlign: "Left",
  },
  {
    field: "importo",
    headerName: "Importo fattura",
    width: 150,
    textAlign: "Right",
  },
  {
    field: "stato",
    headerName: "Stato invio",
    textAlign: "Center",
    width: 100,
    height: 50,
    renderCell: (st) => chipTemplate(st),
  },
  {
    field: "uuid",
    headerName: "PDF",
    textAlign: "Center",
    width: 30,
    height: 50,
    renderCell: (fat) => pdfTemplate(fat, 'fat'),
  },
];

export const utentiTable = [
  {
    field: "cognome",
    headerText: "Cognome",
    textAlign: "Left",
  },
  {
    field: "nome",
    headerText: "Nome",
    textAlign: "Left",
  },
  { field: "mail", headerText: "Email", textAlign: "Left" },
  { field: "piva", headerText: "P.IVA", textAlign: "Left" },
  { field: "numero_iscrizione", headerText: "Albo", textAlign: "Left" },
  { field: "provincia_iscrizione", headerText: "Provincia", textAlign: "Left" },
  { field: "categoria", headerText: "Tipologia", textAlign: "Left" },
  { field: "fatture_emesse", headerText: "Fatture", textAlign: "Left" },
  { field: "tipo_account", headerText: "Account", textAlign: "Left" },
];

export const pazientiAdminTable = [
  {
    field: "cognome",
    headerText: "Cognome",
    textAlign: "Left",
  },
  {
    field: "nome",
    headerText: "Nome",
    textAlign: "Left",
  },
  { field: "mail", headerText: "Email", textAlign: "Left" },
  { field: "codice_fiscale", headerText: "Cod. Fiscale", textAlign: "Left" },
  { field: "telefono", headerText: "Telefono", textAlign: "Left" },
];
