
import React from 'react';
import { getServiceName } from '../../utils/consts';
import { NineCellLoading } from 'react-loadingg';
import Cookies from 'universal-cookie';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';

// Styles 
import './style.css';
import './bootstrap.min.css';
import './owl.carousel.css';
import './owl.theme.css';
import './owl.transitions.css';
import './animate.css';
import './preloader.css';

import translateString from '../../utils/Locales';
import login from '../../services/login';
import moment from 'moment';
import ResetPasswordWidget from '../../widgets/ResetPasswordWidget';
import NavbarMain from '../../components/NavbarMain';
import loginWithGoogle from '../../services/loginWithGoogle';
import RegisterGoogleModale from '../../widgets/RegisterGoogleModale';
import { indigo } from '@material-ui/core/colors';

let t = translateString;
const qs = require('querystring');

export default class Login extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            password: '',
            username: '', 
            email: '', 
            error: false,
            errorMessage: '',
            reset: false,
            googleSignup: false,
            googleInfo: null,
        };
    }

    checkEmail(mail) {
        return (/^[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(mail));
    }

    async componentDidMount() {
        document.title = getServiceName() + ' - Login';

        window.addEventListener('resize', this.handleResize.bind(this));
        this.handleResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {

        this.setState({ bannerHeight: window.innerHeight });

        if (window.innerWidth < 767) {
            this.setState({ isMobile: true });
            return;
        }

        this.setState({ isMobile: false });
    }

    async handleLogin() {

        if (!this.state.username || !this.state.password) {
            
            this.setState({ error: true, errorMessage: t('verifica_dati') });
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 2000);
            return;
        }
   
        this.setState({ isLoading: true, error: false, errorMessage: '' });
        let res = await login(this.state.username, this.state.password);
        if (!res || res.user === null) {
            this.setState({ isLoading: false, error: true, errorMessage: t('credenziali_err') });
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 2000);
            return;
        }

        if (res.user !== null) {
            let cookies = new Cookies();

            cookies.set('username', res.username, { path: '/', expires: moment().add(5, 'days').toDate() });
            cookies.set('token', res.token, { path: '/', expires: moment().add(5, 'days').toDate() });
            cookies.set('type', res.user.type, { path: '/' });

            if (res.user.type === '0') {
                this.redirect('/#/paziente/dashboard', this.state.username, res.token);
            } else if (res.user.type === '1000') { 
                this.redirect('/#/admin/dashboard', this.state.username, res.token);
            } else {
                this.redirect('/#/app/dashboard', this.state.username, res.token);
            }
        }
    }

    redirect(destination, username, token) {
        let parse = qs.parse(window.location.hash);
        var redirect = parse['#/main/login?redirect'] === 'true';

        if (!redirect) {
            window.location.href = destination;
            return;
        }

        var to = parse['to'];
        var id = parse['id'];

        switch(to) {
            case 'prenota':
                window.location.href = '/#/main/prenota?professionista=' + id + '&u=' + username + '&t=' + token;
                break;
            default:
                break;
        }
    }

    async handleGoogleLogin(res) {
        const response = await loginWithGoogle(res.code);
        if (!response || response.token === null) {

            if (response.action === "TO_REGISTER_PERSONALDOC_1304") {
                let info = {
                    email: response.email,
                    name: response.name,
                    surname: response.surname,
                    accessToken: response.access_token,
                    refreshToken: response.refresh_token,
                    idToken: response.id_token,
                }
                this.setState({ googleInfo: info, googleSignup: true });
                return;
            }

            alert('Non è possibile trovare un account legato a questo account Google. Registrati prima oppure collegalo dopo il login con username/email e password.')
            return;
        }

        if (response.user !== null) {
            let cookies = new Cookies();

            cookies.set('username', response.username, { path: '/', expires: moment().add(5, 'days').toDate() });
            cookies.set('token', response.token, { path: '/', expires: moment().add(5, 'days').toDate() });
            cookies.set('type', response.user.type, { path: '/' });

            if (response.user.type === '0') {
                this.redirect('/#/paziente/dashboard', response.username, response.token);
            } else if (response.user.type === '1000') { 
                this.redirect('/#/admin/dashboard', response.username, response.token);
            } else {
                this.redirect('/#/app/dashboard', response.username, response.token);
            }
        }
    }

    generateSuffix() {
        // Generate URL suffix with params
        let info = this.state.googleInfo;
        if (!info) return '';
        let suffix = '?';
        suffix += 'email=' + info.email;
        suffix += '&name=' + info.name;
        suffix += '&surname=' + info.surname;
        suffix += '&accessToken=' + info.accessToken;
        suffix += '&refreshToken=' + info.refreshToken;
        suffix += '&idToken=' + info.idToken;
        return suffix;
    }

    render () {
        return (
            <div id="main_scroll" className='mainBody' style={{width: '100%', height: '100%'}}>
                <header id='home' style={{height: '100%', zIndex:999}}>
                    <NavbarMain noButtons />

                    <div className="new-banner" style={{position: 'absolute', top: 0, left: 0, width: '100vw', backgroundColor: '#626262', flex: 1, height: window.innerWidth < 768 ? 'auto' : this.state.bannerHeight }}>
                        <div className="container" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div className="row" style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                <div className="col-md-10">
                                    <div className="caption d-flex justify-content-center">
                                        { this.state.isLoading ? 
                                            <div className='loginContainer'>
                                                <NineCellLoading />
                                            </div>
                                        : 
                                        <div className="loginContainer" style={{ marginBottom: 10 }}>
                                            <div className='loginColumn'>
                                                <h1 style={{ fontFamily: `url(${require('../../fonts/Nexa.ttf')})`, fontWeight: 'bold', fontSize: 30, alignSelf: 'flex-start' }}>{t('accedi').toUpperCase()}</h1>
                                                { this.state.error && <p className='errorP' style={{ marginBottom: -20, marginTop: -5 }}>{this.state.errorMessage}</p> }
                                                <input className='inputCustom' placeholder='Username o Email' type={'text'} value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })} />

                                                <input className='inputCustom' placeholder='Password' type={'password'} value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })}
                                                    onKeyDown={(e) => {
                                                        if(e.key === 'Enter') {
                                                            this.handleLogin();
                                                        }
                                                    }}
                                                />

                                                <div className='loginButton' onClick={() => this.handleLogin()}>{t('accedi').toUpperCase()}</div>
                                                {/*<div style={{ width: '100%' }}>
                                                    <GoogleOAuthProvider clientId="574939206422-ouvhde4ttktrcohr5jbaiqelrffc54q0.apps.googleusercontent.com">
                                                        <GoogleLoginButton onLogin={this.handleGoogleLogin.bind(this)} />
                                                    </GoogleOAuthProvider>
                                                </div>*/}
                                                <p className='forgotPassword' onClick={() => this.setState({ reset: true })}>{t('password_dimenticata')}</p>
                                                <div className='separator' />
                                                <div style={{border:"none"}} className='signUpButton' onClick={() => window.location.href = '/#/main/psicologosignup'}>{t('registr_prof')}</div>
                                                <div style={{marginTop:"5px", border: "none"}} className='signUpButton' onClick={() => window.location.href = '/#/main/signup'}>Registrazione paziente</div>
                                            </div>
                                        </div> }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                { this.state.reset && (
                    <ResetPasswordWidget onEnd={() => this.setState({ reset: false })} />
                )}

                {this.state.googleSignup && (
                    <RegisterGoogleModale open handleClose={() => this.setState({ googleSignup: false })} isMobile={window.innerWidth < 768} onChoice={(type) => {
                        if (!this.state.googleInfo) return;
                        let suffix = this.generateSuffix();
                        if (type === 'professionista') {
                            window.location.href = '/#/main/psicologosignup' + suffix;
                        } else {
                            window.location.href = '/#/main/signup' + suffix;
                        }
                    }} />   
                )}
            </div>
        );
    }
}

const GoogleLoginButton = ({ onLogin }) => {

    const login = useGoogleLogin({
        onSuccess: (res) => onLogin(res),
        flow: 'auth-code',
        scope: 'profile email https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events'
    })

    return (
        <div className="googleLoginButton" onClick={login}>
            <img width="20px" style={{ marginLeft: 20, height: 30, width: 30 }} alt="Google sign-in" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/768px-Google_%22G%22_logo.svg.png" />
            <p style={{ fontWeight: 'bold', margin: 13, marginLeft: 10 }}>Accedi con Google</p>
        </div>
    )
}