import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import it from 'date-fns/locale/it';
import moment from 'moment';

registerLocale('it', it);

const ExampleCustomInput = ({ value, onClick, placeholder }) => (
    <div style={{width: '340px', height: '50px', borderBottom: '1px solid lightgrey', lineHeight: '50px', paddingLeft: '10px', background: '#ffffff', borderTop: 0, borderLeft: 0, borderRight: 0 }} onClick={onClick}>
      {value ? value : placeholder}
    </div>
  );

export default class CustomDatePicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    render() {

        return(
            <>
                { this.props.title && (
                    <span style={{ marginBottom: -20, fontSize: 10, color: 'lightgrey' }}>{this.props.title}</span>
                )}
                <DatePicker
                    selected={this.props.date}
                    onChange={(date) => this.props.onChange(date)}
                    locale="it"
                    disabledKeyboardNavigation
                    showTimeSelect
                    customInput={<ExampleCustomInput placeholder={this.props.placeholder} />}
                    dateFormat="dd-MM-yyyy HH:mm"
                />
            </>
        )
    }
}