import React from "react";
import { Box, Modal, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import { Button } from "@material-ui/core";

export default function SelectCostoModale({ open, handleClose, onUpdate, cost }) {

    const [costo, setCosto] = useState(cost);

    useEffect(() => {
        setCosto(costo);
    }, [costo]);

    const onClose = () => {
        if (handleClose) {
            handleClose();
        }
    }

    const save = async () => {

        if (!costo) {
            return;
        }

        if (onUpdate) onUpdate(costo);
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={{ flex: 1, height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} onClick={onClose}>
                <Box sx={{ backgroundColor: 'white', borderRadius: 2, maxWidth: { xs: '90%', md: 400 }, minWidth: { xs: '90%', sm: 400, md: 400}, p: 3, }} onClick={e => e.stopPropagation()}>
                    <Stack spacing={2}>

                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Costo base
                        </Typography>

                        <TextField style={{ marginTop: 20 }} fullWidth value={costo} type={'number'} label="Costo (€) *" onChange={(e) => setCosto(parseFloat(e.target.value))} />

                        <Button fullWidth size="large" type="submit" variant="contained" onClick={() => save()}>
                                Salva
                        </Button>
                    </Stack>
                </Box>
            </Box>
        </Modal>
    );
}