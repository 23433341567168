
import React from 'react';
import Cookies from 'universal-cookie';
import Stacked from '../components/Charts/Stacked';
import translateString from '../utils/Locales';

import '../index.css';
import Loading from '../components/Loading';
import getSedute from '../services/getSedute';
import moment from 'moment';
import PieChart from '../components/Charts/PieChart';
import { getServiceName } from '../utils/consts';
import { Card, Typography, Box } from '@mui/material';
import { Stack } from '@mui/material'
import getHomepageStats from '../services/getHomepageStats';
import checkAlerts from '../services/checkAlerts';

const cookies = new Cookies();
let t = translateString;

export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.data = [
            {
                dataSource: [ ], 
                xName: 'x',
                yName: 'y', 
                name: 'Fatturato', 
                type: 'StackingColumn', 
                background: 'blue' 
            }     
        ];

        this.pieData = [
            { x: 'Appuntamenti', y: 0, text: '0%' },
            { x: 'Eventi/Fatture', y: 0, text: '0%' }, 
        ];

        this.state = {
            isLoading: true,
            alerts: [],
        };
    }

    async componentDidMount() {

        window.document.title = getServiceName() + ' - Dashboard';

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        this.sedute = await getSedute(username, token, "psicologo");   

        this.sedute.forEach((seduta, ind) => {
            var sed = seduta;
            sed.data = moment(sed.inizio, 'yyyy-MM-DDTHH:mm').toDate();
            this.sedute[ind] = sed;
        });

        this.sedute = this.sedute.sort((s1, s2) => s1.data.getTime() < s2.data.getTime());
        this.sedute = this.sedute.filter((s) => (s.data.getTime() >= moment().toDate().getTime() && moment(s.data).format('yyyy-MM-DD') === moment().format('yyyy-MM-DD')));
    
        this.stats = await getHomepageStats(username, token);

        const alerts = await checkAlerts(username, token);

        this.data = [
            {
                dataSource: [
                    { x: 'Gen', y: parseFloat(this.stats.fatturato[0])},
                    { x: 'Feb', y: parseFloat(this.stats.fatturato[1]) },
                    { x: 'Mar', y: parseFloat(this.stats.fatturato[2]) },
                    { x: 'Apr', y: parseFloat(this.stats.fatturato[3]) },
                    { x: 'Mag', y: parseFloat(this.stats.fatturato[4]) },
                    { x: 'Giu', y: parseFloat(this.stats.fatturato[5]) },
                    { x: 'Lug', y: parseFloat(this.stats.fatturato[6]) },
                    { x: 'Ago', y: parseFloat(this.stats.fatturato[7]) },
                    { x: 'Set', y: parseFloat(this.stats.fatturato[8]) },
                    { x: 'Ott', y: parseFloat(this.stats.fatturato[9]) },
                    { x: 'Nov', y: parseFloat(this.stats.fatturato[10]) },
                    { x: 'Dic', y: parseFloat(this.stats.fatturato[11]) }
                ], 
                xName: 'x',
                yName: 'y', 
                name: 'Fatturato', 
                type: 'StackingColumn', 
                background: 'blue' 
            }     
        ];

        const total = this.stats.suddivisione[0] + this.stats.suddivisione[1];
        const percent1 = ((this.stats.suddivisione[0] / total) * 100).toFixed(2);
        const percent2 = ((this.stats.suddivisione[1] / total) * 100).toFixed(2);

        this.pieData = [
            { x: 'Appuntamenti', y: percent1, text: percent1 + ' %' },
            { x: 'Eventi/Fatture', y: percent2, text: percent2 + ' %' }, 
        ];
        
        this.setState({ isLoading: false, alerts });
    }

    render() {
        if (this.state.isLoading) return <Loading />;
        return(
            <>
                {this.state.alerts.length > 0 && (
                    <Stack direction={'column'} spacing={2} style={{ marginBottom: 15 }}>
                        {this.state.alerts.map((alert) => {
                            return (
                                <Card key={alert.title} style={{ background: "none", borderRadius: 10 }}>
                                    <Box sx={{ backgroundColor: "#F6AB2Ed4", p: 4, display: 'flex', boxShadow: 'inset 0px 21px 31px 9px rgb(255 255 255 / 19%)' }}>
                                        <Stack direction={'row'} spacing={2}>
                                            <img src={require('../images/caution.png')} alt='Warning' style={{ width: 30, height: 30 }} />
                                            <Stack>
                                                <Typography fontWeight={300} fontSize={20} color={'black'}>{alert.title}</Typography>
                                                <Typography fontSize={16} color={'black'}>{alert.description}</Typography> 
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </Card>
                            )
                        })}
                    </Stack>
                )}
                <Card style={{background:"none", borderRadius:10}}>
                    <Box sx={{ backgroundColor:"#121212d4", p: 4, display: 'flex', boxShadow:'inset 0px 21px 31px 9px rgb(255 255 255 / 19%)' }}>
                        <Stack direction={'row'}>
                            <Stack>
                                <Typography fontWeight={500} fontSize={25} color={'white'}>Benvenuto</Typography>
                                <Typography style={{ marginBottom: 15 }} fontSize={16} color={'lightgrey'}>Ecco il tuo prossimo appuntamento</Typography>
                                { this.sedute.length > 0 ? 
                                    <section className='seduta'>
                                        <p style={{ fontWeight: 'bold', color: 'white' }}>{this.sedute[0].cognome} {this.sedute[0].nome}</p>
                                        <p style={{ color: 'white', marginTop: -15, fontSize: 12 }}>{moment(this.sedute[0].data).format('DD/MM/yyyy - HH:mm')}</p>
                                    </section>
                                :
                                    <section className='seduta'>
                                        <p style={{ color: '#BEBEBE' }}>{t('no_sedute')}</p>
                                    </section>
                                }  
                            </Stack>
                        </Stack>
                    </Box>
                </Card>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
                    <section className='dashview' style={{ marginRight: 10 }}>
                        <h5>Le tue entrate</h5><br/>
                        <PieChart height={'200px'} data={this.pieData} nome={t('entrate')} />
                    </section>
                    <section className='dashview' style={{ marginLeft: 10 }}>
                        <h5>Il tuo fatturato</h5><br/>
                        <Stacked width={'100%'} height={'200px'} elements={this.data} />
                    </section>
                </div>
                
            </>
        );
    }
}