import React from 'react';
import Cookies from 'universal-cookie';
import Button from '../components/Button';
import translateString from '../utils/Locales';
import { HiOutlineIdentification } from 'react-icons/hi';
import { BiRename, BiCurrentLocation } from 'react-icons/bi';
import { FaLocationArrow, FaCity } from 'react-icons/fa';
import { AiOutlineBarcode, AiOutlineMail, AiOutlinePhone, AiOutlineCalendar } from 'react-icons/ai';
import states from '../utils/states.json';
import province from '../utils/regioni_province.json';
import SelectInput from '../widgets/SelectInput';
import { GiSouthAmerica } from 'react-icons/gi';
import { BsGenderAmbiguous } from 'react-icons/bs';
import addPaziente from '../services/addPaziente';
import { getServiceName } from '../utils/consts';
import Switch from 'react-switch';
import { Checkbox, Stack } from '@mui/material';

const cookies = new Cookies();
let t = translateString;

export default class AddPaziente extends React.Component {
    constructor(props) {
        super(props);

        this.saveRef = React.createRef();

        this.lista = [];
        states.forEach((stato) => {
            this.lista.push({ value: stato.iso3361_2_characters, label: stato.italian_country_name_1 ? stato.italian_country_name_1.toUpperCase() : stato.italian_country_name_2 ? stato.italian_country_name_2 : stato.english_country_name.toUpperCase() });
        });

        this.province = [];
        province['regioni'].forEach((regione) => {
            province[regione].forEach((provincia) => {
                this.province.push({ value: provincia.sigla, label: provincia.nome });
            });
        });

        this.generi = [
            { value: 'M', label: 'M' }, 
            { value: 'F', label: 'F' }
        ]

        this.province.sort((p1, p2) => p1.label.localeCompare(p2.label));

        window.document.title = getServiceName() + ' - Nuovo paziente';

        this.state = {
            isLoading: false, 
            nome: '', 
            cognome: '', 
            genere: { value: '', label: 'M/F... (*)' },
            codice_fiscale: '',
            data_nascita: '', 
            indirizzo: '', 
            citta: '',
            cap: '', 
            stato: { value: '', label: 'Stato...'},
            provincia: { value: '', label: 'Provincia...'},
            mail: '', 
            telefono: '',
            error: false, 
            errorMessage: '',
            invio_ts: false, 
            pagamentoAnticipato: false,
        };
    }

    checkCodiceFiscale() {
        
        if (isNaN(this.state.codice_fiscale) && this.state.codice_fiscale.length === 16) {
            return true;
        }

        return false;
    }

    async save() {

        if (!this.state.nome || !this.state.cognome || !this.state.genere.value) {
            this.setState({ error: true, errorMessage: t('verifica_dati') });
            setTimeout(() => {
                this.saveRef.current.stopAnimating();
            }, 200);
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 3000);
            return;
        }

        if (!this.state.codice_fiscale || !this.checkCodiceFiscale()) {
            this.setState({ error: true, errorMessage: t('verifica_codice_fiscale') });
            setTimeout(() => {
                this.saveRef.current.stopAnimating();
            }, 200);
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 5000);
            return;
        }

        const paziente = {
            nome: this.state.nome.trim(), 
            cognome: this.state.cognome.trim(),
            genere: this.state.genere.value,
            fiscale: this.state.codice_fiscale.trim(), 
            data: this.state.data_nascita,
            indirizzo: this.state.indirizzo, 
            citta: this.state.citta, 
            cap: this.state.cap, 
            stato: this.state.stato.value, 
            provincia: this.state.provincia.value, 
            mail: this.state.mail.trim(), 
            telefono: this.state.telefono, 
            invio_ts: this.state.invio_ts ? 1 : 0, 
            pagamentoAnticipato: this.state.pagamentoAnticipato ? 1 : 0, 
        }

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        let res = await addPaziente(username, token, paziente);
        if (!res || res.result === 'KO') {
            
            if (res.description.includes('codice fiscale')) {
                this.setState({ error: true, errorMessage: t('errore_cf') });
                setTimeout(() => {
                    this.saveRef.current.stopAnimating();
                }, 200);
                setTimeout(() => {
                    this.setState({ error: false, errorMessage: '' });
                }, 5000);
                return;
            }

            // Error
            this.setState({ error: true, errorMessage: t('errore_imprevisto') });
            setTimeout(() => {
                this.saveRef.current.stopAnimating();
            }, 200);
            setTimeout(() => {
                this.setState({ error: false, errorMessage: '' });
            }, 5000);
            return;
        }

        window.location.href = '/#/app/pazienti';

    }

    render() {
        
        return(
            <div style={{ marginBottom: 100 }} className="pagePaziente">
                <div className="top-with-btn" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 20 }}>
                    <div style={{ marginRight: 20, marginTop: 10 }}>
                        <h3 className="text-light">{t('nuovo_paziente')}</h3>
                    </div>
                    <Button ref={this.saveRef} button={t('salva_agg')} animated={true} onClick={() => this.save()} />
                </div>
                { this.state.error && (
                    <div className='error-div'>
                        {this.state.errorMessage}
                    </div>
                )}
                <div style={{ marginTop: 50, padding: 30 }} className='dashview'>
                    <h6 style={{ color: 'white', marginBottom: 30 }}>{t('dati')}</h6>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <BiRename color='white' />
                        <section>
                            <input style={{ marginLeft: 10, border: 0, borderBottom: '1px solid lightgrey', height: '20px', color: '#5C5C5C', paddingLeft: 10, paddingRight: 10, minWidth: 200 }} type={'text'} placeholder={t('nome') + ' *'} value={this.state.nome} onChange={(e) => this.setState({ nome: e.target.value })} />
                            <input style={{ marginLeft: 10, border: 0, borderBottom: '1px solid lightgrey', height: '20px', color: '#5C5C5C', paddingLeft: 10, paddingRight: 10, minWidth: 200 }} type={'text'} placeholder={t('cognome') + ' *'} value={this.state.cognome} onChange={(e) => this.setState({ cognome: e.target.value })} />
                        </section>
                    </div>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <BsGenderAmbiguous color='white' />
                                <SelectInput 
                                    value={this.state.genere} 
                                    hidable={false}
                                    isMulti={false}
                                    list={true}
                                    options={this.generi} 
                                    style={{ marginLeft: 10, marginTop: 5, width: 200, color: 'grey' }}
                                    onChange={(option) => this.setState({ genere: option })} 
                                />
                            </div>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <HiOutlineIdentification color='white' />
                        <section>
                            <input style={{ marginLeft: 10, border: 0, borderBottom: '1px solid lightgrey', height: '20px', color: 'grey', paddingLeft: 10, paddingRight: 10, minWidth: 200 }} type={'text'} placeholder={t('codice_fiscale') + ' *'} value={this.state.codice_fiscale} onChange={(e) => this.setState({ codice_fiscale: e.target.value.toUpperCase() })} />
                        </section>
                    </div>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <AiOutlineCalendar color='white' />
                        <section>
                            <input style={{ marginLeft: 10, border: 0, borderBottom: '1px solid lightgrey', height: '20px', color: 'grey', paddingLeft: 10, paddingRight: 10, minWidth: 200 }} type={!this.state.onDate ? 'text' : 'date'} placeholder={t('data_nascita')} onFocus={() => this.setState({ onDate: true })} value={this.state.data_nascita} onChange={(e) => this.setState({ data_nascita: e.target.value })} />
                        </section>
                    </div>
                    <label style={{ marginTop: 30, marginLeft: 5 }}>
                        <span style={{ color: 'white' }}>Invia fatture al Sistema Tessera Sanitaria</span>
                        <Switch uncheckedIcon={false} checkedIcon={false} className="switch" onColor='#7B90C4' width={40} height={20} onChange={(checked) => this.setState({ invio_ts: checked })} checked={this.state.invio_ts} />
                    </label>
                    <Stack direction='row' style={{ marginLeft: -10, marginTop: -10, marginBottom: 0 }}>
                        <Checkbox style={{ color: 'white' }} key={Math.random()} checked={this.state.pagamentoAnticipato} onChange={() => this.setState({ pagamentoAnticipato: !this.state.pagamentoAnticipato })} />
                        <p style={{ marginTop: 18, color: 'white' }}>Richiedi il pagamento anticipato degli appuntamenti (fino a 3gg prima dell'inizio)</p>
                    </Stack>
                </div>
                <div style={{ marginTop: 20, padding: 30 }} className='dashview'>
                    <h6 style={{ color: 'white', marginBottom: 30 }}>{t('indirizzo')}</h6>
                    <section className='horizontal'>
                        <section>
                            <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <FaLocationArrow color='white' />
                                <input style={{ marginLeft: 10, border: 0, borderBottom: '1px solid lightgrey', height: '20px', color: 'grey', paddingLeft: 10, paddingRight: 10, minWidth: 250 }} type={'text'} placeholder={t('indirizzo')} value={this.state.indirizzo} onChange={(e) => this.setState({ indirizzo: e.target.value })} />
                            </div>
                            <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <FaCity color='white' />
                                <input style={{ marginLeft: 10, border: 0, borderBottom: '1px solid lightgrey', height: '20px', color: 'grey', paddingLeft: 10, paddingRight: 10, minWidth: 250 }} type={'text'} placeholder={t('citta')} value={this.state.citta} onChange={(e) => this.setState({ citta: e.target.value })} />
                            </div>
                            <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <AiOutlineBarcode color='white' />
                                <input style={{ marginLeft: 10, border: 0, borderBottom: '1px solid lightgrey', height: '20px', color: 'grey', paddingLeft: 10, paddingRight: 10, minWidth: 250 }} type={'text'} placeholder={t('cap')} value={this.state.cap} onChange={(e) => this.setState({ cap: e.target.value })} />
                            </div>
                        </section>
                        <section className='margin-l-horizontal mt-10'>
                            <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <GiSouthAmerica color='white' />
                                <SelectInput 
                                    value={this.state.stato} 
                                    hidable={false}
                                    isMulti={false}
                                    list={true}
                                    options={this.lista} 
                                    style={{ marginLeft: 10, marginTop: 5 }}
                                    onChange={(option) => this.setState({ stato: option })} 
                                />
                            </div>
                            { this.state.stato.value === 'IT' && <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <BiCurrentLocation color='white' />
                                <SelectInput 
                                    value={this.state.provincia} 
                                    hidable={false}
                                    isMulti={false}
                                    list={true}
                                    options={this.province} 
                                    style={{ marginLeft: 10, marginTop: 5 }}
                                    onChange={(option) => this.setState({ provincia: option })} 
                                /> 
                            </div> }
                        </section>
                    </section>
                </div>
                <div style={{ marginTop: 20, padding: 30 }} className='dashview'>
                    <h6 style={{ color: 'white', marginBottom: 30 }}>{t('contatti')}</h6>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <AiOutlineMail color='white' />
                        <input style={{ marginLeft: 10, border: 0, borderBottom: '1px solid lightgrey', height: '20px', color: 'grey', paddingLeft: 10, paddingRight: 10, minWidth: 250 }} type={'text'} placeholder={t('email')} value={this.state.mail} onChange={(e) => this.setState({ mail: e.target.value })} />
                    </div>
                    <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <AiOutlinePhone color='white' />
                        <input style={{ marginLeft: 10, border: 0, borderBottom: '1px solid lightgrey', height: '20px', color: 'grey', paddingLeft: 10, paddingRight: 10, minWidth: 250 }} type={'text'} placeholder={t('telefono')} value={this.state.telefono} onChange={(e) => this.setState({ telefono: e.target.value })} />
                    </div>
                </div>
            </div>  
        );
    }
}