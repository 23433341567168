
import React from 'react';
import Cookies from 'universal-cookie';
import Stacked from '../components/Charts/Stacked';
import translateString from '../utils/Locales';

import '../index.css';
import Loading from '../components/Loading';
import getSedute from '../services/getSedute';
import moment from 'moment';
import PieChart from '../components/Charts/PieChart';
import { getServiceName } from '../utils/consts';
import { Card, Typography, Box } from '@mui/material';
import { Stack } from '@mui/material'
import getHomepageStats from '../services/getHomepageStats';
import MainSearch from '../components/MainSearch';

const cookies = new Cookies();
let t = translateString;

export default class DashboardPaziente extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true, 
            filter:0,
        };
    }

    async componentDidMount() {

        window.document.title = getServiceName() + ' - Dashboard';

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        this.sedute = await getSedute(username, token, "paziente");   

        this.sedute.forEach((seduta, ind) => {
            var sed = seduta;
            sed.data = moment(sed.inizio, 'yyyy-MM-DDTHH:mm').toDate();
            this.sedute[ind] = sed;
        });

        this.sedute = this.sedute.sort((s1, s2) => s1.data.getTime() < s2.data.getTime());
        this.sedute = this.sedute.filter((s) => (s.data.getTime() >= moment().toDate().getTime() && moment(s.data).format('yyyy-MM-DD') === moment().format('yyyy-MM-DD')));
        
        this.setState({ isLoading: false });
    }

    render() {
        if (this.state.isLoading) return <Loading />;
        return(
            <>
                <Card style={{background:"none", borderRadius:10, boxShadow: "none"}}>
                    <Box sx={{borderRadius:5, backgroundColor:"#121212d4", p: 4, display: 'flex', boxShadow:'inset 0px 21px 31px 9px rgb(255 255 255 / 19%)' }}>
                        <Stack direction={'row'}>
                            <Stack>
                                <Typography fontWeight={500} fontSize={25} color={'white'}>Benvenuto</Typography>
                                <Typography style={{ marginBottom: 15 }} fontSize={16} color={'lightgrey'}>Ecco il tuo prossimo appuntamento</Typography>
                                { this.sedute.length > 0 ? 
                                    <section className='seduta'>
                                        <p style={{ fontWeight: 'bold', color: 'white' }}>{this.sedute[0].cognome} {this.sedute[0].nome}</p>
                                        <p style={{ color: 'white', marginTop: -15, fontSize: 12 }}>{moment(this.sedute[0].data).format('DD/MM/yyyy - HH:mm')}</p>
                                    </section>
                                :
                                    <section className='seduta'>
                                        <p style={{ color: '#BEBEBE' }}>{t('no_sedute')}</p>
                                    </section>
                                }  
                            </Stack>
                            
                        </Stack>
                    </Box>
                    <Box sx={{borderRadius:5, marginTop:"20px", marginBottom: "40px", backgroundColor:"#121212d4", p: 4, display: 'flex', boxShadow:'inset 0px 21px 31px 9px rgb(255 255 255 / 19%)' }}>
                        <Stack direction={'row'}>
                            <Stack>
                                <Typography fontWeight={500} fontSize={25} color={'white'}>Prenota il tuo prossimo appuntamento</Typography>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', marginTop: '10px'}}>
                                    <p style={{ color: 'white', fontWeight: this.state.filter === 0 ? 'bold' : 'normal', textDecoration: this.state.filter === 0 ? 'underline' : 'none', cursor: 'pointer' }} onClick={() => this.setState({ filter: 0 })}>PROFESSIONISTA</p>
                                    <p style={{ marginLeft: '20px', color: 'white', fontWeight: this.state.filter === 1 ? 'bold' : 'normal', textDecoration: this.state.filter === 1 ? 'underline' : 'none', cursor: 'pointer'  }} onClick={() => this.setState({ filter: 1 })}>SPECIALIZZAZIONE</p>
                                    <p style={{ marginLeft: '20px', color: 'white', fontWeight: this.state.filter === 3 ? 'bold' : 'normal', textDecoration: this.state.filter === 3 ? 'underline' : 'none', cursor: 'pointer'  }} onClick={() => this.setState({ filter: 3 })}>LUOGO</p>                                   
                                    <p style={{ marginLeft: '20px', color: 'white', fontWeight: this.state.filter === 2 ? 'bold' : 'normal', textDecoration: this.state.filter === 2 ? 'underline' : 'none', cursor: 'pointer'  }} onClick={() => this.setState({ filter: 2 })}>{t('online')}</p>
                                </div>
                                <MainSearch type={ this.state.filter } /> 
                            </Stack>
                            
                        </Stack>
                    </Box>
                </Card>
                
            </>
        );
    }
}