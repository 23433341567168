import React from 'react';
import { getServiceName } from '../utils/consts';
import Cookies from 'universal-cookie';
import { Box, Card, Container } from '@material-ui/core';

const cookies = new Cookies();

export default class AbbonamentoError extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,  
            data: undefined,
            editSeduta: false,
            evento: null, 
        };
    }

    render() {

        if (this.props.impostazioniTS)
            return(
                <Container style={{ width: '100%', height: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: -100 }}>
                    <Card style={{ backgroundColor: 'white', borderRadius: 5, padding: 40, textAlign: 'center' }}>
                        <h3>Informazioni mancanti</h3>
                        <h6 style={{ marginTop: 20 }}>Per poter utilizzare questa sezione del gestionale devi prima compilare i tuoi dati relativi al Sistema Tessera Sanitaria nella pagina impostazioni.</h6>
                    </Card>
                </Container>
            );

        return(
            <Container style={{ width: '100%', height: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: -100 }}>
                <Card style={{ backgroundColor: 'white', borderRadius: 5, padding: 40, textAlign: 'center' }}>
                    <h3>Sezione non disponibile</h3>
                    <h6 style={{ marginTop: 20 }}>Stai utilizzando la prova gratuita di PersonalDoc, per poter usufrure della funzione richiesta è necessario sottoscrivere un piano di abbonamento. Scopri di più nella pagina del tuo account.</h6>
                </Card>
            </Container>
        );
    }
}