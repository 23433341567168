import React from "react";
import Cookies from "universal-cookie";
import Constants from "../utils/consts";

const cookies = new Cookies();

export default class PdfFattura extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      idAppuntamento: undefined,
    };
  }

  async componentDidMount() {
    const idAppuntamento = cookies.get("id_appuntamento", { path: "/" });
    if (!idAppuntamento)
      window.location.href = window.location.href.includes("app")
        ? "/#/app/agenda"
        : "/#/paziente/agenda";

    this.setState({
      idAppuntamento,
    });
  }

  render() {
    if (!this.state.idAppuntamento) return <></>;

    return (
      <iframe
        src={
          "https://personaldoc-app.com" +
          Constants.apisFolder +
          "fatture/fattura_" +
          this.state.idAppuntamento +
          ".pdf"
        }
        width="100%"
        style={{ height: "100%", marginLeft: -50 }}
      />
    );
  }
}
