import React from "react";
import Cookies from "universal-cookie";
import Button from "../components/Button";
import ImagePicker from "../components/ImagePicker";
import Loading from "../components/Loading";
import getAccountInfo from "../services/getAccountInfo";
import getPaginaPersonale from "../services/getPaginaPersonale";
import translateString from "../utils/Locales";
import SelectInput from "../widgets/SelectInput";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { BsPhone } from "react-icons/bs";
import ReactHtmlParser from "react-html-parser";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import Tick from "../images/tick.png";

import "../index.css";
import createPaginaPersonale from "../services/createPaginaPersonale";
import savePaginaPersonale from "../services/savePaginaPersonale";
import { getServiceName } from "../utils/consts";

const cookies = new Cookies();
let t = translateString;

export default class PaginaPersonale extends React.Component {
  constructor(props) {
    super(props);

    this.titoli = [
      { value: "0", label: t("titolo") },
      { value: "1", label: t("titolo_m") },
      { value: "2", label: t("titolo_f") },
    ];

    this.saveRef = React.createRef();
    this.textRef = React.createRef();
    this.pubblicaRef = React.createRef();

    this.state = {
      isLoading: true,
      foto: "",
      titolo: { value: "0", label: t("titolo") },
      desc: "",
      mail: "",
      telefono: "",
      cellulare: "",
      indirizzo: "",
      show_piani: "0",
      show_servizi: "0",
      pubblicata: "0",
      error: false,
      errorMessage: "",
    };
  }

  async componentDidMount() {
    window.document.title = getServiceName() + " - La tua pagina";

    let username = cookies.get("username", { path: "/" });
    let token = cookies.get("token", { path: "/" });

    this.utente = await getAccountInfo(username, token, "psicologo");
    if (!this.utente) return;

    this.pagina = await getPaginaPersonale(this.utente.indirizzo_pagina);
    console.log(this.pagina);
    if (this.pagina.result === "KO") {
      this.setState({ isLoading: false, mail: this.utente.mail });
    } else {
      this.setState({
        isLoading: false,
        foto: this.pagina.foto,
        titolo: {
          value: this.pagina.titolo,
          label:
            this.pagina.titolo === "0"
              ? t("titolo")
              : this.pagina.titolo === "1"
              ? t("titolo_m")
              : t("titolo_f"),
        },
        mail: this.pagina.mail,
        telefono: this.pagina.telefono,
        cellulare: this.pagina.cellulare,
        indirizzo: this.pagina.indirizzo,
        show_piani: this.pagina.show_piani,
        show_servizi: this.pagina.show_servizi,
        pubblicata: this.pagina.pubblicata,
      });
    }
  }

  async save() {
    if (this.textRef.current.getHtml().toString() === "<p><br></p>") {
      this.showErrorMessage(t("descrizione_err"));
      return;
    }

    let pag = {
      foto: this.state.foto,
      titolo: this.state.titolo.value,
      descrizione: this.textRef.current.getHtml().toString(),
      mail: this.state.mail,
      telefono: this.state.telefono,
      cellulare: this.state.cellulare,
      indirizzo: this.state.indirizzo,
      show_piani: parseInt(this.state.show_piani),
      show_servizi: parseInt(this.state.show_servizi),
      pubblicata: this.pagina.result === "KO" ? 0 : this.pagina.pubblicata,
    };

    let username = cookies.get("username", { path: "/" });
    let token = cookies.get("token", { path: "/" });

    if (this.pagina.result === "KO") {
      let res = await createPaginaPersonale(username, token, pag);
      console.log(res);
      if (!res || res.result === "KO") {
        this.showErrorMessage(t("errore_salva"));
        return;
      }

      window.location.reload();
    } else {
      let res = await savePaginaPersonale(username, token, pag);
      console.log(res);
      if (!res || res.result === "KO") {
        this.showErrorMessage(t("errore_salva"));
        return;
      }

      window.location.reload();
    }
  }

  showErrorMessage(message) {
    setTimeout(() => {
      this.saveRef.current.stopAnimating();
    }, 200);

    this.setState({ error: true, errorMessage: message });

    setTimeout(() => {
      this.setState({ error: false, errorMessage: "" });
    }, 4000);
  }

  async pubblica() {
    if (this.textRef.current.getHtml().toString() === "<p><br></p>") {
      this.showErrorMessage(t("descrizione_err"));
      return;
    }

    let pag = {
      foto: this.state.foto,
      titolo: this.state.titolo.value,
      descrizione: this.textRef.current.getHtml().toString(),
      mail: this.state.mail,
      telefono: this.state.telefono,
      cellulare: this.state.cellulare,
      indirizzo: this.state.indirizzo,
      show_piani: parseInt(this.state.show_piani),
      show_servizi: parseInt(this.state.show_servizi),
      pubblicata: 1,
    };

    let username = cookies.get("username", { path: "/" });
    let token = cookies.get("token", { path: "/" });

    let res = await savePaginaPersonale(username, token, pag);
    if (!res || res.result === "KO") {
      this.showErrorMessage(t("errore_salva"));
      return;
    }

    window.location.reload();
  }

  render() {
    if (this.state.isLoading) return <Loading />;

    return (
      <>
        <div
          className="top-with-btn"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <div style={{ marginRight: 20, marginTop: 10 }}>
            <h3 style={{ color: "white" }}>{t("tua_pagina")}</h3>
            <p style={{ color: "white" }}>{t("info_pagina")}</p>
          </div>
          <Button
            ref={this.saveRef}
            button={t("salva")}
            animated={true}
            onClick={() => this.save()}
          />
        </div>
        {this.state.error && (
          <div className="error-div">{this.state.errorMessage}</div>
        )}
        {!this.pagina.result && this.pagina.pubblicata === "0" && (
          <div className="error-div">
            <h5>{t("non_pubblicata")}</h5>
            <p>{t("non_pubblicata_info")}</p>
            <div style={{ width: "100px" }}>
              <Button
                ref={this.pubblicaRef}
                button={t("pubblica")}
                animated={true}
                onClick={() => this.pubblica()}
              />
            </div>
          </div>
        )}
        <div
          style={{ display: "flex", flexDirection: "column", marginTop: 30 }}
        >
          {this.pagina.pubblicata === "1" && (
            <section
              className="white-view"
              style={{ flex: 1, marginBottom: 10 }}
            >
              <h6 style={{ color: "white" }}>
                La tua pagina personale si trova all'indirizzo:
              </h6>
              <h5 style={{ color: "white", marginBottom: -5 }}>
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  href={
                    "https://personaldoc-app.com/#/info/" +
                    this.utente.indirizzo_pagina
                  }
                  target="_blank"
                >
                  https://www.personaldoc-app.com/#/info/
                  {this.utente.indirizzo_pagina}
                </a>
              </h5>
            </section>
          )}
          <section className="white-view" style={{ flex: 1 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <section
                style={{
                  width: "25%",
                  position: "relative",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <ImagePicker
                  value={this.pagina.result ? undefined : this.pagina.foto}
                  style={{ marginLeft: "calc((100% - 140px)/2)" }}
                  onChange={(img) => {
                    this.setState({ foto: img });
                  }}
                />
              </section>
              <section style={{ width: "75%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <SelectInput
                    value={this.state.titolo}
                    small={true}
                    style={{ width: 120, marginTop: 0 }}
                    hidable={false}
                    isMulti={false}
                    options={this.titoli}
                    onChange={(option) => this.setState({ titolo: option })}
                  />
                  <h5 style={{ color: "white", marginTop: 5, marginLeft: 20 }}>
                    {this.utente.cognome} {this.utente.nome}
                  </h5>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <AiOutlineMail color="white" />
                  <input
                    placeholder="Email"
                    style={{
                      marginLeft: 10,
                      borderRadius: 5,
                      border: 0,
                      borderBottom: "1px solid lightgrey",
                      height: "20px",
                      color: "grey",
                      paddingLeft: 10,
                      paddingRight: 10,
                      minWidth: 200,
                    }}
                    value={this.state.mail}
                    onChange={(e) => this.setState({ mail: e.target.value })}
                  />
                </div>
                <div
                  style={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <AiOutlinePhone color="white" />
                  <input
                    placeholder="Telefono"
                    style={{
                      marginLeft: 10,
                      borderRadius: 5,
                      border: 0,
                      borderBottom: "1px solid lightgrey",
                      height: "20px",
                      color: "grey",
                      paddingLeft: 10,
                      paddingRight: 10,
                      minWidth: 200,
                    }}
                    value={this.state.telefono}
                    onChange={(e) =>
                      this.setState({ telefono: e.target.value })
                    }
                  />
                </div>
                <div
                  style={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <BsPhone color="white" />
                  <input
                    placeholder="Cellulare"
                    style={{
                      marginLeft: 10,
                      borderRadius: 5,
                      border: 0,
                      borderBottom: "1px solid lightgrey",
                      height: "20px",
                      color: "grey",
                      paddingLeft: 10,
                      paddingRight: 10,
                      minWidth: 200,
                    }}
                    value={this.state.cellulare}
                    onChange={(e) =>
                      this.setState({ cellulare: e.target.value })
                    }
                  />
                </div>
              </section>
            </div>
          </section>
          <section className="white-view" style={{ marginTop: 10, flex: 1 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <section
                className="pagina-sections"
                style={{ marginRight: 20 }}
                onClick={() =>
                  this.setState({
                    show_piani: this.state.show_piani === "0" ? "1" : "0",
                  })
                }
              >
                <div
                  style={{
                    position: "absolute",
                    top: 20,
                    left: 20,
                    backgroundColor: "white",
                    width: 30,
                    height: 30,
                    borderRadius: 15,
                  }}
                >
                  {this.state.show_piani === "1" && (
                    <img src={Tick} style={{ width: "100%", height: "100%" }} />
                  )}
                </div>
                <h5 className="text-light">{t("mostra_pacchetti")}</h5>
                <p className="text-light">{t("pacchetti_info")}</p>
              </section>
              <section
                className="pagina-sections"
                style={{ marginLeft: 20 }}
                onClick={() =>
                  this.setState({
                    show_servizi: this.state.show_servizi === "0" ? "1" : "0",
                  })
                }
              >
                <div
                  style={{
                    position: "absolute",
                    top: 20,
                    left: 20,
                    backgroundColor: "white",
                    width: 30,
                    height: 30,
                    borderRadius: 15,
                  }}
                >
                  {this.state.show_servizi === "1" && (
                    <img src={Tick} style={{ width: "100%", height: "100%" }} />
                  )}
                </div>
                <h5 className="text-light">{t("mostra_servizi")}</h5>
                <p className="text-light">{t("servizi_info")}</p>
              </section>
            </div>
          </section>
          <section
            className="white-view"
            style={{ marginTop: 10, flex: 1, marginBottom: 200 }}
          >
            <h5 className="text-light">{t("descrizione")}</h5>
            <p className="text-light" style={{ paddingBottom: 15 }}>
              {t("desc_info")}
            </p>
            <RichTextEditorComponent
              ref={this.textRef}
              style={{ border: 0 }}
              enableAutoUrl={true}
              maxLength={1000}
              pasteCleanupSettings={{ keepFormat: true }}
              toolbarSettings={{
                items: [
                  "Bold",
                  "Italic",
                  "Underline",
                  "|",
                  "Formats",
                  "Alignments",
                  "OrderedList",
                  "UnorderedList",
                  "|",
                  "CreateLink",
                  "|",
                  "Undo",
                  "Redo",
                ],
              }}
              backgroundColor={"white"}
            >
              <Inject
                services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}
              />
              {!this.pagina.result && ReactHtmlParser(this.pagina.descrizione)}
            </RichTextEditorComponent>
          </section>
        </div>
      </>
    );
  }
}
