import React from 'react';
import { BsPersonFill, BsFillPeopleFill } from 'react-icons/bs';
import { HiUserGroup } from 'react-icons/hi';
import { AiFillSchedule } from 'react-icons/ai';

export default class IconaPacchetto extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {

        return(
            <>
                { this.props.tipo === 'singola' ? (
                        <BsPersonFill color='#FFFFFF80' size={30} />
                    ) : this.state.tipo === 'doppia' ? (
                        <BsFillPeopleFill color='#FFFFFF80' size={30} />
                    ) : this.state.tipo === 'gruppo' ? (
                        <HiUserGroup color='#FFFFFF80' size={30} />
                    ) : (
                        <AiFillSchedule color='#FFFFFF80' size={30} />
                )}
            </>
        );
    }
}