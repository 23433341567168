import React from 'react';

export default class SingleLoading extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            opacity: 1.0,
        };
    }

    componentDidMount() {

        setTimeout(() => {
            this.interval = setInterval(() => {

                if (this.state.opacity === 0.2) {
                    this.setState({ opacity: 1.0 });
                } else {
                    this.setState({ opacity: 0.2 });
                }
            }, 500);
        }, Math.floor(Math.random() * 1000));
    } 

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {

        return(
            <section style={{ opacity: this.state.opacity, marginTop: 10, paddingLeft: 15, paddingRight: 15 }} className='loading-item'>
                <div style={{ height: 15, width: '70%', marginTop: 10, backgroundColor: '#F0F0F0', borderRadius: 4 }} />
                <div style={{ height: 10, width: '90%', marginTop: 10, backgroundColor: '#F0F0F0', borderRadius: 4 }} />
                <div style={{ height: 10, width: '90%', marginTop: 5, backgroundColor: '#F0F0F0', borderRadius: 4 }} />
                <div className='list-separator' />
            </section>
        );
    }
}