import React from 'react';
import Cookies from 'universal-cookie';
import getAccountInfo from '../services/getAccountInfo';
import translateString from '../utils/Locales';
import { AiOutlineLogout } from 'react-icons/ai';

import './style.css';
import logout from '../services/logout';
import User from '../images/user.png';
import { BiUserCircle } from 'react-icons/bi';
import isFreePeriodActive from '../services/isFreePeriodActive';
import FattureProgress from './FattureProgress';

const cookies = new Cookies();
let t = translateString;

const MenuItem = (props) => {

    return(
        <section className='menuButton' key={props.keyProp} onClick={() => props.onPress && props.onPress()}>
            {props.icon}
            <p className='menuText'>{props.title}</p>
        </section>
    );
}

export default class Menu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true, 
        };
    }

    async componentDidMount() {

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        this.account = await getAccountInfo(username, token, this.props.paziente ? "paziente" : "psicologo");
        if (!this.account) return;

        if (!this.props.paziente) {
            let provaGratuita = await isFreePeriodActive(username, token);
            if (provaGratuita.result === 'KO') return;

            this.setState({ isLoading: false, isProvaGratuita: provaGratuita.is_prova_gratuita === 1, textProvaGratuita: provaGratuita.message });
            return;
        }

        this.setState({ isLoading: false });
    }

    render() {

        if (this.state.isLoading) return <div />;

        return(
            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100vh', zIndex: 999999999999999 }} onClick={() => this.props.onClose && this.props.onClose()}>
                <div style={menuView} onClick={(e) => e.stopPropagation()}>
                    <div>
                        <section style={account}>
                            <img src={ this.props.picture || User } style={this.props.picture ? { width: 80, height: 80, objectFit: 'cover', borderRadius: 40, marginTop: 10 } : { width: 80, height: 80, marginTop: 10 }} alt="Account" /> 
                            <h5 style={{ color: 'white', marginTop: 10, width: '90%', textAlign: 'center' }}>{this.account.nome} {this.account.cognome}</h5>
                        </section>
                        { this.state.isProvaGratuita ? (
                            <>
                                <p style={{ fontSize: 14, color: '#3B3B3B', textAlign: 'center', paddingLeft: 10, paddingRight: 10, marginTop: 20 }}>{this.state.textProvaGratuita}</p>
                                <div style={separator} />
                            </>
                        ) : (
                            <section style={{ height: 15 }} />
                        )}
                        {!this.props.paziente && <FattureProgress onExit={() => this.props.onClose && this.props.onClose()} />}
                        <div style={separator} />
                        <MenuItem keyProp={1} icon={<BiUserCircle style={{color:"#636465", width: 20, height: 20 }} />} title={'Profilo'} onPress={() => {
                            window.location.href = this.props.paziente ? '/#/paziente/impostazioni' : '/#/app/account';
                            if (this.props.onClose) this.props.onClose();
                        }} />
                        <div style={separator} />
                        <MenuItem keyProp={2} icon={<AiOutlineLogout style={{color:"#636465", width: 20, height: 20 }} />} title={t('logout')} onPress={async () => {
                            
                            // Logout
                            let username = cookies.get('username', { path: '/' });
                            let token = cookies.get('token', { path: '/' });
                            
                            await logout(username, token);
                            
                            cookies.remove('username');
                            cookies.remove('token');

                            window.location.href = '/#/main/login';
                            return;
                        }} />
                    </div>
                </div>
            </div>
        );
    }
}

const menuView = {
    backgroundColor: 'white',
    borderRadius: 5,
    width: '300px', 
    position: 'absolute',
    top: 60, 
    right: 10,
    boxShadow: '0px 2px 10px #313131',
    overflowX: 'hidden', 
    overflowY: 'scroll',
} 

const account = {
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#199289',
    padding: 20,
    width: '100%',
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
}

const separator = {
    height: '1px',
    backgroundColor: 'lightgrey',
    widht: '100%',
}