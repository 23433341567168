import Constants from '../utils/consts';

export default async function addEvento(username, token, evento) {
    return new Promise(function (resolve, reject) {

        fetch(Constants.defaultProtocol + Constants.mainUrl + 'addEvento.php', {
            method: 'POST', 
            mode: 'cors', 
            body: JSON.stringify({
                'titolo': evento.titolo,
                'luogo': evento.luogo,
                'inizio': evento.start, 
                'fine': evento.end, 
                'id_cliente': evento.id_cliente, 
                'note': evento.note, 
                'durata': evento.durata, 
                'start_link': evento.start_link, 
                'join_link': evento.join_link, 
                'zoom_id': evento.zoom_id, 
                'online': evento.isOnline
            }), 
            headers: { 
                'Authorization': 'Bearer ' + username + '.' + token, 
                'Content-Type': 'application/json'
            }
        })
        .then((res) => res.json())
        .then((data) => {
            resolve(data);
        })
        
    });
}
