import React from 'react';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { AiOutlineMenu } from 'react-icons/ai';
import translateString from '../../utils/Locales';
import './style.css';
import Cookies from 'universal-cookie';
import logout from '../../services/logout';

let t = translateString;
const cookies = new Cookies();

export default class NavBarAdmin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isClicked: 0,
        };
    }

    async logout() {
        // Logout
        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });

        await logout(username, token);

        cookies.remove('username');
        cookies.remove('token');

        window.location.href = '/#/main/login';
        return;
    }

    async componentDidMount() {

        this.setState({ isLoading: false, messaggiNonLetti: 0 });
    }

    handleActiveMenu() {
        this.props.onMenuChange();
    }

    render() {

        if (this.state.isLoading) return <div/>;
        
        return(
            <div className='flex justify-between p-2 md:ml-6 md:mr-6 relative' style={{ minWidth: this.props.menu ? 'calc(100vw - 380px' : 'calc(100vw - 92px)' }}>
                <NavButton title="Menu" customFunc={this.handleActiveMenu.bind(this)} color={'white'} icon={<AiOutlineMenu />} />
                <div className="flex">
                    <img alt="" src={require('../../images/logout.png')} style={{ width: 20, height: 20, marginTop: 15, cursor: 'pointer' }} onClick={() => this.logout()} />
                </div>
            </div>
        );
    }
}

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
    <TooltipComponent content={title} position="BottomCenter">
      <button
        type="button"
        onClick={() => customFunc()}
        style={{ color }}
        className="relative text-xl rounded-full p-3 hover:bg-light-gray"
      >
        <span
          style={{ background: dotColor, marginTop: 7, marginRight: 8 }}
          className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
        />
        {icon}
      </button>
    </TooltipComponent>
  );
