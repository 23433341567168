import React, { Component } from "react";
import moment from "moment";
import DatePicker from 'react-datepicker';
import { BsFillCalendarEventFill } from "react-icons/bs";

const ExampleCustomInput = ({ value, onClick, placeholder }) => {
    
    console.log(value);
    let firstDate = moment(value, 'DD/MM/yyyy').startOf('week').format('DD/MM/yyyy');
    let endDate = moment(value, 'DD/MM/yyyy').endOf('week').format('DD/MM/yyyy');

    return(
        <div style={{
            width: '300px',
            height: '50px', 
            marginTop: 30,
            borderBottom: '1px solid lightgrey',
            paddingLeft: '10px',
            display: 'flex',
            flexDirection: 'row', 
            justifyContent: 'flex-start', 
            alignItems: 'center',
            borderTop: 0,
            borderLeft: 0,
            borderRight: 0
        }} onClick={onClick}>
            <BsFillCalendarEventFill size={20} />
            <p style={{ marginLeft: 15, marginTop: 20 }}>{value ? 'Dal ' + firstDate + ' al ' + endDate : placeholder}</p>
        </div>
    );
};

export default class WeekPicker extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      selected: props.selected,  
    };
  }

  isSameISOWeek(date1, selectedDate) {
    return moment(date1).isoWeek() === moment(selectedDate).isoWeek()
  }

  render() {
   
    return (
        <DatePicker
            dayClassName={(date) => 
                this.isSameISOWeek(date, this.state.selected) ? "react-datepicker__day--selected" : ""
            }
            style={{ width: '80%' }}
            selected={this.state.selected}
            onChange={(date) => {
                this.setState({ selected: date })
                if (this.props.onSelect) this.props.onSelect(moment(date).startOf('week').format('DD/MM/yyyy'), moment(date).endOf('week').format('DD/MM/yyyy'));
            }}
            locale="it"
            disabledKeyboardNavigation
            customInput={<ExampleCustomInput placeholder={this.props.placeholder} />}
            dateFormat="dd/MM/yyyy"
        />
    );
  }
}

