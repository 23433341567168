import React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Resize, Sort, Search, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';
import { L10n } from '@syncfusion/ej2-base';
import Constants, { getServiceName } from '../utils/consts';
import Loading from '../components/Loading';
import getPazienti from '../services/getPazienti';
import translateString from '../utils/Locales';
import Cookies from 'universal-cookie';
import { pazientiTable } from '../utils/tables';
import Button from '../components/Button';
import SearchInput from '../components/Search';

L10n.load({
    'it_IT': {
        'grid': {
            'Item': 'Paziente', 
            'Items': 'Pazienti', 
            'CancelButton': 'Annulla',
            'Pdfexport': 'Esporta in PDF', 
            'Excelexport': 'Esporta Excel',
            'Csvexport': 'Esporta in CSV', 
            'ConfirmDelete': 'Sei davvero sicuro di voler eliminare questo paziente?',
            'Export': 'Esporta',
            'SortAscending': 'Ordine crescente', 
            'SortDescending': 'Ordine decrescente', 
            'EmptyRecord': 'Nessun paziente da mostrare',
        }, 
        'pager': {
            'currentPageInfo': 'Pagina {0} di {1}', 
            'totalItemsInfo': '({0} pazienti)',
        }
    }
})

let t = translateString;
const cookies = new Cookies();

export default class Pazienti extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,  
            data: undefined,
        };
    }

    async componentDidMount() {

        window.document.title = getServiceName() + ' - Pazienti';

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });

        let pazienti = await getPazienti(username, token);
        
        let data = [];
        pazienti.forEach((paziente) => {
            data.push({
                id: paziente.id, 
                nome: paziente.nome, 
                cognome: paziente.cognome, 
                dataNascita: paziente.data_nascita,
                foto: paziente.foto,
            })
        });

        this.pazienti = data;

        this.setState({ data, isLoading: false });
    }

    handleRowClick(event) {
        let paziente = event.rowData;

        cookies.set('currentPaziente', paziente.id, { path: '/' });
        window.location.href = '/#/app/visualizza/paziente';
    }

    cerca(testo) {

        if (!testo.trim()) {

            this.setState({ data: this.pazienti });
            return;
        }

        var data = [];
        this.state.data.forEach((paziente) => {
            
            if ((paziente.nome + ' ' + paziente.cognome).toLowerCase().includes(testo.toLowerCase().trim()) || (paziente.cognome + ' ' + paziente.nome).toLowerCase().includes(testo.toLowerCase().trim()))
                data.push(paziente);
        });

        this.setState({ data });
    }

    render() {

        if (this.state.isLoading) return <Loading />;

        const options = { allowDeleting: true };
        const pagingOptions = { pageSize: 25 };

        return(
            <>
                <div className="top-with-btn" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 30, paddingBottom: 20 }}>
                    <div style={{ marginRight: 20, marginTop: 10 }}>
                        <h3 style={{textShadow:"1px 0px 6px #000000", color: 'white' }}>{t('tuoi_pazienti')}</h3>
                    </div>
                    <Button ref={this.saveRef} button={t('nuovo').toUpperCase()} animated={false} onClick={() => window.location.href = '/#/app/aggiungi/paziente'} />
                </div>
                <SearchInput onCerca={(testo) => {
                    this.cerca(testo);
                }} />
                <GridComponent
                    id="pazienti_table"
                    dataSource={this.state.data}
                    locale={'it_IT'}
                    allowPaging
                    allowSorting
                    pageSettings={pagingOptions}
                    recordClick={(e) => this.handleRowClick(e)}
                    contextMenuItems={Constants.contextMenuItems}
                    editSettings={options}
                >
                    <ColumnsDirective>
                        {pazientiTable.map((item, index) => <ColumnDirective key={index} {...item} />)}
                    </ColumnsDirective>
                    <Inject services={[Resize, Search, Sort, Filter, Page, Edit]} />
                </GridComponent>
            </>
        );
    }
}