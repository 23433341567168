import React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Resize, Sort, Search, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';
import { L10n } from '@syncfusion/ej2-base';
import Constants, { getServiceName } from '../utils/consts';
import Loading from '../components/Loading';
import translateString from '../utils/Locales';
import Cookies from 'universal-cookie';
import { utentiTable } from '../utils/tables';
import SearchInput from '../components/Search';
import getUtentiAdmin from '../services/getUtentiAdmin';
import getAbbonamenti from '../services/getAbbonamenti';

L10n.load({
    'it_IT': {
        'grid': {
            'Item': 'Utente', 
            'Items': 'Utenti', 
            'CancelButton': 'Annulla',
            'Pdfexport': 'Esporta in PDF', 
            'Excelexport': 'Esporta Excel',
            'Csvexport': 'Esporta in CSV', 
            'ConfirmDelete': 'Sei davvero sicuro di voler eliminare questo cliente?',
            'Export': 'Esporta',
            'SortAscending': 'Ordine crescente', 
            'SortDescending': 'Ordine decrescente', 
            'EmptyRecord': 'Nessun cliente da mostrare',
        }, 
        'pager': {
            'currentPageInfo': 'Pagina {0} di {1}', 
            'totalItemsInfo': '({0} utenti)',
        }
    }
})

let t = translateString;
const cookies = new Cookies();

export default class UtentiAdmin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,  
            data: undefined,
        };
    }

    async componentDidMount() {

        window.document.title = getServiceName() + ' - Utenti';

        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });

        let utenti = await getUtentiAdmin(username, token);
        this.utenti = utenti;

        let accounts = await getAbbonamenti(username, token);

        utenti.forEach((u, ind) => {
            this.utenti[ind].tipo_account = accounts.find(a => a.id === u.tipo_account).nome;
        })

        this.setState({ data: this.utenti, isLoading: false });
    }

    handleRowClick(event) {
        
    }

    cerca(testo) {

        if (!testo.trim()) {

            this.setState({ data: this.utenti });
            return;
        }

        var data = [...this.state.data].filter((cliente) => {
            
            if (cliente.nome.toLowerCase().includes(testo.toLowerCase().trim())) return true;
            if (cliente.cognome.toLowerCase().includes(testo.toLowerCase().trim())) return true;
            if (cliente.piva.toLowerCase().includes(testo.toLowerCase().trim())) return true;
            if (cliente.tipo_account.toLowerCase().includes(testo.toLowerCase().trim())) return true;
            if (cliente.mail.toLowerCase().includes(testo.toLowerCase().trim())) return true;

            return false;
        });

        this.setState({ data });
    }

    render() {

        if (this.state.isLoading) return <Loading />;

        const options = { allowDeleting: true };
        const pagingOptions = { pageSize: 25 };

        return(
            <>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 30 }}>
                    <div style={{ marginRight: 20 }}>
                        <h3 style={{ color: 'white' }}>I professionisti registrati</h3>
                    </div>
                </div>
                <SearchInput onCerca={(testo) => {
                    this.cerca(testo);
                }} />
                <GridComponent
                    id="clienti_table"
                    dataSource={this.state.data}
                    locale={'it_IT'}
                    allowPaging
                    allowSorting
                    allowExcelExport
                    allowPdfExport
                    pageSettings={pagingOptions}
                    width={'100%'}
                    recordClick={(e) => this.handleRowClick(e)}
                    contextMenuItems={Constants.contextMenuItems}
                    editSettings={options}
                    allowResizing
                >
                    <ColumnsDirective>
                        {utentiTable.map((item, index) => <ColumnDirective style={{ backgroundColor: '#212121', minWidth: 50, maxWidth: 200 }} key={index} {...item} />)}
                    </ColumnsDirective>
                    <Inject services={[Resize, Search, Sort, Filter, Page]} />
                </GridComponent>
            </>
        );
    }
}