import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import translateString from '../utils/Locales';

import './style.css';

import ChatImg from '../images/notification.png';
import NoMessage from '../images/no_message.png';
import getNotifiche from '../services/getNotifiche';
import deleteNotifica from '../services/deleteNotifica';

const cookies = new Cookies();
let t = translateString;

const NotificationItem = (props) => {

    const [notifica, setNotifica] = useState(props.notifica);
    const [hover, setHover] = useState(false);

    const deleteNot = async () => {
        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });
        await deleteNotifica(username, token, notifica.id);
        if (props.onReload) props.onReload();
    }

    return(
        <section className='notificationButton'>
            <p className='notificationText'>{notifica.testo}</p>
            <img alt="" src={hover ? require('../images/tick.png') : require('../images/tick_grey.png')} style={{ height: 20, width: 20, marginTop: -10, cursor: 'pointer' }} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={() => deleteNot()} />
        </section>
    );
}

export default class Notifications extends React.Component {
    constructor(props) {
        super(props);

        this.bottomRef = React.createRef();

        this.state = {
            isLoading: true, 
            messaggi: [],
        };
    }

    async reload() {
        this.setState({ notifiche: [] })
        let username = cookies.get('username', { path: '/' });
        let token = cookies.get('token', { path: '/' });

        let notifiche = await getNotifiche(username, token);
        this.setState({ isLoading: false, notifiche });
    }

    async componentDidMount() {
        await this.reload();
    }

    render() {

        if (this.state.isLoading) return <div />;

        return(
            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100vh' }} onClick={() => this.props.onClose && this.props.onClose()}>
                <div style={menuView} onClick={(e) => e.stopPropagation()}>
                    <div>
                        <section style={account}>
                            <img src={ ChatImg } style={{ width: 40, height: 40 }} alt="Notification image" />
                            <h5 style={{ color: 'white', marginTop: 10, width: '90%', textAlign: 'center' }}>Le tue notifiche</h5>
                        </section>
                        { this.state.notifiche.length === 0 && (
                            <section style={no_messages}>
                                <img src={ NoMessage } style={{ width: 40, height: 40, opacity: 0.5 }} />
                                <p>Nessuna notifica</p>
                            </section>
                        )}
                        { this.state.notifiche.length > 0 && (
                            <section style={messageView}>
                                {this.state.notifiche.map((msg, ind) => {

                                    return(
                                        <div key={ind}>
                                            <NotificationItem notifica={msg} onSend={() => this.props.onClose()} onReload={() => setTimeout(() => this.reload(), 500)} />
                                            { ind !== this.state.notifiche.length - 1 && <div style={separator} /> }
                                        </div>
                                    );
                                })}
                                
                            </section>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const menuView = {
    backgroundColor: 'white',
    width: '300px', 
    maxHeight: '600px',
    position: 'absolute',
    top: 60, 
    right: 50,
    boxShadow: '0px 2px 10px #313131',
    overflowX: 'hidden', 
    overflowY: 'hidden',
    padding: 5,
};

const account = {
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#636465',
    padding: 20,
    width: '100%',
};

const separator = {
    height: '1px',
    backgroundColor: '#F0F0F0',
    widht: '90%',
};

const no_messages = {
    padding: 20, 
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center', 
    justifyContent: 'center', 
    color: '#BBBBBB', 
}; 

const messageView = {
    maxHeight: 300, 
    overflowX: 'hidden', 
    overflowY: 'scroll', 
}